import { instance2 } from '../helper/api'

export const get = async param => {
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
  }
  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/customer/cart/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

// export const getDetail = async (param) => {

//     let resultData = {}
//     const axiosConfig={
//         url: "/shopi/v1/customer/address/detail"
//         , method: "GET"
//         , timeout: 40000
//         , responseType: "json"
//         , responseEncoding: "utf8"
//         , headers: {
//             "Content-Type": "application/json"
//             , "Authorization": "Bearer "+token
//         }
//         , params: JSON.parse(param)
//     };

//     try{
//         const results = await instance2.request(axiosConfig);
//         resultData = results.data.data;
//     } catch (error) {
//         if (error.response) {
//             return error.response.data;
//         }else{
//             return error.message
//         }
//     }

//     return resultData
// };

export const create = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/batch/`,
    method: 'POST',
    data: data
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const update = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/${data.cart_id}/`,
    method: 'PATCH',
    data: data
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const updateQty = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/${data.cart_id}/quantity/`,
    method: 'PATCH',
    data: data
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const updateQtyRekap = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/${data.cart_id}/checked-out/quantity/`,
    method: 'PATCH',
    data: data
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const updateNote = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/${data.cart_id}/note/`,
    method: 'PATCH',
    data: data
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

// export const getOneDetail = async (addressID) => {

//     let resultData = {}
//     const axiosConfig={
//         url: `/shopi/v1/customer/address/${addressID}/detail`
//         , method: "GET"
//         , timeout: 40000
//         , responseType: "json"
//         , responseEncoding: "utf8"
//         , headers: {
//             "Content-Type": "application/json"
//             , "Authorization": "Bearer "+token
//         }
//     };

//     try{
//         const results = await instance2.request(axiosConfig);
//         resultData = results.data.data;
//     } catch (error) {
//         if (error.response) {
//             return error.response.data;
//         }else{
//             return error.message
//         }
//     }

//     return resultData
// };

export const deleteData = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/${data.cart_id}/`,
    method: 'DELETE'
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const deleteDatas = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/`,
    method: 'DELETE',
    data: data.cart_id
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const updateChecked = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/${data.cart_id}/checked-out/`,
    method: 'PATCH',
    data: {
      checked_out: data.checked_out
    }
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const updateCheckedAll = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/cart-item/checked-out/`,
    method: 'PATCH',
    data: data
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
