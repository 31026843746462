import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  body: {
    backgroundColor: '#f5f5f5',

    '& h1, h2, h3, h4, h5, h6, p': {
      marginBottom: '0'
    }
  },

  root_MyAccount: {
    padding: '0 18px',
    paddingTop: '60px',
    paddingBottom: '30px'
  },

  pageTitle: {
    fontSize: '16px',
    color: '#ff3a59',
    marginTop: '20px',
    fontWeight: '600',
    marginBottom: '20px !important'
  },

  content_container: {
    display: 'flex',
    gap: '20px'
  },

  left_Sidebar: {
    width: '20%',
    padding: '1.5rem!important'
  },
  right_Sidebar: {
    width: '80%',

    '& .MuiBox-root': {
      padding: '20px'
    }
  },

  card_style_myOrder: {
    boxShadow: 'none',
    background: 'transparent',

    '& .MuiBox-root': {
      padding: '0px'
    }
  },

  leftTabs_style: {
    display: 'flex',
    gap: '20px'
  },

  Leftmenu: {
    '& .menuItem': {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
      // marginBottom: '20px',
      cursor: 'pointer',

      '& svg': {
        margin: '0 !important',
        fontSize: '20px'
      },
      // "& > :hover": {
      //   color: "#ff3a59 !important",
      // },
      '& .MuiSvgIcon-root': {
        color: 'transparent',
        stroke: 'white'
      }
    },
    '& .menuItem:hover .menuTitle': {
      color: '#ff3a59 !important'
    },
    '& .menuItem:hover svg': {
      stroke: '#ff3a59'
    },

    '& .menuTitle': {
      fontSize: '13px',
      fontWeight: '600'
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .Mui-selected .menuItem': {
      color: '#ff3a59 !important'
    },
    '& .Mui-selected .menuItem svg': {
      stroke: '#ff3a59'
    }
  },

  cardStyle_LeftSide: {
    // position:'sticky',
    color: '#fff',
    padding: '1.5rem !important',
    marginBottom: '15px',
    backgroundColor: '#545454',
    height: 'fit-content',
    top: '170px',

    '& header': {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '40px',

      '& h5': {
        fontSize: '16px',
        color: '#ff3a59',
        marginBottom: '0px',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif'
      }
    },

    '& .MuiTab-wrapper': {
      alignItems: 'flex-start'
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      paddingLeft: '0',
      paddingRight: '0'
    }
  },

  user_profile_name: {
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },

    '& .user_image_fix': {
      position: 'relative',
      width: '54px',
      height: '54px',
      maxHeight: '54px',
      borderRadius: '100%',
      backgroundColor: 'lightgray'
    },
    '& .profileName': {
      fontSize: '16px',
      fontWeight: '600',
      marginBottom: '5px',
      color: '#d4d4d4'
    }
  }
})

export { useStyles }
