import React, { useState, useEffect, useContext } from 'react'
import Header from '../header/header'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Countdown, { zeroPad } from 'react-countdown'
// import { useDispatch } from "react-redux";
import moment from 'moment'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import Loader from '../loader'
import { get as getOrder } from '../../api/order'
import { getCurrentPaymentMethod, priceFormatLocal } from '../../utils/helper'
import { AuthContext } from '../../context/AuthContext'

const Completionist = () => <span>You are good to go!</span>

const renderer = ({ hours, minutes, seconds, days, completed }) => {
  if (completed) {
    return <Completionist />
  } else {
    return (
      <span>
        <span className='text-white count'>{zeroPad(days)}</span>
        <span className=' text-white count'>{zeroPad(hours)}</span>:
        <span className='text-white count '>{zeroPad(minutes)}</span>:
        <span className='text-white count'>{zeroPad(seconds)}</span>
      </span>
    )
  }
}

const SuccessOrder = ({ t }) => {
  const { refreshCart, refreshStatusCountOrder } = useContext(AuthContext)
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const history = useHistory()
  // const orderRes = MyOrderProductResponse();
  // const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem('userid'))
  const [myOrderData, setMyOrderData] = useState({})

  const orderdata = () => {
    let data = new FormData()
    data.append('user_id', userid)
    // dispatch(getSplashDataUser(data));
  }

  const [open, setOpen] = useState(true)
  useEffect(() => {
    // var data3 = new FormData();
    // data3.append('user_id', userid);
    // data3.append('type', 'UNPAID');
    // data3.append('page', 1);
    // dispatch(myOrderProductUser(data3));
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    // setTimeout(() => (setOpen(true)), 1000)
    const getOrderData = async () => {
      try {
        const orderID = localStorage.getItem('order_id')
        const response = await getOrder(
          `{"limit":1, "page":1, "customer_id":${userid}, "id": ${orderID} }`
        )

        if (response.success) {
          setMyOrderData(response.data[0])
        }
      } catch (error) {
        console.log('Error fetching getOrderData:', error)
      }
    }

    getOrderData()
    refreshCart()
    refreshStatusCountOrder()
  }, [])
  return (
    <div>
      {open ? (
        <div>
          <Header title={t('successfull_order')} color={ThemeColor} />
          <div
            style={{
              paddingTop: '46px',
              paddingLeft: '10px',
              paddingRight: '10px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                textAlign: 'center',
                marginTop: '16px',
                fontSize: '14px'
              }}
            >
              {t('you_have_successfully_placed_an_order')}&nbsp;
              {/* {moment(myOrderData
                                    ? moment(myOrderData  && myOrderData.order ? myOrderData.order.created_at : "").add(1, "years").calendar()
                                    : ""
                                ).format("DD-MM-YYYY")} */}
              {moment
                .utc(myOrderData && myOrderData.order ? myOrderData.order.created_at : '')
                .format('DD-MM-YYYY HH:mm:ss')}
              &nbsp;
              {t('with')} {myOrderData && getCurrentPaymentMethod(myOrderData.order)}{' '}
              {t('otherwise_cancelled_auto')}
            </div>
            <div style={{ marginTop: '8px' }}>
              <div style={{ padding: '1px', fontSize: '15px' }}>
                {t('order_time')}:{' '}
                {moment
                  .utc(myOrderData && myOrderData.order ? myOrderData.order.created_at : '')
                  .format('DD-MM-YYYY HH:mm:ss')}
              </div>
            </div>
            <div style={{ marginTop: '8px' }}>
              {myOrderData && myOrderData.order ? (
                <div style={{ padding: '1px' }}>
                  <span style={{ fontSize: '13px' }}>{t('auto_cancel')}</span>:{' '}
                  <Countdown
                    date={moment.utc(myOrderData.order.expired_at).format('YYYY-MM-DD HH:mm:ss')}
                    renderer={renderer}
                    zeroPadTime={2}
                    zeroPadDays={3}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            {myOrderData && myOrderData.order ? (
              myOrderData.order.payment_method === 'COD' ? (
                <div style={{ marginTop: '15px' }}>
                  <div style={{ padding: '1px', fontSize: '13px' }}>
                    Total :<b>{priceFormatLocal(myOrderData.order.grand_total)}</b>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            {myOrderData.order ? (
              myOrderData.order.payment_method === 'BANK TRANSFER' ? (
                <div>
                  <div style={{ marginTop: '15px' }}>
                    <div
                      style={{
                        color: 'grey',
                        padding: '1px',
                        fontSize: '13px'
                      }}
                    >
                      {t('transfer_total')}
                    </div>
                    <div
                      style={{
                        color: ThemeColor ? ThemeColor : '#FFFFFF',
                        padding: '1px',
                        fontSize: '13px'
                      }}
                    >
                      {/* Rp. 422.<u style={{color:"#E70048"}}>078</u> */}
                      <b>
                        <span>
                          Rp.{' '}
                          <u
                            style={{
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }}
                          >
                            {priceFormatLocal(myOrderData.order.grand_total).replaceAll('Rp', '')}
                          </u>
                        </span>
                      </b>
                    </div>
                  </div>
                  <div
                    style={{
                      color: 'red',
                      border: `1px solid red`,
                      borderRadius: '6px',
                      backgroundColor: 'gold',
                      marginTop: '6px',
                      paddingTop: '4px',
                      paddingBottom: '4px',
                      paddingLeft: '3px',
                      paddingRight: '0px',
                      fontSize: '14px'
                    }}
                  >
                    {t('please_make_sure_last_3_digits')}
                  </div>
                  <div style={{ marginTop: '9px' }}>
                    <div style={{ color: 'grey', fontSize: '13px' }}>{t('account_number')}</div>
                    <div style={{ fontWeight: '500', fontSize: '15px' }}>
                      {myOrderData.order.payment_method_detail.account_number}
                    </div>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ color: 'grey', fontSize: '13px' }}>{t('account_name')}</div>
                    <div style={{ fontWeight: '500', fontSize: '15px' }}>
                      {myOrderData.order.payment_method_detail.account_number}
                    </div>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div style={{ color: 'grey' }}>{t('account_bank')}</div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '3px'
                      }}
                    >
                      <div
                        style={{
                          fontWeight: '500',
                          fontSize: '15px'
                        }}
                      >
                        {myOrderData.order.payment_method_detail.bank_name}
                      </div>
                      <div style={{}}>
                        <img
                          src={
                            myOrderData.order.payment_method_detail.bank_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                '/' +
                                myOrderData.order.payment_method_detail.bank_image
                              : '/assets/images/dummy.png'
                          }
                          height='36px'
                          width='50px'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )
            ) : (
              ''
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: '20px',
                marginRight: '20px',
                marginTop: '10px'
              }}
            >
              {myOrderData && myOrderData.order ? (
                myOrderData.order.payment_method === 'BANK TRANSFER' &&
                myOrderData.order.is_moota === false ? (
                  <div
                    style={{
                      marginBottom: '10px',
                      width: '100%'
                    }}
                  >
                    <Button
                      onClick={() => {
                        localStorage.setItem('ordersdata', JSON.stringify(myOrderData))
                        history.push('/paymentconfirm')
                      }}
                      style={{
                        textAlign: 'center',
                        fontWeight: 600,
                        backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF',
                        borderRadius: '6px',
                        color: 'white',
                        width: '100%',
                        fontSize: '15px'
                      }}
                    >
                      {t('payment_confirm')}
                    </Button>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <div
                style={{
                  width: '100%'
                }}
              >
                <Button
                  onClick={() => {
                    orderdata()
                    history.push('/orderhistory')
                  }}
                  style={{
                    textAlign: 'center',
                    fontWeight: 600,
                    backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF',
                    borderRadius: '6px',
                    color: 'white',
                    width: '100%',
                    fontSize: '15px'
                  }}
                >
                  {t('history_order1')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: 'white'
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
    </div>
  )
}

export default withNamespaces()(SuccessOrder)
