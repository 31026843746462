import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { useStore } from 'hooks/useStore'
import React, { useState } from 'react'
import i18n from 'i18next'

const defaultValues = {
  message: null,
  setMessage: () => Promise.resolve()
}

export const ModalMessageContext = React.createContext(defaultValues)

export const ModalMessageProvider = props => {
  const t = i18n.t.bind(i18n)
  const { storeTitle, themeColor } = useStore()
  console.log('storeTitle', storeTitle)

  const sitetitle = storeTitle || process.env.REACT_APP_NICK_SITE_TITLE

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)
  const [onConfirm, setOnConfirm] = useState(null)
  const [confirmText, setConfirmText] = useState(null)

  const handleSetMessage = (message, onConfirm, confirmText) => {
    setOpen(true)
    setMessage(message)

    setOnConfirm(() => onConfirm)
    setConfirmText(confirmText)
  }

  const handleclose = () => {
    setOpen(false)
    setTimeout(() => {
      setOnConfirm(null)
      setConfirmText(null)
      setMessage(null)
    }, 200)
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
    handleclose()
  }

  return (
    <ModalMessageContext.Provider value={{ message, setMessage: handleSetMessage }}>
      {props.children}
      <div>
        <Dialog
          open={open}
          onClick={handleclose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          style={{ zIndex: 99999 }}
        >
          <DialogTitle id='alert-dialog-title' style={{ paddingBottom: '0px' }}>
            <b>{sitetitle}</b>
          </DialogTitle>
          <DialogContent
            id='alert-dialog-description'
            style={{
              color: 'black',
              fontWeight: '500'
            }}
          >
            <div>{message}</div>
          </DialogContent>
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              marginRight: '10px',
              display: 'flex',
              justifyContent: 'end'
            }}
          >
            {onConfirm ? (
              <>
                <Button onClick={handleclose} style={{ color: themeColor }}>
                  {t('no')}
                </Button>
                <Button onClick={handleConfirm} style={{ color: themeColor }}>
                  {confirmText || t('yes')}
                </Button>
              </>
            ) : (
              <Button onClick={handleclose} style={{ color: themeColor }}>
                Tutup
              </Button>
            )}
          </div>
        </Dialog>
      </div>
    </ModalMessageContext.Provider>
  )
}
