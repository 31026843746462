import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { withNamespaces } from 'react-i18next'
import { formatDateTime, orderGetPaymentMethod } from '../../utils/helper'

const Completionist = () => <span>You are good to go!</span>

const renderer = ({ hours, minutes, seconds, days, completed }) => {
  if (completed) {
    return <Completionist />
  } else {
    return (
      <span>
        <span className='text-white count' style={{ marginRight: '8px' }}>
          {zeroPad(days)}
        </span>
        <span className=' text-white count'>{zeroPad(hours)}</span>:
        <span className='text-white count '>{zeroPad(minutes)}</span>:
        <span className='text-white count'>{zeroPad(seconds)}</span>
      </span>
    )
  }
}

const TableDetailOrder = ({ t, order, showExpired = false }) => {
  const useStyles = makeStyles(theme => ({
    orderBox: {
      border: '0.125rem solid #fff',
      minHeight: '6.25rem',
      backgroundColor: '#fff',
      borderRadius: '4px',
      fontWeight: '400',
      margin: '10px !important',
      '& h5': {
        fontSize: '14px',
        margin: '0.25rem 0'
      }
    },
    orderTable: {
      width: '100%',
      fontSize: '14px',
      '& tr td:nth-child(1)': {
        width: '45%',
        paddingLeft: '0.5rem'
      },
      '& tr:nth-child(1)': {
        color: '#000'
        // color: 'rgba(58, 120, 58, 1)'
      },
      '& td': {
        fontWeight: '500'
      },
      '& tr td:nth-child(3)': {
        paddingLeft: '0.5rem'
      },
      '& tr': {
        height: '25px'
      }
    },
    itemSpace: {
      '& span': {
        // marginLeft: "0.5rem",
      }
    },
    myDiv: {
      background: 'white'
    },
    myButton: {
      backgroundColor: 'white'
    }
  }))

  const classes = useStyles()

  return (
    <table className={classes.orderTable}>
      <tbody>
        <tr>
          <td>{t('no_invoice')}</td>
          <td>:</td>
          <td>{order.order.order_number !== '' && order.order.order_number}</td>
        </tr>
        <tr>
          <td>{t('receiver_name')}</td>
          <td>:</td>
          <td>
            <div
              style={{
                width: '100%',
                height: '22px',
                overflow: 'hidden',
                display: 'flex',
                flexShrink: '0',
                fontSize: '14px'
              }}
            >
              {order.order.is_pay_on_spot
                ? order.order.customer.name
                : order.order.customer_address.name}
            </div>
          </td>
        </tr>
        <tr>
          <td>{t('date')}</td>
          <td>:</td>
          <td>{formatDateTime(order.order.created_at)}</td>
        </tr>
        <tr>
          <td>{t('shipping')}</td>
          <td>:</td>
          <td>{order.order.shipping.name}</td>
        </tr>
        <tr>
          <td>{t('payment')}</td>
          <td>:</td>
          <td>{orderGetPaymentMethod(order.order)}</td>
        </tr>
        <tr>
          <td>{t('status')}</td>
          <td>:</td>
          <td className='text-uppercase status'>{order.order.order_status}</td>
        </tr>
        {showExpired && (
          <tr className=''>
            <td>{t('auto_cancel')}</td>
            <td>:</td>
            <td>
              <div className='my-1'>
                <Countdown
                  date={moment.utc(order.order.expired_at).format('YYYY-MM-DD HH:mm:ss')}
                  renderer={renderer}
                  zeroPadTime={2}
                  zeroPadDays={3}
                />
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default withNamespaces()(TableDetailOrder)
