import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import CardProduct from '../../components/card-product/CardProduct'
import NavigationTab from '../home/bottomnav/NavigationTab'

import { getListLastViewProduct } from '../../api/products'

const Viewed = ({ t }) => {
  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor,
        color: '#000'
      }
    }
  })

  const history = useHistory()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const Usercode = JSON.parse(localStorage.getItem('usercode'))

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'instant' })
  }, [])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))

    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [Usercode])

  // eslint-disable-next-line no-unused-vars
  const [visible, setVisible] = useState(false)
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 120) {
      setVisible(true)
    } else if (scrolled <= 120) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 100
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  window.addEventListener('scroll', toggleVisible)

  const [productsLastView, setProductsLastView] = useState([])

  const getAllProductsLastView = async () => {
    const params = {
      page: 1,
      limit: 18
    }
    const response = await getListLastViewProduct(JSON.stringify(params))
    return response
  }

  useEffect(() => {
    const fetchingData = async () => {
      const res = await getAllProductsLastView()
      setProductsLastView(res.data || [])
    }

    fetchingData()
  }, [])

  const [isNext, isNextFunc] = React.useState(false)
  const [pageCount, setCount] = React.useState(1)
  const [page, setPage] = React.useState('')

  const loadMoreData = () => {
    console.log('load more')
    setTimeout(async () => {
      const nextPage = pageCount + 1
      setCount(nextPage)
    }, 700)
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
            backgroundColor: themeColor,
            position: 'fixed',
            zIndex: '11111',
            marginBottom: '40px'
          }}
        >
          <div className='title-box' style={{ height: '48px', maxWidth: '100%' }}>
            <KeyboardBackspaceIcon
              style={{ color: 'white', width: '25px' }}
              onClick={() => {
                history.push('/setting')
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <h1 className='title mb-0'>{t('viewed')}</h1>
            </div>
          </div>
        </div>
        {/* product list */}
        <div className='bg-white' style={{ paddingTop: '50px', boxShadow: 'none' }}>
          <div
            className='container'
            style={{
              paddingLeft: '0px',
              paddingRight: '0px',
              boxShadow: 'none',
              marginTop: '4px'
            }}
          >
            {productsLastView.length > 0 ? (
              <InfiniteScroll
                style={{
                  backgroundColor: '#f5f5f5',
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  marginLeft: '0px',
                  marginRight: '0px',
                  marginTop: '0px',
                  paddingLeft: '0px',
                  paddingRight: '5px',
                  paddingBottom: Mobile ? '0px' : '50px',
                  marginBottom: productsLastView.length >= 6 ? '60px' : '0'
                }}
                dataLength={productsLastView.length}
                next={loadMoreData}
                hasMore={isNext}
                loader={
                  <div style={{ height: '50%', overflow: 'hidden' }}>
                    {pageCount < page ? (
                      <div
                        style={{
                          color: themeColor,
                          textAlign: 'center',
                          marginLeft: '160px'
                        }}
                      >
                        Loading...
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                }
              >
                {productsLastView.map((product, index) => (
                  <div
                    style={{
                      width: Mobile ? '50%' : '28%',
                      paddingLeft: '5px',
                      marginBottom: '6px'
                    }}
                    key={index}
                  >
                    <CardProduct
                      membershipLevel={membershipLevel}
                      product={product}
                      handleClick={() => history.push(`/productdetail?id=${product.id}`)}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  paddingTop: '250px',
                  paddingBottom: '300px',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  height: '100vh'
                }}
              >
                <div>{t('empty_product')}</div>
              </div>
            )}
          </div>
        </div>
        <NavigationTab />
      </React.Fragment>
    </ThemeProvider>
  )
}

export default withNamespaces()(Viewed)
