import React, { useState, useEffect } from 'react'
import { makeStyles, styled } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
// import { useDispatch } from "react-redux";
// import download from "../../../../../../public/assets/frontend/img/download.png";
// import { getMyCartProductUser } from "../../store/actions/GetMyCartActions";
// import { addOrderProduct } from "../../store/actions/AddOrderActions";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import ClipLoader from 'react-spinners/ClipLoader'
// import { myOrderProductUser } from "../../store/actions/myOrderActions";
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import Loader from '../loader'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
// import { addNotes } from "../../store/actions/AddNoteActions";
import { getDetail as getAddressDetail } from '../../api/address'
import { updateNote, updateCheckedAll } from '../../api/cart'
import { create as submitCheckout, generateUniqueCode } from '../../api/checkout'
import { getSelfPickup } from 'api/courier'
import { getDiskonRupiah, priceFormatLocal } from '../../utils/helper'
import { getCOD } from '../../api/payment-method'
import { useSelector } from 'react-redux'
import { useStore } from '../../hooks/useStore'
import { isDiscountValid } from 'utils/pricing'

const makeFirstLetterCap = str => {
  return str
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

const OrderConfirmation = ({ t }) => {
  const { storeTitle, landingPage } = useStore()
  const { checkoutCartItem } = useSelector(state => state.cart)

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })

  const CssTextField = styled(TextField)({
    fontSize: '0.75rem',
    '& laborders.cart_item.Mui-focused': {
      color: ThemeColor ? ThemeColor : '#FFFFFF'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ThemeColor ? ThemeColor : '#FFFFFF'
    }
  })

  const useStyles = makeStyles(() => ({
    input: {
      '&::placeholder': {
        padding: '4px 40px 5px'
      }
    },
    icon: {
      margin: '9px 0px  0px auto !important'
    }
  }))
  const classes = useStyles()
  // const [data1, setData1] = useState([])
  const sitetitle = storeTitle || process.env.REACT_APP_NICK_SITE_TITLE
  // toggle
  const userid = JSON.parse(localStorage.getItem('userid'))
  const history = useHistory()
  // const dispatch = useDispatch();
  const payspot = JSON.parse(localStorage.getItem('pay_spot'))
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const errors = true
  const [message, setMessage] = useState('')
  const [id, setId] = useState('')
  const [finalArray, setFinalarray] = useState([])
  const [cartids, setCartids] = useState([])
  const [rupee, setRupee] = useState(0)

  const Usercode = JSON.parse(localStorage.getItem('usercode'))
  const [uniqueCode, setUniqueCode] = useState(localStorage.getItem('unique_code') || 0)
  const [quantity, setQuantity] = useState([])
  const [weights, setWeights] = useState([])
  const [rps, setRps] = useState([])
  const shippingdata = JSON.parse(localStorage.getItem('shipping'))
  const finalqty = quantity.reduce((sum, a) => sum + a, 0)
  const finalweight = cartids.reduce(
    (sum, a) => sum + a.product.weight * a.cart_item.checked_out_quantity,
    0
  )
  const finalrps = rps.reduce((sum, a) => sum + a, 0)
  const finaltotal = cartids.reduce(
    (sum, a) => sum + a.cart_item.price * a.cart_item.checked_out_quantity,
    0
  )
  const finaldiskon = cartids.reduce((sum, a) => sum + getDiskonRupiah(a), 0)
  const [bankdatas, setBankdatas] = useState(JSON.parse(localStorage.getItem('bankdata')))
  const cartdatas = JSON.parse(localStorage.getItem('cartids'))

  // localStorage.setItem("setT", JSON.stringify(finalrps));
  console.log('finalrps', finalrps)

  // const setShip = JSON.parse(localStorage.getItem("setT"));
  const [ppnTaxShippingAmount, setPpnTaxShippingAmount] = useState(0)
  const [fixTaxAmount, setFixTaxAmount] = useState(0)

  // pajak pengiriman calculate -> if active
  const calculatePPNBaseONWeightGram = data => {
    const totalWeighGram = cartdatas.reduce(
      (acc, val) => acc + val.product.weight * val.cart_item.checked_out_quantity,
      0
    )
    const parameterInit = data.shipping_tax_parameter_init
    const parameterMultiple = data.shipping_tax_parameter_multiple
    const parameterPriceInit = data.shipping_tax_by_weight
    const totalGrams = totalWeighGram
    let value = 0

    if (totalGrams <= parameterInit) {
      value = parameterPriceInit
    } else {
      let i = 1
      let end = true
      do {
        const parameter = parameterInit
        const nextParameter = parameterInit + parameterMultiple * i

        if (totalGrams > parameter && totalGrams <= nextParameter) {
          value = parameterPriceInit * (i + 1)
          end = false
          break
        } else {
          value = 0
        }
        i++
      } while (end)
    }

    setPpnTaxShippingAmount(value)
    setRupee(currentValue => currentValue + value)
  }
  // pajak tetap calculate -> if active
  const calculateFixTaxAmount = data => {
    const fixTaxAmountValue = data.reduce(
      (acc, val) => acc + val.product.fix_tax * val.cart_item.checked_out_quantity,
      0
    )
    setFixTaxAmount(fixTaxAmountValue)

    setRupee(currentValue => currentValue + fixTaxAmountValue)
  }

  if (open) {
    if (cartids) {
      // if(payspot==='active'){
      //   console.log("cartids",cartids)
      //   for (var i = 0; i < cartids.length; i++) {
      //     quantity.push(cartids[i].cart_item.checked_out_quantity);
      //     weights.push(cartids[i].product.weight);
      //     rps.push(cartids[i].cart_item.price * cartids[i].cart_item.checked_out_quantity);
      //   }
      // }else{
      //   for (var i = 0; i < cartids.length; i++) {
      //     quantity.push(cartids[i].cart_item.quantity);
      //     weights.push(cartids[i].product.weight);
      //     rps.push(cartids[i].cart_item.price * cartids[i].cart_item.quantity);
      //   }
      // }
      for (var i = 0; i < cartids.length; i++) {
        quantity.push(cartids[i].cart_item.checked_out_quantity)
        weights.push(cartids[i].product.weight)
        rps.push(
          (cartids[i].cart_item.price - getDiskonRupiah(cartids[i])) *
            cartids[i].cart_item.checked_out_quantity
        )
      }
      if (shippingdata) {
        const dataR = shippingdata.nominal + rps.reduce((sum, a) => sum + a, 0)
        console.log('dataR shippingdata', dataR)
        setRupee(dataR)
      } else {
        setRupee(rps.reduce((sum, a) => sum + a, 0))
      }

      const landingPageSettingLocalData = JSON.parse(localStorage.getItem('landing_page_setting'))
      if (
        landingPageSettingLocalData.is_shipping_tax_by_weight_checkout_active &&
        payspot !== 'active'
      ) {
        calculatePPNBaseONWeightGram(landingPageSettingLocalData)
      }
      if (landingPageSettingLocalData.is_fix_tax_product_checkout_active && payspot !== 'active') {
        calculateFixTaxAmount(cartdatas)
      }

      setOpen(false)
    }
  }
  // console.log("rps",rps)

  const getAddress = async () => {
    console.log('getAddress')
    // let data = new FormData();
    // data.append("user_id", userid);
    // dispatch(getAddressUser(data));
    const userID = JSON.parse(localStorage.getItem('userid'))
    try {
      const response = await getAddressDetail(`{"customer_id":${userID}}`)
      // setData1(response);
      let addressSelected = history.location.search
      addressSelected = localStorage.getItem('address')
        ? JSON.parse(localStorage.getItem('address'))[0]?.customer_address?.id
        : null

      response.forEach((item, index) => {
        if (
          (addressSelected
            ? item.customer_address.id == addressSelected
            : item.customer_address.is_primary === true) ||
          response.length === 1
        ) {
          setId(item.customer_address.id)
          const data = response.filter(x => x.customer_address.id === item.customer_address.id)
          console.log('data', data)
          setFinalarray(data)
          localStorage.setItem('address', JSON.stringify(data))
        }
      })

      // shipping();
      // setMyData(response
      //   ? response.filter((dt) => dt.customer_address.is_primary === true)
      //   : null)
      // console.log("responsedata",response)
    } catch (error) {
      console.log('Error fetching getAddress:', error)
    }
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  // const mycart = () => {
  //   let data = new FormData();
  //   data.append("user_code", Usercode);
  //   data.append("current_date", "2019-03-12 15:33:15");
  //   // dispatch(getMyCartProductUser(data));
  // };

  useEffect(() => {
    setCartids(cartdatas)
    getAddress()
    // mycart();
    setOpen(true)
    setTrue1(true)
    const checkIsNullBankData = localStorage.getItem('bankdata')
    if (checkIsNullBankData === null || checkIsNullBankData === undefined) {
      const getCodData = async () => {
        const codDataResult = await getCOD()
        if (codDataResult.success === true) {
          Object.assign(codDataResult.data, { type: 'Cash on Delivery' })
          // localStorage.setItem('bankdata', JSON.stringify(codDataResult.data))
        }
      }
      getCodData()
    }

    // jika COD aktif dan bankdata kosong
    const payspot = JSON.parse(localStorage.getItem('pay_spot'))
    const bankData = JSON.parse(localStorage.getItem('bankdata'))
    if (
      payspot === 'active' &&
      (bankData === null || bankData === undefined || bankData.length === 0)
    ) {
      const getCodData = async () => {
        const codDataResult = await getCOD()
        if (codDataResult.success === true) {
          // {"id":1,"vendor_id":102,"name":"COD (Bayar Ditempat)","price":2000,"logo":"expeditions/default/cod1.png","is_active":true,"created_at":"2024-01-18T08:36:20.326566Z","created_by":125,"updated_at":"2024-08-29T21:32:09.557601Z","updated_by":125,"type":"Cash on Delivery"}
          // {"id":1,"vendor_id":102,"name":"COD (Bayar Ditempat)","price":2000,"logo":"expeditions/default/cod1.png","is_active":true,"created_at":"2024-01-18T08:36:20.326566Z","created_by":125,"updated_at":"2024-08-29T21:32:09.557601Z","updated_by":125,"type":"Cash on Delivery"}
          const data = { ...codDataResult.data, type: 'Cash on Delivery' }

          localStorage.setItem('bankdata', JSON.stringify(data))
          setBankdatas(data)
        }
      }
      getCodData()
    }

    // jika bankData tidak kosong dan bankData.type === 'Bank Transfer Otomatis'
    if (
      uniqueCode == 0 &&
      bankData !== null &&
      bankData !== undefined &&
      bankData.type === 'Bank Transfer Otomatis'
    ) {
      getUniqueCode()
    }
  }, [])
  // console.log("bankdata value",bankdatas)

  // const data1 = GetAddressResponse();

  // if (open) {
  //   if (data1) {
  //     for (var i = 0; i < data1.data.length; i++) {
  //       if (data1.data[i].as_default === 1) {
  //         setId(data1.data[i].id);
  //         const finalarray = data1.data.filter(
  //           (x) => x.id === data1.data[i].id
  //         );
  //         finalArray.push(finalarray[0]);
  //         localStorage.setItem("address", JSON.stringify(finalArray));
  //       }
  //     }
  //     setOpen(false);
  //   }
  // }

  const handleClose1 = () => {
    setOpen1(false)
  }

  const [disdata, setDisData] = useState([])
  const [qtydata, setQtyData] = useState([])
  const [true1, setTrue1] = useState(true)
  const [timer, setTimer] = useState(null)
  const [inputValue, setInputValue] = useState('')

  const addNoteCart = async (e, j) => {
    // setOpen2(true);
    setInputValue(j)
    var result = [...cartids]
    result = result.map(x => {
      if (x.cart_item.id === e) x.cart_item.note = j
      return x
    })
    localStorage.setItem('cartids', JSON.stringify(result))
    setCartids(result)
    // clearTimeout(timer);
    // const newTimer = setTimeout(() => {
    //   await NoteCart(e, j);
    //   setOpen2(true);
    // }, 3000);
    // setTimer(newTimer);
    await NoteCart(e, j)
    // setOpen2(false);
  }

  const NoteCart = async (e, j) => {
    const data = {
      cart_id: e,
      note: j
    }
    // const response =
    await updateNote(data)
  }

  const getUniqueCode = async () => {
    if (payspot !== 'active') {
      const finalArray = JSON.parse(localStorage.getItem('address'))
      if (shippingdata && bankdatas && finalArray && finalArray.length > 0) {
        const imagePath = shippingdata.courier_image
        let data = {}

        if (bankdatas.type === 'Bank Transfer Otomatis') {
          if (shippingdata.service_type === 'SELFPICKUP') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'SELFPICKUP',
              is_moota: true,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER VENDOR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'COURIER MANUAL',
              is_moota: true,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER RAJA ONGKIR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'COURIER RAJA ONGKIR',
              is_moota: true,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: shippingdata.etd,
                service: shippingdata.rate,
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }
        }

        const response = await generateUniqueCode(data)

        if (response.success) {
          localStorage.setItem('unique_code', response.data.unique_payment_code)
          setUniqueCode(response.data.unique_payment_code)
        }
      } else {
        switch (errors) {
          case finalArray.length === 0:
            return setOpen1(true), setMessage('Please Select Delivery Address')
          case shippingdata === null:
            return setOpen1(true), setMessage('Please Select Shipping Option')
          case bankdatas === null:
            return setOpen1(true), setMessage('Please Select Payment Option')
        }
      }
    } else {
      let data = {}
      let shipping = {
        name: 'Ambil Sendiri',
        estimation: null,
        service: 'SELFPICKUP',
        price: 0,
        logo: null
      }

      const res = await getSelfPickup()
      if (res.success) {
        shipping = {
          name: res.data.name,
          estimation: res.data.estimation,
          service: 'SELFPICKUP',
          price: 0,
          logo: res.data.logo
        }
      }

      if (bankdatas.type == 'Bank Transfer Otomatis') {
        data = {
          payment_method: 'BANK TRANSFER',
          payment_method_id: null,
          order_placed_from: 'WEB',
          shipping_type: 'SELFPICKUP',
          shipping_tax: 0,
          grand_total: rupee === 0 ? finalrps : rupee,
          total: finaltotal - finaldiskon,
          tax: 0,
          is_moota: true,
          discount: finaldiskon,
          shipping,
          is_pay_on_spot: true
        }
      }

      const response = await generateUniqueCode(data)
      if (response.success) {
        setUniqueCode(response.data.unique_payment_code)
        localStorage.setItem('unique_code', response.data.unique_payment_code)
      }
    }
  }

  const AddOrder = async () => {
    if (payspot !== 'active') {
      if (shippingdata && bankdatas && finalArray.length > 0) {
        setOpen2(true)

        const imagePath = shippingdata.courier_image
        let data = {}

        if (bankdatas.type == 'Cash on Delivery') {
          if (shippingdata.service_type === 'SELFPICKUP') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'CASH',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'SELFPICKUP',
              is_moota: false,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER VENDOR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'CASH',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'COURIER MANUAL',
              is_moota: false,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER RAJA ONGKIR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'CASH',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'COURIER RAJA ONGKIR',
              is_moota: false,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: shippingdata.etd,
                service: shippingdata.rate,
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }
        } else if (bankdatas.type === 'Bank Transfer Manual') {
          if (shippingdata.service_type === 'SELFPICKUP') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: parseInt(bankdatas.id),
              order_placed_from: 'WEB',
              shipping_type: 'SELFPICKUP',
              is_moota: false,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER VENDOR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: parseInt(bankdatas.id),
              order_placed_from: 'WEB',
              shipping_type: 'COURIER MANUAL',
              is_moota: false,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER RAJA ONGKIR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: parseInt(bankdatas.id),
              order_placed_from: 'WEB',
              shipping_type: 'COURIER RAJA ONGKIR',
              is_moota: false,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: shippingdata.etd,
                service: shippingdata.rate,
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }
        } else if (bankdatas.type === 'Bank Transfer Otomatis') {
          if (shippingdata.service_type === 'SELFPICKUP') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'SELFPICKUP',
              is_moota: true,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER VENDOR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'COURIER MANUAL',
              is_moota: true,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: null,
                service: 'SELFPICKUP',
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }

          if (shippingdata.service_type === 'COURIER RAJA ONGKIR') {
            data = {
              customer_address_id: finalArray[0].customer_address.id,
              payment_method: 'BANK TRANSFER',
              payment_method_id: null,
              order_placed_from: 'WEB',
              shipping_type: 'COURIER RAJA ONGKIR',
              is_moota: true,
              shipping_tax: ppnTaxShippingAmount,
              grand_total: rupee === 0 ? finalrps : rupee,
              total: finaltotal - finaldiskon,
              tax: fixTaxAmount,
              discount: finaldiskon,
              shipping: {
                name: shippingdata.courier_name,
                estimation: shippingdata.etd,
                service: shippingdata.rate,
                price: shippingdata.nominal,
                logo: imagePath
              },
              is_pay_on_spot: false
            }
          }
        }

        const itemsCart = checkoutCartItem.map(item => ({
          id: item.cart_item.id,
          checked_out: item.cart_item.checked_out,
          checked_out_quantity: item.cart_item.checked_out_quantity
        }))

        const resUpdateCheckedAll = await updateCheckedAll(itemsCart)

        const response = await submitCheckout(data)

        if (resUpdateCheckedAll.success && response.success) {
          localStorage.setItem('order_id', response.data.id)
          setOpen2(false)
          localStorage.removeItem('bankdata')
          localStorage.removeItem('shipping')
          history.push('/successfulorder')
        }
      } else {
        switch (errors) {
          case finalArray.length === 0:
            return setOpen1(true), setMessage('Please Select Delivery Address')
          case shippingdata === null:
            return setOpen1(true), setMessage('Please Select Shipping Option')
          case bankdatas === null:
            return setOpen1(true), setMessage('Please Select Payment Option')
        }
      }
    } else {
      // let bankId = null
      // let paymentMethod = "CASH"
      // let data6 = new FormData();
      // for (var i = 0; i < cartids.length; i++) {
      //   disdata.push(JSON.stringify(cartids[i].cart_item.id));
      //   qtydata.push(JSON.stringify(cartids[i].cart_item.cart_item.checked_out_quantity));
      // }
      // const newdisk = disdata.toString();
      // const qtynew = qtydata.toString();

      // data6.append("cart_id", newdisk);
      // data6.append("user_id", userid);
      // data6.append("user_code", Usercode);
      // data6.append("payment_method", "3");
      // if (bankdatas !== null) {
      //   bankId = parseInt(bankdatas.id)
      //   paymentMethod = "BANK TRANSFER"
      // }
      setOpen2(true)
      let data = {}
      let shipping = {
        name: 'Ambil Sendiri',
        estimation: null,
        service: 'SELFPICKUP',
        price: 0,
        logo: null
      }

      const res = await getSelfPickup()
      if (res.success) {
        shipping = {
          name: res.data.name,
          estimation: res.data.estimation,
          service: 'SELFPICKUP',
          price: 0,
          logo: res.data.logo
        }
      }

      if (bankdatas.type === 'Cash on Delivery') {
        data = {
          payment_method: 'CASH',
          payment_method_id: null,
          order_placed_from: 'WEB',
          shipping_type: 'SELFPICKUP',
          shipping_tax: 0,
          grand_total: rupee === 0 ? finalrps : rupee,
          total: finaltotal - finaldiskon,
          tax: 0,
          discount: finaldiskon,
          is_moota: false,
          shipping,
          is_pay_on_spot: true
        }
      } else if (bankdatas.type == 'Bank Transfer Manual') {
        data = {
          payment_method: 'BANK TRANSFER',
          payment_method_id: parseInt(bankdatas.id),
          order_placed_from: 'WEB',
          shipping_type: 'SELFPICKUP',
          shipping_tax: 0,
          grand_total: rupee === 0 ? finalrps : rupee,
          total: finaltotal - finaldiskon,
          tax: 0,
          is_moota: false,
          discount: finaldiskon,
          shipping,
          is_pay_on_spot: true
        }
      } else if (bankdatas.type == 'Bank Transfer Otomatis') {
        data = {
          unique_payment_code: parseInt(uniqueCode) > 0 ? parseInt(uniqueCode) : undefined,
          payment_method: 'BANK TRANSFER',
          payment_method_id: null,
          order_placed_from: 'WEB',
          shipping_type: 'SELFPICKUP',
          shipping_tax: 0,
          grand_total: rupee === 0 ? finalrps : rupee,
          total: finaltotal - finaldiskon,
          tax: 0,
          is_moota: true,
          discount: finaldiskon,
          shipping,
          is_pay_on_spot: true
        }
      }

      const itemsCart = checkoutCartItem.map(item => ({
        id: item.cart_item.id,
        checked_out: item.cart_item.checked_out,
        checked_out_quantity: item.cart_item.checked_out_quantity
      }))

      const resUpdateCheckedAll = await updateCheckedAll(itemsCart)

      const response = await submitCheckout(data)
      if (resUpdateCheckedAll.success && response.success) {
        localStorage.setItem('order_id', response.data.id)
        setOpen2(false)
        history.push('/successfulorder')
      }
    }
  }

  // const OrderUnpaid = () => {
  //   const userid = JSON.parse(localStorage.getItem("userid"));
  //   var data3 = new FormData();
  //   data3.append("user_id", userid);
  //   data3.append("type", "UNPAID");
  //   data3.append("page", 1);
  //   // dispatch(myOrderProductUser(data3));
  // };

  return (
    <div>
      {
        // <Header title={t("order_information")} />
        true1 && finalqty && finalweight && finalrps ? (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '100wh',
                backgroundColor: ThemeColor,
                position: 'fixed',
                zIndex: '11111',
                marginBottom: '40px'
              }}
            >
              <div className='title-box' style={{ height: '48px' }}>
                <KeyboardBackspaceIcon
                  style={{ color: 'white', width: '25px' }}
                  onClick={() => {
                    history.push('/cart')
                  }}
                />
                <h1 className='title mb-0'>{t('order_information')}</h1>
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <div style={{ paddingTop: '50px' }}>
                {payspot !== 'active' ? (
                  <div className='shadow-card rounded-card bg-white m-2'>
                    <div
                      className='my-1 mx-2'
                      onClick={() => {
                        history.push({
                          pathname: '/selectaddress',
                          search: '?from=orderconfirm'
                        })
                      }}
                    >
                      <div className='d-flex justify-content-left align-items-center'>
                        <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('delivery_address'))}</p>
                        <ArrowForwardIosIcon
                          fontSize='small'
                          style={{ width: '1.25rem', height: '1.25rem' }}
                          className={classes.icon}
                        />
                      </div>
                      {/* {console.log("finalArray",finalArray)} */}
                      {finalArray.length > 0 ? (
                        <div>
                          <div>{finalArray[0].customer_address.name}</div>
                          <div>{finalArray[0].customer_address.address}</div>
                          <div>
                            {finalArray[0].sub_district.name + ', ' + finalArray[0].district.name}
                          </div>
                          <div>{finalArray[0].province.name}</div>
                          <div>{finalArray[0].customer_address.phone}</div>
                        </div>
                      ) : (
                        <p className='order-confirm-title mb-0' style={{ color: '#8B8B8B' }}>
                          {t('please_choose_delivery_address')}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/* <div className="shadow-card py-2 bg-white px-2 m-2">
          <div className="d-flex justify-content-between align-items-center">
            <h6
              fontSize="medium"
              fontWeight="normal"
              style={{ marginBottom: "0px" }}
            >
              Dropship
            </h6>
            <CustomSwitch size="small" onChange={handleChange} />
          </div>
          {toggled ? (
            <div className="display" style={{ height: "auto" }}>
              <TextField
                className="pb-1"
                id="standard-basic"
                label="Name"
                variant="standard"
                fullWidth={true}
              />
              <TextField
                className="pb-1"
                id="standard-basic"
                label="Phone"
                variant="standard"
                fullWidth={true}
              />
            </div>
          ) : (
            ""
          )}
        </div> */}
                {payspot !== 'active' ? (
                  <div className='shadow-card rounded-card bg-white m-2'>
                    <div
                      className='my-1 mx-2'
                      onClick={() => {
                        if (finalArray.length > 0) {
                          localStorage.setItem('totalweight', JSON.stringify(finalweight))
                          localStorage.setItem('cityid', JSON.stringify(finalArray[0].district.id))
                          localStorage.setItem(
                            'subdistrictid',
                            JSON.stringify(finalArray[0].sub_district.id)
                          )
                          localStorage.setItem('setT', JSON.stringify(finalrps))
                          history.push('/shippingmethod')
                        }
                        // else if (localAddress != null) {
                        //   localStorage.setItem(
                        //     "totalweight",
                        //     JSON.stringify(finalweight)
                        //   );
                        //   localStorage.setItem(
                        //     "cityid",
                        //     JSON.stringify(finalArray[0].district.id)
                        //   );
                        //   localStorage.setItem(
                        //     "subdistrictid",
                        //     JSON.stringify(finalArray[0].sub_district.id)
                        //   );
                        //   localStorage.setItem("setT", JSON.stringify(finalrps));
                        //   history.push("/shippingmethod");
                        // }
                        else {
                          setMessage('Please Select Delivery Address First')
                          setOpen1(true)
                        }
                      }}
                    >
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('shipping_option'))}</p>
                        <ArrowForwardIosIcon
                          fontSize='small'
                          style={{ width: '1.25rem', height: '1.25rem' }}
                          className={classes.icon}
                        />
                      </div>

                      {shippingdata !== null ? (
                        <div
                          className='d-flex'
                          style={{
                            alignItems: 'center',
                            gap: '16px',
                            paddingBottom: '10px'
                          }}
                        >
                          <img
                            src={
                              shippingdata.courier_image != ''
                                ? process.env.REACT_APP_FILE_BASE_URL +
                                  '/' +
                                  shippingdata.courier_image
                                : '/assets/images/dummy.png'
                            }
                            height='35px'
                            width='50px'
                            alt=''
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'flex-start'
                            }}
                          />
                          <span
                            style={{
                              // fontSize: "15px",
                              // display: "flex",
                              // alignItems: "center",
                              // // justifyContent: "center",

                              // marginBottom: "13px",
                              // marginLeft: "16px",
                              width: '100%'
                            }}
                          >
                            {/* {shippingdata.courier_name +
                            " " +
                            shippingdata.service_name} */}
                            {shippingdata.courier_name}
                            <br />
                            {shippingdata.etd ? '(' + shippingdata.etd + ') Hari ' : ''}
                            <span>{priceFormatLocal(shippingdata.nominal)}</span>
                            {/* <span style={{ textDecoration: "line-through" }}>
                            {priceFormatLocal(shippingdata.nominal)}
                          </span> */}
                          </span>
                        </div>
                      ) : (
                        <p className='order-confirm-title mb-0' style={{ color: '#8B8B8B' }}>
                          {t('please_choose_shipping_option')}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {payspot !== 'active' ? (
                  <div className='shadow-card rounded-card bg-white m-2'>
                    <div
                      className='my-1 mx-2'
                      onClick={() => {
                        if (shippingdata) {
                          history.push('/paymentmethod')
                          localStorage.setItem(
                            'totalprice',
                            JSON.stringify(rupee === 0 ? finalrps : rupee)
                          )
                        } else {
                          setMessage('Please Select Shipping Option First')
                          setOpen1(true)
                        }
                      }}
                    >
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('payment_option'))}</p>
                        <ArrowForwardIosIcon
                          fontSize='small'
                          style={{ width: '1.25rem', height: '1.25rem' }}
                          className={classes.icon}
                        />
                      </div>

                      {bankdatas !== null && bankdatas.type !== 'Cash on Delivery' ? (
                        <div
                          className='d-flex'
                          style={{
                            alignItems: 'center',
                            paddingBottom: '10px'
                          }}
                        >
                          <img
                            src={
                              bankdatas.picture != ''
                                ? bankdatas.picture
                                : '/assets/images/dummy.png'
                            }
                            height='35px'
                            width='50px'
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'flex-start'
                            }}
                          />
                          <span
                            style={{
                              fontSize: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: "center",
                              marginLeft: '16px',
                              width: '100%'
                            }}
                          >
                            {bankdatas.account_owner_name}
                            <br />
                            {bankdatas.account_no}
                          </span>
                        </div>
                      ) : bankdatas !== null && bankdatas.type === 'Cash on Delivery' ? (
                        <div
                          className='d-flex'
                          style={{
                            alignItems: 'center',
                            paddingBottom: '10px'
                          }}
                        >
                          <img
                            src={
                              bankdatas.logo
                                ? process.env.REACT_APP_FILE_BASE_URL + '/' + bankdatas.logo
                                : '/assets/images/dummy.png'
                            }
                            height='35px'
                            width='50px'
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'flex-start'
                              // marginBottom: "10px",
                            }}
                          />
                          <span
                            style={{
                              fontSize: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: "center",
                              marginLeft: '16px',
                              width: '100%'
                            }}
                          >
                            {bankdatas.name}
                            <br />
                          </span>
                        </div>
                      ) : (
                        <p className='order-confirm-title mb-0' style={{ color: '#8B8B8B' }}>
                          {t('please_choose_payment_option')}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='shadow-card rounded-card bg-white m-2'>
                    <div
                      className='my-1 mx-2'
                      onClick={() => {
                        history.push('/paymentmethod')
                        localStorage.removeItem('bankdata')
                        localStorage.setItem(
                          'totalprice',
                          JSON.stringify(rupee === 0 ? finalrps : rupee)
                        )
                      }}
                    >
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('payment_option'))}</p>
                        <ArrowForwardIosIcon
                          fontSize='small'
                          style={{ width: '1.25rem', height: '1.25rem' }}
                        />
                      </div>
                      {bankdatas !== null && bankdatas.type !== 'Cash on Delivery' ? (
                        <div
                          className='d-flex'
                          style={{
                            alignItems: 'center',
                            paddingBottom: '10px'
                          }}
                        >
                          <img
                            src={
                              bankdatas.picture != ''
                                ? bankdatas.picture
                                : '/assets/images/dummy.png'
                            }
                            height='35px'
                            width='50px'
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'flex-start'
                            }}
                          />
                          <span
                            style={{
                              fontSize: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: "center",
                              marginLeft: '16px',
                              width: '100%'
                            }}
                          >
                            {bankdatas.account_owner_name}
                            <br />
                            {bankdatas.account_no + ' ' + bankdatas.bank_name}
                          </span>
                        </div>
                      ) : bankdatas !== null && bankdatas.type === 'Cash on Delivery' ? (
                        <div
                          className='d-flex'
                          style={{
                            alignItems: 'center',
                            paddingBottom: '10px'
                          }}
                        >
                          <img
                            src={
                              bankdatas.logo
                                ? process.env.REACT_APP_FILE_BASE_URL + '/' + bankdatas.logo
                                : '/assets/images/dummy.png'
                            }
                            height='35px'
                            width='50px'
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'flex-start'
                              // marginBottom: "10px",
                            }}
                          />
                          <span
                            style={{
                              fontSize: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: "center",
                              marginLeft: '16px',
                              width: '100%'
                            }}
                          >
                            {bankdatas.name}
                            <br />
                          </span>
                        </div>
                      ) : (
                        <p className='order-confirm-title mb-0' style={{ color: '#8B8B8B' }}>
                          {t('please_choose_payment_option')}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                <div className='shadow-card rounded-card m-2 pb-1 bg-white'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <p className='mb-0 fw-bold' style={{ marginLeft: '10px', marginTop: '5px' }}>
                      {t('order_detail')}
                    </p>
                  </div>
                  {cartids.length > 0
                    ? cartids.map((orders, index) => (
                        <React.Fragment key={index}>
                          <div className='m-1' key={index} style={{ height: '65px' }}>
                            <div
                              className='d-flex justify-content-start'
                              style={{
                                marginLeft: '10px'
                              }}
                            >
                              <img
                                src={
                                  orders.product.media && orders.product.media.length > 0
                                    ? process.env.REACT_APP_FILE_BASE_URL +
                                      '/' +
                                      orders.product.media[0]
                                    : '/assets/images/dummy.png'
                                }
                                alt='item'
                                style={{
                                  width: '80px',
                                  height: '80px',
                                  position: 'relative'
                                }}
                              />
                              {orders.product.discount_type &&
                              isDiscountValid(
                                orders.product.discount,
                                orders.product.discount_start_date,
                                orders.product.discount_end_date
                              ) &&
                              orders.product.discount_type === 'percentage' ? (
                                <div
                                  style={{
                                    background: 'red',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                    fontSize: '12px',
                                    width: '30px',
                                    height: '20px',
                                    borderRadius: '3px',
                                    marginTop: '2px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    marginLeft: '47px',
                                    position: 'absolute'
                                  }}
                                >
                                  {orders.product.discount}%
                                </div>
                              ) : (
                                ''
                              )}

                              {orders.product.discount_type &&
                              isDiscountValid(
                                orders.product.discount,
                                orders.product.discount_start_date,
                                orders.product.discount_end_date
                              ) &&
                              orders.product.discount_type === 'nominal' ? (
                                <div
                                  style={{
                                    background: 'red',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#fff',
                                    fontSize: '12px',
                                    width: '30px',
                                    height: '20px',
                                    borderRadius: '3px',
                                    marginTop: '2px',
                                    marginBottom: '5px',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    marginLeft: '47px',
                                    position: 'absolute'
                                  }}
                                >
                                  {parseInt(
                                    (orders.product.discount / orders.cart_item.price) * 100
                                  )}
                                  %
                                </div>
                              ) : (
                                ''
                              )}

                              <div className='mx-2'>
                                <div
                                  style={{
                                    maxHeight: '43px',
                                    overflow: 'hidden',
                                    fontSize: '13px',
                                    fontWeight: 'normal'
                                  }}
                                >
                                  {orders.product.name.length > 70
                                    ? orders.product.name.slice(0, 70).toUpperCase() + '...'
                                    : orders.product.name.toUpperCase()}
                                </div>

                                {orders.product_variant !== null ? (
                                  <span
                                    className='item-variant mb-1'
                                    style={{
                                      fontSize: '13px',
                                      fontWeight: 'normal',
                                      backgroundColor: '#EFEFEF'
                                    }}
                                  >
                                    &nbsp;
                                    {t('variant') +
                                      ' : ' +
                                      orders.product_variant.attributes[0].value}
                                    &nbsp;
                                  </span>
                                ) : (
                                  ''
                                )}

                                <div
                                  className=' mb-0 product-option-price'
                                  style={{
                                    display: 'flex',
                                    fontSize: '13px',
                                    fontWeight: 'normal'
                                  }}
                                >
                                  <span style={{ marginRight: '10px' }}>
                                    {orders.cart_item.checked_out_quantity} PCS (
                                    {orders.product.weight} gr)
                                  </span>

                                  {orders.cart_item.discount === 0 ? (
                                    <label>
                                      <p style={{ color: ThemeColor }}>
                                        {priceFormatLocal(orders.cart_item.price)}
                                      </p>
                                    </label>
                                  ) : (
                                    <label>
                                      <p style={{ color: ThemeColor }}>
                                        {priceFormatLocal(
                                          orders.cart_item.price - getDiskonRupiah(orders)
                                        )}
                                      </p>
                                    </label>
                                  )}

                                  {isDiscountValid(
                                    orders.product.discount,
                                    orders.product.discount_start_date,
                                    orders.product.discount_end_date
                                  ) && (
                                    <label
                                      style={{
                                        color: ThemeColor,
                                        marginLeft: '10px'
                                      }}
                                    >
                                      <s className=''>{priceFormatLocal(orders.cart_item.price)}</s>
                                    </label>
                                  )}

                                  {/* <span className="badge badge-red ms-2">50%</span> */}
                                  {/* <s className="text-muted ms-4">Rp {orders.grand_total}</s> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <CssTextField
                            id={`ids${index}`}
                            label={t('optional_message_here')}
                            variant='standard'
                            value={orders.cart_item.note}
                            onChange={e => {
                              addNoteCart(orders.cart_item.id, e.target.value)
                            }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            style={{
                              width: '95%',
                              borderWidth: '0 0 1px',
                              borderColor: '#8b8b8b',
                              fontSize: '0.75rem',
                              marginLeft: '10px',
                              marginTop: '20px'
                            }}
                            size='small'
                          />
                        </React.Fragment>
                      ))
                    : ''}
                </div>

                {/* <div className="px-2 m-2 shadow-card bg-white py-2">
          <div className=" d-flex justify-content-between mb-2">
            <img
              src={VoucherTag}
              alt="voucher-tag"
              className=""
              style={{ height: "1.5rem", margin: "0 0.5rem" }}
            />

            <input
              type="text"
              placeholder="voucher"
              className="outline-0 border-0 w-100 voucher-input"
            />
            <p className="p-0 mb-0 bg-white">Apply</p>
          </div>
          <div
            className=""
            style={{ borderBottom: "1px solid rgb(141 140 140)" }}
          ></div>
        </div> */}

                <div className='shadow-card mx-2 rounded-card p-1 bg-white'>
                  <table style={{ width: '100%', margin: '0.25rem !important' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('total_items')}</td>
                        <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'right'
                            }}
                          >
                            <label style={{ fontSize: '1rem !important' }}>{finalqty} Item</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: '100%', margin: '0.25rem !important' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', paddingLeft: '0.25rem' }}>
                          {t('total_weight')}
                        </td>
                        <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'right'
                            }}
                          >
                            <label style={{ fontSize: '1rem !important' }}>
                              <span>{priceFormatLocal(finalweight).replaceAll('Rp', '')} Gram</span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: '100%', margin: '0.25rem !important' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('total_order')}</td>
                        <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '56px',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                              }}
                            >
                              <label>Rp.</label>
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center'
                              }}
                            >
                              {' '}
                              <label style={{ fontSize: '1rem !important' }}>
                                <span>{priceFormatLocal(finalrps).replaceAll('Rp', '')}</span>
                              </label>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* {payspot !== 'active' ? (
                    <table style={{ width: '100%', margin: '0.25rem !important' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('voucher')}</td>
                          <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '56px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <label>Rp.</label>
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <label>0</label>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ''
                  )} */}

                  {payspot !== 'active' ? (
                    <table style={{ width: '100%', margin: '0.25rem !important' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', paddingLeft: '0.25rem' }}>
                            {t('shipping_cost')}
                          </td>
                          <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '56px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <label>Rp.</label>
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <label>
                                  {shippingdata ? (
                                    <span>
                                      {priceFormatLocal(shippingdata.nominal).replaceAll('Rp', '')}
                                    </span>
                                  ) : (
                                    '0'
                                  )}
                                </label>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ''
                  )}

                  {uniqueCode > 0 && (
                    <table style={{ width: '100%', margin: '0.25rem !important' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', paddingLeft: '0.25rem' }}>
                            {t('unique_code')}
                          </td>
                          <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '56px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <label>Rp.</label>
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <label>
                                  <span className='bill-value'>
                                    {priceFormatLocal(uniqueCode).replaceAll('Rp', '')}
                                  </span>
                                </label>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {payspot !== 'active' &&
                  landingPage?.setting?.is_shipping_tax_by_weight_checkout_active ? (
                    <table style={{ width: '100%', margin: '0.25rem !important' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', paddingLeft: '0.25rem' }}>
                            {t('ppn_shipping_tax')}
                          </td>
                          <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '56px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <label>Rp.</label>
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <label>
                                  {ppnTaxShippingAmount > 0 ? (
                                    <span className='bill-value'>
                                      {priceFormatLocal(ppnTaxShippingAmount).replaceAll('Rp', '')}
                                    </span>
                                  ) : (
                                    0
                                  )}
                                </label>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ''
                  )}

                  {payspot !== 'active' &&
                  landingPage?.setting?.is_fix_tax_product_checkout_active ? (
                    <table style={{ width: '100%', margin: '0.25rem !important' }}>
                      <tbody>
                        <tr>
                          <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('fix_tax')}</td>
                          <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '56px',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                                }}
                              >
                                <label>Rp.</label>
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: 'right',
                                  alignItems: 'center'
                                }}
                              >
                                <label>
                                  {fixTaxAmount > 0 ? (
                                    <span className='bill-value'>
                                      {priceFormatLocal(fixTaxAmount).replaceAll('Rp', '')}
                                    </span>
                                  ) : (
                                    0
                                  )}
                                </label>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ''
                  )}

                  <table style={{ width: '100%', margin: '0.25rem !important' }}>
                    <tbody>
                      <tr>
                        <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('grand_total')}</td>
                        <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '56px',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                              }}
                            >
                              <label>Rp.</label>
                            </span>
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'right',
                                alignItems: 'center'
                              }}
                            >
                              <label>
                                {rupee === 0 ? (
                                  <span className='bill-value'>
                                    {priceFormatLocal(finalrps).replaceAll('Rp', '')}
                                  </span>
                                ) : Object.keys(rupee.toString()).length > 0 ? (
                                  <span className='bill-value'>
                                    {priceFormatLocal(rupee).replaceAll('Rp', '')}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </label>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p
                  fontSize='small'
                  fontWeight='bold'
                  className='my-2 mx-2'
                  style={{
                    display: 'flex',
                    flexShrink: 0,
                    flexWrap: 'wrap'
                  }}
                >
                  <span>{t('with_payment_i_agree_with_terms_and_conditions')}</span>
                  <span
                    style={{
                      color: ThemeColor ? ThemeColor : '#FFFFFF',
                      marginLeft: '5px',
                      borderBottom: `0.5px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                      cursor: 'pointer',
                      marginRight: JSON.parse(localStorage.getItem('Language')) === 'id' ? '5px' : 0
                    }}
                  >
                    {t('terms')}
                  </span>
                  <span
                    style={{
                      color: ThemeColor ? ThemeColor : '#FFFFFF',
                      borderBottom: `0.5px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                      cursor: 'pointer',
                      marginRight: JSON.parse(localStorage.getItem('Language')) === 'id' ? '5px' : 0
                    }}
                  >
                    {t('condition')}
                  </span>

                  <span>{t('shop')}</span>
                </p>
                <div className='mx-2 mb-2'>
                  <button
                    className='p-2 rounded text-light w-100 border-0'
                    style={{
                      backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF'
                    }}
                    onClick={() => {
                      AddOrder()
                    }}
                  >
                    {t('finish')}
                  </button>
                </div>
              </div>
            </ThemeProvider>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              paddingTop: '250px',
              paddingBottom: '300px',
              flexDirection: 'column',
              height: '100vh',
              backgroundColor: 'white'
            }}
          >
            <div>
              <Loader />
            </div>
            <div>Loading...</div>
          </div>
        )
      }
      {open1 ? (
        <div>
          <Dialog
            open={open1}
            onClick={handleClose1}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title' style={{ paddingBottom: '0px' }}>
              <label>{sitetitle}</label>
            </DialogTitle>
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500'
              }}
            >
              <div>{message}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end'
                }}
              >
                <Button
                  onClick={handleClose1}
                  style={{ color: ThemeColor ? ThemeColor : '#FFFFFF' }}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {open2 ? (
        <div>
          <Dialog
            open={open2}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '12px 24px'
              }}
            >
              <div style={{ marginRight: '14px' }}>
                <ClipLoader
                  color={ThemeColor ? ThemeColor : '#FFFFFF'}
                  height={50}
                  style={{
                    paddingLeft: '1px',
                    marginTop: '8px',
                    fontSize: '4'
                  }}
                />{' '}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default withNamespaces()(OrderConfirmation)
