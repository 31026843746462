import { createSlice } from '@reduxjs/toolkit'
import { getProducts } from '../../../api/products'

const initialState = {
  bestSellerProducts: [],
  loading: 'idle'
}

export const bestSellerProducts = createSlice({
  name: 'bestSellerProducts',
  initialState,
  reducers: {
    bestSellerProductsLoading(state, action) {
      state.loading = action.payload
    },

    bestSellerProductsReceived(state, action) {
      state.bestSellerProducts = action.payload
    }
  }
})

export const { bestSellerProductsLoading, bestSellerProductsReceived } = bestSellerProducts.actions

const fetchBestSellerProducts = () => async dispatch => {
  try {
    dispatch(bestSellerProductsLoading('loading'))
    const products = await getProducts(
      JSON.stringify({
        page: 1,
        limit: 10,
        labels: '["Terlaris"]',
        status: 'live'
      })
    )

    if (products.success) {
      dispatch(bestSellerProductsReceived(products.data || []))
      dispatch(bestSellerProductsLoading('success'))
    } else {
      dispatch(bestSellerProductsLoading('error'))
    }
  } catch (error) {
    console.log('async reducer ', error)
    dispatch(bestSellerProductsLoading('error'))
  }
}

export { fetchBestSellerProducts }

export default bestSellerProducts.reducer
