import React, { useEffect } from 'react'
import { useStyles } from './myOrder.style'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Unpaid from './pages/unpaid/Unpaid'
import OnProcess from './pages/onProcess/OnProcess'
import Finish from './pages/finish/Finish'
import Cancel from './pages/cancel/Cancel'
import OnDelivery from './pages/onDelivery/OnDelivery'
// import { GetSplashResponse } from "../../../../store/Selectors/getSplashData";
import i18n from '../../../language/localization'
import { t } from 'i18next'
import { getCountOrderByStatus } from '../../../../api/badge'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const MyOrder = props => {
  const { webThemeColor, themeColor } = props
  const classes = useStyles(props)

  const [value, setValue] = React.useState(0)
  const [unpaidTotalRecord, setunpaidTotalRecord] = React.useState(0)
  const [onProcessTotalRecord, setonProcessTotalRecord] = React.useState(0)
  const [finishTotalRecord, setfinishTotalRecord] = React.useState(0)
  const [cancelTotalRecord, setcancelTotalRecord] = React.useState(0)
  const [onDeliveryTotalRecord, setonDeliveryTotalRecord] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // ------------------
  const userid = JSON.parse(localStorage.getItem('userid'))

  const data1 = {}
  const [field, setField] = React.useState()
  const getCount = async () => {
    try {
      const response = await getCountOrderByStatus()
      if (response.success) {
        setunpaidTotalRecord(
          response.data['UNPAID'] +
            response.data['WAITING VALIDATION'] +
            response.data['ON VALIDATION']
        )
        setonProcessTotalRecord(response.data['ON PROCESS'])
        setonDeliveryTotalRecord(response.data['ON DELIVERY'])
        setfinishTotalRecord(response.data['COMPLETED'])
        setcancelTotalRecord(response.data['CANCELED'])
      } else {
        throw response
      }
    } catch (error) {
      console.log('Error fetching unpaid:', error)
    }
  }

  useEffect(() => {
    getCount()
  }, [])

  useEffect(() => {
    // let data = new FormData();
    // data.append("user_id", userid);
    // fetch(`${baseUrlApi()}/Api/Dashboard/getSplashData`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //     "X-localization" : localStorage.getItem("Language"),
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.ResponseCode == 1) {
    //       setField(res.data);
    //     }
    //   });
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  // {
  //   `${t("on_process")} ${
  //     onprocessorder !== null
  //       ? "(" + onprocessorder + ")"
  //       : data1
  //       ? "(" + data1.data.onprocess + ")"
  //       : field
  //       ? "(" + field.onprocess + ")"
  //       : ""
  //   }`;
  // }

  // const unpaidTotalRecord = localStorage.getItem("unpaidtotalrecord");
  // const onProcessTotalRecord = localStorage.getItem("onprocessorder");
  // const finishTotalRecord = localStorage.getItem("finishtotalrecord");
  // const cancelTotalRecord = localStorage.getItem("canceltotalrecord");
  // const onDeliveryTotalRecord = localStorage.getItem("ondeliverytotalrecord");

  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <div className={classes.body}>
      <AppBar position='static' style={{ boxShadow: 'none' }}>
        <Tabs value={value} onChange={handleChange} aria-label='simple tabs example'>
          <Tab
            label={`${t('unpaid')} (${unpaidTotalRecord ? unpaidTotalRecord : 0})`}
            {...a11yProps(0)}
          />
          <Tab
            label={`${t('on_process')} (${onProcessTotalRecord ? onProcessTotalRecord : 0})`}
            {...a11yProps(1)}
          />
          {/* <Tab
            label={`On Process (${onProcessTotalRecord})`}
            {...a11yProps(1)}
          /> */}
          <Tab
            label={`${t('on_delivery')} (${onDeliveryTotalRecord ? onDeliveryTotalRecord : 0})`}
            {...a11yProps(2)}
          />
          <Tab
            label={`${t('finish')} (${finishTotalRecord ? finishTotalRecord : 0})`}
            {...a11yProps(3)}
          />
          {/* <Tab label={`Cancel (${cancelTotalRecord})`} {...a11yProps(4)} /> */}
          <Tab
            label={`${t('cancel')} (${cancelTotalRecord ? cancelTotalRecord : 0})`}
            {...a11yProps(4)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Unpaid webThemeColor={webThemeColor} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OnProcess webThemeColor={webThemeColor} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OnDelivery webThemeColor={webThemeColor} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Finish webThemeColor={webThemeColor} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Cancel webThemeColor={webThemeColor} />
      </TabPanel>
    </div>
  )
}

export default MyOrder
