import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import i18n from '../../language/localization'
import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import Badge from '@material-ui/core/Badge'
import { useAuth } from '../../../hooks/useAuth'

// import { AuthContext } from '../../../context/AuthContext'

const BoxSvg = styled.svg`
  fill: none;
  height: 25px;
  width: 25px;
  margin: 0px !important;
`

const NavigationTab = ({ t }) => {
  const { token, countUnpaid } = useAuth()

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const useStyles = makeStyles(theme => ({
    Main: {
      width: '500px',
      position: 'fixed',
      bottom: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        position: 'fixed',
        bottom: 0
      }
    },
    navBar: {
      '&> . :active': {
        color: `${ThemeColor} !important`
      },
      '&> .nav-hover:hover': {
        color: `${ThemeColor} !important`
      }
    },
    linkNav: {
      '&> .link:hover': {
        color: `${ThemeColor} !important`
      }
    },
    wrapperSvg: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'end',
      userSelect: 'none',
      height: '100%'
    }
  }))
  const userid = JSON.parse(localStorage.getItem('userid'))
  const landing = JSON.parse(localStorage.getItem('Lanpage'))
  const history = useHistory()

  // const dispatch = useDispatch();
  const classes = useStyles()

  const orderdata = () => {
    let data = new FormData()
    data.append('user_id', userid)
    // dispatch(getSplashDataUser(data));
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <div
      className={classes.Main}
      // style={{
      //   width: "500px",
      //   position: "fixed",
      //   bottom: 0,
      // }}
    >
      <nav
        className={classes.navBar + ' ' + ' shadow-card'}
        // className='shadow-card'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: 'white',
          padding: '10px 0 5px'
        }}
      >
        <div className={classes.linkNav + ' ' + ' w-50 text-center nav-hover'}>
          <div
            className={`${classes.wrapperSvg} link`}
            onClick={() => {
              history.push('/')
            }}
          >
            <BoxSvg>
              <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9.52 2.84004L4.13 7.04004C3.23 7.74004 2.5 9.23004 2.5 10.36V17.77C2.5 20.09 4.39 21.99 6.71 21.99H18.29C20.61 21.99 22.5 20.09 22.5 17.78V10.5C22.5 9.29004 21.69 7.74004 20.7 7.05004L14.52 2.72004C13.12 1.74004 10.87 1.79004 9.52 2.84004Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12.5 17.99V14.99'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </BoxSvg>
            <span style={{ fontSize: '0.6rem', cursor: 'pointer' }}>{t('home1')}</span>
          </div>
        </div>
        <div className={classes.linkNav + ' ' + ' w-50 text-center nav-hover'}>
          <div
            className={`${classes.wrapperSvg} link`}
            onClick={() => {
              if (token) {
                orderdata()
                localStorage.setItem('ordertab', JSON.stringify(1))
                history.push('/orderhistory')
              } else {
                history.push('/login')
              }
            }}
          >
            <Badge badgeContent={countUnpaid} color='error'>
              <BoxSvg>
                <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M8.5 12.2H15.5'
                    stroke='black'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M8.5 16.2H12.88'
                    stroke='black'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M10.5 6H14.5C16.5 6 16.5 5 16.5 4C16.5 2 15.5 2 14.5 2H10.5C9.5 2 8.5 2 8.5 4C8.5 6 9.5 6 10.5 6Z'
                    stroke='black'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M16.5 4.02002C19.83 4.20002 21.5 5.43002 21.5 10V16C21.5 20 20.5 22 15.5 22H9.5C4.5 22 3.5 20 3.5 16V10C3.5 5.44002 5.17 4.20002 8.5 4.02002'
                    stroke='black'
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </BoxSvg>
            </Badge>
            <span style={{ fontSize: '0.6rem', cursor: 'pointer' }}>{t('order')}</span>
          </div>
        </div>
        <div className={classes.linkNav + ' ' + ' w-50 text-center nav-hover'}>
          <div
            className={`${classes.wrapperSvg} link`}
            onClick={() => {
              history.push('/category')
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              userSelect: 'none'
            }}
          >
            <BoxSvg>
              <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C15.7909 14 14 15.7909 14 18C14 20.2091 15.7909 22 18 22Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </BoxSvg>
            <span style={{ fontSize: '0.6rem', cursor: 'pointer' }}>{t('category')}</span>
          </div>
        </div>
        <div className={classes.linkNav + ' ' + ' w-50 text-center nav-hover'}>
          <div
            className={`${classes.wrapperSvg} link`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              userSelect: 'none'
            }}
            onClick={() => {
              // if (token) {
              //   history.push("/notification");
              // } else {
              //   history.push("/login");
              // }
              history.push('/notification')
            }}
          >
            <BoxSvg>
              <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12.02 2.91003C8.70997 2.91003 6.01997 5.60003 6.01997 8.91003V11.8C6.01997 12.41 5.75997 13.34 5.44997 13.86L4.29997 15.77C3.58997 16.95 4.07997 18.26 5.37997 18.7C9.68997 20.14 14.34 20.14 18.65 18.7C19.86 18.3 20.39 16.87 19.73 15.77L18.58 13.86C18.28 13.34 18.02 12.41 18.02 11.8V8.91003C18.02 5.61003 15.32 2.91003 12.02 2.91003Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                />
                <path
                  d='M13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.90002 21.1801C9.36002 20.6401 9.02002 19.8801 9.02002 19.0601'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                />
              </svg>
            </BoxSvg>
            <span style={{ fontSize: '0.6rem', cursor: 'pointer' }}>{t('notification')}</span>
          </div>
        </div>
        <div className={classes.linkNav + ' ' + ' w-50 text-center nav-hover'}>
          <div
            className={`${classes.wrapperSvg} link`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              userSelect: 'none'
            }}
            onClick={() => {
              if (token) {
                orderdata()
                history.push('/setting')
              } else {
                history.push('/login')
              }
            }}
          >
            <BoxSvg>
              <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z'
                  stroke='black'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </BoxSvg>
            <span style={{ fontSize: '0.6rem', cursor: 'pointer' }}>{t('settings2')}</span>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default withNamespaces()(NavigationTab)
