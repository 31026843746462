import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useStyles } from './MyWishlist.style'
import { t } from 'i18next'
import i18n from '../../../language/localization'

import { Box } from '@material-ui/core'
import CardProduct from '../../../../components/card-product/CardProduct'
import SkeletonHome from '../../../../components/SkeletonHome'
import NoDataFound from '../../compo/NoDataFound'
import MyPagination from '../../../../components/Pagination'

import { useAuth } from '../../../../hooks/useAuth'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../../../stores/features/snackbar/snackbar'

import { getProductsWishlist, deleteProductWishlist } from '../../../../api/wishlist'

const MyWishlist = ({ valueTab }) => {
  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const classes = useStyles({ themeColor })

  const history = useHistory()
  const dispatch = useDispatch()
  const { refreshCountWishlist } = useAuth()

  const [productsWishlist, setProductsWishlist] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [loadingProductWishlist, setLoadingProductWishlist] = useState(true)

  const getWishlist = async ({ page = 1 } = {}) => {
    setLoadingProductWishlist(true)
    const res = await getProductsWishlist(
      JSON.stringify({
        page: page,
        limit: 15
      })
    )
    if (res.success) {
      setProductsWishlist(res.data || [])
      setTotalPage(res.meta.total_pages)
      setLoadingProductWishlist(false)
    } else {
      setLoadingProductWishlist(false)
    }
  }

  useEffect(() => {
    if (valueTab === 3) {
      getWishlist()
    }
  }, [valueTab])

  const handleChangePage = (event, value) => {
    console.log('change page ', value)
    setPage(value)
    getWishlist({ page: value })
  }

  const [loading, setLoading] = useState(false)
  const removeFromWishlist = async productId => {
    setLoading(true)
    try {
      const res = await deleteProductWishlist(productId)
      if (res.success) {
        const newProductsWishlist = productsWishlist.filter(item => item.id !== productId)
        setProductsWishlist(newProductsWishlist)
        refreshCountWishlist()
        dispatch(
          setSnackbar({
            show: true,
            message: 'Successfully removed wishlist'
          })
        )
      } else {
        dispatch(
          setSnackbar({
            show: true,
            message: res.message
          })
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          setSnackbar({
            show: true,
            message: error.message
          })
        )
      } else {
        dispatch(
          setSnackbar({
            show: true,
            message: 'Ops, something went wrong'
          })
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const membershipData = localStorage.getItem('membership')
  const usercode = JSON.parse(localStorage.getItem('usercode'))
  const [membershipLevel, setMembershipLevel] = useState(1)

  useEffect(() => {
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [usercode])

  const seeDetailProduct = id => {
    history.push(`/product-detail?${id}`)
  }

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <Box className={classes.body}>
      <header>
        <h4>{t('my_wishlist')}</h4>
      </header>
      {loadingProductWishlist && (
        <Box>
          {[1, 2, 3].map(item => (
            <SkeletonHome key={item} />
          ))}
        </Box>
      )}
      {!loadingProductWishlist && productsWishlist.length > 0 && (
        <React.Fragment>
          <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '5px' }}>
            {productsWishlist.map((val, index) => (
              <CardProduct
                key={index}
                width='100%'
                membershipLevel={membershipLevel}
                product={val}
                clickableCard={false}
                isWishlist
                loadingWishlist={loading}
                handleClick={() => {}}
                handleRemoveWishlist={() => removeFromWishlist(val.id)}
                handleBuyProduct={() => seeDetailProduct(val.id)}
              />
            ))}
          </Box>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <MyPagination
              count={totalPage}
              variant='outlined'
              shape='rounded'
              page={page}
              handleChange={handleChangePage}
            />
          </div>
        </React.Fragment>
      )}
      {!loadingProductWishlist && productsWishlist.length <= 0 && <NoDataFound />}
    </Box>
  )
}

export default MyWishlist
