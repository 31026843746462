import { makeStyles, Checkbox, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../home/DesktopFooter'
import ProductData from './ProductData'
import i18n from '../language/localization'
import { t } from 'i18next'
import { getByClient as getProductsDetail } from '../../api/products'
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'
import TabSortProductDesktop from '../../components/tab/TabSortProductDesktop'

const useStyles = makeStyles(() => ({
  MainRoot: {
    paddingTop: '1rem',
    zIndex: -1,
    display: 'flex',
    alignItems: 'start',
    columnGap: '20px',
    width: '1200px',
    margin: '0px auto',
    paddingBottom: '6rem'
  },
  FilterRoot: {
    width: '290px',
    padding: '15px',
    flexFlow: 'column',
    background: '#fff',
    maxHeight: '100vh',
    // position: "sticky",
    // top: "152px",
    overflow: 'auto'
  },
  filterHead: {
    fontSize: '22px',
    color: '#333',
    margin: '0 0 25px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '0',
    marginBottom: '0',
    listStyle: 'none'
  },
  nav_list: {
    width: '100%',
    margin: '0 0 20px'
  },
  labelText: {
    fontSize: '18px',
    padding: '0 0 10px',
    cursor: 'pointer',
    position: 'relative',
    fontFamily: '"Open Sans", sans-serif',
    display: 'block',
    margin: '0 0 15px',
    transition: 'all 0.25s ease-in'
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  // sortByRootIcon: {
  //   color: "#000",
  //   borderRadius: "14px",
  //   fontSize: "14px",
  //   border: "1px solid #ccc",
  //   padding: "3px 10px",
  //   display: "flex",
  //   alignItems: "center",
  //   columnGap: "10px",
  //   "&>svg": {
  //     margin: "0px !important",
  //     fontSize: "18px !important",
  //   },
  //   "&:hover": {
  //     background: "#ff8000",
  //     color: "#000",
  //     border: "1px solid #ff8000",
  //     cursor: "pointer",
  //   },
  // },
  // sortByRoot: {
  //   color: "#000",
  //   borderRadius: "14px",
  //   fontSize: "14px",
  //   border: "1px solid #ccc",
  //   padding: "3px 10px",
  //   "&:hover": {
  //     background: "#ff8000",
  //     color: "#000",
  //     border: "1px solid #ff8000",
  //     cursor: "pointer",
  //   },
  // },

  NaviPageItems: {
    '& .MuiButton-root': {
      minWidth: '25px',
      height: '25px',
      '& i': {
        // color: "#999",
        margin: '0 10px'
      },
      '& span': {
        padding: '4px 8px',
        marginRight: '5px'
      }
    },
    '& .btnNext': {
      marginLeft: '5px'
    }
  }
}))
const ProductList = () => {
  const { token } = useAuth()

  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const classes = useStyles()

  const CatId2 = JSON.parse(localStorage.getItem('CatId'))
  const totalRecordOnLS = localStorage.getItem('totalRecord')
  const categoryNameOnLS = localStorage.getItem('categoryName')
  const categorydata = JSON.parse(localStorage.getItem('tabs'))
  const [catId, setCatId] = useState()
  const [sortBy, setSortBy] = useState()
  const [inlineCategory, setInlineCategory] = useState(false)
  const [cartitems, setItems] = useState(0)
  const [newPageNo, setNewPageNo] = useState()
  const [MyPage, setMyPage] = useState()
  const [totalRecord, setTotalRecord] = useState()
  const [categoryName, setCategoryName] = useState('')
  const [categoriesData, setCategoriesData] = useState([])
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  const Usercode1 = JSON.parse(localStorage.getItem('usercode'))
  const mycart = async () => {
    // let data = new FormData();
    // data.append("user_code", Usercode1);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));

    // fetch(`${baseUrlApi()}/Api/Order/myCart`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setItems(res.data.length);
    //   });
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }
  useEffect(() => {
    // mycart();
  }, [Usercode1])

  useEffect(() => {
    // const UserId = localStorage.getItem("userid");
    // const Usercode = localStorage.getItem("usercode");

    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");

    // var formdata = new FormData();
    // formdata.append("user_id", UserId);
    // formdata.append("user_code", Usercode);
    // formdata.append("category", CatId2);
    // formdata.append("page", "1");

    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setMyPage(res.total_page);
    //     setTotalRecord(res.total_record)
    //   });
    //   setCategoryName(localStorage.getItem("categoryName"))

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }

    const getProductsDetailData = async () => {
      try {
        const response = await getProductsDetail(`{"page":1, "limit": 1 ,"category_id":${CatId2}}`)
        setMyPage(response.meta.total_pages)
        setTotalRecord(response.meta.total_count)
      } catch (error) {
        console.log('Error fetching getProductsDetail:', error)
      }
    }

    getProductsDetailData()

    setCategoryName(localStorage.getItem('categoryName'))

    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }

    mycart()
  }, [])

  let isCheckedCategoryDatas = []
  for (
    let counterCategoryData = 0;
    counterCategoryData < categorydata.length;
    counterCategoryData++
  ) {
    if (CatId2 === categorydata[counterCategoryData].id) {
      isCheckedCategoryDatas.push(true)
    } else {
      isCheckedCategoryDatas.push(false)
    }
  }
  const [isCheckedCategoryData, setIsCheckedCategoryData] = useState(isCheckedCategoryDatas)

  const handleChangeCheckedCategoryData = async (e, index) => {
    if (isCheckedCategoryDatas.length > 0) {
      for (
        let counterisCheckedCategoryDatas = 0;
        counterisCheckedCategoryDatas < isCheckedCategoryDatas.length;
        counterisCheckedCategoryDatas++
      ) {
        isCheckedCategoryDatas[counterisCheckedCategoryDatas] = false
      }
    }
    isCheckedCategoryDatas[index] = e.target.checked
    setIsCheckedCategoryData(isCheckedCategoryDatas)
  }

  const handleClickLabelCategory = async category => {
    setCatId(category.id)
    setInlineCategory(true)
    setCategoryName(category.name)

    try {
      const response = await getProductsDetail(
        `{"page":1, "limit": 1 ,"category_id":${category.id}}`
      )
      setMyPage(response.meta.total_pages)
      setTotalRecord(response.meta.total_count)
    } catch (error) {
      console.log('Error fetching getProductsDetail:', error)
    }
  }

  return (
    <div
      style={{ background: '#f5f5f5', height: 750 }}
      // onScroll={listenToScroll}
    >
      <DesktopHeaderWrap
        clickTab={() => setInlineCategory(false)}
        themeColor={'rgb(231, 0, 116)'}
        cartNumber={cartitems}
        isFixed={false}
        categoriesData={categoriesData}
      />

      <div className={classes.MainRoot}>
        {/* <FillterBy/> */}
        <div className={classes.FilterRoot}>
          {/* <h2 className={classes.filterHead}>Filter By</h2> */}
          <nav className={classes.nav}>
            <ul className={classes.nav_list}>
              <li>
                <label
                  className={classes.labelText}
                  style={{
                    borderBottom: '2px solid ' + themeColor,
                    color: themeColor
                  }}
                >
                  {/* Categories */}
                  {t('category')}
                </label>
                <ul>
                  {categorydata.map((ct, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          handleClickLabelCategory(ct)
                        }}
                        style={{ height: '40px' }}
                      >
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            color: '#575757',
                            textTransform: 'capitalize',
                            fontSize: '15px'
                          }}
                          className={classes.hoverPointer}
                        >
                          {ct.name}
                          {/* <NavigateNextIcon
                            style={{
                              color: themeColor,
                              fontSize: "18px",
                            }}
                          /> */}
                          <Checkbox
                            style={{
                              color: themeColor,
                              width: '20px',
                              padding: 0
                            }}
                            size='small'
                            key={index}
                            checked={isCheckedCategoryData[index]}
                            onChange={e => handleChangeCheckedCategoryData(e, index)}
                          />
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <div style={{ width: '100%' }}>
          <div
            style={{
              background: '#fff',
              padding: '7px',
              margin: '0 0 5px',
              display: 'FLEX',
              columnGap: '10PX',
              alignItems: 'CENTER',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                // background: "#fff",
                padding: '7px',
                margin: '0 0 5px',
                display: 'flex',
                columnGap: '10PX',
                alignItems: 'center',
                justifyContent: 'start'
              }}
            >
              <TabSortProductDesktop sortBy={sortBy} setSortBy={setSortBy} />

              <Typography style={{ color: 'black' }}>
                {t('show')} {inlineCategory ? totalRecord : totalRecordOnLS} {t('product')}{' '}
                {t('to')}{' '}
              </Typography>
              <Typography style={{ color: themeColor }}>
                {`"${inlineCategory ? categoryName : categoryNameOnLS}"`}
              </Typography>
            </div>
          </div>
          <div>
            {inlineCategory ? (
              <ProductData
                catId={catId}
                sortBy={sortBy}
                setNewPageNo={setNewPageNo}
                membershipLevel={membershipLevel}
              />
            ) : (
              <ProductData catId={CatId2} sortBy={sortBy} membershipLevel={membershipLevel} />
            )}
          </div>
        </div>
      </div>
      <DeskTopFooter />
    </div>
  )
}
export default ProductList
