import React, { useEffect } from 'react'
import Header from '../header/header'
import i18n from '../language/localization'
import { withNamespaces } from 'react-i18next'

const Chat = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const userid = JSON.parse(localStorage.getItem('userid'))

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <React.Fragment>
      <Header title='Chat' color={ThemeColor} />
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white'
        }}
      >
        {userid ? <>{t('coming_soon')}</> : <>No Message Found</>}
      </div>
    </React.Fragment>
  )
}

export default withNamespaces()(Chat)
