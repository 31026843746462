import React, { useState, useEffect } from 'react'
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  forgotPasswordResetPassword,
  forgotPasswordSendOtp,
  forgotPasswordVerifcationOtp
} from 'api/forgot-password'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'stores/features/snackbar/snackbar'

const DesktopForgotPassword = ({ t, CancleClick }) => {
  const dispatch = useDispatch()
  const [stepType, setStepType] = useState('sendOtp')

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      width: '100%',
      zIndex: 1
    },
    passInput: {
      '& .MuiIconButton-root': {
        padding: '0'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0'
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: '0'
      }
    }
  }))
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const submitSendOtp = async () => {
    const data = {
      email: email
    }

    forgotPasswordSendOtp(data)
      .then(() => {
        setStepType('verifyOtp')
      })
      .catch(error => {
        dispatch(
          setSnackbar({
            show: true,
            message: error?.response?.data?.message || error.message
          })
        )
      })
  }

  const submitVerifyOtp = async () => {
    const data = {
      email: email,
      otp: otp
    }

    forgotPasswordVerifcationOtp(data)
      .then(response => {
        setStepType('reset')
        setCode(response.data.data.code)
      })
      .catch(error => {
        dispatch(
          setSnackbar({
            show: true,
            message: error?.response?.data?.message || error.message
          })
        )
      })
  }

  const submitReset = async () => {
    const data = {
      email: email,
      code: code,
      new_password: password,
      new_password_confirmation: confirmPassword
    }

    forgotPasswordResetPassword(data)
      .then(response => {
        dispatch(
          setSnackbar({
            show: true,
            message: response.data.message
          })
        )

        CancleClick()
      })
      .catch(error => {
        dispatch(
          setSnackbar({
            show: true,
            message: error?.response?.data?.message || error.message
          })
        )
      })
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {stepType == 'sendOtp' ? (
        <div className={classes.root}>
          <h2 style={{ marginTop: '1rem', fontSize: 21, textAlign: 'center' }}>
            {t('forgot_password')}
          </h2>
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '0px', marginBottom: '1rem' }}
          >
            <ValidatorForm
              className='w-100'
              onSubmit={submitSendOtp}
              onError={errors => console.log(errors)}
            >
              <Grid item xs={12} style={{ margin: '15px 15px' }}>
                <TextValidator
                  label={t('enter_email')}
                  fullWidth={true}
                  onChange={e => setEmail(e.target.value)}
                  name='email'
                  value={email}
                  variant='outlined'
                  size='small'
                  validators={['required']}
                  errorMessages={['this field is required', 'email is not valid']}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                />
              </Grid>
              <div
                className='finish'
                style={{
                  marginBottom: '0rem',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 9
                }}
              >
                <button
                  className='finish-btn  text-uppercase'
                  style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF', height: '3rem' }}
                >
                  {t('continue')}
                </button>
                <button
                  className='text-uppercase'
                  style={{ height: '3rem', border: 'none' }}
                  onClick={CancleClick}
                >
                  <span
                    style={{
                      marginLeft: '-1rem'
                    }}
                  >
                    <ChevronLeft />
                  </span>

                  {t('cancel')}
                </button>
              </div>
            </ValidatorForm>
          </div>
        </div>
      ) : stepType == 'verifyOtp' ? (
        <div className={classes.root}>
          <h2 style={{ marginTop: '1rem', fontSize: 21, textAlign: 'center' }}>
            {t('verify_otp')}
          </h2>
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '0px', marginBottom: '1rem' }}
          >
            <ValidatorForm
              className='w-100'
              onSubmit={submitVerifyOtp}
              onError={errors => console.log(errors)}
            >
              <Grid item xs={12} style={{ margin: '15px 15px' }}>
                <TextValidator
                  label={t('enter_otp')}
                  fullWidth={true}
                  onChange={e => setOtp(e.target.value)}
                  name='otp'
                  value={otp}
                  variant='outlined'
                  size='small'
                  validators={['required']}
                  errorMessages={['this field is required', 'otp is not valid']}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                />
              </Grid>
              <div
                className='finish'
                style={{
                  marginBottom: '0rem',
                  height: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: 9
                }}
              >
                <button
                  className='finish-btn  text-uppercase'
                  style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF', height: '3rem' }}
                >
                  {t('continue')}
                </button>
                <button
                  className='text-uppercase'
                  style={{ height: '3rem', border: 'none' }}
                  onClick={CancleClick}
                >
                  <span
                    style={{
                      marginLeft: '-1rem'
                    }}
                  >
                    <ChevronLeft />
                  </span>

                  {t('back_change_email_phone')}
                </button>
              </div>
            </ValidatorForm>
          </div>
        </div>
      ) : (
        stepType == 'reset' && (
          <div className={classes.root}>
            <h2 style={{ marginTop: '1rem', fontSize: 21, textAlign: 'center' }}>
              {t('enter_new_passwrod')}
            </h2>
            <div
              className='d-flex align-items-center justify-content-center'
              style={{ paddingTop: '0px', marginBottom: '1rem' }}
            >
              <ValidatorForm
                className='w-100'
                onSubmit={submitReset}
                onError={errors => console.log(errors)}
              >
                <Grid item xs={12} style={{ margin: '15px 15px' }}>
                  <TextValidator
                    className={classes.passInput}
                    label={t('new_password')}
                    fullWidth={true}
                    onChange={e => setPassword(e.target.value)}
                    variant='outlined'
                    name='password'
                    size='small'
                    placeholder={t('new_password')}
                    type={showPassword ? 'text' : 'password'}
                    validators={['required']}
                    errorMessages={['Please enter password']}
                    value={password}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start' classes={{ positionStart: '0px' }}>
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '15px 15px' }}>
                  <TextValidator
                    className={classes.passInput}
                    label={t('retype_new_password')}
                    fullWidth={true}
                    onChange={e => setConfirmPassword(e.target.value)}
                    variant='outlined'
                    name='confirmPassword'
                    size='small'
                    placeholder={t('retype_new_password')}
                    type={showPassword ? 'text' : 'password'}
                    validators={['required']}
                    errorMessages={['Please enter confirm password']}
                    value={confirmPassword}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='start' classes={{ positionStart: '0px' }}>
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <div
                  className='finish'
                  style={{
                    marginBottom: '0rem',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 9
                  }}
                >
                  <button
                    className='finish-btn  text-uppercase'
                    style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF', height: '3rem' }}
                  >
                    {t('reset_password')}
                  </button>
                </div>
              </ValidatorForm>
            </div>
          </div>
        )
      )}
    </ThemeProvider>
  )
}
export default withNamespaces()(DesktopForgotPassword)
