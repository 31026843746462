import { Box, Button, TextField } from '@material-ui/core'
import React, { useEffect } from 'react'
import Amount from '../../../../components/common/Amount'
import ButtonCustom from '../../../../components/common/ButtonCustom'
import Card from '../../../../components/common/Card'
import { useStyles } from './RightCheckout.style'
import { t } from 'i18next'
import i18n from '../../../language/localization'
// import { getPaymentProductUser } from "../../../../store/actions/getPaymentActions";
import { baseUrlApi } from '../../../../helper/api'
import { useSelector } from 'react-redux'
import { getFormatCurrency } from 'utils/helper'

const RightCheckout = props => {
  const {
    radioPoBankTrans,
    singleShipping,
    checkOut,
    getCartData,
    isBank,
    webThemeColor,
    loadingBtn,
    ppnTaxShippingAmount,
    fixTaxAmount
  } = props
  const classes = useStyles()
  const { checkoutCartItem, isCheckout } = useSelector(state => state.cart)
  const [uniqueCode, setUniquecode] = React.useState()
  const [uniqueCodeStatus, setUniquecodeStatus] = React.useState()
  const OrderDetail = checkoutCartItem.filter(item => item.cart_item.checked_out)
  const checkoutpage = localStorage.getItem('checkoutPage') // to detect user click button hceckout or not
  const total = OrderDetail.reduce(
    (acc, val) => acc + (val.price - val.discount_price) * val.checked_out_quantity,
    0
  )
  const grand_total = props.shippingCost + total
  // const grand_total2 = grand_total + parseInt(getCartData.cart_additional_fee);

  const ReturnTotal = () => {
    const ShippingCost = getCartData
      ? getCartData.selfpickup_is_active == '1'
        ? getCartData.selfpickup[0].price
        : null
      : null
    if (isCheckout) {
      if (isBank) {
        if (uniqueCodeStatus == 1) {
          return grand_total + parseInt(uniqueCode) + ppnTaxShippingAmount + fixTaxAmount
        } else {
          return grand_total + ppnTaxShippingAmount + fixTaxAmount
        }
      } else {
        return grand_total + ppnTaxShippingAmount + fixTaxAmount
      }
    } else {
      if (isBank) {
        if (uniqueCodeStatus == 1) {
          return grand_total + parseInt(uniqueCode) + ShippingCost
        } else {
          return grand_total + ShippingCost
        }
      } else {
        return (
          // grand_total + parseInt(getCartData.cart_additional_fee) + ShippingCost
          grand_total + ShippingCost
        )
      }
    }
  }

  const BankData = () => {
    let data = new FormData()
    fetch(`${baseUrlApi()}/Api/Dashboard/getBankData`, {
      method: 'POST',
      headers: {
        key: 'c4c5fc54cd5c071f3fe4785b8ae167e0',
        token: '81506e6ff4a12e7f0e207a52e16d6735',
        'Access-Control-Allow-Origin': '*'
      },
      body: data
    })
      .then(res => res.json())
      .then(res => {
        setUniquecodeStatus(res.unique_code)
      })
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    // BankData();
  }, [])

  useEffect(() => {
    // let data = new FormData();
    // data.append("payment_method", 1);
    // data.append("bank_id", 3);
    // fetch(`${baseUrlApi()}/Api/Order/uniquePaymentCode`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     token: "81506e6ff4a12e7f0e207a52e16d6735",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setUniquecode(res.unique_payment_code);
    //     // setUniquecodeStatus(res.ResponseCode);
    //     console.log("res.unique_payment_code", res.unique_payment_code);
    //   });
  }, [])

  // console.log("props.CODfees props.CODfees", parseInt(props.CODfees) + grand_total)
  // console.log("props.CODfees", grand_total2)

  const LSshipingCost = localStorage.getItem('shipingCost')

  const totalItem = OrderDetail.map(cart => {
    return cart.cart_item.checked_out_quantity
  })

  const totalWeight = OrderDetail.map(cart => {
    return cart.cart_item.weight * cart.cart_item.checked_out_quantity
  })

  const totalPrice = OrderDetail.map(cart => {
    return cart.cart_item.price * cart.cart_item.checked_out_quantity
  })

  const listData = [
    {
      lable: `${t('Total items')}`,
      value: `${totalItem.reduce((prev, next) => prev + next, 0)} Pcs`
    },
    {
      lable: `${t('Total weight')}`,
      value: `${getFormatCurrency(totalWeight.reduce((prev, next) => prev + next, 0))} Gram`
    },
    {
      lable: `${t('Total order')}`,
      value: <Amount amount={totalPrice.reduce((prev, next) => prev + next, 0)} />,
      valueText: 'Rp'
    },
    {
      lable: isBank ? (uniqueCodeStatus == 1 ? `${t('Unique Code')}` : null) : null,
      value: isBank ? (
        uniqueCodeStatus == 1 ? (
          <Amount amount={parseInt(uniqueCode)} />
        ) : null
      ) : null,
      valueText: isBank ? (uniqueCodeStatus == 1 ? 'Rp' : null) : null
    },

    {
      lable: `${t('voucher')}`,
      value: <Amount amount={'0'} />,
      valueText: 'Rp'
    },
    {
      lable: `${t('shipping_cost')}`,
      value: <Amount amount={props.shippingCost == '0' ? '0' : props.shippingCost} />,

      valueText: 'Rp'
    },
    {
      lable: `${t('ppn_shipping_tax')}`,
      value: <Amount amount={ppnTaxShippingAmount} style={{ fontWeight: '600' }} />,
      valueText: 'Rp'
    },
    {
      lable: `${t('fix_tax')}`,
      value: <Amount amount={fixTaxAmount} style={{ fontWeight: '600' }} />,
      valueText: 'Rp'
    },

    {
      lable: <label style={{ fontWeight: '600' }}>{t('grand_total')}</label>,
      value: (
        <Amount amount={OrderDetail ? props.grandTotal : null} style={{ fontWeight: '600' }} />
        // <Amount
        //   amount={OrderDetail ? ReturnTotal() : null}
        //   style={{ fontWeight: "600" }}
        // />
      ),
      valueText: <label style={{ fontWeight: '600' }}>Rp</label>
    }
  ]

  const listData2 = [
    {
      lable: `${t('Total items')}`,
      value: `${totalItem.reduce((prev, next) => prev + next, 0)} Pcs`
    },
    {
      lable: `${t('Total weight')}`,
      value: `${getFormatCurrency(totalWeight.reduce((prev, next) => prev + next, 0))} Gram`
    },
    {
      lable: `${t('Total order')}`,
      value: <Amount amount={totalPrice.reduce((prev, next) => prev + next, 0)} />,
      valueText: 'Rp'
    },
    // {
    //   lable: isBank
    //     ? uniqueCodeStatus == 1
    //       ? "Unique Code"
    //       : null
    //     : "COD Fees",

    //   value: isBank ? (
    //     <div>
    //       {uniqueCodeStatus == 1 ? (
    //         <Amount amount={parseInt(uniqueCode)} />
    //       ) : null}
    //     </div>
    //   ) : (
    //     <Amount amount={0} />
    //     // <Amount amount={getCartData.cart_additional_fee} />
    //   ),
    //   valueText: isBank ? (uniqueCodeStatus == 1 ? "Rp" : null) : null,
    // },
    // {
    //   lable: `${t("voucher")}`,
    //   value: <Amount amount={"0"} />,
    //   valueText: "Rp",
    // },
    // {
    //   lable: `${t("shipping_cost")}`,
    //   value: (
    //     <Amount
    //       amount={
    //         getCartData
    //           ? getCartData.selfpickup_is_active == "1"
    //             ? getCartData.selfpickup[0].price.toString()
    //             : "0"
    //           : null
    //       }
    //     />
    //   ),

    //   valueText: "Rp",
    // },

    {
      lable: <label style={{ fontWeight: '600' }}>{t('grand_total')}</label>,
      value: (
        <Amount amount={OrderDetail ? props.grandTotal : null} style={{ fontWeight: '600' }} />
      ),
      valueText: <label style={{ fontWeight: '600' }}>Rp</label>
    }
  ]

  // console.log(
  //   "getCartData selfpickup",
  //   getCartData &&
  //     getCartData.selfpickup.reduce((acc, val) => acc + val.price, 0)
  // );
  const ListItem = props => {
    console.log('props.lable', props.lable)
    return (
      <Box className={classes.listStyle}>
        <h4 className='lable'>{props.lable}</h4>
        <div className={classes.valueText}>
          <h4 className='lable'>{props.valueText}</h4>
          <h4 className='lable'>{props.value}</h4>
        </div>
      </Box>
    )
  }

  // console.log("radioPoBankTrans__",radioPoBankTrans)

  // const handealPlaceOrder =()=> {
  //   props.PlaceOrderClick;
  //   console.log("singleShipping__right", singleShipping)
  // }
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <Card className={classes.cardStyle}>
      <header>
        <label className='heading'>{t('shopping_summary')}</label>
      </header>
      <Box className='cardContent'>
        <div className={classes.applyInput}>
          <TextField
            width='full'
            id='outlined-secondary'
            label='Code Promo'
            variant='outlined'
            size='small'
            className={classes.borderColorTextfield}
          />
          <Button variant='outlined' style={{ color: webThemeColor, borderColor: webThemeColor }}>
            Apply
          </Button>
        </div>
        <Box className='listItems'>
          {isCheckout
            ? listData.map(data =>
                (() => {
                  if (data.lable) {
                    return (
                      <ListItem lable={data.lable} valueText={data.valueText} value={data.value} />
                    )
                  }
                  return null
                })()
              )
            : listData2.map(data =>
                (() => {
                  if (data.lable) {
                    return (
                      <ListItem lable={data.lable} valueText={data.valueText} value={data.value} />
                    )
                  }
                  return null
                })()
              )}
        </Box>
        {isCheckout ? (
          <ButtonCustom
            bg={webThemeColor}
            width='full'
            className={classes.btn_fullwidth11}
            onClick={props.PlaceOrderClick}
          >
            Checkout
            {loadingBtn ? null : null}
          </ButtonCustom>
        ) : (
          <ButtonCustom
            bg={webThemeColor}
            width='full'
            className={classes.btn_fullwidth11}
            onClick={props.PlaceOrderClickPayonspot}
          >
            Place To Order
            {loadingBtn ? null : null}
          </ButtonCustom>
        )}
        <p className='side-terms'>
          Upon clicking {`"Place Order"`}, I confirm I have read and Acknowledge all
          <u> terms and policies.</u>
        </p>
      </Box>
    </Card>
  )
}

export default RightCheckout
