import React, { useState, useEffect } from 'react'
import Header from '../header/header2'
import './paymentMethod.css'
// import { getPaymentProductUser } from "../../store/actions/getPaymentActions";
// import { GetPaymentDataResponse } from "../../store/Selectors/getpaymentdata";
import { useHistory } from 'react-router-dom'
// import { useDispatch } from "react-redux";
import Loader from '../loader'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
import {
  get as getVendorBankData,
  getCOD,
  getMootaBank as getMootaBankData
} from '../../api/payment-method'
import { priceFormatLocal } from '../../utils/helper'
import { ArrowDown2, ArrowUp2 } from 'iconsax-react'
import { Radio } from '@mui/material'

const installments = [
  {
    number: '1st',
    amount: '0.00'
  },
  {
    number: '2nd',
    amount: '75.00'
  },
  {
    number: '3rd',
    amount: '75.00'
  },
  {
    number: '4th',
    amount: '75.00'
  }
]

const PaymentMethod = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  // bankdata
  const bankSelected = localStorage.getItem('bankdata')
    ? JSON.parse(localStorage.getItem('bankdata'))
    : null
  const [true1, setTrue1] = useState(true)
  const [open, setOpen] = useState('')
  const [open1, setOpen1] = useState(true)
  const [open2, setOpen2] = useState(true)
  const [vendorBankDatas, setVendorBankDatas] = useState([])
  const [mootaBankDatas, setMootaBankDatas] = useState({})
  const [codData, setCodData] = useState({})
  const history = useHistory()
  // const dispatch = useDispatch();

  const getPayment = async () => {
    setTrue1(false)
    const UserId = JSON.parse(localStorage.getItem('userid'))
    const vendorBankDataResult = await getVendorBankData(`{"customer_id":${UserId}}`)
    setVendorBankDatas(vendorBankDataResult)

    const mootaBankDataResult = await getMootaBankData(`{"customer_id":${UserId}}`)
    if (mootaBankDataResult.success) {
      if (mootaBankDataResult.data.status === true) {
        setMootaBankDatas(mootaBankDataResult.data)
      }
    }

    const codDataResult = await getCOD()
    if (codDataResult.success) {
      const payOnSpot = JSON.parse(localStorage.getItem('pay_spot'))
      if (codDataResult.data.is_active === true || payOnSpot === 'active') {
        setCodData(codDataResult.data)
      }
    }
    setOpen(true)
    setTrue1(true)
  }

  useEffect(() => {
    getPayment()
  }, [])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    // let data = new FormData();
    // dispatch(getSplashDataUser(data));
  }, [])

  // const payData = GetPaymentDataResponse();
  // const data = GetSplashResponse()
  const totalprice = JSON.parse(localStorage.getItem('totalprice'))
  const payspot = JSON.parse(localStorage.getItem('pay_spot'))
  return (
    <React.Fragment>
      {true1 ? (
        <React.Fragment>
          <Header title={t('payment_method')} color={ThemeColor} />
          <div
            className='shadow-card ps-2 pe-2'
            style={{
              paddingTop: ' 48px'
            }}
          >
            <div
              className='d-flex justify-content-between payment-box pe-2 rounded shadow-card'
              style={{
                paddingTop: ' 15px',
                paddingBottom: ' 15px',
                paddingRight: '10px',
                paddingLeft: '10px',
                margin: '10px'
              }}
            >
              <h6
                style={{
                  marginLeft: '5px',
                  fontSize: '18px',
                  marginBottom: '0px',
                  MozUserSelect: 'none',
                  WebkitUserSelect: 'none',
                  MsUserSelect: 'none',
                  userSelect: 'none'
                }}
              >
                Total
              </h6>
              <h6 style={{ fontSize: '18px', marginBottom: '0px' }}>
                {priceFormatLocal(totalprice)}
              </h6>
            </div>

            {codData && Object.keys(codData).length > 0 ? (
              <div
                className='payment-box rounded shadow-card'
                style={{ margin: '10px' }}
                onClick={() => {
                  Object.assign(codData, { type: 'Cash on Delivery' })
                  localStorage.setItem('bankdata', JSON.stringify(codData))
                  localStorage.setItem('unique_code', '0')
                  history.push('/orderconfirm')
                }}
              >
                <div
                  className='d-flex justify-content-left'
                  style={{
                    marginLeft: '14px',
                    marginBottom: '0rem',
                    MozUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    MsUserSelect: 'none',
                    userSelect: 'none'
                  }}
                >
                  <img
                    src={
                      codData.logo
                        ? process.env.REACT_APP_FILE_BASE_URL + '/' + codData.logo
                        : '/assets/images/dummy.png'
                    }
                    height='35px'
                    width='50px'
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'flex-start',
                      marginTop: '10px',
                      objectFit: 'contain'
                    }}
                  />

                  <span
                    style={{
                      fontSize: '15px',
                      display: 'flex',
                      alignItems: 'center',
                      // justifyContent: "center",
                      marginLeft: '16px',
                      width: '100%',
                      MozUserSelect: 'none',
                      WebkitUserSelect: 'none',
                      MsUserSelect: 'none',
                      userSelect: 'none'
                    }}
                  >
                    {codData.name}
                  </span>
                  <Radio
                    checked={bankSelected?.type === 'Cash on Delivery'}
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      flexDirection: 'column',
                      alignItems: 'flex-end'
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: ThemeColor ? ThemeColor : '#FFFFFF'
                      }
                    }}
                  />
                </div>
              </div>
            ) : null}

            <div className='payment-box p-2 shadow-card' style={{ margin: '10px' }}>
              <div
                style={{
                  marginLeft: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
                onClick={() => setOpen1(!open1)}
              >
                <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1'>
                  <h6
                    style={{
                      marginBottom: '0px'
                    }}
                  >
                    {t('bank_transfer_manual_confirm')}
                  </h6>
                </span>
                {open1 ? <ArrowUp2 /> : <ArrowDown2 />}
              </div>

              {open1 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '-10px',
                    marginRight: '-10px'
                  }}
                >
                  {vendorBankDatas.length > 0 ? (
                    vendorBankDatas.map((payment, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          localStorage.setItem(
                            'bankdata',
                            JSON.stringify({
                              account_no: payment.account_number,
                              account_owner_name: payment.account_name,
                              // bank_code: payment.bank_code,
                              bank_name: payment.bank_name,
                              // branch: payment.branch,
                              id: payment.id,
                              // is_active: payment.is_active,
                              // is_auto_active: payment.is_auto_active,
                              // password_ibanking: payment.password_ibanking,
                              picture: payment.bank_image
                                ? process.env.REACT_APP_FILE_BASE_URL + '/' + payment.bank_image
                                : '/assets/images/dummy.png',
                              type: 'Bank Transfer Manual'
                              // pos_active: payment.pos_active,
                              // username_ibanking: payment.username_ibanking,
                            })
                          )
                          localStorage.setItem('unique_code', '0')
                          history.push('/orderconfirm')
                        }}
                      >
                        <div
                          className='d-flex d-flex pl-2'
                          style={{
                            marginLeft: '14px',
                            marginRight: '14px',
                            borderBottom:
                              index === vendorBankDatas.length - 1 ? 'none' : '1px solid #e0e0e0',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          {/* <div className="bank-card rounded"></div> */}
                          <img
                            src={
                              payment.bank_image
                                ? process.env.REACT_APP_FILE_BASE_URL + '/' + payment.bank_image
                                : '/assets/images/dummy.png'
                            }
                            height='35px'
                            width='50px'
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'flex-start',
                              objectFit: 'contain'
                            }}
                          />
                          <span
                            style={{
                              fontSize: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              // justifyContent: "center",
                              marginLeft: '16px',
                              width: '100%',
                              MozUserSelect: 'none',
                              WebkitUserSelect: 'none',
                              MsUserSelect: 'none',
                              userSelect: 'none'
                            }}
                          >
                            {payment.account_name}
                            <br />
                            {payment.account_number}
                          </span>
                          <Radio
                            checked={
                              bankSelected?.id === payment.id &&
                              bankSelected?.type === 'Bank Transfer Manual'
                            }
                            style={{
                              marginRight: '-12px',
                              display: 'flex',
                              justifyContent: 'right',
                              flexDirection: 'column',
                              alignItems: 'flex-end'
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: ThemeColor ? ThemeColor : '#FFFFFF'
                              }
                            }}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <b>No Data Found</b>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className='payment-box p-2 shadow-card' style={{ margin: '10px' }}>
              <div
                style={{
                  marginLeft: '5px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
                onClick={() => setOpen2(!open2)}
              >
                <span className='MuiTypography-root MuiFormControlLabel-label MuiTypography-body1'>
                  <h6
                    style={{
                      marginBottom: '0px'
                    }}
                  >
                    {t('bank_transfer_auto_confirm')}
                  </h6>
                </span>
                {open2 ? <ArrowUp2 /> : <ArrowDown2 />}
              </div>

              {open2 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '-10px',
                    marginRight: '-10px'
                  }}
                >
                  {Object.keys(mootaBankDatas).length > 0 ? (
                    <div
                      key={mootaBankDatas.id}
                      onClick={() => {
                        localStorage.setItem(
                          'bankdata',
                          JSON.stringify({
                            account_no: mootaBankDatas.account_number,
                            account_owner_name: mootaBankDatas.account_name,
                            // bank_code: payment.bank_code,
                            bank_name: mootaBankDatas.bank_name,
                            // branch: payment.branch,
                            id: mootaBankDatas.id,
                            // is_active: payment.is_active,
                            // is_auto_active: payment.is_auto_active,
                            // password_ibanking: payment.password_ibanking,
                            picture: mootaBankDatas.bank_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                '/' +
                                mootaBankDatas.bank_image
                              : '/assets/images/dummy.png',
                            type: 'Bank Transfer Otomatis'
                            // pos_active: payment.pos_active,
                            // username_ibanking: payment.username_ibanking,
                          })
                        )
                        const uniqueCode = localStorage.getItem('unique_code') || '0'
                        if (uniqueCode === '0') {
                          localStorage.setItem('unique_code', '0')
                        }
                        history.push('/orderconfirm')
                      }}
                    >
                      <div
                        className='d-flex d-flex pl-2 rounded'
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginLeft: '14px',
                          marginRight: '14px'
                        }}
                      >
                        {/* <div className="bank-card rounded"></div> */}
                        <img
                          src={
                            mootaBankDatas.bank_image
                              ? process.env.REACT_APP_FILE_BASE_URL +
                                '/' +
                                mootaBankDatas.bank_image
                              : '/assets/images/dummy.png'
                          }
                          height='35px'
                          width='50px'
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'flex-start',
                            objectFit: 'contain'
                          }}
                        />
                        <span
                          style={{
                            fontSize: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            // justifyContent: "center",
                            marginLeft: '16px',
                            width: '100%',
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none'
                          }}
                        >
                          {mootaBankDatas.account_name}
                          <br />
                          {mootaBankDatas.account_number}
                        </span>
                        <Radio
                          checked={
                            bankSelected?.id === mootaBankDatas.id &&
                            bankSelected?.type === 'Bank Transfer Otomatis'
                          }
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            marginRight: '-12px'
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <b>No Data Found</b>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: 'white'
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
    </React.Fragment>
  )
}

export default withNamespaces()(PaymentMethod)
