import { Box, Container, Grid } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../home/DesktopFooter'
import { useStyles } from './AddToCart.style'
import CartLeft from './compo/left/CartLeft'
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'

const AddToCart = props => {
  const { token } = useAuth()

  const { webThemeColor } = props
  const classes = useStyles()
  const [itemsCart, setItems] = useState(0)
  const [categoriesData, setCategoriesData] = useState([])
  const Usercode1 = JSON.parse(localStorage.getItem('usercode'))
  const mycartdata = async () => {
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }

  useEffect(() => {
    mycartdata()

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }
  }, [Usercode1])

  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <div className={classes.body}>
      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={'rgb(231, 0, 116)'}
        cartNumber={itemsCart}
        categoriesData={categoriesData}
      />
      <Container>
        <Box className={classes.root_addtocart}>
          <h5 className={classes.pageTitle}></h5>

          <Grid container spacing={2}>
            <CartLeft webThemeColor={webThemeColor} />
          </Grid>
        </Box>
      </Container>
      <DeskTopFooter />
    </div>
  )
}

export default AddToCart
