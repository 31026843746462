import React from 'react'
import { Dialog as MuiDialog, Typography, Box, Button } from '@material-ui/core'

const BasicModal = ({ isOpen, title, content, onClose }) => {
  return (
    <MuiDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
      >
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body1'>{content}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={onClose}>OK</Button>
        </Box>
      </Box>
    </MuiDialog>
  )
}

export default BasicModal
