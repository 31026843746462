import React, { useState, useEffect, useRef } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons//Visibility'
import VisibilityOff from '@material-ui/icons//VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
// import { signupUser } from "../../store/actions/userActions";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import BeatLoader from 'react-spinners/BeatLoader'
// import { LoginResponse, ValResponse } from "../../store/Selectors/user";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import Cookies from 'universal-cookie'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import FacebookIcon from '@material-ui/icons/Facebook';
// import GoogleIcon from '@mui/icons-material/Google';
import DesktopForgotPassword from './DesktopForgetPassword'
// import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import { signUp } from '../../api/authorization'
import Snackbar from '@material-ui/core/Snackbar'
import { addCodeNumber } from '../../utils/addCodeNumberPhone'
import { useAuth } from '../../hooks/useAuth'
import { FormControlLabel } from '@mui/material'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography component='div' role='tabpanel' hidden={value !== index} {...other}>
      <Box p={3} style={{ padding: '0px' }}>
        {children}
      </Box>
    </Typography>
  )
}

const DesktopLoginForm = ({ t, tabIndexOpen, LoginFunction, SignUpFunction }) => {
  const auth = useAuth()

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  // const loginres = LoginResponse();
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })

  const useStyles = makeStyles(theme => ({
    main_root: {
      '& .MuiAppBar-root': {
        width: '96% !important'
      }
    },

    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: 'white'
      // minHeight:"700px",
      // border: "1px solid #d3d3d3a3"
      // backgroundColor: theme.palette.background.paper
    },
    buttonColor: {
      '&> .finish-btn': {
        backgroundColor: `${ThemeColor ? ThemeColor : '#FFFFFF'} !important`
      }
    },
    select: {
      // width: '100px !important',

      '&> .MuiTabs-fixed.MuiTabs-flexContainer.Mui-selected': {
        color: `${ThemeColor ? ThemeColor : '#FFFFFF'} !important`
      },
      '& .MuiTab-root': {
        minWidth: 'auto'
      }
    },
    googlebutton: {
      '& button': {
        display: 'none'
      }
    },

    rememberMe: {
      '& .MuiCheckbox-root': {
        padding: '0px'
      }
    },

    passInput: {
      '& .MuiIconButton-root': {
        padding: '0'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0'
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: '0'
      }
    },

    tabsStyle: {
      // "& .MuiTabs-flexContainer": {
      //   WebkitBoxPack: "unset !important",
      //   justifyContent: "normal !important",
      // },
      // // "& .MuiTab-root": {
      // //   width: '30% !important',
      // // },
      // "@media(min-width: 600px)": {
      //   "& .MuiTab-root": {
      //     width: '35% !important',
      //     minWidth: 'auto !important',
      //   },
      // },
    }
  }))
  let type = 'password'
  const classes = useStyles()
  const history = useHistory()
  const googleref1 = useRef(null)
  const [value, setValue] = React.useState(tabIndexOpen)
  const [divop, setDivop] = React.useState(false)
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false
  })
  const [login, setLogin] = React.useState({
    user: '',
    password: ''
  })
  const [signup, setSignup] = React.useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    language_id: 1
  })
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    if (showPassword) {
      setShowPassword(false)
    } else {
      setShowPassword(true)
    }
  }
  const [showPassword2, setShowPassword2] = useState(false)
  const handleClickShowPassword2 = () => {
    if (showPassword2) {
      setShowPassword2(false)
    } else {
      setShowPassword2(true)
    }
  }
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }
  const handleChangeSignup = e => {
    setSignup({ ...signup, [e.target.name]: e.target.value })
  }
  const handleChangeLogin = e => {
    setLogin({ ...login, [e.target.name]: e.target.value })
  }
  const [loginmsg, setLoginMsg] = useState({
    open: false,
    message: ''
  })
  const [signupmsg, setSignupMsg] = useState({
    open: false,
    message: ''
  })
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    }
  }
  // const handleSubmit = (props) => {
  //   alert("signup done");
  // };

  const cookies = new Cookies()
  const rememberfun = e => {
    setCheck(e.target.checked)
  }

  function handleChangeTab(event, newValue) {
    setValue(newValue)
  }

  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [check, setCheck] = useState(false)
  const [whatsapp, setWhatsapp] = useState(false)
  const [isForgetpass, setForget] = useState(false)
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))

  // const dispatch = useDispatch();
  const random = () => {
    return Math.floor(1000 + Math.random() * 90000000)
  }

  const handleSubmitSignUp = async () => {
    if (
      signup.name !== '' &&
      signup.email !== '' &&
      signup.phone !== '' &&
      signup.password !== ''
    ) {
      // show loading
      setOpen2(true)

      const data = {
        name: signup.name,
        email: signup.email,
        phone: addCodeNumber(signup.phone),
        password: signup.password,
        // hardcode
        language_id: signup.language_id
      }

      const response = await signUp(data)
      if (response.success) {
        setSignup({
          ...signup,
          name: '',
          email: '',
          phone: '',
          password: '',
          language_id: 1
        })
        setValue(0)
        setSignupMsg({
          open: true,
          message: 'Pendaftaran berhasil, silahkan login kembali untuk mengakses halaman utama'
        })
      } else {
        setSignupMsg({
          open: true,
          message: response.message
        })
      }

      // hide loading
      setOpen2(false)
    }
  }

  // hardcode profile
  const profileHardcode = {
    customer: {
      id: 1,
      vendor_id: 102,
      name: 'John Doee',
      username: 'johndoee',
      code: '1d646a6a8d9c9a675b3a6d270fd3f333',
      email: 'johndoe@example.com',
      phone: '555-12345888',
      profile_picture: 'customers/profile_picture/f8fe1a06-5d60-4a1c-a0bd-2603e5af0356.jpg',
      status: 'Active',
      language_id: 1,
      points: 0,
      membership_id: 740,
      maximum_order_qty_product_in_cart: 100,
      firebase_token:
        'dBM18ilFToeOFdDJfZ_SGd:APA91bEAWP460KnQ5C75b7J_PvJiBvo0QpD5J-ymYsUD62e0RB2GEeXYGA62VLkf_4G9eK8AgvN-zHIbZBpDCFH31CXKoSP5nbIuJ4cj3MdxTs3A28PKf83xntC1jV1KTEDaf4mi5gGG',
      last_login: '2024-02-02T15:51:41.434487Z',
      deleted_at: null,
      created_at: '2023-09-11T05:25:50.791144Z',
      created_by: null,
      updated_at: '2024-01-21T00:23:33.729701Z',
      updated_by: 125
    },
    language: {
      id: 1,
      code: 'ind',
      name: 'Indonesian',
      native_name: 'Bahasa Indonesia',
      created_at: '2023-09-11T05:25:49.539132Z',
      created_by: 0,
      updated_at: '0001-01-01T00:00:00Z',
      updated_by: null
    },
    membership: {
      id: 740,
      vendor_id: 102,
      level: 1,
      name: 'Selling Price',
      points: 0,
      is_active: true,
      created_at: '2023-11-27T06:10:11.389268Z',
      created_by: null,
      updated_at: '2023-11-28T09:22:57.860696Z',
      updated_by: 125
    }
  }

  const Login = async () => {
    let user = login.user
    let password = login.password

    if (user && password) {
      const data = {
        user: addCodeNumber(user),
        password: password
      }

      const response = await auth.login(data.user, data.password)

      if (!response.success) {
        setLoginMsg({
          open: true,
          message: response.data || response.message
        })
      } else {
        if (check) {
          cookies.set('remember', 'true')
          cookies.set('user', login.user)
          cookies.set('password', login.password)
        } else {
          cookies.remove('remember')
          cookies.remove('user')
          cookies.remove('password')
        }

        LoginFunction()
        history.go(0)
      }
    }
  }

  // if (open1 === true) {
  //   setTimeout(() => {
  //     setLoginMsg(true);
  //     setOpen1(false)
  //   }, 400);
  //   LoginFunction();
  // }

  // if (open2 === true) {
  //   setTimeout(() => {
  //     setSignupMsg(true);
  //     setOpen2(false);
  //     SignUpFunction();
  //   }, 100);
  // }

  // if (loginmsg === true) {
  //   setTimeout(() => {
  //     setLoginMsg(false);
  //   }, 2000);
  // }

  // const data = ValResponse();
  // if (signupmsg === true) {
  //   setTimeout(() => {
  //     setSignupMsg(false);
  //     if (data === true) {
  //       setSignup({
  //         ...signup,
  //         username: "",
  //         phone: "",
  //         password: "",
  //       });
  //       setValue(0);
  //     }
  //   }, 100);
  // }

  useEffect(() => {
    let data = new FormData()
    // dispatch(getSplashDataUser(data));
    setDivop(true)
  }, [])
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])
  const landingPageValue = JSON.parse(localStorage.getItem('landing_page'))
  const landing = JSON.parse(localStorage.getItem('Lanpage'))
  const lang = JSON.parse(localStorage.getItem('Language'))

  // const responseGoogle = (response) => {
  //   console.log(response);
  // }

  // const [login, setLogin] = useState(false);
  // const [data, setData] = useState({});
  // const [picture, setPicture] = useState('');

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   // setData(response);
  //   // setPicture(response.picture.data.url);
  //   // if (response.accessToken) {
  //   //   setLogin(true);
  //   // } else {
  //   //   setLogin(false);
  //   // }
  // }

  // <GoogleLogin
  //   clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
  //   // buttonText="Login"
  //   onSuccess={responseGoogle}
  //   onFailure={responseGoogle}
  //   cookiePolicy={'single_host_origin'}
  //   style={{ backgroundColor: "yellow !important" }}
  //   ref={googleref1}
  // />
  // <FacebookLogin
  //   appId="921201001964201"
  //   autoLoad={true}
  //   fields="name,email,picture"
  //   scope="public_profile,user_friends"
  //   callback={responseFacebook}
  //   icon="fa-facebook" />

  useEffect(() => {
    // check remember me
    if (cookies.get('remember') == 'true') {
      setLogin({
        user: cookies.get('user'),
        password: cookies.get('password')
      })

      setCheck(true)
    }
  }, [])

  return (
    <div
      className={classes.main_root}
      style={{
        width: '90%',
        margin: '0px auto',
        marginTop: '-20px'
      }}
    >
      {isForgetpass ? (
        <DesktopForgotPassword CancleClick={() => setForget(false)} />
      ) : (
        <div>
          <ThemeProvider theme={theme}>
            <div className={classes.root}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {landing ? (
                  landing.landing_page == 0 ? (
                    <div
                      style={{
                        borderBottom: `2px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                        boxShadow:
                          '0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 2%)'
                      }}
                    >
                      {/* <KeyboardBackspaceIcon
                        style={{ color: ThemeColor ? ThemeColor : "#FFFFFF", width: "25px" }}
                        onClick={() => {
                          history.goBack();
                        }}
                      /> */}
                    </div>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
                <AppBar
                  position='static'
                  color='transparent'
                  className='shadow-card'
                  elevation={1}
                  style={{
                    width: landing ? (landing.landing_page == 0 ? '100%' : '100%') : '',
                    boxShadow:
                      '0px 2px 1px -1px rgb(0 0 0 / 10%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 2%)'
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor='primary'
                    // textColor="pink"
                    style={{ color: ThemeColor ? ThemeColor : '#FFFFFF' }}
                    className={classes.tabsStyle}
                  >
                    <Tab
                      label={t('login1')}
                      className={classes.select}
                      {...a11yProps(1)}
                      style={{ width: '50%' }}
                    />
                    <Tab
                      className={classes.select}
                      label={t('register1')}
                      {...a11yProps(1)}
                      style={{ width: '50%' }}
                    />
                  </Tabs>
                </AppBar>
              </div>
              <div className='p-0 d-flex align-items-center justify-content-center'>
                <TabPanel value={value} index={0} className='w-100'>
                  {whatsapp ? (
                    <ValidatorForm
                      onSubmit={() => {
                        console.log('hello')
                      }}
                    >
                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          label='Phone Number'
                          fullWidth={true}
                          onChange={handleChangeLogin}
                          name='phonenumber'
                          variant='standerd'
                          validators={['required']}
                          errorMessages={['Please enter phone number']}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                      </Grid>

                      <div className={classes.buttonColor + ' ' + ' finish'}>
                        <button className='finish-btn text-uppercase mt-1'>
                          {open1 === true ? (
                            <div style={{ paddingTop: '3px' }}>
                              <BeatLoader size={12} color={'white'} />
                            </div>
                          ) : (
                            'Continue'
                          )}
                        </button>
                      </div>
                    </ValidatorForm>
                  ) : (
                    <ValidatorForm
                      // useRef="form"
                      onSubmit={() => {
                        Login()
                      }}
                    >
                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          label={t('phonenumber_email_username')}
                          variant='outlined'
                          fullWidth={true}
                          onChange={handleChangeLogin}
                          size='small'
                          name='user'
                          placeholder='tesssssss'
                          value={login.user}
                          // variant="standerd"
                          validators={['required']}
                          errorMessages={['Please enter username']}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          className={classes.passInput}
                          label={t('password')}
                          fullWidth={true}
                          onChange={handleChangeLogin}
                          variant='outlined'
                          name='password'
                          size='small'
                          placeholder={t('password')}
                          type={showPassword ? 'text' : 'password'}
                          validators={['required']}
                          errorMessages={['Please enter password']}
                          value={login.password}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                          InputProps={{
                            endAdornment:
                              type === 'password' ? (
                                <InputAdornment position='start' classes={{ positionStart: '0px' }}>
                                  <IconButton onClick={handleClickShowPassword}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ) : null
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} className='mb-0 mx-1' style={{}}>
                        <div className={`${classes.rememberMe} d-flex justify-content-between `}>
                          <div
                            style={{
                              marginTop: '-8px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                '&.MuiFormControlLabel-root': {
                                  marginLeft: 0,
                                  marginBottom: 'none'
                                },
                                '& .MuiFormControlLabel-label': {
                                  marginLeft: '-8px !important'
                                }
                              }}
                              control={
                                <Checkbox
                                  checked={check}
                                  onChange={rememberfun}
                                  style={{
                                    color: ThemeColor ? ThemeColor : '#FFFFFF'
                                  }}
                                />
                              }
                              label={t('remember_me')}
                            />
                          </div>
                          <p
                            className=''
                            onClick={() => {
                              setForget(true)
                            }}
                            style={{
                              color: ThemeColor ? ThemeColor : '#FFFFFF',
                              backgroundColor: 'white',
                              cursor: 'pointer',
                              marginTop: '2px',
                              marginRight: '10px'
                            }}
                          >
                            {t('forgot_password')}
                          </p>
                        </div>
                      </Grid>
                      <div className={classes.buttonColor + ' ' + ' finish'}>
                        <button
                          className='finish-btn text-uppercase mt-1'
                          // onClick={() => {
                          //   Login();
                          // }}
                        >
                          {open1 === true ? (
                            <div style={{ paddingTop: '3px' }}>
                              <BeatLoader size={12} color={'white'} />
                            </div>
                          ) : (
                            t('login1')
                          )}
                        </button>
                      </div>
                      {/* belum done login socmed */}
                      {/* {
                        landingPageValue && landingPageValue.data?
                        landingPageValue.data.login_register_whatsapp === 1 || landingPageValue.data.facebook_login===1 || landingPageValue.data.google_login === 1?
                         <div style={{ margin: "14px" }}>
                         <div style={{ borderBottom: "1px solid grey" }}></div>
                         <div style={{ marginTop: "-12px", display: "flex" }}>
                           <div
                             style={{
                               backgroundColor: "transparent",
                               width: "42.5%",
                             }}
                           ></div>
                           <div
                             style={{
                               borderRadius: "50px",
                               backgroundColor: "white",
                               width: "15%",
                               textAlign: "center",
                             }}
                           >
                             OR
                           </div>
                           <div
                             style={{
                               backgroundColor: "transparent",
                               width: "42.5%",
                             }}
                           ></div>
                         </div>
                       </div>
                       :null
                       :null
                       } */}
                      {/* <div
                          style={{ margin: "14px" }}
                          className={classes.googlebutton}
                        >
                          {landingPageValue&&landingPageValue.data?landingPageValue.data.login_register_whatsapp === 1 ? (
                            <div
                              // onClick={() => {
                              //   // setWhatsapp(false);
                              // }}
                              style={{
                                height: "36px",
                                borderRadius: "3px",
                                backgroundColor: "#4acd32",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "10%" }}>
                                <WhatsAppIcon />
                              </div>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                              >
                                Login Using WhatsApp
                              </div>
                            </div>
                          ) : null:null}
                          {landingPageValue?landingPageValue.data.facebook_login == 1 ? (
                            <div
                              style={{
                                height: "36px",
                                borderRadius: "3px",
                                backgroundColor: "#455bcd",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div style={{ width: "10%" }}>
                                <BsFacebook style={{ width: "24px" }} />
                              </div>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                              >
                                Login Using FaceBook
                              </div>
                            </div>
                          ) : null:null}
                          {landingPageValue?landingPageValue.data.google_login == 1 ? (
                            <div
                              style={{
                                height: "36px",
                                borderRadius: "3px",
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid grey",
                                marginTop: "10px",
                              }}
                            >
                              <div style={{ width: "10%" }}>
                                <FcGoogle style={{ width: "24px" }} />
                              </div>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                              >
                                Login Using Google
                              </div>
                            </div>
                          ) : null:null}
                        </div> */}

                      <div
                        onClick={() => {
                          if (lang == 'id') {
                            localStorage.setItem('lg', JSON.stringify('en'))
                            localStorage.setItem('Language', JSON.stringify('en'))
                            if (landing && landing.landing_page == 1) {
                              window.location.href = '/'
                            } else {
                              // window.location.href = "/userlogin";
                            }
                          } else {
                            localStorage.setItem('lg', JSON.stringify('id'))
                            localStorage.setItem('Language', JSON.stringify('id'))
                            if (landing && landing.landing_page == 1) {
                              window.location.href = '/'
                            } else {
                              // window.location.href = "/userlogin";
                            }
                          }
                        }}
                        style={{
                          bottom: 0,
                          width: Mobile ? '100%' : '',

                          textAlign: 'center',
                          cursor: 'pointer'
                        }}
                      >
                        {/* <GTranslateIcon size="25" style={{ color: "gray" }} />
                      {lang == "id" ? "English" : "Bahasa"} */}
                      </div>
                      <div
                        style={{
                          bottom: 0,

                          marginBottom: '20px',
                          width: Mobile ? '100%' : '',

                          textAlign: 'center'
                        }}
                      >
                        {t('need_help')} ?{' '}
                        <a
                          href={`https://wa.me/${
                            landingPageValue
                              ? landingPageValue.setting_customer.phone_activations
                              : ''
                          }`}
                          target='_blank'
                          style={{
                            color: `${ThemeColor ? ThemeColor : '#FFFFFF'} !important`,
                            marginLeft: '5px'
                          }}
                          rel='noreferrer'
                        >
                          <span
                            style={{
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }}
                          >
                            {t('contact_us')}.
                          </span>
                        </a>
                      </div>
                    </ValidatorForm>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1} className='w-100'>
                  {whatsapp ? (
                    <ValidatorForm
                      onSubmit={() => {
                        console.log('hello')
                      }}
                    >
                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          label='Phone Number'
                          fullWidth={true}
                          // onChange={handleChangeLogin}
                          name='phonenumber'
                          variant='standard'
                          validators={['required']}
                          errorMessages={['Please enter phone number']}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                      </Grid>
                      <div className={classes.buttonColor + ' ' + ' finish'}>
                        <button className='finish-btn text-uppercase mt-1'>
                          {open1 === true ? (
                            <div style={{ paddingTop: '3px' }}>
                              <BeatLoader size={12} color={'white'} />
                            </div>
                          ) : (
                            'Continue'
                          )}
                        </button>
                      </div>
                    </ValidatorForm>
                  ) : (
                    <ValidatorForm
                      // useRef="form"
                      onSubmit={handleSubmitSignUp}
                      noValidate={true}
                    >
                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          id='outlined-basic'
                          label={t('name')}
                          variant='outlined'
                          size='small'
                          fullWidth={true}
                          onChange={handleChangeSignup}
                          name='name'
                          value={signup.name}
                          validators={['required']}
                          errorMessages={['Please enter name']}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          id='standard-basic'
                          label={t('email')}
                          variant='outlined'
                          size='small'
                          fullWidth={true}
                          onChange={handleChangeSignup}
                          name='email'
                          value={signup.email}
                          validators={['required', 'isEmail']}
                          errorMessages={['Please enter email', 'Please enter valid email']}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          label={t('phone_number')}
                          onChange={handleChangeSignup}
                          name='phone'
                          fullWidth={true}
                          value={signup.phone}
                          variant='outlined'
                          size='small'
                          validators={['required', 'minStringLength:10', 'maxStringLength:20']}
                          errorMessages={[
                            'Please enter phone number',
                            'Please enter valid phone number',
                            'Please enter valid phone number'
                          ]}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                        />
                      </Grid>

                      <Grid item xs={12} style={{ margin: '15px 15px' }}>
                        <TextValidator
                          className={classes.passInput}
                          label={t('password')}
                          fullWidth={true}
                          onChange={handleChangeSignup}
                          name='password'
                          type={showPassword2 ? 'text' : 'password'}
                          value={signup.password}
                          variant='outlined'
                          size='small'
                          validators={['required', 'minStringLength:6']}
                          errorMessages={['Please enter password', 'Password minimal 6 characters']}
                          InputLabelProps={{ style: { fontSize: 15 } }}
                          InputProps={{
                            endAdornment:
                              type === 'password' ? (
                                <InputAdornment position='start' classes={{ positionStart: '0px' }}>
                                  <IconButton onClick={handleClickShowPassword2}>
                                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              ) : null
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} className='mb-0 mx-1' style={{}}>
                        <div
                          // className="d-flex justify-content-between"
                          style={{
                            marginLeft: '13px',
                            marginRight: '13px',
                            fontSize: '13px'
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              flexShrink: 0,
                              flexWrap: 'wrap'
                            }}
                          >
                            <span>{t('click_to_register')}</span>
                            <span
                              style={{
                                color: ThemeColor ? ThemeColor : '#FFFFFF',
                                display: 'flex',
                                flexShrink: 0,
                                borderBottom: `0.5px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                                marginLeft: '5px',
                                cursor: 'pointer'
                              }}
                            >
                              {t('terms')}
                            </span>
                            <span
                              style={{
                                color: ThemeColor ? ThemeColor : '#FFFFFF',
                                display: 'flex',
                                flexShrink: 0,
                                borderBottom: `0.5px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                                marginRight: '5px',
                                cursor: 'pointer'
                              }}
                            >
                              {t('condition')}
                            </span>
                            {t('also')}
                            <span
                              style={{
                                color: ThemeColor ? ThemeColor : '#FFFFFF',
                                display: 'flex',
                                flexShrink: 0,
                                borderBottom: `0.5px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                                marginLeft: '5px',
                                cursor: 'pointer',
                                marginRight:
                                  JSON.parse(localStorage.getItem('Language')) == 'id' ? '5px' : 0
                              }}
                            >
                              {t('privacy')}
                            </span>
                            {t('our_shop')}
                          </span>
                        </div>
                      </Grid>
                      <div className={classes.buttonColor + ' ' + ' finish'}>
                        <button
                          className='finish-btn mt-3 text-uppercase'
                          // onClick={() => {
                          //   signUp();
                          // }}
                          // type="submit"
                        >
                          {open2 === true ? (
                            <div style={{ paddingTop: '3px' }}>
                              <BeatLoader size={12} color={'white'} />
                            </div>
                          ) : (
                            t('register1')
                          )}
                        </button>
                      </div>
                      {/* {
                        landingPageValue&&landingPageValue.data?
                        landingPageValue.data.login_register_whatsapp == 1 || landingPageValue.data.facebook_login==1 || landingPageValue.data.google_login == 1?
                         <div style={{ margin: "14px" }}>
                         <div style={{ borderBottom: "1px solid grey" }}></div>
                         <div style={{ marginTop: "-12px", display: "flex" }}>
                           <div
                             style={{
                               backgroundColor: "transparent",
                               width: "42.5%",
                             }}
                           ></div>
                           <div
                             style={{
                               borderRadius: "50px",
                               backgroundColor: "white",
                               width: "15%",
                               textAlign: "center",
                             }}
                           >
                             OR
                           </div>
                           <div
                             style={{
                               backgroundColor: "transparent",
                               width: "42.5%",
                             }}
                           ></div>
                         </div>
                       </div>
                       :null
                       :null
                       } */}
                      {/* <div
                          style={{ margin: "14px" }}
                          className={classes.googlebutton}
                        >
                          {landingPageValue&&landingPageValue.data?landingPageValue.data.login_register_whatsapp === 1 ? (
                            <div
                              // onClick={() => {
                              //   // setWhatsapp(false);
                              // }}
                              style={{
                                height: "36px",
                                borderRadius: "3px",
                                backgroundColor: "#4acd32",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "10%" }}>
                                <WhatsAppIcon />
                              </div>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                              >
                                Login Using WhatsApp
                              </div>
                            </div>
                          ) : null:null}
                          {landingPageValue?landingPageValue.data.facebook_login == 1 ? (
                            <div
                              style={{
                                height: "36px",
                                borderRadius: "3px",
                                backgroundColor: "#455bcd",
                                color: "white",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <div style={{ width: "10%" }}>
                                <BsFacebook style={{ width: "24px" }} />
                              </div>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                              >
                                Login Using FaceBook
                              </div>
                            </div>
                          ) : null:null}
                          {landingPageValue?landingPageValue.data.google_login == 1 ? (
                            <div
                              style={{
                                height: "36px",
                                borderRadius: "3px",
                                backgroundColor: "white",
                                color: "black",
                                textTransform: "capitalize",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid grey",
                                marginTop: "10px",
                              }}
                            >
                              <div style={{ width: "10%" }}>
                                <FcGoogle style={{ width: "24px" }} />
                              </div>
                              <div
                                style={{
                                  width: "90%",
                                  textAlign: "left",
                                  marginLeft: "50px",
                                }}
                              >
                                Login Using Google
                              </div>
                            </div>
                          ) : null:null}
                        </div> */}
                      {/* <div style={{ margin: "14px" }}>
                          <div
                            // onClick={() => {
                            //   // setWhatsapp(false);
                            // }}
                            style={{
                              height: "36px",
                              borderRadius: "3px",
                              backgroundColor: "#4acd32",
                              color: "white",
                              textTransform: "capitalize",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "10%" }}>
                              <WhatsAppIcon />
                            </div>
                            <div
                              style={{
                                width: "90%",
                                textAlign: "left",
                                marginLeft: "50px",
                              }}
                            >
                              Login Using WhatsApp
                            </div>
                          </div>
                          <div
                            style={{
                              height: "36px",
                              borderRadius: "3px",
                              backgroundColor: "#455bcd",
                              color: "white",
                              textTransform: "capitalize",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                            <div style={{ width: "10%" }}>
                              <BsFacebook style={{ width: "24px" }} />
                            </div>
                            <div
                              style={{
                                width: "90%",
                                textAlign: "left",
                                marginLeft: "50px",
                              }}
                            >
                              Login Using FaceBook
                            </div>
                          </div>
                          <div
                            style={{
                              height: "36px",
                              borderRadius: "3px",
                              backgroundColor: "white",
                              color: "black",
                              textTransform: "capitalize",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid grey",
                              marginTop: "10px",
                            }}
                          >
                            <div style={{ width: "10%" }}>
                              <FcGoogle style={{ width: "24px" }} />
                            </div>
                            <div
                              style={{
                                width: "90%",
                                textAlign: "left",
                                marginLeft: "50px",
                              }}
                            >
                              Login Using Google
                            </div>
                          </div>
                        </div> */}
                    </ValidatorForm>
                  )}
                </TabPanel>
              </div>
            </div>

            {loginmsg.open ? (
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={loginmsg.open}
                autoHideDuration={6000}
                onClose={() => {
                  setLoginMsg({
                    open: false,
                    message: ''
                  })
                }}
                message={loginmsg.message}
                action={
                  <Button
                    color='secondary'
                    size='small'
                    onClick={() => {
                      setLoginMsg({
                        open: false,
                        message: ''
                      })
                    }}
                  >
                    OK
                  </Button>
                }
              />
            ) : (
              ''
            )}

            {signupmsg.open ? (
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={signupmsg.open}
                autoHideDuration={6000}
                onClose={() => {
                  setSignupMsg({
                    open: false,
                    message: ''
                  })
                }}
                message={signupmsg.message}
                action={
                  <Button
                    color='secondary'
                    size='small'
                    onClick={() => {
                      setSignupMsg({
                        open: false,
                        message: ''
                      })
                    }}
                  >
                    OK
                  </Button>
                }
              />
            ) : (
              ''
            )}
          </ThemeProvider>
        </div>
      )}
    </div>
  )
}
export default withNamespaces()(DesktopLoginForm)
