import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
// import { myOrderProductUser } from "../../store/actions/myOrderActions";
// import { useDispatch } from "react-redux";
// import { MyOrderProductResponse } from "../../store/Selectors/myorder";
import InfiniteScroll from 'react-infinite-scroll-component'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import { get as getOrders } from '../../api/order'
import TableDetailOrder from './TableDetailOrder'

const useStyles = makeStyles(theme => ({
  orderBox: {
    border: '0.125rem solid #fff',
    minHeight: '6.25rem',
    backgroundColor: '#fff',
    borderRadius: '4px',
    fontWeight: '400',
    marginLeft: '10px !important',
    marginRight: '10px !important',
    '& h5': {
      fontSize: '14px',
      margin: '0.25rem 0'
    }
  },
  orderTable: {
    width: '100%',
    fontSize: '14px',
    '& tr td:nth-child(1)': {
      width: '45%',
      paddingLeft: '0.5rem'
    },
    '& tr:nth-child(1)': {
      color: 'rgba(58, 120, 58, 1)'
    },
    '& td': {
      fontWeight: '500'
    },
    '& tr td:nth-child(3)': {
      paddingLeft: '0.5rem'
    }
  },
  itemSpace: {
    '& span': {
      // marginLeft: "0.5rem",
    }
  },
  myDiv: {
    background: 'white'
  },
  myButton: {
    backgroundColor: 'white'
  }
}))

const Ondelivery = ({ t, color }) => {
  const classes = useStyles()
  // const dispatch = useDispatch();
  const history = useHistory()
  let [responseData, setResponseData] = React.useState([])
  let [isNext, isNextFunc] = React.useState(false)
  let [pageCount, setCount] = React.useState(1)
  let [page, setPage] = React.useState('')
  let [status, setStatus] = React.useState(false)
  let [open, setOpen] = React.useState(false)
  const loadMoreData = () => {
    setCount(pageCount + 1)
  }
  React.useEffect(() => {
    if (pageCount !== 1) isNextFunc(true)
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append('user_id', userid);
    // data3.append('type', 'Shipping');
    // data3.append('page', pageCount);
    // dispatch(myOrderProductUser(data3));
    // setTimeout(() => {
    //   setOpen(true);
    // }, 700)
    const getOrderDataOndelivery = async () => {
      try {
        // belum done hardcode status
        const response = await getOrders(
          `{"order_status": "ON DELIVERY", "page": ${pageCount}, "limit": 15}`
        )
        if (response.success) {
          if (response.data && response.data.length > 0) {
            if (pageCount === 1) {
              setResponseData(response.data)
            } else {
              const dataValue = responseData.concat(response.data)
              setResponseData(dataValue)
            }
            isNextFunc(true)
            setPage(response.meta.total_pages)
            localStorage.setItem('ondeliveryorder', JSON.stringify(response.meta.total_count))
          }
        }
      } catch (error) {
        console.log('Error fetching get order:', error)
      }
    }
    getOrderDataOndelivery()
  }, [pageCount])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setStatus(true)
  //   }, 1200);
  //   return () => clearTimeout(timer);
  // }, []);

  // const data1 = MyOrderProductResponse();
  // console.log(data1);
  // if (open === true) {
  //   setResponseData([...responseData, ...data1.Shipping]);
  //   isNextFunc(true);
  //   setPage(data1.total_page);
  //   setOpen(false);
  //   localStorage.setItem("ondeliveryorder", JSON.stringify(data1.total_record));
  // }

  const [orderId, setOrderId] = useState([])
  const setOrder = e => {
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i].id === e) {
        orderId.push(responseData[i])
      }
    }
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <React.Fragment>
      {responseData.length > 0 ? (
        <InfiniteScroll
          style={{ minHeight: '750px', backgroundColor: '#FAFAFA' }}
          dataLength={responseData.length}
          next={loadMoreData}
          hasMore={isNext}
          //  loader={
          //    <div style={{ height: "30%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "8px" }}>
          //      {pageCount < page ? (
          //        <ClipLoader color={ThemeColor} height={50} style={{ marginTop: "8px", marginBottom: "8px", fontSize: "7", fontWeight: "600" }} />
          //      ) : (
          //        ""
          //      )}
          //    </div>
          //  }
        >
          {responseData.map((order, idx) => (
            <div
              key={idx}
              className={`${classes.orderBox} p-2 py-1 mt-2 mb-3 shadow-card rounded mb-3`}
            >
              <TableDetailOrder order={order} key={idx} />
              <div
                className='d-flex justify-content-between p-2 rounded grey-box mt-2'
                style={{ backgroundColor: '#F0F4F7' }}
              >
                <h5 className={classes.itemSpace}>
                  Total Qty: <span>{order.order.item_qty} PCS</span>
                </h5>
                <h5 className={classes.itemSpace}>
                  {t('payment')} Rp.
                  {Object.keys(order.order.grand_total.toString()).length > 0 ? (
                    Object.keys(order.order.grand_total.toString()).length <= 3 ? (
                      <span>{order.order.grand_total.toString()}</span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 4 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-4, 1)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 5 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-5, 2)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 6 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 7 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-7, 1)}.
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 8 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-8, 2)}.
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 9 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-9, 3)}.
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </h5>
              </div>
              <div className='d-flex justify-content-evenly mt-2' style={{ gap: '10px' }}>
                {order.order.payment_status === 'paid' ? (
                  <Button
                    variant='contained'
                    style={{
                      fontSize: '13px',
                      color: 'white',
                      position: 'unset'
                    }}
                    className='w-100 text-uppercase bg-success'
                    // if confirmed green if not pink
                  >
                    Tracking Order
                  </Button>
                ) : (
                  <Button
                    style={{
                      fontSize: '13px',
                      color: 'white',
                      position: 'unset',
                      background: color
                    }}
                    variant='contained'
                    className='w-100 text-uppercase'
                  >
                    Tracking Order
                  </Button>
                )}

                <Button
                  variant='contained'
                  style={{
                    fontSize: '13px',
                    color: 'white',
                    position: 'unset',
                    background: color
                  }}
                  className='w-100 shadow-card'
                  onClick={() => {
                    //  setOrder(order.order.id);
                    localStorage.setItem('ordersdata', JSON.stringify([order]))
                    localStorage.setItem('ordertab', JSON.stringify(3))
                    history.push('/orderdetail')
                  }}
                >
                  {t('order_details')}
                </Button>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '40px',
            paddingBottom: '40px',
            backgroundColor: '#f8f9fa',
            height: '100%',
            minHeight: '740px'
          }}
        >
          No Data Found
        </div>
      )}
      {/* <br />
      <br />
      <NavigationTab /> */}
    </React.Fragment>
  )
}

export default withNamespaces()(Ondelivery)
