import {
  Box,
  Grid,
  Snackbar,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import React, { useState } from 'react'
import Card from '../../../../components/common/Card'
import { useStyles } from './LeftCheckout.style'
import Switch from 'react-switch'
import { useEffect } from 'react'
// import { getAddressUser } from "../../../../store/actions/GetAddressActions";
// import { GetAddressResponse } from "../../../../store/Selectors/getaddress";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
// import { GetShippingDataResponse } from "../../../../store/Selectors/getShipping";
// import { GetPaymentDataResponse } from "../../../../store/Selectors/getpaymentdata";
// import { getPaymentProductUser } from "../../../../store/actions/getPaymentActions";
import RightCheckout from '../right/RightCheckout'
// import { addOrderProduct } from "../../../../store/actions/AddOrderActions";
import Amount from '../../../../components/common/Amount'
// import { AddOrderResponse } from "../../../../store/Selectors/AddOrder";
import MuiAlert from '@material-ui/lab/Alert'
import i18n from '../../../language/localization'
import { t } from 'i18next'
import SkeletonCircleText from '../../../../components/common/SkeletonCircleText'
import CloseIcon from '@material-ui/icons/Close'
// import {
//   getCityDataUser,
//   getDistrictDataUser,
//   getProvinceDataUser,
// } from "../../../../store/actions/getProvinceCityDistrict";
// import {
//   GetCityResponse,
//   GetDistrictResponse,
//   GetProvinceResponse,
// } from "../../../../store/Selectors/getCityDistrictData";
// import { addAddressUser } from "../../../../store/actions/userActions";
import { get as getCart, updateCheckedAll } from '../../../../api/cart'
import { getDetail as getAddressDetail } from '../../../../api/address'
import { update as updateAddressData } from '../../../../api/address'
import {
  getProvince as getProvinceData,
  getCity as getCityData,
  getDistrict as getDistrictData
} from '../../../../api/region'
import {
  getVendor as getCourierVendor,
  getRajaOngkir as getCourierRajaOngkir,
  getSelfPickup as getCourierSelfPickup
} from '../../../../api/courier'
import {
  get as getVendorBankData,
  getCOD,
  getMootaBank as getMootaBankData
} from '../../../../api/payment-method'
import { create as submitCheckout } from '../../../../api/checkout'
import { priceFormatLocal } from '../../../../utils/helper'
import { useSelector } from 'react-redux'
import { isDiscountValid } from '../../../../utils/pricing'

const PaymentOption = [
  {
    value: 'Cash',
    id: 'Cash',
    label: `${t('cash_on_delivery')}`
  },
  {
    value: 'Bank (Konfirmasi Manual)',
    id: 'Bank',
    label: `${t('bank_transfer_manual_confirm')}`
  },
  {
    value: 'Bank (Konfirmasi Otomatis)',
    id: 'Bank_Auto_Confirm',
    label: `${t('bank_transfer_auto_confirm')}`
  }
]

const userid = JSON.parse(localStorage.getItem('userid'))

const LeftCheckout = props => {
  const { checkoutCartItem, isCheckout } = useSelector(state => state.cart)
  const { setActive, setRadioPoBankTrans, webThemeColor } = props

  const orderDetailCart = checkoutCartItem
    .filter(item => Boolean(item.cart_item.checked_out))
    .map(item => item.cart_item)
  // const orderDetailCart = checkoutCartItem;
  const orderDetailComplete = checkoutCartItem.filter(item => item.cart_item.checked_out)

  // const orderDetailCart = JSON.parse(localStorage.getItem("oderDetail"));
  // console.log("orderDetailCart", orderDetailCart);
  let history = useHistory()
  const classes = useStyles()
  // const dispatch = useDispatch();
  const [checked, setChecked] = useState(false)
  const [radioJT, setRadioJT] = useState('')

  const [bankId, setBankId] = useState()
  const [isCOD, setISCod] = useState(false)
  const [isBankAuto, setISBankAuto] = useState(false)
  const [singleShipping, setSingleShipping] = useState({})
  const [shipingCost, setShipingCost] = useState(0)
  const [isSnackbar, setSnackbar] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [uniqueCode, setUniquecode] = React.useState()
  const [uniqueCodeStatus, setuniqueCodeStatus] = React.useState()
  const [shippingId, setShippingId] = React.useState()
  const [showSelfPick, setShowSelfPick] = React.useState(false)
  const [showCourierRajaOngkir, setShowCourierRajaOngkir] = React.useState(false)
  const [showCourierVendor, setShowCourierVendor] = React.useState(false)

  const [addressData, setAddressData] = React.useState([])
  const [provinceData, setProvinceData] = React.useState([])
  const [cityData, setCityData] = React.useState([])
  const [districtData, setdistrictData] = React.useState([])
  const [ppnTaxShippingAmount, setPpnTaxShippingAmount] = useState(0)
  const [fixTaxAmount, setFixTaxAmount] = useState(0)
  const [bodyCheckout, setBodyCheckout] = useState({})
  const [getCartData, setGetCartData] = useState([])

  // pajak pengiriman calculate -> if active
  const calculatePPNBaseONWeightGram = data => {
    const totalWeighGram = orderDetailCart.reduce(
      (acc, val) => acc + val.weight * val.checked_out_quantity,
      0
    )
    const parameterInit = data.shipping_tax_parameter_init
    const parameterMultiple = data.shipping_tax_parameter_multiple
    const parameterPriceInit = data.shipping_tax_by_weight
    const totalGrams = totalWeighGram
    let value = 0

    if (totalGrams <= parameterInit) {
      value = parameterPriceInit
    } else {
      let i = 1
      let end = true
      do {
        const parameter = parameterInit
        const nextParameter = parameterInit + parameterMultiple * i

        if (totalGrams > parameter && totalGrams <= nextParameter) {
          value = parameterPriceInit * (i + 1)
          end = false
          break
        } else {
          value = 0
        }
        i++
      } while (end)
    }

    setPpnTaxShippingAmount(value)
  }

  // pajak tetap calculate -> if active
  const calculateFixTaxAmount = data => {
    setFixTaxAmount(data.reduce((acc, val) => acc + val.fix_tax * val.checked_out_quantity, 0))
  }

  const handleChange = nextChecked => {
    setChecked(nextChecked)
  }

  // const BankData = () => {
  //   let data = new FormData();
  //   fetch(`${baseUrlApi()}/Api/Dashboard/getBankData`, {
  //     method: "POST",
  //     headers: {
  //       key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
  //       token: "81506e6ff4a12e7f0e207a52e16d6735",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setuniqueCodeStatus(res.unique_code);
  //     });
  // };

  useEffect(() => {
    // let data = new FormData();
    // belum done unique payment code
    // data.append("payment_method", 1);
    // data.append("bank_id", 3);
    // fetch(`${baseUrlApi()}/Api/Order/uniquePaymentCode`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     token: "81506e6ff4a12e7f0e207a52e16d6735",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setUniquecode(res.unique_payment_code);
    //     console.log("res.unique_payment_code", res.unique_payment_code);
    //   });
    // BankData();
  }, [])
  const handleChangeShippingSelf = data => {
    setShippingId(data.value)
    setShowSelfPick(true)
    setShowCourierRajaOngkir(false)
    setShowCourierVendor(false)
    setRadioJT(data)
    setSingleShipping(data)
    setShipingCost(data.price)
    setShippingId(data.id)
  }
  const handleChangeShippingVendor = async data => {
    setRadioJT(data)
    // const data = shippingData.data.filter(
    //   (el) => el.courier_id == e.target.value
    // );
    setShowSelfPick(false)
    setShowCourierRajaOngkir(false)
    setShowCourierVendor(true)
    // console.log("singleShipping____", data);
    setSingleShipping(data)
    setShipingCost(data.price)
    setShippingId(data.id)
    // console.log("++++++++",shippingData.data.filter((el) => el.courier_id == radioJT))
    // console.log("shippingData_", shippingData.data);
    // console.log("radioJT__", radioJT);
    localStorage.setItem('shipingCost', data.price)

    const landingPageSettingLocalData = JSON.parse(localStorage.getItem('landing_page_setting'))
    if (landingPageSettingLocalData.is_shipping_tax_by_weight_checkout_active) {
      calculatePPNBaseONWeightGram(landingPageSettingLocalData)
    }

    if (landingPageSettingLocalData.is_fix_tax_product_checkout_active) {
      calculateFixTaxAmount(orderDetailCart)
    }
  }

  // const handleChangeShippingSelf = async (data) => {
  //   setRadioJT(data.id);
  //   // const data = shippingData.data.filter(
  //   //   (el) => el.courier_id == e.target.value
  //   // );
  //   setShowSelfPick(true);
  //   // console.log("singleShipping____", data);
  //   setSingleShipping(data);
  //   setShipingCost(data.price);
  //   setShippingId(data.id);
  //   // console.log("++++++++",shippingData.data.filter((el) => el.courier_id == radioJT))
  //   // console.log("shippingData_", shippingData.data);
  //   // console.log("radioJT__", radioJT);
  //   localStorage.setItem("shipingCost", data.price);

  //   setPpnTaxShippingAmount(0)
  //   setFixTaxAmount(0)
  // };

  const handleChangeShippingRajaOngkir = data => {
    setRadioJT(data)
    // const data = courierRajaOngkir.filter((el) => el.service_name == e.target.value);
    setShowSelfPick(false)
    // console.log("singleShipping____", data);
    setSingleShipping(data)
    setShipingCost(data.price)
    // console.log("++++++++",shippingData.data.filter((el) => el.courier_id == radioJT))
    // console.log("shippingData_", shippingData.data);
    // console.log("radioJT__", radioJT);
    localStorage.setItem('shipingCost', data.price)
    setShowSelfPick(false)
    setShowCourierRajaOngkir(true)
    setShowCourierVendor(false)

    // calculatePPNBaseONWeightGram(carts)
    const landingPageSettingLocalData = JSON.parse(localStorage.getItem('landing_page_setting'))
    if (landingPageSettingLocalData.is_shipping_tax_by_weight_checkout_active) {
      calculatePPNBaseONWeightGram(landingPageSettingLocalData)
    }

    if (landingPageSettingLocalData.is_fix_tax_product_checkout_active) {
      calculateFixTaxAmount(orderDetailCart)
    }

    // PR
    // check alamat is exclude
    // const carts = (getCartData?getCartData:null)
    // const isExclude = carts.taxsetting_city_id.includes(addressPrimary[0].city_id)
    // if(carts.ppn_shipping_tax_active===1){
    //   if(!isExclude){
    //     // calc pajak weight gram
    //     calculatePPNBaseONWeightGram(carts)
    //   }else{
    //     setPpnTaxShippingAmount(0)
    //   }
    // }
  }

  const CheckoutPage = localStorage.getItem('checkoutPage')
  const [checkOut2, setcheckOut2] = useState()

  const [checkOut, setcheckOut] = useState(isCheckout ? '' : 'Cash')
  const [radioPO, setRadioPO] = useState(checkOut == 'Cash' ? 'Cash' : '')
  const [isBank, setBank] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    setcheckOut2(localStorage.getItem('checkoutPage'))
  }, [])

  // console.log("checkOut____", checkOut);

  const handleChangeRadioPO = e => {
    // console.log("e target", e.target.value)
    setRadioPO(e.target.value)
    // console.log(e.target.value);
    setcheckOut(e.target.value)
    localStorage.setItem('paymentOption', e.target.value)
    setBank(false)
    setISCod(false)
    setISBankAuto(false)
  }

  const getData = async () => {
    const userID = JSON.parse(localStorage.getItem('userid'))
    let response

    try {
      response = await getAddressDetail(`{"customer_id":${userID}}`)
      setAddressData(response)

      if (response) {
        setaddressPrimary(response.filter(dt => dt.customer_address.is_primary === true))
        console.log('SEBELUM SHIPPING')
        await shipping(response.filter(dt => dt.customer_address.is_primary === true))
      }
    } catch (error) {
      console.log('Error fetching data:', error)
    }
  }
  useEffect(() => {
    getData()
    getProvince()

    const landingPageSettingLocalData = JSON.parse(localStorage.getItem('landing_page_setting'))
    if (landingPageSettingLocalData.is_shipping_tax_by_weight_checkout_active) {
      calculatePPNBaseONWeightGram(landingPageSettingLocalData)
    }

    if (landingPageSettingLocalData.is_fix_tax_product_checkout_active) {
      calculateFixTaxAmount(orderDetailCart)
    }
  }, [])

  const [addressPrimary, setaddressPrimary] = useState([])
  const [openPopUpAddressList, setOpenPopUpAddressList] = useState(false)
  const handleClosePopUpAddressList = async () => {
    setOpenPopUpAddressList(false)
  }
  const handleClickOpenPopUpAddressList = async () => {
    // setActive("changeAddress");
    // history.push("/my-profile");
    setOpenPopUpAddressList(true)
  }
  const [addressChecked, setAddressChecked] = useState({})
  const handleChangeRadioAddressList = e => {
    // console.log("addressChecked checked",JSON.parse(e.target.value))
    setAddressChecked(JSON.parse(e.target.value))
  }

  const handleClickChangeAddress = async () => {
    // console.log("addressChecked",addressChecked)
    setaddressPrimary([addressChecked])
    setOpenPopUpAddressList(false)
    await shipping([addressChecked])

    // check alamat is exclude
    // PR -> is_shipping_tax_by_weight_checkout_active
    // const carts = (getCartData?getCartData:null)
    // const isExclude = carts.taxsetting_city_id.includes(addressPrimary[0].city_id)
    // if(carts.ppn_shipping_tax_active===1){
    //   if(isExclude){
    //     setPpnTaxShippingAmount(0)
    //   }else{
    //     if(showSelfPick){
    //       setPpnTaxShippingAmount(0)
    //     }else{
    //       calculatePPNBaseONWeightGram(carts)
    //     }
    //   }
    // }
  }
  const [openPopUpAddressUpdate, setOpenPopUpAddressUpdate] = useState(false)
  const UpdateAddressOpenPopUp = async valueData => {
    setOpenPopUpAddressUpdate(true)
    // console.log("valueData",valueData)
    setFormData(valueData)
    try {
      const response = await getCityData(`{"province_id":${valueData.province_id}}`)

      setCityData(response)
    } catch (error) {
      console.log('Error fetching getCityData:', error)
    }
    try {
      const response = await getDistrictData(`{"district_id":${valueData.district_id}}`)

      setdistrictData(response)
    } catch (error) {
      console.log('Error fetching getDistrictData:', error)
    }
  }
  const handleClosePopUpdateAddress = async () => {
    setOpenPopUpAddressUpdate(false)
  }

  const handleClickChangeAddressUpdate = async () => {
    // console.log("update", formdata);
    // var formdataForUpdateAddress = new FormData();
    // formdataForUpdateAddress.append("address_id", formdata ? formdata.id : null);
    // formdataForUpdateAddress.append("user_id", userid);
    // formdataForUpdateAddress.append("name", formdata ? formdata.name : null);
    // formdataForUpdateAddress.append("address", formdata ? formdata.address : null);
    // formdataForUpdateAddress.append("province_id", formdata ? formdata.province_id : null);
    // formdataForUpdateAddress.append("province_name", formdata ? formdata.province_name : null);
    // formdataForUpdateAddress.append("city_id", formdata ? formdata.city_id : null);
    // formdataForUpdateAddress.append("city_name", formdata ? formdata.city_name : null);
    // formdataForUpdateAddress.append("district_id", formdata ? formdata.district_id : null);
    // formdataForUpdateAddress.append("district_name", formdata ? formdata.district_name : null);
    // formdataForUpdateAddress.append("postal_code", formdata ? formdata.postal_code : null);
    // formdataForUpdateAddress.append("phone", formdata ? formdata.phone : null);
    // formdataForUpdateAddress.append("as_default", formdata ? formdata.as_default : null);
    // dispatch(addAddressUser(formdataForUpdateAddress));
    setLoading(true)
    const data = {
      address_id: formdata.id,
      customer_id: parseInt(userid),
      name: formdata.name,
      phone: formdata.phone,
      address: formdata.address,
      province_id: parseInt(formdata.province_id),
      district_id: parseInt(formdata.district_id),
      subdistrict_id: parseInt(formdata.subdistrict_id),
      postal_code: formdata.postal_code,
      // hardcode
      country_id: 62,
      village_id: 3201130010,
      latitude: -6.534398,
      longitude: 106.821891,
      note: '',
      label: '',
      is_primary: formdata.is_primary
    }

    const response = await updateAddressData(data)
    if (response.success) {
      await getData()
      setLoading(false)
      setOpenPopUpAddressUpdate(false)
      setOpenPopUpAddressList(true)
    }
  }

  // const ProvinceData = GetProvinceResponse();
  // const cityData = GetCityResponse();
  // const districtData = GetDistrictResponse();
  const [formdata, setFormData] = useState(null)
  const onChangeNameForUpdateAddress = e => {
    setFormData({ ...formdata, name: e.target.value })
  }
  const onChangeAddressForUpdateAddress = e => {
    setFormData({ ...formdata, address: e.target.value })
  }
  const onChangePostCodeForUpdateAddress = e => {
    setFormData({ ...formdata, postal_code: e.target.value })
  }
  const onChangePhoneForUpdateAddress = e => {
    setFormData({ ...formdata, phone: e.target.value })
  }
  const getProvince = async () => {
    // let formDataGetProvince = new FormData();
    // dispatch(getProvinceDataUser(formDataGetProvince));
    try {
      const response = await getProvinceData(null)
      setProvinceData(response)
    } catch (error) {
      console.log('Error fetching getProvinceData:', error)
    }
  }
  const getDistric = async e => {
    const selectedProvinceName = provinceData.find(dt => dt.id === e.target.value)?.name

    // let formDataGetCity = new FormData();
    setFormData({ ...formdata, province_id: e.target.value })
    setFormData({
      ...formdata,
      province_name: selectedProvinceName
    })
    // formDataGetCity.append("province_id", e.target.value);
    // dispatch(getCityDataUser(formDataGetCity));
    try {
      const response = await getCityData(`{"province_id":${e.target.value}}`)

      setCityData(response)
    } catch (error) {
      console.log('Error fetching getCityData:', error)
    }
  }
  const getSubDistrict = async e => {
    // let formDataGetSubDistrict = new FormData();
    const selectedCityName = cityData.find(dt => dt.id === e.target.value)?.name
    setFormData({ ...formdata, district_id: e.target.value })
    setFormData({
      ...formdata,
      district_name: selectedCityName
    })
    // formDataGetSubDistrict.append("city_id", e.target.value);
    // dispatch(getDistrictDataUser(formDataGetSubDistrict));
    try {
      const response = await getDistrictData(`{"district_id":${e.target.value}}`)

      setdistrictData(response)
    } catch (error) {
      console.log('Error fetching getDistrictData:', error)
    }
  }
  const subDistChange = e => {
    const selectedDistrictName = districtData.find(dt => dt.id === e.target.value)?.name

    setFormData({ ...formdata, subdistrict_id: e.target.value })
    // setFormData({
    //   ...formdata,
    //   subdistrict_name: selectedDistrictName,
    // });
    // console.log("formdata",formdata)
  }

  // Shipping Data
  const [courierRajaOngkir, setCourierRajaOngkir] = useState([])
  const [courierVendor, setCourierVendor] = useState([])
  const [courierSelfPickup, setCourierSelfPickup] = useState({})

  const prices = JSON.parse(localStorage.getItem('totalweight'))
  const cityids = JSON.parse(localStorage.getItem('cityid'))
  const districtids = JSON.parse(localStorage.getItem('subdistrictid'))
  const totlalPrice = JSON.parse(localStorage.getItem('setT'))
  const cartdetail = JSON.parse(localStorage.getItem('cartids'))

  // const datas = GetShippingDataResponse();
  const PlaceOrder = () => {
    alert('cloci')
  }

  const shipping = async adddressData => {
    setCourierVendor([])
    setCourierRajaOngkir([])
    setCourierSelfPickup({})

    // const cartItems = orderDetailCart.map((item) => item.cart_item);
    const calculateCartItem = orderDetailCart.map(item => item.weight * item.checked_out_quantity)
    const totalWeight = calculateCartItem.length
      ? calculateCartItem.reduce((prev, next) => prev + next)
      : calculateCartItem[0]

    const courierVendorResult = await getCourierVendor(
      `{"district_id":${adddressData[0].district.id}, "weight": ${totalWeight}}`
    )
    if (courierVendorResult.success) {
      setCourierVendor(courierVendorResult.data)
    }

    const courierRajaOngkirResult = await getCourierRajaOngkir(
      `{"subdistrict_id":${adddressData[0].sub_district.id}, "weight": ${totalWeight}}`
    )
    if (courierRajaOngkirResult.success) {
      setCourierRajaOngkir(courierRajaOngkirResult.data.data)
    }

    const courierSelfPickupResult = await getCourierSelfPickup()
    if (courierSelfPickupResult.success) {
      if (courierSelfPickupResult.data.is_active === true) {
        setCourierSelfPickup(courierSelfPickupResult.data)
      }
    }

    // const total = orderDetailCart.reduce((acc, val) => acc + val.Total_Price, 0);
    // let data = new FormData();
    // data.append(
    //   "city_id",
    //   addressPrimary?addressPrimary.length > 0 ? addressPrimary[0].city_id : null:null
    // );
    // data.append(
    //   "subdistrict_id",
    //   addressPrimary?addressPrimary.length > 0 ? addressPrimary[0].district_id : null:null
    // );
    // data.append("weight", 400);
    // data.append("total", total);
    // data.append("cart", cartdetail ? cartdetail[0].product_id : "68");

    // fetch(`${baseUrlApi()}/Api/Dashboard/getCourierData`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     // console.log("appi time",res.data.length);
    //     // setCourierRajaOngkir([...courierRajaOngkir,...res.data]);
    //     // console.log("resposneshiping", res);
    //     console.log("courier res", res);
    //     if (res.ResponseCode == 1) {
    //       // console.log("api time res.",res.data)
    //       setCourierRajaOngkir(res.data);
    //       console.log("courier data", res.data);
    //     }
    //   });
    // dispatch(getShippingUserData(data));
  }

  // useEffect(() => {
  //   shipping();
  //   console.log("addressData",addressData)
  //   setaddressPrimary(addressData
  //     ? addressData.data.filter((dt) => dt.is_primary == 1)
  //     : null)
  // }, [addressData]);

  // console.log("courierRajaOngkir", courierRajaOngkir)

  // Bank Transfer
  const getPayment = () => {
    // let data = new FormData();
    // dispatch(getPaymentProductUser(data));
  }
  useEffect(() => {
    // getPayment();
  }, [])
  const onChangeBank = e => {
    setBankId(e.target.value)
    // console.log(e.target);
    localStorage.setItem('bankOption', e.target.value)
    setBank(true)
    setISCod(false)
    setISBankAuto(false)
  }

  const onChangeCod = e => {
    setISCod(true)
    setBank(false)
    setISBankAuto(false)
    localStorage.setItem('bankOption', e.target.value)
  }

  const onChangeBankAuto = e => {
    // console.log(e.target);
    localStorage.setItem('bankOption', e.target.value)
    setBank(false)
    setISCod(false)
    setISBankAuto(true)
  }

  let LSshipingCost = localStorage.getItem('shipingCost')
  let LSpaymentOption = localStorage.getItem('paymentOption')
  let LSbankOption = localStorage.getItem('bankOption')
  // console.log("LSshipingCost", LSshipingCost);
  // console.log("LSbankOption", LSbankOption);
  // console.log("LSbankOption payData.data", payData);

  // On Click Checkout Button !NOT CHECKOUT!
  const placeOrder = async () => {
    // setLoadingBtn(true);
    try {
      if (addressPrimary.length > 0) {
        if (radioJT) {
          console.log('radioJT', radioJT)
          if (
            radioPO == 'Cash' ||
            radioPO == 'Bank (Konfirmasi Manual)' ||
            radioPO == 'Bank (Konfirmasi Otomatis)'
          ) {
            let data = {}
            const total = orderDetailCart.reduce(
              (acc, val) => acc + val.price * val.checked_out_quantity,
              0
            )
            const totalDiscount = orderDetailCart.reduce(
              (acc, val) => acc + val.discount * val.checked_out_quantity,
              0
            )

            if (radioPO == 'Cash') {
              if (showSelfPick === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'CASH',
                  payment_method_id: null,
                  order_placed_from: 'WEB',
                  shipping_type: 'SELFPICKUP',
                  is_moota: false,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: radioJT.name,
                    estimation: null,
                    service: 'SELFPICKUP',
                    price: radioJT.price,
                    logo: radioJT.logo
                  },
                  is_pay_on_spot: false
                }
              }

              if (showCourierVendor === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'CASH',
                  payment_method_id: null,
                  order_placed_from: 'WEB',
                  shipping_type: 'COURIER MANUAL',
                  is_moota: false,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: radioJT.name,
                    estimation: null,
                    service: 'SELFPICKUP',
                    price: radioJT.price,
                    logo: radioJT.logo
                  },
                  is_pay_on_spot: false
                }
              }

              if (showCourierRajaOngkir === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'CASH',
                  payment_method_id: null,
                  order_placed_from: 'WEB',
                  shipping_type: 'COURIER RAJA ONGKIR',
                  is_moota: false,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: singleShipping.name,
                    estimation: singleShipping.estimation,
                    service: singleShipping.service,
                    price: singleShipping.price,
                    logo: singleShipping.logo
                  },
                  is_pay_on_spot: false
                }
              }
            } else if (radioPO == 'Bank (Konfirmasi Manual)') {
              if (showSelfPick === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'BANK TRANSFER',
                  payment_method_id: parseInt(bankId),
                  order_placed_from: 'WEB',
                  shipping_type: 'SELFPICKUP',
                  is_moota: false,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: radioJT.name,
                    estimation: null,
                    service: 'SELFPICKUP',
                    price: radioJT.price,
                    logo: radioJT.logo
                  },
                  is_pay_on_spot: false
                }
              }

              if (showCourierVendor === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'BANK TRANSFER',
                  payment_method_id: parseInt(bankId),
                  order_placed_from: 'WEB',
                  shipping_type: 'COURIER MANUAL',
                  is_moota: false,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: radioJT.name,
                    estimation: null,
                    service: 'SELFPICKUP',
                    price: radioJT.price,
                    logo: radioJT.logo
                  },
                  is_pay_on_spot: false
                }
              }

              if (showCourierRajaOngkir === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'BANK TRANSFER',
                  payment_method_id: parseInt(bankId),
                  order_placed_from: 'WEB',
                  shipping_type: 'COURIER RAJA ONGKIR',
                  is_moota: false,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: singleShipping.name,
                    estimation: singleShipping.estimation,
                    service: singleShipping.service,
                    price: singleShipping.price,
                    logo: singleShipping.logo
                  },
                  is_pay_on_spot: false
                }
              }
            } else if (radioPO == 'Bank (Konfirmasi Otomatis)') {
              if (showSelfPick === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'BANK TRANSFER',
                  payment_method_id: null,
                  order_placed_from: 'WEB',
                  shipping_type: 'SELFPICKUP',
                  is_moota: true,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: radioJT.name,
                    estimation: null,
                    service: 'SELFPICKUP',
                    price: radioJT.price,
                    logo: radioJT.logo
                  },
                  is_pay_on_spot: false
                }
              }

              if (showCourierVendor === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'BANK TRANSFER',
                  payment_method_id: null,
                  order_placed_from: 'WEB',
                  shipping_type: 'COURIER MANUAL',
                  is_moota: true,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: radioJT.name,
                    estimation: null,
                    service: 'SELFPICKUP',
                    price: radioJT.price,
                    logo: radioJT.logo
                  },
                  is_pay_on_spot: false
                }
              }

              if (showCourierRajaOngkir === true) {
                data = {
                  customer_address_id: addressPrimary[0].customer_address.id,
                  payment_method: 'BANK TRANSFER',
                  payment_method_id: null,
                  order_placed_from: 'WEB',
                  shipping_type: 'COURIER RAJA ONGKIR',
                  is_moota: true,
                  shipping_tax: ppnTaxShippingAmount,
                  grand_total: ReturnTotal(),
                  total: total - totalDiscount,
                  tax: fixTaxAmount,
                  discount: totalDiscount,
                  shipping: {
                    name: singleShipping.name,
                    estimation: singleShipping.estimation,
                    service: singleShipping.service,
                    price: singleShipping.price,
                    logo: singleShipping.logo
                  },
                  is_pay_on_spot: false
                }
              }
            }

            const itemsCart = checkoutCartItem.map(item => ({
              id: item.cart_item.id,
              checked_out: item.cart_item.checked_out,
              checked_out_quantity: item.cart_item.checked_out_quantity
            }))

            const resUpdateCheckedAll = await updateCheckedAll(itemsCart)

            const resSubmit = await submitCheckout(data)
            if (resSubmit.success && resUpdateCheckedAll) {
              localStorage.setItem('order_id', resSubmit.data.id)
              history.push('/success-order-pay')
            }
          } else {
            alert('Select Payment')
            setLoadingBtn(false)
          }
        } else {
          alert('Select Shipping')
          setLoadingBtn(false)
        }
      } else {
        alert('Select Delivery Address')
      }
    } catch (error) {
      console.error('ERROR placeOrder')
    }
  }
  // const ReturnTotal = () => {
  //   const total = orderDetailCart.reduce((acc, val) => acc + val.Total_Price, 0);
  //     const grand_total = shipingCost + total;
  //   if (checkoutpage == "true") {
  //     return grand_total;
  //   } else {
  //     if (isBank) {
  //       return grand_total + parseInt(uniqueCode);
  //     } else {
  //       return grand_total + parseInt(getCartData.cart_additional_fee);
  //     }
  //   }
  // };

  const ReturnTotal = () => {
    const total = orderDetailCart.reduce(
      (acc, val) => acc + (val.price - val.discount) * val.checked_out_quantity,
      0
    )
    // console.log("total orderDetailCart",total)
    const grand_total = shipingCost + total
    const ShippingCost = getCartData
      ? getCartData.selfpickup_is_active == '1'
        ? getCartData.selfpickup[0].price
        : null
      : null
    if (isCheckout) {
      if (isBank) {
        if (uniqueCodeStatus == 1) {
          return grand_total + parseInt(uniqueCode) + ppnTaxShippingAmount + fixTaxAmount
        } else {
          console.log('atas')
          return grand_total + ppnTaxShippingAmount + fixTaxAmount
        }
      } else {
        console.log('bawah', grand_total + ppnTaxShippingAmount + fixTaxAmount)
        return grand_total + ppnTaxShippingAmount + fixTaxAmount
      }
    } else {
      if (isBank) {
        if (uniqueCodeStatus == 1) {
          return grand_total + parseInt(uniqueCode)
        } else {
          return grand_total
        }
      } else {
        return (
          // grand_total + parseInt(getCartData.cart_additional_fee) + ShippingCost
          grand_total
        )
      }
    }
  }

  // On Click Pay On Spot Button
  const placeOrderPayonspot = async () => {
    setLoadingBtn(true)

    try {
      // alert(radioPO);
      if (radioPO == 'Cash' || bankId) {
        const total = orderDetailCart.reduce(
          (acc, val) => acc + val.price * val.checked_out_quantity,
          0
        )
        const totalDiscount = orderDetailCart.reduce(
          (acc, val) => acc + val.discount * val.checked_out_quantity,
          0
        )
        let data = {}
        if (radioPO == 'Cash') {
          data = {
            payment_method: 'CASH',
            payment_method_id: null,
            order_placed_from: 'WEB',
            shipping_type: 'SELFPICKUP',
            shipping_tax: 0,
            grand_total: ReturnTotal(),
            total: total - totalDiscount,
            tax: 0,
            discount: totalDiscount,
            is_moota: false,
            shipping: {
              name: 'Ambil Sendiri',
              estimation: null,
              service: 'SELFPICKUP',
              price: 0,
              logo: null
            },
            is_pay_on_spot: true
          }
        } else if (radioPO == 'Bank (Konfirmasi Manual)') {
          data = {
            payment_method: 'BANK TRANSFER',
            payment_method_id: parseInt(bankId),
            order_placed_from: 'WEB',
            shipping_type: 'SELFPICKUP',
            shipping_tax: 0,
            grand_total: ReturnTotal(),
            total: total - totalDiscount,
            tax: 0,
            is_moota: false,
            discount: totalDiscount,
            shipping: {
              name: 'Ambil Sendiri',
              estimation: null,
              service: 'SELFPICKUP',
              price: 0,
              logo: null
            },
            is_pay_on_spot: true
          }
        } else if (radioPO == 'Bank (Konfirmasi Otomatis)') {
          data = {
            payment_method: 'BANK TRANSFER',
            payment_method_id: null,
            order_placed_from: 'WEB',
            shipping_type: 'SELFPICKUP',
            shipping_tax: 0,
            grand_total: ReturnTotal(),
            total: total - totalDiscount,
            tax: 0,
            is_moota: true,
            discount: totalDiscount,
            shipping: {
              name: 'Ambil Sendiri',
              estimation: null,
              service: 'SELFPICKUP',
              price: 0,
              logo: null
            },
            is_pay_on_spot: true
          }
        }

        console.log('data', checkoutCartItem)

        const itemsCart = checkoutCartItem.map(item => ({
          id: item.cart_item.id,
          checked_out: item.cart_item.checked_out,
          checked_out_quantity: item.cart_item.checked_out_quantity
        }))

        console.log('itemsCart', itemsCart)

        const resUpdateCheckedAll = await updateCheckedAll(itemsCart)

        const resSubmit = await submitCheckout(data)

        if (resSubmit.success && resUpdateCheckedAll) {
          localStorage.setItem('order_id', resSubmit.data.id)
          history.push('/success-order-pay')
        }
      } else {
        alert('Select Payment')
        setLoadingBtn(false)
      }
    } catch (error) {
      console.error('ERROR placeOrderPayonspot')
    }
  }

  // const payData = GetPaymentDataResponse();
  // const oderResponse = AddOrderResponse();

  // Get Cart Data Start
  // const [getCartData, setGetCartData] = useState('')
  const GetCartData = async () => {
    // const Usercode = JSON.parse(localStorage.getItem("usercode"));
    // let data = new FormData();
    // data.append("user_code", Usercode);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));

    // const response = await axios({
    //   method: "post",
    //   url: `${baseUrlApi()}/Api/Order/myCart`,
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   data: data,
    // })
    //   .then(function(response) {
    //     // console.log("response.data__", response.data);
    //     setGetCartData(response.data);
    //     // setGetCartData(response.data.cart_additional_fee)
    //   })
    //   .catch(function(response) {
    //     console.log(response);
    //   });
    const UserId = JSON.parse(localStorage.getItem('userid'))
    const cartDataResult = await getCart(`{"get_all_data": true}`)
    if (cartDataResult.success) {
      setGetCartData(cartDataResult.data.cart_items)
    }
  }

  const [vendorBankDatas, setVendorBankDatas] = useState([])
  const getVendorBank = async () => {
    const UserId = JSON.parse(localStorage.getItem('userid'))
    const vendorBankDataResult = await getVendorBankData(`{"customer_id":${UserId}}`)
    setVendorBankDatas(vendorBankDataResult)
    // if(vendorBankDataResult.success){
    //   setVendorBankDatas(vendorBankDataResult)
    // }
  }

  const [mootaBankDatas, setMootaBankDatas] = useState({})
  const getMootaBank = async () => {
    const mootaBankDataResult = await getMootaBankData()
    if (mootaBankDataResult.success) {
      if (mootaBankDataResult.data.status === true) {
        setMootaBankDatas(mootaBankDataResult.data)
      }
    }
  }

  const [codData, setCodData] = useState({})
  const getCodData = async () => {
    const codDataResult = await getCOD()
    if (codDataResult.success) {
      if (codDataResult.data.is_active === true) {
        setCodData(codDataResult.data)
      }
    }
  }

  useEffect(() => {
    GetCartData()
    getVendorBank()
    getMootaBank()
    getCodData()
  }, [])

  // Get Cart Data End
  // console.log("addressPrimary",addressPrimary)
  return (
    <div style={{ display: 'flex', columnGap: 20 }}>
      {/* {console.log("payData_", payData)} */}
      {/* {
        console.log("response",oderResponse)
      } */}

      {/* {console.log("getCartData__", getCartData)} */}
      <div style={{ width: '100%' }}>
        {/* Delivery Address */}

        {isCheckout && (
          <Card className={classes.cardStyle}>
            <Box>
              <header>
                <label>{t('delivery_address')} </label>
                {Boolean(addressPrimary.length) && (
                  <Button
                    onClick={handleClickOpenPopUpAddressList}
                    style={{
                      marginBottom: '0',
                      borderColor: webThemeColor,
                      color: webThemeColor,
                      width: '147px',
                      height: '35px'
                    }}
                    variant='outlined'
                    size='small'
                  >
                    {t('change_address')}
                  </Button>
                )}
              </header>
              <Box className={classes.address}>
                {/* {console.log("addressPrimary",addressPrimary)} */}

                {!addressPrimary.length && (
                  <Box
                    width='100%'
                    height={150}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <h5>Belum ada alamat</h5>
                    <Button
                      onClick={() => history.push('/my-profile')}
                      style={{
                        marginBottom: '0',
                        borderColor: webThemeColor,
                        color: webThemeColor,
                        width: '200px',
                        height: '35px'
                      }}
                      variant='outlined'
                      size='small'
                    >
                      {t('add_new_address')}
                    </Button>
                  </Box>
                )}

                {addressPrimary.map((dt, index) => {
                  return (
                    <div className='del-detail' key={index}>
                      <div className='del-to-name'>
                        {dt.customer_address.name}{' '}
                        {dt.customer_address.is_primary === true ? (
                          <span className='default-lab'>default</span>
                        ) : null}
                      </div>
                      <div className='del-transaction-no'>{dt.customer_address.phone}</div>
                      <div className='del-address'>
                        {dt.customer_address.address}
                        <br />
                        {dt.district.name},{dt.sub_district.name} {dt.province.name} -
                        {dt.sub_district.postal}
                      </div>
                    </div>
                  )
                })}

                <Box className={classes.dropshipper_style}>
                  <dvi className='switchParent'>
                    <Switch
                      onChange={handleChange}
                      checked={checked}
                      className='react-switch'
                      onColor={webThemeColor}
                      offColor='#ccc'
                      height={14}
                      width={36}
                    />
                    <span style={{ zIndex: 1, fontSize: '16px' }}>Dropshipper</span>
                  </dvi>
                  {checked ? (
                    <div className='inputs'>
                      <TextField
                        id='outlined-secondary'
                        label={t('name')}
                        variant='outlined'
                        size='small'
                        className={classes.borderColorTextfieldWithCustom}
                      />
                      <TextField
                        id='outlined-secondary'
                        label={t('phone')}
                        variant='outlined'
                        size='small'
                        className={classes.borderColorTextfieldWithCustom}
                      />
                    </div>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Card>
        )}

        {/* Shipping Option */}
        {isCheckout && (
          <Card className={classes.cardStyle + '  ' + classes.shippingOption}>
            <Box className={classes.radio_style}>
              <header>
                <label>{t('shipping_option')}</label>
              </header>

              {/* Live data */}
              {addressPrimary.length > 0 ? (
                <form className={classes.radio_shipingOption}>
                  {courierSelfPickup && Object.keys(courierSelfPickup).length > 0 ? (
                    <div className={classes.radio_item + ' inputItem'}>
                      <input
                        type='radio'
                        value={courierSelfPickup.id}
                        id={'courier_selfpickup_' + courierSelfPickup.id}
                        onChange={() => handleChangeShippingSelf(courierSelfPickup)}
                        name='courierlist'
                      />
                      <img
                        src={
                          courierSelfPickup.logo
                            ? process.env.REACT_APP_FILE_BASE_URL + '/' + courierSelfPickup.logo
                            : '/assets/images/dummy.png'
                        }
                        alt=''
                      />
                      <label htmlFor={'courier_selfpickup_' + courierSelfPickup.id}>
                        {courierSelfPickup.name}
                      </label>
                      <label htmlFor={'courier_selfpickup_' + courierSelfPickup.id}>
                        {priceFormatLocal(courierSelfPickup.price)}
                      </label>
                      {/* <label for={data.courier_id} className={"actual_rate"}>
                        Rp&nbsp;
                        <Amount amount={data.actual_rate} />
                      </label> */}
                    </div>
                  ) : null}

                  {courierVendor && courierVendor.length > 0
                    ? courierVendor.map((data, index) => {
                        return (
                          <div className={classes.radio_item + ' inputItem'} key={index}>
                            <input
                              type='radio'
                              value={data.id}
                              id={'courier_vendor_' + index}
                              onChange={() => handleChangeShippingVendor(data)}
                              name='courierlist'
                            />
                            <img
                              src={
                                data.logo
                                  ? process.env.REACT_APP_FILE_BASE_URL + '/' + data.logo
                                  : '/assets/images/dummy.png'
                              }
                              alt=''
                            />
                            <label htmlFor={'courier_vendor_' + index}>{data.service}</label>
                            <label htmlFor={'courier_vendor_' + index}>
                              {priceFormatLocal(data.price)}
                            </label>
                            {/* <label for={data.courier_id} className={"actual_rate"}>
                        Rp&nbsp;
                        <Amount amount={data.actual_rate} />
                      </label> */}
                          </div>
                        )
                      })
                    : null}

                  {courierRajaOngkir && courierRajaOngkir.length > 0 ? (
                    courierRajaOngkir.map((data, index) => {
                      return (
                        <div className={classes.radio_item + ' inputItem'} key={index}>
                          <input
                            type='radio'
                            value={data.name + ' ' + data.service}
                            id={'courier_rajaongkir_' + index}
                            onChange={() => handleChangeShippingRajaOngkir(data)}
                            name='courierlist'
                          />
                          <img
                            src={
                              data.logo
                                ? process.env.REACT_APP_FILE_BASE_URL + '/' + data.logo
                                : '/assets/images/dummy.png'
                            }
                            alt=''
                          />
                          <label htmlFor={'courier_rajaongkir_' + index}>
                            {data.name + ' ' + data.service}
                          </label>
                          <label htmlFor={'courier_rajaongkir_' + index}>
                            {/* Rp&nbsp; */}
                            {priceFormatLocal(data.price)}
                            {/* <Amount amount={data.rate} /> */}
                          </label>
                          {/* <label
                            for={data.courier_id}
                            className={"actual_rate"}
                          >
                            Rp&nbsp;
                            <Amount amount={data.actual_rate} />
                          </label> */}
                        </div>
                      )
                    })
                  ) : (
                    <div>
                      <SkeletonCircleText style={{ width: '100%' }} />
                      <SkeletonCircleText style={{ width: '100%' }} />
                      <SkeletonCircleText style={{ width: '100%' }} />
                      <SkeletonCircleText style={{ width: '100%' }} />
                      <SkeletonCircleText style={{ width: '100%' }} />
                      <SkeletonCircleText style={{ width: '100%' }} />
                    </div>
                  )}
                </form>
              ) : (
                <label>{t('please_choose_delivery_address')}</label>
              )}
            </Box>
          </Card>
        )}

        {/* Payment Option: */}
        <Card className={classes.cardStyle + '  '}>
          <Box className={classes.radio_style}>
            <header>
              <label>{t('payment_option')}</label>
            </header>
            <form>
              {/* {console.log("PaymentOption val",PaymentOption)} */}
              {isCheckout
                ? PaymentOption.map((data, index) => (
                    <div className={classes.radio_item} key={index}>
                      <input
                        type='radio'
                        value={data.value}
                        id={data.id}
                        onChange={handleChangeRadioPO}
                        name='radio_paymentOption'
                        // LSpaymentOption
                      />
                      <label htmlFor={data.id}>{data.label}</label>
                    </div>
                  ))
                : PaymentOption.map((data, index) => (
                    <div className={classes.radio_item} key={index}>
                      <input
                        type='radio'
                        value={data.value}
                        id={data.id}
                        onChange={handleChangeRadioPO}
                        name='radio_paymentOption'
                        // LSpaymentOption
                        checked={checkOut == data.value}
                      />
                      <label htmlFor={data.id}>{data.label}</label>
                    </div>
                  ))}

              {/* {radioPO === 'Cash' && Object.keys(codData).length > 0 ? (
                <div className={classes.radio_item_bank}>
                <input
                  type="radio"
                  value={codData.id}
                  id={codData.id}
                  onChange={onChangeCod}
                  name="COD"
                />

                <div className="imgLabels">
                  <img src={(codData.logo?process.env.REACT_APP_FILE_BASE_URL + '/' + codData.logo:"/assets/images/dummy.png")} for={codData.id} />
                  <div>
                    <label for={codData.id}>
                      {codData.name}
                    </label>
                  </div>
                </div>
              </div>
              ) : null } */}

              {radioPO == 'Bank (Konfirmasi Manual)'
                ? vendorBankDatas.length > 0 &&
                  vendorBankDatas.map((el, index) => (
                    <div className={classes.radio_item_bank} key={index}>
                      <input
                        type='radio'
                        value={el.id}
                        id={el.id}
                        onChange={onChangeBank}
                        name='bank_transfer_manual'
                        // LSbankOption
                      />

                      <div className='imgLabels'>
                        <img
                          src={
                            el.bank_image
                              ? process.env.REACT_APP_FILE_BASE_URL + '/' + el.bank_image
                              : '/assets/images/dummy.png'
                          }
                          htmlFor={el.id}
                        />
                        <div>
                          {/* <label for={el.id}>{el.account_owner_name}</label> */}
                          <label htmlFor={el.id}>
                            {el.account_number} {el.bank_name}
                          </label>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              {radioPO === 'Bank (Konfirmasi Otomatis)' &&
              Object.keys(mootaBankDatas).length > 0 ? (
                <div className={classes.radio_item_bank}>
                  <input
                    type='radio'
                    value={mootaBankDatas.id}
                    id={mootaBankDatas.id}
                    onChange={onChangeBankAuto}
                    name='bank_transfer_auto'
                    // LSbankOption
                  />

                  <div className='imgLabels'>
                    <img
                      src={
                        mootaBankDatas.bank_image
                          ? process.env.REACT_APP_FILE_BASE_URL + '/' + mootaBankDatas.bank_image
                          : '/assets/images/dummy.png'
                      }
                      htmlFor={mootaBankDatas.id}
                    />
                    <div>
                      {/* <label for={el.id}>{el.account_owner_name}</label> */}
                      <label htmlFor={mootaBankDatas.id}>
                        {mootaBankDatas.account_number} {mootaBankDatas.bank_name}
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
            </form>
          </Box>
        </Card>

        {/* Order Details */}
        <Card className={classes.cardStyle + '  '}>
          <Box className={classes.radio_style}>
            <header>
              <label>{t('order_detail')}</label>
            </header>
            {orderDetailComplete.map((cart, index) => {
              return (
                <Box className={classes.tableContent} key={index}>
                  <Grid container spacing={2}>
                    <Grid item md={2}>
                      <img
                        src={
                          cart.product.media && cart.product.media.length > 0
                            ? process.env.REACT_APP_FILE_BASE_URL + '/' + cart.product.media[0]
                            : '/assets/images/dummy.png'
                        }
                        style={{
                          position: 'relative',
                          width: '100px',
                          height: '100px'
                        }}
                        alt=''
                      />
                    </Grid>
                    {isDiscountValid(
                      cart.product.discount,
                      cart.product.discount_start_date,
                      cart.product.discount_end_date
                    ) && (
                      <div
                        style={{
                          background: ' #D92D20',
                          color: '#fff',
                          fontSize: '12px',
                          width: '28px',
                          height: '19px',
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '11px',
                          marginLeft: '76px'
                        }}
                      >
                        {cart.product.discount}%
                      </div>
                    )}

                    <Grid item md={4}>
                      <Box className={classes.description_style}>
                        <h4 className='title_de'>{cart.cart_item.name}</h4>
                        <p className='values'>
                          <span>{t('variant')}: </span>
                          {cart.product_variant?.attributes[0].value}
                        </p>
                      </Box>
                    </Grid>

                    {/* {cart.color === null ? null : (
                      <Grid item md={4}>
                        <Box className={classes.description_style}>
                          <h4 className="title_de">{cart.name}</h4>
                          <p className="values">
                            <span>{t("variant")}: </span>
                            {cart.color.attributes[0].value}
                          </p>
                        </Box>
                      </Grid>
                    )} */}

                    <Grid item md={2}>
                      {cart.cart_item.checked_out_quantity} pcs
                    </Grid>
                    <Grid item md={2}>
                      <Box className={classes.priceContent_style}>
                        {!isDiscountValid(
                          cart.product.discount,
                          cart.product.discount_start_date,
                          cart.product.discount_end_date
                        ) ? (
                          <p className='values'>
                            Rp&nbsp;
                            <Amount amount={cart.cart_item.price} />
                          </p>
                        ) : (
                          <p className='values'>
                            Rp&nbsp;
                            <Amount amount={cart.cart_item.price - cart.cart_item.discount} />
                          </p>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={2}>
                      <Box className={classes.priceContent_style}>
                        {!isDiscountValid(
                          cart.product.discount,
                          cart.product.discount_start_date,
                          cart.product.discount_end_date
                        ) ? (
                          <p className='values'>
                            Rp&nbsp;
                            <Amount
                              amount={cart.cart_item.price * cart.cart_item.checked_out_quantity}
                            />
                          </p>
                        ) : (
                          <p className='values'>
                            Rp&nbsp;
                            <Amount
                              amount={
                                cart.cart_item.price * cart.cart_item.checked_out_quantity -
                                cart.cart_item.discount
                              }
                            />
                          </p>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            })}
            {/* <SkeletonCircleText /> */}
          </Box>
        </Card>
      </div>

      <div style={{ width: '57%' }}>
        <RightCheckout
          PlaceOrderClick={placeOrder}
          PlaceOrderClickPayonspot={placeOrderPayonspot}
          singleShipping={singleShipping}
          shippingCost={shipingCost}
          loding={isLoading}
          checkOut={checkOut}
          // CODfees={getCartData}
          getCartData={getCartData}
          isBank={isBank}
          webThemeColor={webThemeColor}
          loadingBtn={loadingBtn}
          ppnTaxShippingAmount={ppnTaxShippingAmount}
          fixTaxAmount={fixTaxAmount}
          grandTotal={ReturnTotal()}
        />
      </div>

      <Snackbar
        open={isSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setSnackbar(false)}
        autoHideDuration={2000}
      >
        <MuiAlert severity='success'>{t('oder_add_successfully')}</MuiAlert>
      </Snackbar>

      <Dialog
        open={openPopUpAddressList}
        onClose={handleClosePopUpAddressList}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{
          paper: classes.paperDialogAddressList
        }}
      >
        <DialogTitle id='alert-dialog-title' style={{ height: '50px', padding: '5px 15px' }}>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <label style={{ fontSize: '20px' }}>{'Alamat Saya'}</label>
            <CloseIcon
              onClick={handleClosePopUpAddressList}
              style={{ fontSize: '30px', paddingBottom: '10px' }}
            />
          </Grid>
        </DialogTitle>
        <DialogContent>
          {addressData
            ? addressData
              ? addressData.map((dt, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        borderBottom: '1px solid #EBEBEB',
                        paddingTop: '20px',
                        paddingBottom: '20px'
                      }}
                    >
                      <Grid container>
                        <Grid item md={1}>
                          <div className={classes.radio_item}>
                            <input
                              type='radio'
                              id={dt.customer_address.id}
                              value={JSON.stringify(dt)}
                              onChange={handleChangeRadioAddressList}
                              name='radioAddressChoice'
                              style={{
                                accentColor: webThemeColor,
                                height: '20px',
                                width: '20px',
                                cursor: 'pointer'
                              }}
                              key={index}
                              defaultChecked={
                                addressPrimary &&
                                addressPrimary.length > 0 &&
                                addressPrimary[0].customer_address.id === dt.customer_address.id
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item md={4}>
                          {dt.customer_address.name}
                        </Grid>
                        <Grid
                          item
                          md={4}
                          style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'left'
                          }}
                        >
                          {dt.customer_address.phone}
                          {dt.customer_address.is_primary === true ? (
                            <Button
                              variant='outlined'
                              color='secondary'
                              size='small'
                              style={{
                                color: '#DC6803',
                                border: '1px #DC6803 solid'
                              }}
                            >
                              {t('default_unbold')}
                            </Button>
                          ) : null}
                        </Grid>
                        <Grid item md={3}>
                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              color: webThemeColor,
                              border: 'none',
                              alignItems: 'right',
                              justifyContent: 'right'
                            }}
                          >
                            <Button
                              style={{
                                background: 'transparent',
                                color: webThemeColor
                              }}
                              onClick={() => UpdateAddressOpenPopUp(dt.customer_address)}
                              size='small'
                            >
                              {t('edit')}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item md={6}>
                          {dt.customer_address.address}
                        </Grid>
                        <Grid item md={5}></Grid>
                      </Grid>
                    </div>
                  )
                })
              : null
            : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopUpAddressList} style={{ color: webThemeColor }}>
            {t('cancel1')}
          </Button>
          <Button
            variant='contained'
            onClick={handleClickChangeAddress}
            style={{ backgroundColor: webThemeColor, color: 'white' }}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPopUpAddressUpdate}
        onClose={handleClosePopUpdateAddress}
        aria-labelledby='alert-dialog-title-update-address'
        aria-describedby='alert-dialog-description-update-address'
        classes={{
          paper: classes.paperDialogAddressUpdate
        }}
      >
        <DialogTitle id='alert-dialog-title' style={{ height: '50px', padding: '5px 15px' }}>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <label style={{ fontSize: '20px' }}>{'Ubah Alamat'}</label>
            <CloseIcon
              onClick={handleClosePopUpdateAddress}
              style={{ fontSize: '30px', paddingBottom: '10px' }}
            />
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <TextField
                type='text'
                label={t('name')}
                className={classes.borderColorTextfield}
                variant='outlined'
                size='small'
                style={{ width: '100%' }}
                defaultValue={formdata ? formdata.name : null}
                onChange={onChangeNameForUpdateAddress}
              />
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <TextField
                type='text'
                label={t('phone_number')}
                className={classes.borderColorTextfield}
                variant='outlined'
                size='small'
                style={{ width: '100%' }}
                defaultValue={formdata ? formdata.phone : null}
                onChange={onChangePhoneForUpdateAddress}
              />
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='demo-simple-select-label-province'>{t('province')}</InputLabel>
                <Select
                  labelId='demo-simple-select-label-province'
                  id='demo-simple-select-province'
                  label={t('province')}
                  onChange={getDistric}
                  defaultValue={formdata ? formdata.province_id : null}
                >
                  {provinceData.length > 0
                    ? provinceData.map((dt, index) => {
                        return (
                          <MenuItem value={dt.id} key={index}>
                            {dt.name}
                          </MenuItem>
                        )
                      })
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='demo-simple-select-label-district-city'>
                  {t('district/city')}
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label-district-city'
                  id='demo-simple-select-district-city'
                  label={t('district/city')}
                  onChange={getSubDistrict}
                  defaultValue={formdata ? formdata.district_id : null}
                >
                  {cityData.length > 0
                    ? cityData.map((ct, index) => {
                        return (
                          <MenuItem value={ct.id} key={index}>
                            {ct.name}
                          </MenuItem>
                        )
                      })
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <FormControl variant='outlined' fullWidth>
                <InputLabel id='demo-simple-select-label-subdistricts'>
                  {t('subdistricts')}
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label-subdistricts'
                  id='demo-simple-select-subdistricts'
                  label={t('subdistricts')}
                  onChange={subDistChange}
                  defaultValue={formdata ? formdata.subdistrict_id : null}
                >
                  {districtData.length > 0
                    ? districtData.map((sd, index) => {
                        return (
                          <MenuItem value={sd.id} key={index}>
                            {sd.name}
                          </MenuItem>
                        )
                      })
                    : null}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <TextField
                type='text'
                label={t('postcode')}
                className={classes.borderColorTextfield}
                variant='outlined'
                size='small'
                style={{ width: '100%' }}
                onChange={onChangePostCodeForUpdateAddress}
                defaultValue={formdata ? formdata.postal_code : null}
              />
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '15px' }}>
            <Grid item md={12}>
              <TextField
                type='text'
                label={t('address')}
                className={classes.borderColorTextfield}
                variant='outlined'
                size='small'
                style={{ width: '100%' }}
                multiline
                onChange={onChangeAddressForUpdateAddress}
                defaultValue={formdata ? formdata.address : null}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopUpdateAddress} style={{ color: webThemeColor }}>
            {t('cancel1')}
          </Button>
          <Button
            variant='contained'
            onClick={handleClickChangeAddressUpdate}
            style={{ backgroundColor: webThemeColor, color: 'white' }}
          >
            {t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default LeftCheckout
