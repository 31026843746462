import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
  message: ''
}

export const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action) => {
      state.show = action.payload.show
      state.message = action.payload.message
      state.sx = action.payload.sx
      state.action = action.payload.action
    }
  }
})

export const { setSnackbar } = snackbar.actions

export default snackbar.reducer
