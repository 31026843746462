import React, { useEffect, useState } from 'react'
import { makeStyles, IconButton, TextField, Badge, styled } from '@material-ui/core'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { FaShareSquare } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import 'react-multi-carousel/lib/styles.css'
import { withNamespaces } from 'react-i18next'
import { RWebShare } from 'react-web-share'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
// import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import DialogAlert from '../../components/common/DialogAlert'
import CardProduct from '../../components/card-product/CardProduct'
import { getByClient as getProductsDetail } from '../../api/products'
import { addToCart as createToCart } from 'helper/orderHelper'
import { createProductWishlist, deleteProductWishlist } from '../../api/wishlist'
import { priceFormatLocal } from '../../utils/helper'
import { isDiscountValid } from '../../utils/pricing'
import { useStore } from '../../hooks/useStore'
import { useAuth } from '../../hooks/useAuth'
import { handleNavBack } from '../../utils/navigationHelper'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../../stores/features/snackbar/snackbar'
import { ArrowLeft, ShoppingCart, Home2 } from 'iconsax-react'
import { useQuery } from 'hooks/useQuery'
import { useModalMessage } from 'hooks/useModalMessage'
import { ChevronRight } from '@material-ui/icons'

const DetailsProduct = ({ t, color, visible, product, refreshProductData }) => {
  const dispatch = useDispatch()
  const { landingPage, storeTitle, storeSettingCMS } = useStore()
  const { countCart, refreshCart, refreshCountWishlist, user, carts } = useAuth()

  const { setMessage } = useModalMessage()

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      top: 8,
      right: 8
    }
  }))

  const useStyles = makeStyles(theme => ({
    box: {
      // boxShadow: '0px 0px 8px -1px rgb(219 213 213)',
      backgroundColor: '#ffffff',
      '& h6': {
        color: props => props.colorTheme
      }
    },

    cards_style: {
      '& .card:hover': {}
    },

    cardTitle: {
      '& .read-more-less--more': {
        display: 'none'
      }
    },

    imageslide: {
      '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail.active':
        {
          border: props => `1.5px solid ${props.colorTheme} !important`,
          borderRadius: `4.5px !important`
        },
      // '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail':
      //   {
      //     width: '22vw !important'
      //   },
      '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail .image-gallery-thumbnail-image':
        {
          objectFit: 'cover !Important'
        },
      '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails':
        {
          padding: '0px !important',
          width: '100% !important'
        },
      '& .image-gallery .image-gallery-content .image-gallery-slide .image-gallery-image': {
        height: '350px !important',
        objectFit: 'cover !Important'
      },
      '& .image-gallery-thumbnails': {
        backgroundColor: '#F5F5F5'
      },
      '& .image-gallery .image-gallery-index': {
        padding: '5px',
        borderRadius: '50%',
        fontSize: '13px !important'
      }
    },
    title: {
      fontSize: '13px'
    },
    avatarfav: {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      marginBottom: '60px'
    },
    avatarwhat: {
      backgroundColor: 'rgba(20, 9, 9, 0.3);',
      border: '1px solid #fff',
      marginBottom: '5px'
    },
    whatsAppIcon: {
      background: 'rgba(37, 211, 102, 1)',
      borderRadius: '50%'
    },
    favBorderIcon: {
      backgroundColor: props => props.colorTheme,
      marginBottom: '0px'
    },
    wholeSale: {
      color: 'rgba(68, 210, 255, 1)',
      fontSize: '10px'
    },
    colorBox: {
      height: '3.4rem',
      width: '3.5rem',
      color: 'black'
    },
    icons: {
      color: '#8B8B8B'
    },
    chatBtn: {
      backgroundColor: '#FFF',
      color: '#000',
      border: 'none',
      borderRadius: '0px',
      width: '100%',
      height: '100%',
      marginBottom: '9px',
      paddingBottom: '5px'
    },
    buyBtn: {
      backgroundColor: props => props.colorTheme,
      color: '#fff',
      width: '100%',
      border: 'none',
      height: '100%',
      marginBottom: '12px',
      paddingBottom: '12px'
    },
    specificationTable: {
      color: 'rgba(139, 139, 139, 1)',
      width: '100%',
      borderCollapse: 'collapse',
      '& td': {
        paddingTop: '0.4rem',
        paddingBottom: '0.4rem',
        paddingLeft: '0.25rem'
      },
      '& td:nth-child(1)': {
        width: '40%'
      },
      '& tr:nth-child(odd)': {
        backgroundColor: '#F2F0F1'
      }
    },
    bestSeller: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      backgroundColor: 'red',
      color: '#fff',
      fontSize: '10px',
      borderRadius: '2px'
    },
    shareButton: {
      backgroundColor: '#E4E5E9',
      border: 'none',
      borderRadius: '100px 0 0 100px',
      width: '25%',
      fontSize: '10px',
      textAlign: 'center',
      position: 'absolute',
      right: '-6px',
      padding: '0px 10px 0px 0px'
    },
    snackbarStyleViaContentProps1: {
      backgroundColor: '#363636 !important',
      textAlign: 'center !important',
      color: 'white !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      fontWeight: '500 !important',
      fontSize: '15px !important',
      borderRadius: '8px !important',
      margin: '12px 24px 123px 24px !important',
      height: '47px !important',
      width: '359px !important',
      paddding: 0,
      '& .MuiSnackbarContent-message': {
        padding: 0
      }
    },
    snackbarStyleCopyProduct: {
      backgroundColor: '#363636 !important',
      textAlign: 'center !important',
      color: 'white !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      fontWeight: '500 !important',
      fontSize: '15px !important',
      borderRadius: '8px !important',
      margin: '12px 24px 12px 24px !important',
      height: '47px !important',
      width: '359px !important',
      paddding: 0,
      '& .MuiSnackbarContent-message': {
        padding: 0
      }
    },
    desdiv: {
      '& p': {
        marginBottom: '0px !important'
      }
    },

    mobile_slider_cards: {
      '& .card-body': {
        padding: '5px 2px 0px 6px !important'
      },
      '& .mainImageThum': {
        height: '200px'
      },
      '& .cardTitle ': {
        fontSize: '13px !important'
      },
      '& .TowLineText ': {
        height: '38px'
      }
    },
    styleTextfieldNotedForSale: {
      width: '97%',
      marginLeft: '5px',
      marginRight: '5px',
      border: '0',
      '& label.Mui-focused': {
        color: props => props.colorTheme
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: props => props.colorTheme
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: props => props.colorTheme
        }
      }
    },
    qtyinput: {
      '& input[type=number]': {
        WebkitAppearance: 'textfield',
        mozAppearance: 'textfield',
        appearance: 'textfield',
        textAlign: 'center'
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none'
      },
      '& input[type=number]:disabled': {
        cursor: 'not-allowed'
      }
    },
    buttonWishlist: {
      padding: '6px',
      background: '#80808040',
      borderRadius: '54px',
      width: '30px',
      height: '30px',
      outline: 'none',
      border: 'none'
    },
    buttonIcon: {
      border: 'none',
      outline: 'none',
      borderRadius: '50%',
      background: 'rgb(111 104 104 / 25%)',
      width: '35px',
      height: '35px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg.MuiSvgIcon-root': {
        margin: '0px !important'
      }
    },
    table: {
      '& tbody tr td': {
        fontSize: '14px'
      }
    },
    indexThumbnail: {
      padding: '6px',
      minWidth: '42px',
      height: '30px',
      borderRadius: '100px',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }))
  const history = useHistory()
  const searchParams = useQuery()
  const id = searchParams.get('id')
  const usercode = JSON.parse(localStorage.getItem('usercode'))
  const userid = JSON.parse(localStorage.getItem('userid'))
  const [data2, setData2] = useState({})
  const [variant, setVariant] = useState([])
  const [productDetail, setProductDetail] = useState({})
  const [relatedProductsData, setrelatedProductsData] = useState([])

  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(true)
  const [images, setImages] = useState([])
  const [bestSellerFlag, setbestSellerFlag] = useState('')
  const [membershipLevel, setMembershipLevel] = useState(1)
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const sitetitle = storeTitle || process.env.REACT_APP_NICK_SITE_TITLE
  const landingPageValue = JSON.parse(localStorage.getItem('landing_page'))

  if (open1 && open) {
    if (productDetail.product.media && productDetail.product.media.length > 0) {
      for (var i = 0; i < productDetail.product.media.length; i++) {
        const getExtension = productDetail.product.media[i].split('.').pop()
        if (
          ['mp4', 'webm', 'ogg', 'avi', 'flv', 'mov', 'wmv', '3gp', 'mkv', 'm4v'].includes(
            getExtension
          )
        ) {
          images.unshift({
            original: process.env.REACT_APP_FILE_BASE_URL + '/' + productDetail.product.media[i], // url
            thumbnail: process.env.REACT_APP_FILE_BASE_URL + '/' + productDetail.product.media[0],
            renderItem: renderVideo.bind(this),
            renderThumbInner: videoThumbInner.bind(this)
          })
        } else {
          images.push({
            original: process.env.REACT_APP_FILE_BASE_URL + '/' + productDetail.product.media[i],
            thumbnail: process.env.REACT_APP_FILE_BASE_URL + '/' + productDetail.product.media[i]
          })
        }
      }
    }
    setOpen1(false)
  }

  useEffect(() => {
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [])

  const openVariant = idVaraint => {
    history.push(`/productdetailvariant?id=${id}${idVaraint ? '&variantId=' + idVaraint : ''}`)
  }

  const opens = JSON.parse(localStorage.getItem('open'))
  if (opens !== null) {
    if (opens == false) {
      setOpen(false)
      images.splice(0)
      setOpen1(true)
      localStorage.setItem('open', JSON.stringify(true))
    }
  }

  const [isActiveButtonAddToCart, setActiveButtonAddToCart] = useState(false)
  const [related, setReleated] = useState([])

  const classes = useStyles({ colorTheme: color })

  const getSingleProduct = e => {
    images.splice(0)
    history.push(`/productdetail?id=${e}`)

    setOpen(false)
    setOpen1(true)
    related.splice(0, related.length)
    window.scrollTo(0, 0)
    // refreshProductData()
    // singleData();
  }

  const [isWishlished, setIsWishlished] = useState(false)
  const [loadingAddToWishlist, setLoadingAddToWishlist] = useState(false)
  const [loadingRemoveFromWishlist, setLoadingRemoveFromWishlist] = useState(false)

  const addToWishlist = async () => {
    setLoadingAddToWishlist(true)
    try {
      const res = await createProductWishlist({ product_id: productDetail.product.id })
      if (res.success) {
        setIsWishlished(true)
        refreshCountWishlist()
        dispatch(
          setSnackbar({
            show: true,
            message: t('success_add_to_wishlist')
          })
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          setSnackbar({
            show: true,
            message: error.message
          })
        )
      } else {
        dispatch(
          setSnackbar({
            show: true,
            message: 'Ops, something went wrong'
          })
        )
      }
    } finally {
      setLoadingAddToWishlist(false)
    }
  }

  const removeFromWishlist = async () => {
    setLoadingRemoveFromWishlist(true)
    try {
      const res = await deleteProductWishlist(productDetail.product.id)
      if (res.success) {
        setIsWishlished(false)
        refreshCountWishlist()
        dispatch(
          setSnackbar({
            show: true,
            message: t('success_remove_from_wishlist')
          })
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          setSnackbar({
            show: true,
            message: error.message
          })
        )
      } else {
        dispatch(
          setSnackbar({
            show: true,
            message: 'Ops, something went wrong'
          })
        )
      }
    } finally {
      setLoadingRemoveFromWishlist(false)
    }
  }

  const QuantityForProduct = (variantID, quantity, price, isInput) => {
    if (quantityPri.length > 0) {
      const datass = quantityPri.find(function (p) {
        if (p.id !== variantID) return false
        p.qty = isInput ? parseInt(quantity) : (p.qty += parseInt(quantity))
        return true
      })
      if (datass == undefined) {
        setQuantitypri(quantityPri => [
          ...quantityPri,
          {
            id: variantID,
            qty: parseInt(quantity) < 0 ? 0 : parseInt(quantity),
            colorid: variantID,
            total: price * (parseInt(quantity) < 0 ? 0 : parseInt(quantity))
          }
        ])
      } else {
        const findIndex = quantityPri.findIndex(data => data.id === variantID)
        let updateData = [...quantityPri]
        updateData[findIndex].qty = datass.qty < 0 ? 0 : datass.qty
        updateData[findIndex].total = price * parseInt(datass.qty)
        setQuantitypri(updateData)
      }
    } else {
      setQuantitypri(quantityPri => [
        ...quantityPri,
        {
          id: variantID,
          qty: parseInt(quantity) < 0 ? 0 : parseInt(quantity),
          colorid: variantID,
          total: price * (parseInt(quantity) < 0 ? 0 : parseInt(quantity))
        }
      ])
    }
  }

  const vertical = 'bottom',
    horizontal = 'center'
  const [trueopen, setTrueOpen] = useState(false)
  const [openSnackbarCopyDescriptionProduct, setOpenSnackbarCopyDescriptionProduct] =
    useState(false)
  const [quantityPri, setQuantitypri] = useState([])
  const [totalPri, setTotalpri] = useState([])
  const [totalQty, setTotalQty] = useState([])
  const [coloridPri, setColoridpri] = useState([])
  const [showPopupStockNotEnought, setShowPopupStockNotEnought] = useState(false)
  let data = {}

  const customer = user

  const AddToCartProduct = async () => {
    // const Usercode = JSON.parse(localStorage.getItem("usercode"));
    // console.log("quantityPri onclick",quantityPri)
    if (quantityPri.length > 0) {
      for (var i = 0; i < quantityPri.length; i++) {
        if (quantityPri[i].qty !== '') {
          const findIndexStock = variant.findIndex(data => data.id === quantityPri[i].id)
          if (quantityPri[i].qty > variant[findIndexStock].stock) {
            setShowPopupStockNotEnought(true)
            return
          }
          totalQty.push(quantityPri[i].qty)
          totalPri.push(quantityPri[i].total)
          coloridPri.push(quantityPri[i].colorid)
        }
      }
    }

    data = quantityPri.map(item => ({
      product_id: Number(id),
      product_variant_id: item.id,
      quantity: item.qty,
      checked_out_quantity: item.qty,
      note: valueTextFieldNotedForSale
    }))

    try {
      const response = await createToCart(
        data.filter(item => item.quantity > 0),
        landingPage,
        customer,
        carts,
        product,
        variant
      )
      if (response.success) {
        // setTrueOpen(true)
        // setOpen3(true)
        // setMessage(response.message);
        totalPri.splice(0)
        totalQty.splice(0)
        coloridPri.splice(0)
        // setTrueOpen(false)

        dispatch(
          setSnackbar({
            show: true,
            sx: {
              top: { xs: '50vh', sm: 0 }
            },
            message: t('success_add_to_cart'),
            action: {
              text: t('show_cart'),
              onClick: () => {
                history.push('/cart')
              }
            }
          })
        )
        refreshCart()
      } else {
        setMessage(response.message)
      }
    } catch (error) {
      setMessage(error.message)
    }
  }
  // handleChangeTextFieldNotedForSale
  const [valueTextFieldNotedForSale, setValueTextFieldNotedForSale] = useState('')
  const handleChangeTextFieldNotedForSale = async value => {
    setValueTextFieldNotedForSale(value)
  }

  const gotoCart = () => {
    history.push('/cart')
  }

  const actionSnackbar = (
    <React.Fragment>
      <Button
        onClick={() => gotoCart()}
        style={{ color: '#FFC907', fontSize: '15px', fontWeight: '500' }}
      >
        Go To Cart
      </Button>
    </React.Fragment>
  )

  const handleClickBack = () => {
    localStorage.setItem('open', JSON.stringify(false))

    handleNavBack()
  }

  const directToProductByCategory = category => {
    localStorage.setItem('viewproid', category.id)
    localStorage.setItem('viewproname', category.name)
    // localStorage.setItem("viewprochild", JSON.stringify(category.slug));
    localStorage.setItem('viewprochild', JSON.stringify(''))

    history.push('/products')
  }

  const handleClickCopyProductDescription = () => {
    const sanitizedDescription = data2.detail.replaceAll(/<\/?p[^>]*>|&nbsp;/g, '\n')

    // copy sanitizedDescription to clipboard
    navigator.clipboard.writeText(sanitizedDescription)

    setOpenSnackbarCopyDescriptionProduct(true)
    // setTimeout(() => {
    //   setOpenSnackbarCopyDescriptionProduct(false)
    // }, 1000)
  }

  const closeModalStockNotEnought = () => {
    setShowPopupStockNotEnought(false)
  }

  const addToCart = async () => {
    try {
      if (userid) {
        if (variant.length > 0 && quantityPri.length <= 0) {
          if (landingPage?.setting_cms?.view_detail_product_variant === 2) {
            history.push(`/productdetailvariant?id=${id}`)

            return
          }

          setMessage('Please insert qty.')
        } else {
          localStorage.setItem('productId', Number(id))
          await AddToCartProduct()
        }
      } else {
        history.push('/login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (product) {
      if (product.product.stock_status !== 'Sold Out') {
        setActiveButtonAddToCart(true)
      }

      setData2(product.product)
      if (product.variants) {
        setVariant(product.variants)
      }
      setProductDetail(product)
      setIsWishlished(product.customer_wishlist ? true : false)
      const findBestSellerLabel =
        product.product.labels && product.product.labels.length > 0
          ? product.product.labels.filter(data => data === 'Terlaris')
          : null

      if (findBestSellerLabel && findBestSellerLabel.length > 0) {
        setbestSellerFlag(findBestSellerLabel[0])
      } else {
        setbestSellerFlag('')
      }

      setOpen(true)

      if (landingPageValue.setting_cms.is_show_related_products === true) {
        getProductsDetail(
          `{"page": 1, "limit": 25, "category_id":${product.product.category_id} }`
        ).then(data => {
          if (data.data && data.data.length > 0) {
            data = data.data.filter(data => data.product.id !== product.product.id)
          }

          setrelatedProductsData(data)
        })
      }
    }
  }, [product])

  const [currentIndexImage, setCurrentIndexImage] = useState(1)
  const handleOnSlide = currentIndex => {
    const showCurrentIndex = currentIndex === 0 ? 1 : currentIndex + 1
    setCurrentIndexImage(showCurrentIndex)
  }

  return (
    <div
      style={{
        overflowX: 'hidden'
      }}
    >
      {data2 ? (
        <div style={{ background: '#F5F5F5', height: 'auto' }}>
          <div
            // className="image-container"
            style={{
              position: 'relative',
              marginTop: visible ? '48px' : '0',
              backgroundColor: '#ffffff'
            }}
          >
            <div
              className={
                // classes.imageslide +
                // " " +
                // " d-flex justify-content-start justify-content-center align-items-center"
                classes.imageslide
              }
            >
              {images.length > 0 ? (
                <ImageGallery
                  items={images}
                  showThumbnails={storeSettingCMS?.is_show_variant_image}
                  slideOnThumbnailOver={true}
                  showNav={false}
                  showPlayButton={false}
                  disableSwipe={false}
                  showFullscreenButton={false}
                  infinite={true}
                  slideDuration={200}
                  className={classes.imageslide}
                  onSlide={handleOnSlide}
                />
              ) : (
                <img src='/assets/images/dummy.png' height='350px' width='100%' />
              )}
              {productDetail &&
              productDetail.product &&
              productDetail.product.stock_status === 'Sold Out' ? (
                <div
                  className='position-absolute d-flex justify-content-center align-items-center rounded-circle '
                  style={{
                    width: '12rem',
                    height: '12rem',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    bottom: '150px',
                    left: '26%'
                  }}
                >
                  <span
                    className='text-light'
                    style={{
                      fontSize: '28px',
                      fontWeight: 'bolder',
                      cursor: 'pointer'
                    }}
                  >
                    {t('sold_out')}
                  </span>
                </div>
              ) : (
                ''
              )}
              <div
                className='position-absolute'
                style={{
                  bottom: storeSettingCMS?.is_show_variant_image ? 100 : 68,
                  right: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 10
                }}
              >
                {isWishlished ? (
                  <button
                    className={classes.buttonWishlist}
                    disabled={loadingRemoveFromWishlist}
                    onClick={removeFromWishlist}
                  >
                    <img
                      src='/assets/frontend/img/wishlist_fav.png'
                      height='21px'
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '0px',
                        cursor: 'pointer'
                      }}
                    />
                  </button>
                ) : (
                  <button
                    className={classes.buttonWishlist}
                    disabled={loadingAddToWishlist}
                    onClick={addToWishlist}
                  >
                    <img
                      src='/assets/frontend/img/wishlist_list.png'
                      height='21px'
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '0px',
                        cursor: 'pointer'
                      }}
                    />
                  </button>
                )}
                <div className={classes.indexThumbnail}>
                  <span style={{ fontSize: '13px' }}>
                    {currentIndexImage}/{images.length}
                  </span>
                </div>
              </div>
              {!visible && (
                <React.Fragment>
                  <div
                    className='position-absolute'
                    style={{
                      left: 10,
                      top: 10,
                      right: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <button className={classes.buttonIcon} onClick={handleClickBack}>
                      <ArrowLeft size={20} color='white' />
                    </button>
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <button
                        className={classes.buttonIcon}
                        style={{ position: 'relative' }}
                        onClick={() => {
                          history.push('/cart')
                        }}
                      >
                        <StyledBadge badgeContent={countCart} color='error'>
                          <ShoppingCart size={20} color='white' />
                        </StyledBadge>
                      </button>
                      <button
                        className={classes.buttonIcon}
                        onClick={() => {
                          history.push('/')
                        }}
                      >
                        <Home2 size={20} color='white' />
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div
            className='p-2'
            style={{
              marginTop: storeSettingCMS?.is_show_variant_image ? '0px' : '8px',
              backgroundColor: '#ffffff'
            }}
          >
            {data2 ? (
              data2.price ? (
                <React.Fragment>
                  <div
                    className='d-flex'
                    style={{
                      justifyContent: 'space-start',
                      gap: '5px',
                      alignItems: 'center',
                      position: 'relative'
                    }}
                  >
                    <label
                      style={{
                        cursor: 'pointer',
                        color: color,
                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginBottom: 0
                      }}
                    >
                      {data2.discount_type &&
                        isDiscountValid(
                          data2.discount,
                          data2.discount_start_date,
                          data2.discount_end_date
                        ) &&
                        data2.discount_type === 'nominal' && (
                          <p
                            style={{
                              marginBottom: '0px'
                            }}
                          >
                            {priceFormatLocal(data2.price[membershipLevel] - data2.discount)}
                          </p>
                        )}

                      {data2.discount_type &&
                        isDiscountValid(
                          data2.discount,
                          data2.discount_start_date,
                          data2.discount_end_date
                        ) &&
                        data2.discount_type === 'percentage' && (
                          <p
                            style={{
                              marginBottom: '0px'
                            }}
                          >
                            {priceFormatLocal(
                              data2.price[membershipLevel] -
                                (data2.discount / 100) * data2.price[membershipLevel]
                            )}
                          </p>
                        )}

                      {!isDiscountValid(
                        data2.discount,
                        data2.discount_start_date,
                        data2.discount_end_date
                      ) && (
                        <p
                          style={{
                            marginBottom: '0px'
                          }}
                        >
                          {priceFormatLocal(data2.price[membershipLevel])}
                        </p>
                      )}
                    </label>
                    {data2 ? (
                      <React.Fragment>
                        {data2.discount_type &&
                          isDiscountValid(
                            data2.discount,
                            data2.discount_start_date,
                            data2.discount_end_date
                          ) &&
                          data2.discount_type === 'percentage' && (
                            <div className={classes.bestSeller}>
                              <span style={{ cursor: 'pointer' }}>{data2.discount}%</span>
                            </div>
                          )}

                        {data2.discount_type &&
                          isDiscountValid(
                            data2.discount,
                            data2.discount_start_date,
                            data2.discount_end_date
                          ) &&
                          data2.discount_type === 'nominal' && (
                            <div className={classes.bestSeller}>
                              <span style={{ cursor: 'pointer' }}>
                                {parseInt((data2.discount / data2.price[membershipLevel]) * 100)}%
                              </span>
                            </div>
                          )}

                        {isDiscountValid(
                          data2.discount,
                          data2.discount_start_date,
                          data2.discount_end_date
                        ) && (
                          <span
                            style={{
                              textDecoration: 'line-through',
                              color: 'grey',
                              fontSize: '15px',
                              cursor: 'pointer'
                            }}
                          >
                            {priceFormatLocal(data2.price[membershipLevel])}
                          </span>
                        )}
                      </React.Fragment>
                    ) : null}
                    <RWebShare
                      className='Share-icon'
                      data={{
                        url: window.location.href,
                        title: 'Share'
                      }}
                    >
                      <button
                        className={classes.shareButton}
                        style={{ marginLeft: 'auto', marginRight: '0' }}
                      >
                        <FaShareSquare size='10' color='#6c757d' />
                        <span className='text-muted'>{t('share')}</span>
                      </button>
                    </RWebShare>
                  </div>
                </React.Fragment>
              ) : null
            ) : null}

            <div
              style={{
                height: '100%',
                cursor: 'pointer'
              }}
            >
              <div>
                {bestSellerFlag ? (
                  <span
                    className={classes.bestSeller}
                    style={{
                      position: 'inline',
                      paddingTop: '3px',
                      paddingBottom: '3px',
                      color: 'yellow',
                      marginRight: '5px'
                    }}
                  >
                    {bestSellerFlag}
                  </span>
                ) : (
                  ''
                )}
                <span
                  style={{
                    paddingTop: '2px',
                    cursor: 'pointer',
                    position: 'inline'
                  }}
                >
                  {data2.name}
                </span>
              </div>
            </div>
          </div>

          {variant.length > 0 ? (
            <React.Fragment>
              {landingPage?.setting_cms?.view_detail_product_variant === 1 ? (
                <div className={`${classes.box} mt-2 ps-1 pe-1 pt-2 pb-2`}>
                  <table
                    className={classes.table}
                    style={{
                      border: '1px solid lightgray',
                      textAlign: 'center',
                      cursor: 'pointer',
                      marginLeft: '5px',
                      width: '96.7%'
                    }}
                  >
                    <thead
                      style={{
                        background: color,
                        color: 'white',
                        cursor: 'pointer'
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            border: '1px solid lightgray',
                            cursor: 'pointer'
                          }}
                        >
                          {t('column_color')}
                        </td>
                        <td
                          style={{
                            border: '1px solid lightgray',
                            cursor: 'pointer',
                            width: '20%'
                          }}
                        >
                          {t('column_stock')}
                        </td>
                        <td
                          style={{
                            border: '1px solid lightgray',
                            cursor: 'pointer',
                            width: `100px`
                          }}
                        >
                          {t('column_price')}
                        </td>
                        <td
                          style={{
                            border: '1px solid lightgray',
                            cursor: 'pointer',
                            width: `80px`
                          }}
                        >
                          {t('column_qty')}
                        </td>
                      </tr>
                    </thead>
                    {variant.map((itemVariant, indexVariant) => {
                      const findIndex = quantityPri.findIndex(data => data.id === itemVariant.id)
                      const valueQuantity = findIndex >= 0 ? quantityPri[findIndex].qty : 0
                      const priceValue =
                        data2.discount_type === 'nominal'
                          ? itemVariant.price[membershipLevel] - data2.discount
                          : data2.discount_type === 'percentage'
                            ? itemVariant.price[membershipLevel] -
                              (data2.discount / 100) * itemVariant.price[membershipLevel]
                            : itemVariant.price[membershipLevel]
                      return (
                        <tbody key={indexVariant}>
                          <tr>
                            <td
                              style={{
                                border: '1px solid lightgray',
                                cursor: 'pointer',
                                padding: '5px'
                              }}
                            >
                              {itemVariant.attributes[0].value}
                            </td>
                            {itemVariant.stock_status === 'Sold Out' ? (
                              <td
                                style={{
                                  border: '1px solid lightgray',
                                  cursor: 'pointer',
                                  padding: '5px',
                                  color: '#f9020a'
                                }}
                              >
                                {storeSettingCMS?.show_stock_product === 1
                                  ? itemVariant.stock
                                  : itemVariant.stock_status}
                              </td>
                            ) : itemVariant.stock_status === 'Limited' ? (
                              <td
                                style={{
                                  border: '1px solid lightgray',
                                  cursor: 'pointer',
                                  padding: '5px',
                                  color: '#e49661'
                                }}
                              >
                                {storeSettingCMS?.show_stock_product === 1
                                  ? itemVariant.stock
                                  : itemVariant.stock_status}
                              </td>
                            ) : (
                              <td
                                style={{
                                  border: '1px solid lightgray',
                                  cursor: 'pointer',
                                  padding: '5px'
                                }}
                              >
                                {storeSettingCMS?.show_stock_product === 1
                                  ? itemVariant.stock
                                  : 'Ready'}
                              </td>
                            )}
                            <td
                              style={{
                                border: '1px solid lightgray',
                                cursor: 'pointer',
                                padding: '5px'
                              }}
                            >
                              {priceFormatLocal(priceValue)}
                            </td>
                            <td
                              style={{
                                border: '1px solid lightgray',
                                cursor: 'pointer'
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center'
                                }}
                                className={classes.qtyinput}
                              >
                                <IconButton
                                  disabled={itemVariant.stock_status === 'Sold Out'}
                                  style={{ padding: 0, width: '25px' }}
                                  onClick={() => {
                                    QuantityForProduct(
                                      itemVariant.id,
                                      -1,
                                      itemVariant.price[membershipLevel],
                                      false
                                    )
                                  }}
                                >
                                  <RemoveIcon
                                    fontSize='small'
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      fill: 'black'
                                    }}
                                  />
                                </IconButton>
                                <input
                                  style={{
                                    width: '25px',
                                    border: 'none',
                                    textAlign: 'center'
                                  }}
                                  type='number'
                                  onChange={e => {
                                    QuantityForProduct(
                                      itemVariant.id,
                                      e.target.value,
                                      itemVariant.price[membershipLevel],
                                      true
                                    )
                                  }}
                                  value={valueQuantity}
                                  disabled={itemVariant.stock_status === 'Sold Out'}
                                />

                                <IconButton
                                  disabled={itemVariant.stock_status === 'Sold Out'}
                                  style={{ padding: 0, width: '25px' }}
                                  onClick={() => {
                                    QuantityForProduct(
                                      itemVariant.id,
                                      +1,
                                      itemVariant.price[membershipLevel],
                                      false
                                    )
                                  }}
                                >
                                  <AddIcon
                                    fontSize='small'
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      fill: 'black'
                                    }}
                                  />
                                </IconButton>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )
                    })}
                  </table>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '8px',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingLeft: '8px',
                      cursor: 'pointer'
                    }}
                    onClick={() => openVariant()}
                  >
                    <div>Pilih Variasi</div>
                    <div>
                      <ChevronRight />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      overflowX: 'auto',
                      padding: '8px',
                      paddingTop: '0px'
                    }}
                  >
                    {variant.map((itemVariant, indexVariant) => (
                      <div
                        key={indexVariant}
                        style={{
                          minWidth: '78px',
                          backgroundColor: '#f5f5f5',
                          padding: '4px',
                          textAlign: 'center',
                          fontSize: '12px',
                          cursor: 'pointer'
                        }}
                        onClick={() => openVariant(itemVariant.id)}
                      >
                        {itemVariant.attributes.map(item => item.value).join(', ')}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : null}

          <div className={`${classes.box} mt-2 ps-1 pe-1 pt-2 pb-2`}>
            {/* catatan untuk penjual */}
            <TextField
              id='textfield-noted-for-sale'
              label='Catatan untuk penjual'
              variant='outlined'
              size='small'
              className={classes.styleTextfieldNotedForSale}
              onChange={e => {
                handleChangeTextFieldNotedForSale(e.target.value)
              }}
            />
          </div>

          {/* </div> */}
          <div className={`${classes.box} d-flex justify-content-around align-items-center mt-1`}>
            {/* <ProductLocation /> */}
          </div>
          <div
            id='specification'
            className={`${classes.box} mt-1 p-1`}
            style={{
              paddingTop: '8px !important',
              paddingLeft: '8px !important'
            }}
          >
            <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='ml-1 mt-2'>
              {t('specification')}
            </div>
            <div className='mt-3'>
              <table className={classes.specificationTable}>
                <tbody>
                  <tr>
                    <td style={{ cursor: 'pointer' }}>{t('minimum_order')}</td>
                    <td style={{ paddingLeft: '0px', cursor: 'pointer' }}>: &nbsp;&nbsp; 1 PCS</td>
                  </tr>
                  {/* {
                      productDetail?
                      productDetail.set_stock?
                      productDetail.set_stock.is_show_grand_stock == 1 ? (
                        <tr>
                          <td style={{ cursor: "pointer" }}>{t("stock")}</td>
                          <td style={{ paddingLeft: "0px", cursor: "pointer" }}>
                            : &nbsp;&nbsp; {productDetail.total_stock} PCS
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                      :null
                      :null
                    } */}
                  {/* {
                      <tr>
                      <td style={{ cursor: "pointer" }}>{t("stock")}</td>
                      <td style={{ paddingLeft: "0px", cursor: "pointer" }}>
                        : &nbsp;&nbsp; {(productDetail && productDetail.product?productDetail.product.stock : 0)} PCS
                      </td>
                    </tr>
                    } */}
                  <tr>
                    <td style={{ cursor: 'pointer' }}>{t('category')}</td>
                    <td
                      style={{
                        color: 'rgba(68, 180, 155, 1)',
                        paddingLeft: '0px'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          flexDirection: 'row'
                        }}
                      >
                        <div>:</div>
                        <div
                          style={{
                            display: ' -webkit-inline-box',
                            overflowY: 'hidden',
                            marginLeft: '16px',
                            cursor: 'pointer'
                          }}
                        >
                          {productDetail ? (
                            productDetail.category ? (
                              <span
                                style={{
                                  fontSize: '15px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => {
                                  directToProductByCategory(productDetail.category)
                                }}
                              >
                                {productDetail.category.name}
                              </span>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ cursor: 'pointer' }}>{t('product_sku')}</td>
                    <td
                      style={{
                        paddingLeft: '0px',
                        cursor: 'pointer',
                        display: 'flex'
                      }}
                    >
                      : &nbsp;&nbsp; <div>{data2.sku}</div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ cursor: 'pointer' }}>{t('weight')}</td>
                    <td style={{ paddingLeft: '0px', cursor: 'pointer' }}>
                      : &nbsp;&nbsp;{data2.weight} Gram
                    </td>
                  </tr>
                  <tr>
                    <td style={{ cursor: 'pointer' }}>{t('rack_position')}</td>
                    <td style={{ paddingLeft: '0px', cursor: 'pointer' }}>
                      : &nbsp;&nbsp;{data2.rack_position || '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            id='description'
            className={`${classes.box} mt-2 pe-1 pb-2`}
            style={{
              paddingLeft: '8px',
              paddingTop: '12px !important'
            }}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <div style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                {t('description_product')}
              </div>
              <svg
                style={{ width: '24px', height: '24px' }}
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                onClick={handleClickCopyProductDescription}
              >
                <path
                  d='M17.5 13.4V16.4C17.5 20.4 15.9 22 11.9 22H8.1C4.1 22 2.5 20.4 2.5 16.4V12.6C2.5 8.6 4.1 7 8.1 7H11.1'
                  stroke={color}
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M17.5001 13.4H14.3001C11.9001 13.4 11.1001 12.6 11.1001 10.2V7L17.5001 13.4Z'
                  stroke={color}
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M12.1001 2H16.1001'
                  stroke={color}
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.5 5C7.5 3.34 8.84 2 10.5 2H13.12'
                  stroke={color}
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.4999 8V14.19C22.4999 15.74 21.2399 17 19.6899 17'
                  stroke={color}
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.5 8H19.5C17.25 8 16.5 7.25 16.5 5V2L22.5 8Z'
                  stroke={color}
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
            <div
              className={classes.desdiv}
              dangerouslySetInnerHTML={{ __html: data2.detail }}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                textAlign: 'left',
                cursor: 'pointer',
                marginBottom: '0px !important'
              }}
            ></div>
          </div>
          <div
            className='mt-2'
            style={{
              paddingLeft: '8px',
              paddingTop: '12px',
              cursor: 'pointer',
              fontWeight: 'bold'
            }}
          >
            {t('related_products')}
          </div>
          <div
            className='container'
            style={{
              paddingRight: '0px',
              paddingLeft: '0px',
              paddingTop: '10px'
            }}
          >
            {relatedProductsData.length > 0 ? (
              <div
                className={classes.cards_style + ' ' + classes.mobile_slider_cards}
                style={{
                  width: '100%',
                  display: 'flex',
                  overflow: 'auto',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  gap: '5px',
                  marginLeft: '0px',
                  marginRight: '0px',
                  marginTop: '0px',
                  paddingLeft: '1px',
                  paddingRight: '0px',
                  boxSizing: 'border-box'
                }}
              >
                {relatedProductsData.map((product, index) => {
                  return (
                    <div
                      style={{
                        width: '49%'
                      }}
                      key={index}
                    >
                      <CardProduct
                        membershipLevel={membershipLevel}
                        product={product.product}
                        handleClick={getSingleProduct}
                      />
                    </div>
                  )
                })}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <b>No Product Found</b>
              </div>
            )}
          </div>
          <br />
          {productDetail &&
          productDetail.product &&
          productDetail.product.stock_status === 'Sold Out' ? (
            <div
              className='d-flex fixed-bottom'
              style={{
                marginBottom: '-14px',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: '57px',
                background: 'white',
                maxWidth: '100wh'
              }}
            >
              <button className={classes.chatBtn}>
                <img
                  src={'/assets/frontend/img/Header Icon/ic_message_top_si.png'}
                  className='me-1'
                  style={{
                    width: '24px',
                    height: '25px',
                    marginBottom: '1px'
                  }}
                  alt='chat'
                />
                {t('chat')}
              </button>
              <button className={classes.buyBtn} style={{ opacity: '0.7' }}>
                <span>{t('buy')}</span>
              </button>
            </div>
          ) : (
            <div
              className='d-flex fixed-bottom'
              style={{
                marginBottom: '-14px',
                height: '57px',
                maxWidth: '100wh',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            >
              <button
                className={classes.chatBtn}
                onClick={() => {
                  if (userid) {
                    history.push('/chat')
                  } else {
                    // if (landing&&landing.landing_page == 0) {
                    //   history.push("/login");
                    // } else {
                    //   history.push("/");
                    // }
                    history.push('/login')
                  }
                }}
              >
                {/* <img
                    src={
                      "/assets/frontend/img/Header Icon/ic_message_top_si.png"
                    }
                    className="me-1"
                    style={{
                      width: "24px",
                      height: "25px",
                      marginBottom: "1px",
                      marginRight: "10px",
                    }}
                    alt="chat"
                  /> */}
                <svg
                  style={{ width: '20px', height: '26px' }}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z'
                    stroke={color}
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M15.9965 11H16.0054'
                    stroke={color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.9955 11H12.0045'
                    stroke={color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M7.99451 11H8.00349'
                    stroke={color}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>

                <span style={{ fontSize: '17px', color: color }}>{t('chat')}</span>
              </button>
              <button
                className={classes.buyBtn}
                disabled={isActiveButtonAddToCart === false ? true : false}
                onClick={addToCart}
              >
                <span>{t('buy')}</span>
              </button>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            marginTop: '125px'
          }}
        >
          No Product Found
        </div>
      )}
      {trueopen ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={trueopen}
          ContentProps={{
            'aria-describedby': 'message-id',
            className: classes.snackbarStyleViaContentProps1
          }}
          message='Item Added To Cart'
          key={vertical + horizontal}
          action={actionSnackbar}
        />
      ) : (
        ''
      )}

      {openSnackbarCopyDescriptionProduct ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSnackbarCopyDescriptionProduct}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbarCopyDescriptionProduct(false)}
          ContentProps={{
            'aria-describedby': 'copy-product-id',
            className: classes.snackbarStyleCopyProduct
          }}
          message='Deskripsi Produk Berhasil Disalin'
          key={vertical + horizontal}
        />
      ) : (
        ''
      )}

      {showPopupStockNotEnought ? (
        <DialogAlert
          open={showPopupStockNotEnought}
          yesno='false'
          ok='true'
          close={closeModalStockNotEnought}
        >
          Stok Tidak Tersedia Untuk Produk Ini
        </DialogAlert>
      ) : (
        ''
      )}
    </div>
  )
}

const videoThumbInner = videoItem => {
  return (
    <video width='65px' height='65px'>
      <source src={videoItem.original} type='video/mp4' />
    </video>
  )
}

const renderVideo = item => {
  return (
    <div>
      {item.original ? (
        <video autoPlay={false} width='100%' height='350px' controls>
          <source src={item.original} />
        </video>
      ) : null}
    </div>
  )
}

export default withNamespaces()(DetailsProduct)
