import { makeStyles } from '@material-ui/core'
const themeColor = JSON.parse(localStorage.getItem('themeColor'))

const useStyles = makeStyles({
  cardStyle: {
    padding: '15px',
    marginBottom: '15px',

    '& header': {
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '40px',
      borderBottom: '1px solid #d6cbcb',
      marginBottom: '15px',

      '& label': {
        fontSize: '16px',
        color: 'black',
        marginBottom: '0px',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif'
      }
    }
  },

  btn_fullwidth: {
    display: 'block',
    backgroundColor: '#ff3a59',
    lineHeight: '40px',
    borderRadius: '4px',
    textAlign: 'center',
    color: '#fff !important',
    width: '100%',
    marginBottom: '10px'
  },

  address: {
    color: '#575757',
    '& .del-detail': {
      marginBottom: '20px'
    }
  },

  dropshipper_style: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',

    '& .switchParent': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center'
    },
    '& .inputs': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center'
    },
    '& .react-switch-bg': {
      // backgroundColor: '#ff3a59 !important'
    }
  },

  radio_style: {
    "& input[type='radio']": {
      accentColor: themeColor,
      height: '18px',
      width: '18px',
      cursor: 'pointer'
    }
  },

  radio_item: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginBottom: '15px',

    '& label': {
      cursor: 'pointer',
      marginBottom: '0'
    }
  },

  description_style: {
    '& .title_de': {
      color: '#000',
      fontSize: '14px'
      // fontWeight: "bold",
    },
    '& .values': {
      marginBottom: '0',
      fontSize: '14px'
    }
  },

  priceContent_style: {
    '& .values': {
      marginBottom: '0'
    },
    '& .discount-rate span': {
      padding: '3px 10px'
    },
    '& .price-wdout': {
      textDecoration: 'line-through'
    }
  },

  tableContent: {
    // borderBottom: "1px solid gray",
    paddingBottom: '15px',
    marginBottom: '20px',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '0px'
    }
  },

  radio_item_bank: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #c8c8c8',
    // justifyContent: "space-between",
    gap: '30px',
    padding: '10px 30px',

    '& img': {
      width: '80px'
    },
    '& .imgLabels': {
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      // width: "40%",

      '& label': {
        display: 'block',
        marginBottom: '2px',
        cursor: 'pointer'
      }
    }
  },

  shippingOption: {
    '& img': {
      width: '80px'
    },
    '& .actual_rate': {
      textDecoration: 'line-through',
      color: 'grey',
      fontSize: '14px',
      userSelect: 'none',
      '& span': {
        textDecoration: 'line-through'
      }
    }
  },

  radio_shipingOption: {
    '& .inputItem:first-child': {
      // borderBottom: "1px solid #c9c9c9",
      padding: '12px 0px'
      // borderTop: "1px solid #c9c9c9",
    }
  },
  borderColorTextfield: {
    '& label.Mui-focused': {
      color: themeColor
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: themeColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: themeColor
      }
    }
  },
  paperDialogAddressList: {
    width: '555px',
    height: '478px'
  },
  paperDialogAddressUpdate: {
    width: '398px',
    height: '654px'
  },
  inputStyleUpdateAddress: {
    '& input': {
      width: '300px'
    },

    '& .label': {
      width: '32.4%'
    },
    '& input:focus': {
      boxShadow: 'none',
      border: '1px solid ' + themeColor
    }
  },
  borderColorTextfieldWithCustom: {
    '& label.Mui-focused': {
      color: themeColor
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: themeColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: themeColor
      }
    },
    '& .MuiInputBase-root': {
      height: '30px'
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0.5'
    }
  }
})

export { useStyles }
