import { Avatar, Grid, Snackbar } from '@material-ui/core'
import React, { useRef, useState } from 'react'
import ButtonCustom from '../../../../components/common/ButtonCustom'
import Input from '../../../../components/common/form/Input'
import { useStyles } from './myProfile.style'
// import { getAddressUser } from "../../../../store/actions/GetAddressActions";
// import { GetAddressResponse } from "../../../../store/Selectors/getaddress";
import MuiAlert from '@material-ui/lab/Alert'
import { useEffect } from 'react'
// import { useDispatch } from "react-redux";
// import { addAddressUser } from "../../../../store/actions/userActions";
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
// import { updateUserData } from "../../../../store/actions/updateUserActions";
// import { UpdateUserResponse } from "../../../../store/Selectors/UpdateUser";
import { t } from 'i18next'
import i18n from '../../../language/localization'
import LoaderDots from '../../../../components/common/LoaderDots'
import { updateProfile, updateProfilePicture } from '../../../../api/authorization'

const MyProfile = props => {
  const { webThemeColor } = props
  const classes = useStyles(props)
  // const dispatch = useDispatch();
  const getData = () => {
    // let data = new FormData();
    // data.append("user_id", userid);
    // dispatch(getAddressUser(data));
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  useEffect(() => {
    getData()
  }, [])
  const AddressData = {}

  const storeData =
    AddressData && AddressData.data ? AddressData.data.filter(ad => ad.as_default == 1) : null

  const [images, setImages] = React.useState([])
  const usersdata = JSON.parse(localStorage.getItem('userdata'))
  const userid = JSON.parse(localStorage.getItem('userid'))
  const [profile, setProfile] = React.useState(usersdata ? usersdata : null)
  const [myData, setmyData] = React.useState()
  const [Image, setImage] = useState('')
  const imageRef = useRef()
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [file, setFile] = useState('')
  const [profileMessage, setProfileMessage] = useState({
    open: false,
    message_type: '',
    message: ''
  })

  const onChangeFile = e => {
    setImage(URL.createObjectURL(e.target.files[0]))
    setFile(e.target.files[0])
  }

  useEffect(() => {
    setmyData(storeData ? storeData[0] : null)
  })

  const onChangeUname = e => {
    setProfile({ ...profile, username: e.target.value })
  }
  const onChnageName = e => {
    setProfile({ ...profile, name: e.target.value })
  }
  const onChnageEmail = e => {
    setProfile({ ...profile, email: e.target.value })
  }
  const onChnagePhone = e => {
    setProfile({ ...profile, phone: e.target.value })
  }

  const UpdateProfile = async () => {
    console.log('profile', profile)
    //update profile data
    // var formdata = new FormData();
    // formdata.append("user_id", userid);

    // formdata.append("email", profile ? profile.email : null);
    // formdata.append("name", profile ? profile.name : null);
    // formdata.append("email", profile ? profile.email : null);
    // formdata.append("phone", profile ? profile.phone : null);
    // formdata.append("username", profile ? profile.username : null);
    // if (Image !== "") {
    //   formdata.append("photo", file);
    // }

    // //update Adddress
    // var formdata1 = new FormData();
    // formdata1.append("address_id", myData ? myData.id : null);
    // formdata1.append("user_id", userid);
    // formdata1.append("name", myData ? myData.name : null);
    // formdata1.append("address", myData ? myData.address : null);
    // formdata1.append("province_id", myData ? myData.province_id : null);
    // formdata1.append("province_name", myData ? myData.province_name : null);
    // formdata1.append("city_id", myData ? myData.city_id : null);
    // formdata1.append("city_name", myData ? myData.city_name : null);
    // formdata1.append("district_id", myData ? myData.district_id : null);
    // formdata1.append("district_name", myData ? myData.district_name : null);
    // formdata1.append("postal_code", myData ? myData.postal_code : null);
    // formdata1.append("as_default", myData ? myData.as_default : null);
    // formdata1.append("phone", profile ? profile.phone : null);

    // dispatch(updateUserData(formdata));
    // dispatch(addAddressUser(formdata1));
    setLoading(true)

    if (Image !== '') {
      let formdata = new FormData()
      formdata.append('file', file)
      const responsePicture = await updateProfilePicture(formdata)
      if (responsePicture.success) {
        console.log('sukses update picture')
      } else {
        console.log('error update picture', responsePicture)
      }
    }

    const data = {
      username: profile.username,
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      // hardcode
      language_id: 1
    }

    const response = await updateProfile(data)
    if (response.success) {
      localStorage.setItem('userdata', JSON.stringify(response.data))
      setLoading(false)
      setProfileMessage({
        open: true,
        message_type: 'success',
        message: response.message
      })
    } else {
      setProfileMessage({
        open: true,
        message_type: 'error',
        message: response.message
      })
    }
  }
  // const dataRes = UpdateUserResponse();
  // if (open) {
  //   setTimeout(() => {
  //     localStorage.setItem(
  //       "userdata",
  //       JSON.stringify(dataRes ? dataRes.data : null)
  //     );
  //   }, 1000);
  // }

  return (
    <div className={classes.body}>
      {console.log('Add_ress', myData ? myData : null)}
      <header>
        {' '}
        <h3 className={classes.title}>{t('my_profile')}</h3>
        <hr />
      </header>
      <Grid container spacing={2} className={classes.content11}>
        <Grid item md={4}>
          <Avatar
            src={
              Image !== ''
                ? Image
                : profile
                  ? process.env.REACT_APP_FILE_BASE_URL + '/' + profile.profile_picture
                  : null
            }
            onClick={() => imageRef.current.click()}
            style={{
              height: '10rem',
              width: '10rem',
              cursor: 'pointer',
              margin: '0px auto'
            }}
            className='mb-4 mt-2'
          />
          <input type='file' ref={imageRef} style={{ display: 'none' }} onChange={onChangeFile} />
          <ButtonCustom
            bg={webThemeColor}
            className={classes.btn_buy}
            style={{ width: 70, margin: '0px auto' }}
            onClick={() => imageRef.current.click()}
          >
            <CloudUploadIcon />
          </ButtonCustom>
        </Grid>
        <Grid item md={8} className={classes.pr15}>
          <Input
            type='text'
            leftLabel={t('username')}
            value={profile ? profile.username : null}
            className={classes.input_style}
            onChange={onChangeUname}
            style={{ width: '100%' }}
          />
          <Input
            type='text'
            leftLabel={t('name')}
            value={profile ? profile.name : null}
            className={classes.input_style}
            onChange={onChnageName}
            style={{ width: '100%' }}
          />
          <Input
            type='text'
            leftLabel={t('phone_number')}
            value={profile ? profile.phone : null}
            className={classes.input_style}
            onChange={onChnagePhone}
            style={{ width: '100%' }}
          />
          <Input
            type='text'
            leftLabel={t('email')}
            value={profile ? profile.email : null}
            className={classes.input_style}
            onChange={onChnageEmail}
            style={{ width: '100%' }}
          />
          <ButtonCustom
            bg={webThemeColor}
            className={classes.btn_buy}
            style={{ float: 'right' }}
            onClick={UpdateProfile}
            disabled={isLoading ? true : false}
          >
            {t('update')}{' '}
            {
              isLoading ? <LoaderDots /> : null
              // isLoading?
              // <ClipLoader size={15} color="white"/>:`${t('update')}`
            }
          </ButtonCustom>
        </Grid>
      </Grid>

      {profileMessage.open ? (
        <Snackbar
          open={profileMessage.open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() =>
            setProfileMessage({
              open: false,
              message_type: '',
              message: ''
            })
          }
          autoHideDuration={2000}
        >
          <MuiAlert severity={profileMessage.message_type}>{profileMessage.message}</MuiAlert>
        </Snackbar>
      ) : null}
    </div>
  )
}

export default MyProfile
