import React, { useEffect, useState } from 'react'
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Header from '../header/header'
import i18n from '../language/localization'
import { withNamespaces } from 'react-i18next'
import {
  forgotPasswordResetPassword,
  forgotPasswordSendOtp,
  forgotPasswordVerifcationOtp
} from 'api/forgot-password'
import BasicModal from 'components/modal/BasicModal'
import { useHistory } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import OtpInput from 'react-otp-input'

const countDownOtpInterval = 30

const ForgotPassword = ({ t }) => {
  const history = useHistory()

  const [errorMessages, setErrorMessages] = useState(undefined)
  const [successReset, setSuccessReset] = useState(undefined)

  const [stepType, setStepType] = useState('sendOtp')
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: 'white',
      height: '100vh'
    },
    passInput: {
      '& .MuiButtonBase-root': {
        padding: '0'
      },
      '& .MuiSvgIcon-root': {
        margin: '0 !important'
      }
    }
  }))
  const classes = useStyles()

  const [countDownOtp, setCountDownOtp] = useState(0)

  const [email, setEmail] = useState('')
  const [otp, setOtp] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const submitSendOtp = async () => {
    const data = {
      email: email
    }

    forgotPasswordSendOtp(data)
      .then(() => {
        setStepType('verifyOtp')
        setTimeout(() => {
          setCountDownOtp(countDownOtpInterval)
        }, 200)
      })
      .catch(error => {
        setErrorMessages(error?.response?.data?.message || error.message)
      })
  }

  const submitVerifyOtp = async () => {
    const data = {
      email: email,
      otp: otp
    }

    forgotPasswordVerifcationOtp(data)
      .then(response => {
        setStepType('reset')
        setCode(response.data.data.code)
      })
      .catch(error => {
        setErrorMessages(error?.response?.data?.message || error.message)
      })
  }

  const submitReset = async () => {
    const data = {
      email: email,
      code: code,
      new_password: password,
      new_password_confirmation: confirmPassword
    }

    forgotPasswordResetPassword(data)
      .then(response => {
        setSuccessReset(response.data.message)
      })
      .catch(error => {
        setErrorMessages(error?.response?.data?.message || error.message)
      })
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  useEffect(() => {
    if (countDownOtp > 0 && stepType === 'verifyOtp') {
      const interval = setInterval(() => {
        setCountDownOtp(countDownOtp - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [countDownOtp])

  return (
    <ThemeProvider theme={theme}>
      {stepType === 'sendOtp' ? (
        <div className={classes.root}>
          <Header
            title={t('forgot_password')}
            color={ThemeColor}
            showCartButton={false}
            showMessageButton={false}
          />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '46px' }}
          >
            <ValidatorForm
              className='w-100'
              onSubmit={submitSendOtp}
              onError={errors => console.log(errors)}
            >
              <Grid item xs={12} style={{ margin: '15px 15px' }}>
                <TextValidator
                  label={t('enter_email')}
                  fullWidth={true}
                  onChange={e => setEmail(e.target.value)}
                  name='email'
                  value={email}
                  variant='standard'
                  validators={['required']}
                  errorMessages={['this field is required', 'email is not valid']}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                />
              </Grid>
              <div className='finish'>
                <button
                  className='finish-btn mt-3 text-uppercase'
                  style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF' }}
                >
                  {t('continue')}
                </button>
              </div>
            </ValidatorForm>
          </div>
        </div>
      ) : stepType === 'verifyOtp' ? (
        <div className={classes.root}>
          <Header
            title={t('verify_otp')}
            color={ThemeColor}
            onClickBack={() => setStepType('sendOtp')}
            showCartButton={false}
            showMessageButton={false}
          />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '80px' }}
          >
            <ValidatorForm
              className='w-100'
              onSubmit={submitVerifyOtp}
              onError={errors => console.log(errors)}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center'
                }}
              >
                <h5>{t('title_otp')}</h5>
                <p>
                  {t('subtitle_otp')} {email}
                </p>

                <div
                  style={{
                    marginTop: '48px',
                    marginBottom: '48px'
                  }}
                >
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={props => <input {...props} />}
                    inputStyle={{
                      width: '32px',
                      height: '32px',
                      fontSize: '20px',
                      margin: '0 6px',
                      borderRadius: 'none',
                      border: 'none',
                      borderBottom: '1px solid #000000'
                    }}
                  />
                </div>

                <div>
                  <button
                    type='submit'
                    className='finish-btn mt-3 text-uppercase btn'
                    style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF' }}
                  >
                    {t('verify')}
                  </button>
                  <button
                    type='button'
                    className='finish-btn mt-3 text-uppercase btn'
                    style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF' }}
                    disabled={countDownOtp > 0}
                    onClick={() => {
                      setCountDownOtp(countDownOtpInterval)
                      submitSendOtp()
                    }}
                  >
                    {t('resend')} {countDownOtp > 0 ? `(${countDownOtp})` : ''}
                  </button>
                </div>
              </div>
            </ValidatorForm>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <Header
            title={t('enter_new_passwrod')}
            color={ThemeColor}
            back={false}
            showCartButton={false}
            showMessageButton={false}
          />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '46px' }}
          >
            <ValidatorForm
              className='w-100'
              onSubmit={submitReset}
              onError={errors => console.log(errors)}
            >
              <Grid item xs={12} style={{ margin: '15px 15px' }}>
                <TextValidator
                  className={classes.passInput}
                  label={t('new_password')}
                  fullWidth={true}
                  onChange={e => setPassword(e.target.value)}
                  variant='standard'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  validators={['required']}
                  errorMessages={['Please enter password']}
                  value={password}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start' classes={{ positionStart: '0px' }}>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            color: ThemeColor ? ThemeColor : '#FFFFFF'
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ margin: '15px 15px' }}>
                <TextValidator
                  className={classes.passInput}
                  label={t('retype_new_password')}
                  fullWidth={true}
                  onChange={e => setConfirmPassword(e.target.value)}
                  variant='standard'
                  name='confirmPassword'
                  type={showPassword ? 'text' : 'password'}
                  validators={['required']}
                  errorMessages={['Please enter password']}
                  value={confirmPassword}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start' classes={{ positionStart: '0px' }}>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            color: ThemeColor ? ThemeColor : '#FFFFFF'
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <div className='finish'>
                <button
                  className='finish-btn mt-3 text-uppercase'
                  style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF' }}
                >
                  {t('continue')}
                </button>
              </div>
            </ValidatorForm>
          </div>
        </div>
      )}
      {errorMessages ? (
        <BasicModal
          isOpen={true}
          title='Error Forgot Password'
          content={errorMessages}
          onClose={() => setErrorMessages(undefined)}
        />
      ) : null}

      {successReset ? (
        <BasicModal
          isOpen={true}
          title='Success Reset Password'
          content={successReset}
          onClose={() => {
            setSuccessReset(undefined)

            history.push('/login')
          }}
        />
      ) : null}
    </ThemeProvider>
  )
}
export default withNamespaces()(ForgotPassword)
