import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import BannerWeb from './BannerWeb'
import ScrollableTabsButtonAuto from './Tab'
import { makeStyles } from '@material-ui/core'
import Searchbar from './Searchbar'
import ProductSlider from './Slider'
import ProductSliderWeb from './SliderWeb'
import ShortcutMenu from './Shortcutmenu'
import MenuCategories from './MenuCategories'
import ShortcutMenuWeb from './ShortcutmenuWeb'
import MenuCategoriesWeb from './MenuCategoriesWeb'
import BottNavigation from './bottomnav/NavigationTab'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DeskTopFooter from './DesktopFooter'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import { useHistory } from 'react-router'
// import Loader from "../loader";

// async action redux
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchAllProducts,
  fetchNewestProducts,
  fetchDiscountProducts,
  fetchBestSellerProducts,
  fetchRandomProducts
} from '../../stores/features/products'
import { useStore } from '../../hooks/useStore'
import { Container } from '@mui/material'
import MaintenanceModal from 'components/modal/MaintenanceModal'

const useStyles = makeStyles(theme => ({
  Root: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      border: 'none',
      width: '100%',
      maxWidth: '100wh'
      // margin: "0px auto",
    }
  }
}))

function Home() {
  const { landingPage: landingPageData, themeColor, isMaintenance, maintenanceText } = useStore()

  const dispatch = useDispatch()
  const { allProducts, newestProducts, discountProducts, bestSellerProducts, randomProducts } =
    useSelector(state => state)
  const auth = useSelector(state => state.auth)

  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [banner, setBanner] = useState([])
  const classes = useStyles()
  const Usercode = JSON.parse(localStorage.getItem('usercode'))
  const indexTab = localStorage.getItem('tabval')
  const history = useHistory()

  useEffect(() => {
    dispatch(fetchAllProducts())
    dispatch(fetchNewestProducts())
    dispatch(fetchDiscountProducts())
    dispatch(fetchBestSellerProducts())
  }, [])

  useEffect(() => {
    if (landingPageData) {
      dispatch(fetchRandomProducts(landingPageData.product_categories.slice(5)))
    }
  }, [landingPageData])

  useEffect(() => {
    localStorage.setItem('desktopSearch', null)
    localStorage.setItem('searchname', '')
  }, [Usercode])

  useEffect(() => {
    i18n.changeLanguage('id')
    window.scroll({ top: 0, left: 0, behavior: 'instant' })
  }, [])

  const settingCustomer = landingPageData?.setting_customer

  useEffect(() => {
    if (
      Mobile &&
      landingPageData &&
      settingCustomer?.is_customer_required_login === true &&
      !auth.token
    ) {
      // history.push('/login')
    }

    if (landingPageData && landingPageData?.setting) {
      const bannerImages = (landingPageData?.vendor_banner || [])
        .filter(banner => banner.banner_master.status)
        .map(banner => banner.banner_images)
        .flat()

      setBanner(bannerImages)

      localStorage.setItem('tabs', JSON.stringify(landingPageData?.product_categories))
    }
  }, [Mobile, history, landingPageData, settingCustomer])

  const settingCms = landingPageData?.setting_cms

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         textAlign: "center",
  //         paddingTop: "250px",
  //         paddingBottom: "300px",
  //         flexDirection: "column",
  //         backgroundColor: "white",
  //         height: "100vh",
  //       }}
  //     >
  //       <div>
  //         <Loader />
  //       </div>
  //       <div>{t("loadingdot")}</div>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <div className={classes.Root}>
        <MaintenanceModal isOpen={isMaintenance} title='Maintenance' content={maintenanceText} />
        <React.Fragment>
          {Mobile ? (
            <React.Fragment>
              {Mobile ? <Searchbar color={themeColor} /> : null}
              {landingPageData && settingCms && settingCms.is_show_category_in_header === true ? (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: '1',
                    width: '100vw',
                    marginTop: '45px'
                  }}
                >
                  <ScrollableTabsButtonAuto
                    color={themeColor}
                    activeTab={indexTab}
                    categoriesData={landingPageData?.product_categories}
                  />
                </div>
              ) : (
                ''
              )}
            </React.Fragment>
          ) : (
            <DesktopHeaderWrap
              clickTab={() => {}}
              wishlistCount={0}
              isFixed={false}
              showCategory={settingCms && settingCms.is_show_category_in_header}
              categoriesData={landingPageData?.product_categories}
              landingPage={landingPageData}
            />
          )}

          <div style={{ background: Mobile ? 'white' : '#f5f5f5' }}>
            {Mobile ? (
              <div>
                {landingPageData &&
                  settingCms &&
                  (settingCms.is_show_category_in_header === true ||
                    settingCms.is_show_banners === true) && (
                    <div
                      style={{
                        paddingTop:
                          (settingCms.is_show_category_in_header === true && 72) +
                          (settingCms.is_show_banners === true &&
                            banner.length > 0 &&
                            settingCms.is_show_category_in_header === false &&
                            28) +
                          'px'
                      }}
                    ></div>
                  )}
                {landingPageData &&
                settingCms &&
                settingCms.is_show_banners === true &&
                banner.length > 0 ? (
                  <Banner bannerData={banner} />
                ) : (
                  ''
                )}

                {landingPageData &&
                settingCms &&
                settingCms.is_show_shorcuts === true &&
                landingPageData?.vendor_shortcuts.length > 0 ? (
                  <ShortcutMenu shortcutsData={landingPageData?.vendor_shortcuts} />
                ) : (
                  ''
                )}

                {landingPageData && settingCms && settingCms.is_show_category_in_body === true ? (
                  <MenuCategories categoriesData={landingPageData?.product_categories} />
                ) : (
                  ''
                )}
                <ProductSlider
                  color={themeColor}
                  productsAllData={allProducts.allProducts || []}
                  productsNewestData={newestProducts.newestProducts || []}
                  productsDiscountData={discountProducts.discountProducts || []}
                  productsBestSellerData={bestSellerProducts.bestSellerProducts || []}
                  productsByCategoriesData={randomProducts.randomProducts || []}
                />

                <BottNavigation />
              </div>
            ) : (
              <Container>
                <div style={{ width: '100%', margin: '0px auto', zIndex: -1 }}>
                  {settingCms && settingCms.is_show_banners === true && banner.length > 0 ? (
                    <BannerWeb bannerData={banner} />
                  ) : (
                    ''
                  )}

                  {landingPageData &&
                  settingCms &&
                  settingCms.is_show_shorcuts === true &&
                  landingPageData?.vendor_shortcuts.length > 0 ? (
                    <ShortcutMenuWeb shortcutsData={landingPageData?.vendor_shortcuts} />
                  ) : (
                    ''
                  )}

                  {landingPageData && settingCms && settingCms.is_show_category_in_body === true ? (
                    <MenuCategoriesWeb categoriesData={landingPageData?.product_categories} />
                  ) : (
                    ''
                  )}
                  <ProductSliderWeb color={themeColor} />
                </div>
              </Container>
            )}
          </div>
          {Mobile ? null : <DeskTopFooter />}
        </React.Fragment>
      </div>
    </React.Fragment>
  )
}

export default withNamespaces()(Home)
