import React, { useEffect, useState } from 'react'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import DesktopFooter from '../home/DesktopFooter'
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'

const PrivacyPolictPage = () => {
  const { token } = useAuth()

  const [cartitems, setItems] = useState(0)
  const [categoriesData, setCategoriesData] = useState([])

  const mycart = async () => {
    // let data = new FormData();
    // data.append("user_code", Usercode1);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));

    // fetch(`${baseUrlApi()}/Api/Order/myCart`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setItems(res.data.length);
    //   });
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }

  useEffect(() => {
    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }

    mycart()
  }, [])

  return (
    <>
      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={'rgb(231, 0, 116)'}
        cartNumber={cartitems}
        isFixed={false}
        categoriesData={categoriesData}
      />{' '}
      <div
        style={{
          paddingTop: '76px',
          paddingBottom: '38px',
          width: ' 1200px',
          margin: '0px auto'
        }}
      >
        <div className='main-container'>
          <h1 className='slug-title'>Kebijakan Privasi Data</h1>
          <div>
            <p>
              App Seller Studio build this app as a free app. This service is provided by App Seller
              Studio at no cost and is intended for use as is. This page is used to inform visitors
              regarding our policies with the collection, use, and disclosure of personal
              information if anyone decided to use our service. If you choose to use our service,
              then you agree to the collection and use of information in relation to this policy.
              The personal Information that we collect is used for providing and improving the
              service. We will not use or share your information with anyone except as described in
              this privacy policy. The terms used in this privacy policy have the same meanings as
              in our terms and conditions, which is accessible at this unless otherwise defined in
              this privacy policy.
            </p>
            <p>
              <strong>Information Collection and Use</strong>
            </p>
            <p>
              For a better experience, while using our service, we may require you to provide us
              with certain personally identifiable information. The information that we request will
              be retained by us and used as described in this privacy policy. The app does use third
              party services that may collect information used to identify you. Link to privacy
              policy of third party service providers used by the app
            </p>
            <p>
              <strong>Log Data</strong>
            </p>
            <p>
              We want to inform you that whenever you use our service, in a case of an error in the
              app we collect data and information (through third party products) on your phone
              called log data. This log data may include information such as your device internet
              protocol (“IP”) address, device name, operating system version, the configuration of
              the app when utilizing our service, the time and date of your use of the service, and
              other statistics.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Cookies are files with a small amount of data that are commonly used as anonymous
              unique identifiers. These are sent to your browser from the websites that you visit
              and are stored on your {`device's`} internal memory.
              <br />
              <br />
              This service does not use these “cookies” explicitly. However, the app may use third
              party code and libraries that use “cookies” to collect information and improve their
              services. You have the option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our cookies, you may not
              be able to use some portions of this service.
            </p>
            <p>
              <strong>Service Providers</strong>
            </p>
            <p>I may employ third-party companies and individuals due to the following reasons:</p>
            <ul>
              <li>To facilitate our service;</li>
              <li>To provide the service on our behalf;</li>
              <li>To perform service-related services; or</li>
              <li>To assist us in analyzing how our service is used.</li>
            </ul>
            <p>
              We want to inform users of this service that these third parties have access to your
              personal information. The reason is to perform the tasks assigned to them on our
              behalf. However, they are obligated not to disclose or use the information for any
              other purpose.
            </p>
            <p>
              <strong>Security</strong>
            </p>
            <p>
              We value your trust in providing us with your personal information, thus we are
              striving to use commercially acceptable means of protecting it. But remember that no
              method of transmission over the Internet, or method of electronic storage is 100%
              secure and reliable, and we cannot guarantee its absolute security.
            </p>
            <p>
              <strong>Links to Other Sites</strong>
            </p>
            <p>
              This service may contain links to other sites. If you click on a third-party link, you
              will be directed to that site. Note that these external sites are not operated by us.
              Therefore, we strongly advise you to review the privacy policy of these websites. We
              have no control over and assume no responsibility for the content, privacy policies,
              or practices of any third-party sites or services.
            </p>
            <p>
              <strong>Children’s Privacy</strong>
            </p>
            <p>
              These Services do not address anyone under the age of 13. We do not knowingly collect
              personally identifiable information from children under 13. In the case we discover
              that a child under 13 has provided us with personal information, we immediately delete
              this from our servers. If you are a parent or guardian and you are aware that your
              child has provided us with personal information, please contact us so that we will be
              able to do necessary actions.
            </p>
            <p>
              <strong>Changes to This Privacy Policy</strong>
            </p>
            <p>
              We may update our privacy policy from time to time. Thus, you are advised to review
              this page periodically for any changes. We will notify you of any changes by posting
              the new privacy policy on this page. These changes are effective immediately after
              they are posted on this page.
            </p>
            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              If you have any questions or suggestions about our Privacy Policy, do not hesitate to
              contact us at
              <a href='mailto:appsellerstudio@gmail.com'>appsellerstudio@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
      <DesktopFooter themeColor={'rgb(231, 0, 116)'} />
    </>
  )
}

export default PrivacyPolictPage
