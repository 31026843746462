import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { ThemeProvider } from '@material-ui/core/styles'
import { styled } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Specification from './Specification'
import Description from './Description'
import DetailsProduct from './DetailProduct'
import { withNamespaces } from 'react-i18next'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useStore } from '../../hooks/useStore'
import { getDetail } from '../../api/products'
import { useQuery } from 'hooks/useQuery'

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #fff',
  width: '100%',
  '& .MuiTabs-indicator': {
    backgroundColor: '#fff'
  }
})

const AntTab = styled(d => <Tab disableRipple {...d} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },

  '&.Mui-selected': {
    color: '#fff !important',
    textTransform: 'uppercase !important',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#fff'
  }
}))

function DetailTab({ t }) {
  const history = useHistory()
  const searchParams = useQuery()
  const id = searchParams.get('id')

  if (id == undefined || id == '') {
    history.goBack()
  }

  const [productDetail, setProductDetail] = useState(null)

  const getProductDetail = async () => {
    try {
      const response = await getDetail(id)
      if (response.success) {
        setProductDetail(response.data)
      }
    } catch (error) {
      console.log('Error fetching getDetailData:', error)
    }
  }

  const { themeColor, theme } = useStore()

  const [value, setValue] = React.useState(0)

  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      localStorage.setItem('detailtab', JSON.stringify('Detail'))
    } else if (newValue === 1) {
      localStorage.setItem('detailtab', JSON.stringify('Specification'))
    } else {
      localStorage.setItem('detailtab', JSON.stringify('Description'))
    }
    setValue(newValue)
  }

  const productR = () => {
    localStorage.setItem('open', JSON.stringify(false))
    history.goBack()
  }

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 120) {
      setVisible(true)
    } else if (scrolled <= 120) {
      setVisible(false)
    }
  }

  const [isVisible, setIsVisible] = useState(true)
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 100
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  window.addEventListener('scroll', toggleVisible)

  useEffect(() => {
    getProductDetail()
  }, [id])

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      {(visible || value != 0) && (
        <ThemeProvider theme={theme}>
          <Box
            className='text-white'
            sx={{
              maxWidth: Mobile ? 500 : '100%',
              display: 'flex',
              position: 'fixed',
              zIndex: '1111',
              width: '100%'
            }}
            bgcolor='primary.main'
          >
            <div
              className='d-flex'
              style={{
                width: '50px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={() => {
                productR()
                // history.goBack();
              }}
            >
              <KeyboardBackspaceIcon size='30' />
            </div>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label='ant example'
              variant='scrollable'
              scrollButtons='off'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '0px'
              }}
            >
              <AntTab label={t('detail')} className='text-white text-uppercase' {...a11yProps(0)} />
              <AntTab
                label={t('specification')}
                className='text-white text-uppercase'
                {...a11yProps(1)}
              />
              <AntTab
                label={t('description')}
                className='text-white text-uppercase'
                {...a11yProps(2)}
              />
            </AntTabs>
          </Box>
        </ThemeProvider>
      )}
      {value == 0 ? (
        <DetailsProduct
          color={themeColor}
          visible={visible}
          product={productDetail}
          refreshProductData={getProductDetail}
        />
      ) : value == 1 ? (
        <Specification product={productDetail} />
      ) : value == 2 ? (
        <Description product={productDetail} />
      ) : null}
    </React.Fragment>
  )
}
export default withNamespaces()(DetailTab)
