import React, { useState, useEffect } from 'react'
import { createTheme } from '@material-ui/core/styles'
// import { GetProductDataResponse } from "../../../store/Selectors/getproductdata";
// import { getProductData } from "../../../store/actions/GetProductdataActions";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
// import { getCategoryDataProduct } from "../../../store/actions/getCategoryActions";
// import { GetCategoryResponse } from "../../../store/Selectors/getCategory";
import { ThemeColor } from '../../themecolor'
import Loader from '../../loader'
import { withNamespaces } from 'react-i18next'
import i18n from '../../language/localization'
import 'react-web-tabs/dist/react-web-tabs.css'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import { addWishlistProductUser } from "../../../store/actions/addWishlistActions";
import { useStyles1 } from '../../themecolor/FontSize'
import { getByClient as getProductsDetail } from '../../../api/products'
import CardProduct from '../../../components/card-product/CardProduct'

const ProductView = ({ t, membershipLevel, sortBy }) => {
  const classes1 = useStyles1()
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF',
        color: '#000'
      }
    }
  })

  const history = useHistory()
  // const dispatch = useDispatch();
  const viewid =
    typeof localStorage.getItem('viewproid') === 'string'
      ? localStorage.getItem('viewproid')
      : JSON.parse(localStorage.getItem('viewproid'))
  let [responseData, setResponseData] = React.useState([])
  let [isNext, isNextFunc] = React.useState(false)
  let [pageCount, setCount] = React.useState(1)
  let [page, setPage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  let [status, setStatus] = React.useState(false)
  let [open, setOpen] = React.useState(false)

  const search = localStorage.getItem('searchname') || ''

  const loadMoreData = () => {
    setTimeout(async () => {
      const nextPage = pageCount + 1
      setCount(pageCount + 1)
      try {
        const response = await getProductsDetail(
          `{"page":${nextPage}, "limit": 10
              ${search ? `, "query": ${search}` : ''}
              ${viewid ? `,"category_id":${viewid}` : ''}
            }`
        )

        if (response.data) {
          setResponseData([...responseData, ...response.data])
          isNextFunc(true)
          setPage(response.meta.page)
        }
      } catch (error) {
        console.log('Error fetching load more product detail:', error)
      }
    }, 700)
  }

  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  React.useEffect(() => {
    // let isMounted = true;
    // const userID = JSON.parse(localStorage.getItem("userid"));
    // if (pageCount !== 1) {
    //   isNextFunc(true);
    // }
    // const usercode = JSON.parse(localStorage.getItem("usercode"));
    // var data3 = new FormData();
    // data3.append("page", pageCount);
    // const viewproid =
    //   typeof localStorage.getItem("viewproid") === "string"
    //     ? localStorage.getItem("viewproid")
    //     : JSON.parse(localStorage.getItem("viewproid"));
    // const searchname = JSON.parse(localStorage.getItem("searchname"));
    // if (viewproid) {
    //   data3.append("category", viewid);
    // }
    // if (searchname) {
    //   data3.append("search", searchname);
    // }
    // setOpen(true);

    // setOpen(false);
    const getProductsDetailData = async () => {
      setLoading(true)
      try {
        const response = await getProductsDetail(
          `{"page":${pageCount}, "limit": 10
            ${search ? `, "query": ${search}` : ''}
            ${viewid ? `,"category_id":${viewid}` : ''}
          }`
        )

        if (response.data) {
          setResponseData([...responseData, ...response.data])
          isNextFunc(true)
          setPage(response.meta.page)
        }
      } catch (error) {
        console.log('Error fetching getProductsDetail:', error)
      } finally {
        setLoading(false)
      }
    }

    getProductsDetailData()

    // return () => {
    //   isMounted = false;
    // };
  }, [])

  useEffect(() => {
    // getSubCategory();
    let isMounted = true
    localStorage.removeItem('procatid')
    // setTimeout(()=>(),300)
    if (viewid !== '') {
      // get sub kategori PR
      // getSubCategory();
    }
    // setStatus(true);
  }, [])

  useEffect(() => {
    let isMounted = true
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    return () => {
      isMounted = false
    }
  }, [])

  // const dataProduct = GetProductDataResponse();
  // if (open === true) {
  //   setResponseData([...responseData, ...dataproduct.product.data]);
  //   isNextFunc(true);
  //   setPage(dataproduct.product.total_page);
  //   setOpen(false);
  // }

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem('usercode'))
    let data1 = new FormData()
    data1.append('product_id', e)
    data1.append('user_code', usercode)
    data1.append('date_time', '2018-12-25 10:23:00')
    // dispatch(addWishlistProductUser(data1));
    // add wishlist product PR
    let tmpArr = Object.assign([], responseData)
    let tmpObj = Object.assign({}, tmpArr[j])
    tmpObj['is_wishlist'] = '1'
    tmpArr[j] = tmpObj
    setResponseData(tmpArr)
  }

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem('usercode'))
    let data1 = new FormData()
    data1.append('product_id', e)
    data1.append('user_code', usercode)
    data1.append('date_time', '2018-12-25 10:23:00')
    // dispatch(addWishlistProductUser(data1));
    // add wishlist product PR
    let tmpArr = Object.assign([], responseData)
    let tmpObj = Object.assign({}, tmpArr[j])
    tmpObj['is_wishlist'] = '0'
    tmpArr[j] = tmpObj
    setResponseData(tmpArr)
  }

  const getSingleProduct = e => {
    history.push(`/productdetail?id=${e}`)
  }

  const getSubCategory = () => {
    let data2 = new FormData()
    data2.append('parent_id', viewid)
    // dispatch(getCategoryDataProduct(data2));
  }

  const [field1, setField1] = useState(false)
  const [field2, setField2] = useState(false)
  const [field3, setField3] = useState(false)
  const [field4, setField4] = useState(false)

  const [inc, setInc] = useState(0)

  const viewprochild = JSON.parse(localStorage.getItem('viewprochild'))
  const categoryData = {}

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: '250px',
          paddingBottom: '300px',
          flexDirection: 'column',
          backgroundColor: 'white',
          height: '100vh'
        }}
      >
        <div>
          <Loader />
        </div>
        <div>{t('loadingdot')}</div>
      </div>
    )
  }

  return (
    <div
      className='container bg-white'
      style={{
        paddingLeft: '0px',
        paddingRight: '0px',
        boxShadow: 'none',
        marginTop: '4px'
      }}
    >
      <React.Fragment>
        {responseData.length > 0 ? (
          <InfiniteScroll
            style={{
              backgroundColor: '#f5f5f5',
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              marginLeft: '0px',
              marginRight: '0px',
              marginTop: '0px',
              paddingLeft: '0px',
              paddingRight: '5px',
              paddingBottom: Mobile ? '0px' : '50px',
              marginBottom: responseData.length >= 6 ? '60px' : '0'
            }}
            dataLength={responseData.length}
            next={loadMoreData}
            hasMore={isNext}
            loader={
              <div style={{ height: '50%', overflow: 'hidden' }}>
                {pageCount < page ? (
                  <div
                    style={{
                      color: ThemeColor,
                      textAlign: 'center',
                      marginLeft: '160px'
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  ''
                )}
              </div>
            }
          >
            {responseData.map((product, index) => (
              <div
                style={{
                  width: Mobile ? '50%' : '28%',
                  paddingLeft: '5px',
                  marginBottom: '6px'
                }}
                key={index}
              >
                <CardProduct
                  membershipLevel={membershipLevel}
                  product={product.product}
                  handleClick={getSingleProduct}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              paddingTop: '250px',
              paddingBottom: '300px',
              flexDirection: 'column',
              backgroundColor: 'white',
              height: '100vh'
            }}
          >
            <div>{t('empty_product')}</div>
          </div>
        )}
      </React.Fragment>
    </div>
  )
}

export default withNamespaces()(ProductView)
