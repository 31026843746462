import React, { useEffect, useState } from 'react'
import NavigationTab from '../../home/bottomnav/NavigationTab'
import { useHistory } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import i18n from '../../language/localization'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createTheme } from '@material-ui/core/styles'
import Loader from '../../loader'
import Searchbar from '../../home/Searchbar'
import DesktopHeaderWrap from '../../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../../../containers/home/DesktopFooter'
import { makeStyles, Card, Grid, CardMedia, CardContent, CardHeader } from '@material-ui/core'
// import { get as getLandingPage } from '../../../api/landing-page';
import { getBadgeCart } from '../../../api/badge'
import { useAuth } from '../../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  Root: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      border: 'none',
      width: '100%',
      maxWidth: '100wh',
      margin: '0px auto'
    }
  },
  labelTitle: {
    textAlign: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    fontSize: '20px',
    width: '1200px'
  },
  cardCategory: {
    '&  :hover': {
      cursor: 'pointer'
    }
  }
}))

const CategoriesSection = ({ t }) => {
  const { token } = useAuth()

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  console.log('ThemeColor', ThemeColor)
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })
  // const categorydata = JSON.parse(localStorage.getItem("tabs"));
  const history = useHistory()
  const go = (e, j, k) => {
    localStorage.setItem('viewproid', JSON.stringify(e))
    localStorage.setItem('viewproname', JSON.stringify(j))
    localStorage.setItem('viewprochild', JSON.stringify(k))
    history.push('/products')
  }
  // const [true1, setTrue1] = useState(true);

  const [TotalCartItems, setItems] = useState(0)
  const [TotalWishlistItems, setWishlistItems] = useState(0)
  const [categoriesData, setCategoriesData] = useState([])
  let data = new FormData()
  data.append('user_id', JSON.parse(localStorage.getItem('userid')))
  const userCode = JSON.parse(localStorage.getItem('userCode'))

  const mycart = async () => {
    // let data = new FormData();
    // data.append("user_code", userCode);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));
    // count cart
    // setItems(res.data.length)
    // count wishlist
    // setWishlistItems(res.data.length)

    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    // setTimeout(() => {
    //   setTrue1(true);
    // }, 700);

    // mycart()

    if (token) {
      const getBadgeCartData = async () => {
        try {
          const response = await getBadgeCart()
          if (response.success) {
            setItems(response.data)
          }
        } catch (error) {
          console.log('Error fetching getBadgeCartData:', error)
        }
      }
      getBadgeCartData()
    }
  }, [])

  useEffect(() => {
    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }
  }, [])

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 100
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()

  const handleClickCardCategory = (categoryID, categoryName, haveChild) => {
    localStorage.setItem('viewproid', categoryID)
    localStorage.setItem('viewproname', categoryName)
    localStorage.setItem('viewprochild', JSON.stringify(haveChild))
    // localStorage.setItem("viewtabs", JSON.stringify("zero"));
    history.push('/products')
    // localStorage.setItem("CatId", e);
    // history.push("/product-by-category");
  }

  return (
    <div>
      <div className={classes.Root}>
        <div>
          {Mobile ? (
            <div>
              <Searchbar color={ThemeColor} title={t('category')} cartCount={TotalCartItems} />
              {categoriesData ? (
                <div>
                  {/* <Header title={t("category")} color={ThemeColor} /> */}
                  <div
                    style={{
                      paddingTop: '50px',
                      marginTop: '0px',
                      boxShadow: 'none',
                      paddingLeft: '8px',
                      paddingRight: '8px'
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: '10px'
                      }}
                    >
                      {/* width 116px height 148px */}
                      {categoriesData.map((item, index) => (
                        // <div className="d-flex gap-1" key={index}>
                        <div
                          key={index}
                          style={{
                            width: '33.3%',
                            height: '148px',
                            padding: '2.5px'
                          }}
                        >
                          <div
                            className='card border-0 rounded d-flex align-items-center category'
                            style={{
                              width: '100%',
                              height: '100%',
                              boxShadow: '0px 2px 8px 0px #6B6B6B0D',
                              justifyContent: 'center'
                            }}
                          >
                            <div
                              className='rounded-circle'
                              style={{
                                backgroundColor: 'transparent !important'
                              }}
                              onClick={() => {
                                go(item.id, item.name, null)
                              }}
                            >
                              <img
                                src={
                                  item.image
                                    ? process.env.REACT_APP_FILE_BASE_URL + '/' + item.image
                                    : '/assets/images/dummy.png'
                                }
                                className='p-2 m-auto rounded-circle'
                                loading='lazy'
                                alt='heart-favorite'
                                role='button'
                              />
                            </div>
                            <div className='rounded-bottom'>
                              <h6
                                className='text-center m-auto mt-2 mb-2'
                                style={{ fontSize: '0.7rem', cursor: 'pointer' }}
                              >
                                {item.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <br />
                    <br />
                    <div
                      className='mt-5'
                      style={{
                        marginLeft: '-0.25rem'
                      }}
                    >
                      <NavigationTab />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    paddingTop: '250px',
                    paddingBottom: '300px',
                    flexDirection: 'column',
                    height: '100vh',
                    backgroundColor: 'white'
                  }}
                >
                  <div>
                    <Loader />
                  </div>
                  <div>{t('loadingdot')}</div>
                </div>
              )}
              )
            </div>
          ) : (
            isVisible && (
              <DesktopHeaderWrap
                clickTab={() => {}}
                cartNumber={TotalCartItems}
                wishlistCount={TotalWishlistItems}
                categoriesData={categoriesData.map(item => item)}
              />
            )
          )}

          <div style={{ background: Mobile ? 'white' : '#f5f5f5' }}>
            {categoriesData && !Mobile ? (
              <div
                style={{
                  width: '1200px',
                  margin: '0px auto',
                  zIndex: -1,
                  paddingTop: '135px'
                }}
              >
                {/* <label className={classes.labelTitle}>-- Semua Kategori --</label> */}

                <Card variant='outlined'>
                  <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('all_category')} />
                  <Grid
                    container
                    spacing={1}
                    justify='left'
                    style={{
                      paddingTop: '20px'
                    }}
                  >
                    {categoriesData.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        className={classes.cardCategory}
                        onClick={() => handleClickCardCategory(item.id, item.name, null)}
                        style={{
                          width: '119.1px',
                          height: '150px'
                        }}
                      >
                        <CardMedia
                          component='img'
                          alt='Category Image'
                          image={
                            item.image
                              ? process.env.REACT_APP_FILE_BASE_URL + '/' + item.image
                              : '/assets/images/dummy.png'
                          }
                          title={item.name}
                          style={{
                            height: 64,
                            width: 64,
                            margin: 'auto',
                            borderRadius: '50%'
                          }}
                          onError={currentTarget => (currentTarget.src = 'assets/images/dummy.png')}
                        />
                        <CardContent style={{ textAlign: 'center' }}>
                          <label style={{ fontSize: '12px' }}>{item.name}</label>
                        </CardContent>
                      </Grid>
                    ))}
                  </Grid>
                </Card>

                <br />
              </div>
            ) : null}
          </div>

          {Mobile ? null : <DeskTopFooter />}
        </div>
      </div>
    </div>
  )
}
export default withNamespaces()(CategoriesSection)
