import { baseUrlApi, instance, instance2 } from '../helper/api'

export const getRajaOngkir = async param => {
  // custom parameter
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, { get_all_data: true })
  }

  let resultData = {}
  const axiosConfig = {
    url: baseUrlApi() + '/v1/storefront/courier/raja-ongkir/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getVendor = async param => {
  // custom parameter
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, { get_all_data: true })
  }

  let resultData = {}
  const axiosConfig = {
    url: baseUrlApi() + '/v1/storefront/courier/vendor/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getSelfPickup = async () => {
  let resultData = {}
  const axiosConfig = {
    url: baseUrlApi() + '/v1/storefront/courier/self-pickup/',
    method: 'GET'
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
