import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

const CardListMobile = ({ links }) => {
  return (
    <div className='shadow-card settingsCouponBox mb-1 rounded mx-auto'>
      <ul className='list-group list-group-flush'>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.link}
            {...(link.external ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
          >
            <li style={{ cursor: 'pointer', backgroundColor: '#fff' }}>
              <div className='d-flex'>
                {/* <AiOutlineWhatsApp size="25" style={{ width: "40px" }} /> */}
                {link.icon}

                <div
                  className='d-flex justify-content-left align-items-center p-0'
                  style={{
                    borderBottom: '1px solid #DEDEDE',
                    width: '-webkit-fill-available'
                  }}
                >
                  <div className=' me-auto'>
                    <p
                      className='mb-0'
                      style={{
                        color: 'rgba(119, 129, 137, 1)',
                        textAlign: 'left',
                        fontSize: '16px'
                      }}
                    >
                      {link.title}
                    </p>
                  </div>
                  <span
                    style={{
                      marginRight: '9px',
                      marginLeft: 'auto'
                      // width: '60px'
                    }}
                  >
                    <IoIosArrowForward
                      fontSize='20px'
                      className='allorders-icon'
                      style={{ color: 'grey' }}
                    />
                  </span>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  )
}

export default CardListMobile
