import React, { useState } from 'react'
import { getProfileDetail, signIn } from '../api/authorization'
import { get as getCart } from '../api/cart'
import { getCountOrderByStatus } from '../api/badge'
import { getCountWishlist } from '../api/wishlist'
import { getCountLastViewProduct } from '../api/products'

import { setAuthToken } from '../stores/features/auth/auth'
import { useDispatch } from 'react-redux'
import { useStore } from '../hooks/useStore'
import { useMediaQuery, useTheme } from '@material-ui/core'

const defaultValues = {
  user: null,
  token: null,
  carts: null,
  countCart: 0,
  countUnpaid: 0,
  countWishlist: 0,
  countLastViewedProduct: 0,
  initAuth: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refreshCart: () => Promise.resolve(),
  refreshStatusCountOrder: () => Promise.resolve(),
  refreshCountWishlist: () => Promise.resolve(),
  refreshCountLastViewedProduct: () => Promise.resolve()
}

const AuthContext = React.createContext(defaultValues)

const AuthProvider = props => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { landingPage } = useStore()
  const dispatch = useDispatch()

  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)
  const [carts, setCarts] = useState(null)
  const [countCart, setCountCart] = useState(0)
  const [countUnpaid, setCountUnpaid] = useState(0)
  const [countWishlist, setCountWishlist] = useState(0)
  const [countLastViewedProduct, setCountLastViewedProduct] = useState(0)

  // carts
  const refreshCart = () => {
    console.log('refresh cart')

    getCart(`{"limit": 1, "page": 1}`).then(data => {
      if (data) {
        setCarts(data.data)

        setCountCart(data.data.cart_items?.length ?? 0)
      }
    })
  }

  // my orders
  const refreshStatusCountOrder = () => {
    getCountOrderByStatus().then(res => {
      if (res.success) {
        setCountUnpaid(res.data.UNPAID)
      }
    })
  }

  // wishlist
  const refreshCountWishlist = () => {
    getCountWishlist().then(res => {
      if (res.success) {
        setCountWishlist(res.data)
      }
    })
  }

  // last viewed product
  const refreshCountLastViewedProduct = () => {
    getCountLastViewProduct().then(res => {
      if (res.success) {
        setCountLastViewedProduct(res.data)
      }
    })
  }

  // auth login, logout, init

  const getTokenStore = () => localStorage.getItem('token')

  const setTokenStore = token => localStorage.setItem('token', token)

  const removeTokenStore = () => {
    setToken(null)
    localStorage.removeItem('token')
  }

  const handleSuccessLogin = (token, profileData) => {
    // update state
    setUser(profileData)
    setToken(token)

    // set redux auth
    dispatch(setAuthToken(token))

    // set localstorage
    localStorage.setItem('userid', JSON.stringify(profileData.customer.id))
    localStorage.setItem('usercode', JSON.stringify(profileData.customer.id))
    localStorage.setItem('userdata', JSON.stringify(profileData.customer))
    localStorage.setItem('membership', JSON.stringify(profileData.membership))
  }

  const handleLogout = () => {
    removeTokenStore()
    setUser(null)
    setCountCart(0)
    setCountUnpaid(0)
    setCountWishlist(0)
    setCountLastViewedProduct(0)

    localStorage.removeItem('userid')
    localStorage.removeItem('usercode')
    localStorage.removeItem('userdata')
    localStorage.removeItem('membership')
    dispatch(setAuthToken(''))

    handleRequiredLogin()
  }

  const handleRequiredLogin = () => {
    if (landingPage.setting_customer.is_customer_required_login && isMobile) {
      if (window.location.pathname !== '/login') {
        window.location.href = '/login'
      }
    }
  }

  const initAuth = () => {
    const storedToken = getTokenStore()

    if (storedToken) {
      getProfileDetail(storedToken).then(data => {
        if (data) {
          if (data.success) {
            handleSuccessLogin(storedToken, data.data)

            refreshCart()
            refreshStatusCountOrder()
            refreshCountWishlist()
            refreshCountLastViewedProduct()
          } else {
            logout()
          }
        } else {
          logout()
        }
      })
    } else {
      handleRequiredLogin()
    }
  }

  const login = async (user, password) => {
    const data = {
      user,
      password
    }

    const response = await signIn(data)
    if (response.success) {
      // set token to localstorage
      setTokenStore(response.data.access_token)
      initAuth()

      return response
    } else {
      return response
    }
  }

  const logout = () => {
    handleLogout()
  }

  const values = {
    user,
    token,
    carts,
    countCart,
    countUnpaid,
    countWishlist,
    countLastViewedProduct,
    initAuth,
    login,
    logout,
    refreshCart,
    refreshStatusCountOrder,
    refreshCountWishlist,
    refreshCountLastViewedProduct
  }

  return <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
