import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import i18n from '../../../../../language/localization'
import NoDataFound from '../../../../compo/NoDataFound'
import { useStyles } from './onProcess.style'
import SkeletonCircleText from '../../../../../../components/common/SkeletonCircleText'
import { get as getOrders } from '../../../../../../api/order'
import CardHistoryOrder from 'components/card-history-order/CardHistoryOrder'

const OnProcess = props => {
  const { webThemeColor } = props
  const classes = useStyles()
  const [isOff, setIsOff] = useState(0)

  const history = useHistory()
  let [responseData, setResponseData] = React.useState([])
  let [isNext, isNextFunc] = React.useState(false)
  let [pageCount, setCount] = React.useState(1)
  let [page, setPage] = React.useState('')
  let [status, setStatus] = React.useState(false)
  let [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(true)
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)

  const loadMoreData = () => {
    setCount(pageCount + 1)
  }
  React.useEffect(() => {
    if (pageCount !== 1) isNextFunc(true)
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("user_id", userid);
    // data3.append("type", "ON PROCESS");
    // data3.append("page", pageCount);
    // dispatch(myOrderProductUser(data3));
    // setTimeout(() => {
    //   setOpen(true);
    // }, 700);
    setLoading(true)
    const getOrderDataOnProcess = async () => {
      try {
        const response = await getOrders(`{"order_status": "ON PROCESS", "get_all_data": true}`)
        if (response.success) {
          setResponseData([...response.data])
          isNextFunc(true)
          setPage(response.meta.total_pages)
          localStorage.setItem('onprocessorder', JSON.stringify(response.meta.total_count))
          setLoading(false)
        }
      } catch (error) {
        console.log('Error fetching get order:', error)
      }
    }
    getOrderDataOnProcess()
  }, [pageCount])

  useEffect(() => {
    setStatus(true)
  }, [])
  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])
  const data1 = {}
  // if (open === true) {
  //   // setLoading(true)
  //   setResponseData([...responseData, ...data1["ON PROCESS"]]);
  //   isNextFunc(true);
  //   setPage(data1.total_page);
  //   setOpen(false);
  //   localStorage.setItem("onprocessorder", JSON.stringify(data1.total_record));
  //   setLoading(false);
  // }

  const [orderId, setOrderId] = useState([])
  const setOrder = e => {
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i].id === e) {
        orderId.push(responseData[i])
      }
    }
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [])

  // console.log("responseData_", responseData);

  return (
    <div className={classes.cardStyle}>
      {/* {responseData.length > 0 && (
        <Box className={classes.header}>
          <Input
            type="date"
            //   leftLabel="Email"
            className={classes.input_style}
          />
          <Input
            type="text"
            search="true"
            //   leftLabel="Email"
            className={classes.input_style}
          />
          <Button className={classes.btn_reset}>reset</Button>
        </Box>
      )} */}

      {loading ? (
        // <Loading />
        ['', '', '', '', ''].map((_item, index) => (
          <SkeletonCircleText
            key={index}
            style={{
              width: '100%',
              height: '207px',
              borderRadius: '8px'
            }}
          />
        ))
      ) : responseData.length > 0 ? (
        responseData.map((item, index) => (
          <CardHistoryOrder
            key={index}
            data={item}
            showDetail={isOff}
            setShowDetail={setIsOff}
            webThemeColor={webThemeColor}
          />
        ))
      ) : (
        <NoDataFound />
      )}
    </div>
  )
}

export default OnProcess
