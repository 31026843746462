import { instance } from '../helper/api'

export const get = async param => {
  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/customer/address/',
    method: 'GET',
    params: JSON.parse(param)
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getDetail = async param => {
  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/customer/address/detail/',
    method: 'GET',
    params: JSON.parse(param)
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const create = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/address/`,
    method: 'POST',
    data: data
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const update = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/address/${data.address_id}/`,
    method: 'PATCH',
    data: data
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getOneDetail = async addressID => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/address/${addressID}/detail/`,
    method: 'GET'
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const deleteData = async data => {
  let resultData = {}
  const axiosConfig = {
    url: `/shopi/v1/customer/address/${data.address_id}/`,
    method: 'DELETE',
    data: data
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
