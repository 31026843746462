import React from 'react'

import { makeStyles } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

const themeColor = JSON.parse(localStorage.getItem('themeColor'))
const useStyles = makeStyles(() => ({
  root: {
    '&>ul li Button': {
      border: '1px solid ' + themeColor + '',
      color: 'black'
    },
    '&>ul li .MuiPaginationItem-page.Mui-selected': {
      border: '1px solid ' + themeColor + '',
      background: themeColor,
      color: '#fff'
    }
  }
}))

const MyPagination = ({ count, page, handleChange }) => {
  const classes = useStyles()

  return (
    <Pagination
      count={count}
      variant='outlined'
      shape='rounded'
      page={page}
      onChange={handleChange}
      className={classes.root}
    />
  )
}

export default MyPagination
