import React, { useEffect, useState } from 'react'
import { Box, Container, Grid } from '@material-ui/core'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../home/DesktopFooter'
import { useStyles } from './Checkout.style'
import LeftCheckout from './compo/left/LeftCheckout'
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'

const Checkout = props => {
  const { token } = useAuth()
  const { setActive, webThemeColor } = props
  const classes = useStyles()
  const [itemsCart, setItems] = useState(0)
  const Usercode1 = JSON.parse(localStorage.getItem('usercode'))
  const [categoriesData, setCategoriesData] = useState([])

  const mycartdata = async () => {
    // let data = new FormData();
    // data.append("user_code", Usercode1);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // // dispatch(getMyCartProductUser(data));

    // fetch(`${baseUrlApi()}/Api/Order/myCart`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log("getSearchProduct", res.data);
    //     setItems(res.data.length)
    //   });
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }
  useEffect(() => {
    mycartdata()

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }
  }, [Usercode1])
  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <div className={classes.body}>
      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={'rgb(231, 0, 116)'}
        cartNumber={itemsCart}
        categoriesData={categoriesData}
      />
      <Container>
        <Box className={classes.root_checkout}>
          <h5 className={classes.pageTitle}></h5>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <LeftCheckout setActive={setActive} webThemeColor={webThemeColor} />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <DeskTopFooter />
    </div>
  )
}

export default Checkout
