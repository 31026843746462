import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
// import { DashboardResponse } from "../../store/Selectors/dashboard";
const BannerWeb = ({ bannerData }) => {
  // const data = DashboardResponse();
  // const landing = JSON.parse(localStorage.getItem("dash"));
  // if (bannerData) {
  //   localStorage.setItem("tabs", JSON.stringify(bannerData));
  // }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
      // paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
      // paritialVisibilityGutter: 50,
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          // paddingTop: landing.show_parent_category == 0 ? "47px" : "145px",
          paddingTop: '10px'
        }}
      >
        {bannerData.length > 0 ? (
          <Carousel
            showDots={true}
            ssr={true}
            containerClass='carousel-container'
            infinite={true}
            customTransition='all .5'
            transitionDuration={300}
            autoPlay={true}
            keyBoardControl={true}
            responsive={responsive}
            removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
          >
            {bannerData.map((slide, index) => {
              return (
                <div key={index}>
                  <img
                    src={slide.image ? process.env.REACT_APP_FILE_BASE_URL + '/' + slide.image : ''}
                    alt='img'
                    style={{
                      width: '100%',
                      height: '302px',
                      userSelect: 'none',
                      borderRadius: '2px'
                    }}
                  />
                </div>
              )
            })}
          </Carousel>
        ) : (
          <Paper>
            <Skeleton animation='wave' variant='rect' style={{ width: '100%', height: '400px' }} />
          </Paper>
        )}
      </div>
    </React.Fragment>
  )
}

export default BannerWeb
