import { makeStyles } from '@material-ui/core'
const themeColor = JSON.parse(localStorage.getItem('themeColor'))
const useStyles = makeStyles({
  main_image: {
    width: '100px',
    height: '100px',
    position: 'relative',
    cursor: 'pointer'
  },
  checkbox_mainimage_sec: {
    display: 'flex',
    // padding: "0",
    // gap: "10px",
    alignItems: 'center'
  },

  cardStyle: {
    marginBottom: '15px',
    '& header': {
      backgroundColor: 'white',
      padding: '8px 8px'
    },
    '& .checkbox': {
      display: 'flex',
      padding: '0',
      gap: '10px',
      alignItems: 'center',
      width: '100%',

      '& .css-label': {
        marginBottom: 0,
        fontSize: '16px'
      },

      "& input[type='checkbox']": {
        accentColor: themeColor,
        height: '18px',
        width: '18px',
        cursor: 'pointer'
      }
    }
  },
  cardStyle2: {
    marginBottom: '15px',

    "& input[type='checkbox']": {
      accentColor: '#ff3a59',
      height: '20px',
      width: '20px',
      cursor: 'pointer'
    },

    '& .removeSelected': {
      justifyContent: 'center'
    },
    '& .checkbox': {
      display: 'flex',
      padding: '0',
      gap: '10px',
      alignItems: 'center',
      width: '100%',
      margin: 'auto 0',
      height: '100%',

      '& .css-label': {
        marginBottom: 0
      }
    }
  },

  tableContent: {
    padding: '12px 8px'
  },

  description_style: {
    '& .title_de': {
      fontSize: '14px',
      cursor: 'pointer'
    },
    '& .values': {
      marginBottom: '0',
      fontSize: '14px'
    }
  },

  priceContent_style: {
    paddingTop: '10px',
    '& .values': {
      marginBottom: '0',
      fontSize: '16px'
    },
    '& .discount-rate span': {
      padding: '3px 10px'
    }
  },

  quantity: {
    // border: "1px solid",
    display: 'flex',
    // borderRadius: "4px",
    width: 'fit-content',
    alignItems: 'center',
    border: '1px solid',
    borderColor: '#d6d6d6',

    '& .minus-qty, .qty1, .plus-qty': {
      float: 'unset'
    },
    '& .minus-qty': {
      width: 'fit-content',
      padding: '0 1px',
      border: '1px solid',
      borderColor: '#d6d6d6',
      fontWeight: '700',
      fontSize: '20px',
      '& svg.MuiSvgIcon-root': {
        margin: '0px !important',
        color: '#9a9a9a'
      }
    },
    '& .plus-qty': {
      width: 'fit-content',
      padding: '0 3px',
      border: '1px solid',
      borderColor: '#d6d6d6',
      fontWeight: '700',
      fontSize: '20px',
      '& svg.MuiSvgIcon-root': {
        margin: '0px !important',
        color: '#9a9a9a'
      }
    },

    '& .qty1': {
      margin: '0 !important',
      border: '1px solid',
      borderColor: '#eaeaea',
      fontSize: '20px',
      fontWeight: '100'
    },

    '& .MuiSvgIcon-root': {
      margin: '0 !important'
    }
  },
  backdrop: {
    zIndex: 1,
    color: '#fff'
  },
  quantityStyle: {
    '& .item': {
      display: 'flex',
      gap: '15px',
      alignItems: 'center',
      marginBottom: '15px',

      '& .lable': {
        minWidth: '55px',
        margin: '0 !important',
        fontSize: '16px'
      }
    }
  },

  totalPrice: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',

    '& .MuiSvgIcon-root': {
      margin: '0 !important',
      color: '#ff0000'
    },

    '& .remove': {
      marginBottom: '0px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      cursor: 'pointer'
    }
  },

  btn_fullwidth: {
    display: 'block',
    backgroundColor: '#ff3a59',
    lineHeight: '26px',
    borderRadius: '4px',
    textAlign: 'center',
    color: '#fff !important',
    width: '100%',
    margin: 'auto 0'
  },

  emptyCart: {
    width: '100%',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  btn_remove: {
    // padding: '0',
    textTransform: 'capitalize'

    // "& .MuiButton-label": {
    //   justifyContent:'flex-start',
    //   width: 'fit-content',
    // },
    // "& .MuiButtonBase-root": {
    //   display: 'flex',
    //   justifyContent:'flex-start !important',
    //   width: 'fit-content',
    // },
  },
  TextFieldcustomActiveTextField: {
    '& label.Mui-focused': {
      color: themeColor
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: themeColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: themeColor
      }
    }
  }
})

export { useStyles }
