import React, { useState, useEffect } from 'react'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
// import { getProductData } from "../../store/actions/GetProductdataActions";
// import { GetProductDataResponse } from "../../store/Selectors/getproductdata";
// import { useDispatch } from "react-redux";
import { ThemeColor } from '../themecolor/index'
import CallMadeIcon from '@material-ui/icons/CallMade'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import { getByClient as getProductsDetail } from '../../api/products'

var Highlight = require('react-highlighter')

let MY_LIST = []
var pageCount = 1
const useStyles = makeStyles(theme => ({
  inputRoot: {
    color: 'inherit'
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: '8px',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },

  Highlight_style: {
    '& .highlight': {
      backgroundColor: '#fff',
      color: ThemeColor,
      fontWeight: '600'
    }
  },

  listProductSearch: {
    backgroundColor: 'white'
  }
}))

const SearchPage = ({ t }) => {
  const classes = useStyles()
  // const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem('userid'))
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(pageCount)
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState({})
  const [filterlist, setFilterlist] = useState(MY_LIST)
  const [value, setValue] = useState()
  const [data1, setData1] = useState({})

  const OnKeyPressHandler = e => {
    if (e.key === 'Enter') {
      SearchbarClick()
    }
  }

  function onKeyUpHandler(e) {
    setSearch(e.target.value.toLowerCase())
    if (filterlist) {
      filterlist.splice(0, filterlist.length)
    }
    pageCount = 1
    // setPage(pageCount);
    console.log(e.target.value)
    setValue(e.target.value)
  }
  function clearFilter() {
    document.getElementById('searchFilter').value = ''
  }
  const SearchbarClick = () => {
    if (value === '' || value === undefined) {
      localStorage.setItem('viewproname', JSON.stringify('All Product'))
      localStorage.setItem('viewproid', JSON.stringify(''))
      localStorage.setItem('viewprochild', JSON.stringify(''))
      history.push('/products')
    } else {
      localStorage.setItem('viewproid', JSON.stringify(''))
      localStorage.setItem('searchname', JSON.stringify(value))
      localStorage.setItem('viewproname', value)
      localStorage.setItem('viewprochild', JSON.stringify(''))
      history.push('/products')
    }
  }
  // const data1 = GetProductDataResponse();
  // if (open) {
  //   // console.log("open in ",pageCount);
  //   // console.log("page length",data1.total_page);
  //   if (data1.total_page > pageCount) {
  //     setPage(pageCount++);
  //     setFilterlist([...filterlist, ...data1.data]);
  //     // console.log("condition in ",pageCount);
  //   }
  //   if (data1.total_page == 1) {
  //     setFilterlist([...filterlist, ...data1.data]);
  //   }
  //   setOpen(false);
  // }

  useEffect(() => {
    localStorage.removeItem('searchname')
    // var data3 = new FormData();
    // data3.append("page", "1");
    // data3.append("search", search);
    // dispatch(getProductData(data3));
    // setTimeout(() => setOpen(true), 400);
    const getProductsDetailData = async () => {
      try {
        const response = await getProductsDetail(
          `{${search ? `"query":"${search}"` : ''}, "limit": 50}`
        )
        setData1(response)
        // if (response.meta.total_pages > pageCount) {
        //   setPage(pageCount++);
        //   setFilterlist([...filterlist, ...response.data]);
        //   // console.log("condition in ",pageCount);
        // }
        // if (response.total_pages == 1) {
        //   setFilterlist([...filterlist, ...response.data]);
        // }
        // console.log("response.data",response.data)
        // console.log("filterlist", filterlist)
        if (search !== '') {
          setFilterlist(response.data)
        }
      } catch (error) {
        console.log('Error fetching getProductsDetail:', error)
      }
    }

    getProductsDetailData()
  }, [search, page])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'white',
          position: 'fixed',
          zIndex: '11111',
          marginBottom: '40px',
          maxWidth: '100wh'
        }}
      >
        <div
          className='title-box'
          style={{
            boxShadow:
              '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 12%)'
          }}
        >
          <KeyboardBackspaceIcon
            style={{ color: 'black', width: '25px' }}
            onClick={() => {
              history.goBack()
            }}
          />
          <InputBase
            autoFocus
            className='search-input'
            placeholder={t('search_product_here')}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            style={{ width: '100%', height: '50px', padding: '0px' }}
            inputProps={{ 'aria-label': 'search' }}
            name='search'
            onKeyUp={onKeyUpHandler}
            onKeyPress={OnKeyPressHandler}
          />
          <svg
            style={{
              fill: 'transparent',
              cursor: 'pointer',
              height: '24px',
              width: '20px'
            }}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={SearchbarClick}
          >
            <path
              d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
              stroke='black'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M22 22L20 20'
              stroke='black'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          {/* onClick={()=>{history.push("/search")}} */}
        </div>
      </div>
      <div
        style={{
          paddingTop: '50px'
        }}
      >
        <ul className={`list-group ${classes.listProductSearch}`}>
          {filterlist && filterlist.length > 0
            ? filterlist.map((item, key) => (
                <div key={key}>
                  <li
                    key={key}
                    style={{
                      textAlign: 'left',
                      border: '1px solid #DEDEDE80',
                      // paddingTop: "2px",
                      // paddingBottom: "2px",
                      cursor: 'pointer',
                      padding: '5px 0px 5px 10px'
                    }}
                    value={item.product.id}
                    onClick={() => {
                      history.push(`/productdetail?id=${item.product.id}`)
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'start'
                        }}
                      >
                        <Highlight
                          className={classes.Highlight_style}
                          search={search}
                          style={{ fontWeight: '500', fontSize: '14px' }}
                        >
                          {item.product.name}
                        </Highlight>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end'
                        }}
                      >
                        <CallMadeIcon
                          style={{
                            transform: 'scaleX(-1)',
                            fontSize: '1.3rem'
                          }}
                        />
                      </div>
                    </div>
                  </li>
                </div>
              ))
            : ''}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default withNamespaces()(SearchPage)
