import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ThemeColor } from '../themecolor'
import { withNamespaces } from 'react-i18next'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  specificationTable: {
    color: 'rgba(139, 139, 139, 1)',
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      paddingTop: '0.4rem',
      paddingBottom: '0.4rem',
      paddingLeft: '0.25rem'
    },
    '& td:nth-child(1)': {
      width: '40%'
    },
    '& tr:nth-child(odd)': {
      backgroundColor: '#F2F0F1'
    }
  },
  box: {
    boxShadow: '0px 0px 8px -1px rgb(219 213 213)',
    '& h6': {
      color: ThemeColor
    }
  }
}))

const Specification = ({ t, product }) => {
  const history = useHistory()
  const classes = useStyles()

  const directToProductByCategory = category => {
    localStorage.setItem('viewproid', category.id)
    localStorage.setItem('viewproname', category.name)
    // localStorage.setItem("viewprochild", JSON.stringify(category.slug));
    localStorage.setItem('viewprochild', JSON.stringify(''))

    history.push('/products')
  }

  return (
    <div style={{ background: '#fff' }}>
      <div
        className={`${classes.box}`}
        style={{
          backgroundColor: 'white',
          marginTop: '0px',
          paddingTop: '58px',
          paddingLeft: '7px',
          paddingRight: '7px',
          paddingBottom: '7px',
          minHeight: '700px',
          cursor: 'pointer'
        }}
      >
        <div style={{ cursor: 'pointer', fontWeight: 'bold' }} className='mb-2'>
          {t('specification')}
        </div>
        {product ? (
          <div className='mt-1'>
            <table className={classes.specificationTable}>
              <tbody>
                <tr>
                  <td style={{ cursor: 'pointer' }}>{t('minimum_order')}</td>
                  <td style={{ paddingLeft: '0px', cursor: 'pointer' }}>: &nbsp;&nbsp; 1 PCS</td>
                </tr>
                <tr>
                  <td style={{ cursor: 'pointer' }}>{t('category')}</td>
                  <td
                    style={{
                      color: 'rgba(68, 180, 155, 1)',
                      paddingLeft: '0px'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'row'
                      }}
                    >
                      <div>:</div>
                      <div
                        style={{
                          display: ' -webkit-inline-box',
                          overflowY: 'hidden',
                          marginLeft: '16px',
                          cursor: 'pointer'
                        }}
                      >
                        {product ? (
                          product.category ? (
                            <label
                              style={{
                                fontSize: '15px',
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                directToProductByCategory(product.category)
                              }}
                            >
                              {product.category.name}
                            </label>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ cursor: 'pointer' }}>{t('product_sku')}</td>
                  <td
                    style={{
                      paddingLeft: '0px',
                      cursor: 'pointer',
                      display: 'flex'
                    }}
                  >
                    : &nbsp;&nbsp; <div>{product.product.sku}</div>
                  </td>
                </tr>
                <tr>
                  <td style={{ cursor: 'pointer' }}>{t('weight')}</td>
                  <td style={{ paddingLeft: '0px', cursor: 'pointer' }}>
                    : &nbsp;&nbsp;{product.product.weight} Gram
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <br />
            No Product Specified Found
          </div>
        )}
      </div>
    </div>
  )
}
export default withNamespaces()(Specification)
