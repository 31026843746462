import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  cardStyle: {
    '& .cardContent': {
      padding: '15px'
    },

    '& .listItems': {
      minHeight: '90px'
    },
    '& header': {
      '& .heading': {
        padding: '8px 15px',
        fontSize: '16px',
        fontFamily: '"Poppins", sans-serif',
        fontWeight: '600',
        backgroundColor: 'white'
        // backgroundColor: "#e6e6e6",
      }
    },

    '& .MuiButton-root:hover': {
      backgroundColor: '#000',
      color: '#fff'
    },
    '& .MuiButton-text': {
      padding: '2px 8px'
    }
  },

  listStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h4': {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '40px',
      marginBottom: '0'
    }
  },

  btn_fullwidth: {
    display: 'block',
    backgroundColor: '#ff3a59',
    lineHeight: '40px',
    borderRadius: '4px',
    textAlign: 'center',
    color: '#fff !important',
    width: '100%',
    marginBottom: '10px'
  },

  NavLink_style_none: {
    lineHeight: '0 !important',
    borderRadius: '0 !important',
    border: 'none !important',
    padding: '0 !important',
    marginRight: '0 !important'
  }
})

export { useStyles }
