import { createSlice } from '@reduxjs/toolkit'
import { getProducts } from '../../../api/products'

/**
 * Structure randomProducts
 * {
 *  category_name: string
 *  category_id: string
 * }
 */
const initialState = {
  randomProducts: [],
  loading: 'idle'
}

export const randomProducts = createSlice({
  name: 'randomProducts',
  initialState,
  reducers: {
    setRandomProductsLoading(state, action) {
      state.loading = action.payload
    },

    setRandomProductsReceived(state, action) {
      if (state.loading === 'pending') {
        state.loading = 'idle'
        state.randomProducts = action.payload
      }
    },

    pushRandomProductReceived(state, action) {
      state.randomProducts = action.payload
    }
  }
})

export const { setRandomProductsLoading, setRandomProductsReceived, pushRandomProductReceived } =
  randomProducts.actions

const fetchRandomProducts = categoriesId => async dispatch => {
  try {
    dispatch(setRandomProductsLoading('loading'))
    let products = []

    Promise.all(
      categoriesId.map(async item => {
        const res = await getProducts(
          JSON.stringify({
            page: 1,
            limit: 10,
            category_id: item.id,
            status: 'live'
          })
        )

        if (await res.success) {
          return {
            categoryName: item.name,
            id: item.id,
            products: res.data || []
          }
        } else {
          dispatch(setRandomProductsLoading('error'))
        }
      })
    ).then(newProduct => {
      dispatch(pushRandomProductReceived(newProduct))
      dispatch(setRandomProductsLoading('success'))
    })
  } catch (error) {
    dispatch(setRandomProductsLoading('error'))
    console.log('async reducer ', error)
  }
}

export { fetchRandomProducts }

export default randomProducts.reducer
