import { create } from 'api/cart'

export const addToCart = async (data, landingPage, customer, carts, product, productVariant) => {
  // check setting landing page
  // maksimal qty per produk
  // let maximum_order_product_in_cart = 0
  // maksimal uniq produk
  // let maximum_order_qty_product_in_cart = 0
  // maksimal qty per customer
  // let maximum_order_quantity_per_customer_in_cart = 0

  console.log('data', data)
  console.log('landingPage', landingPage)
  console.log('customer', customer)
  console.log('product', product)
  console.log('cart', carts)
  console.log('productVariant', productVariant)

  if (landingPage.setting.is_maximum_order_product_in_cart) {
    data.forEach(item => {
      if (!item.product_variant_id) {
        if (
          (product.maximum_order && item.quantity > product.maximum_order) ||
          item.quantity > landingPage.setting.maximum_order_product_in_cart
        ) {
          // let maximum_order_product_in_cart =
          //   product.maximum_order && item.quantity > product.maximum_order
          //     ? product.maximum_order
          //     : landingPage.setting.maximum_order_product_in_cart
          throw new Error(
            'Maaf, variant ini dibatasi jumlah pembeliannya, silakan coba lagi dengan jumlah yang lebih kecil.'
          )
        }
      } else {
        const variant = productVariant.find(variant => variant.id === item.product_variant_id)

        if (
          (variant.maximum_order && item.quantity > variant.maximum_order) ||
          item.quantity > landingPage.setting.maximum_order_product_in_cart
        ) {
          // let maximum_order_product_in_cart =
          //   variant.maximum_order && item.quantity > variant.maximum_order
          //     ? variant.maximum_order
          //     : landingPage.setting.maximum_order_product_in_cart
          throw new Error(
            'Anda sudah mencapai batas maksimal Keepan, Silakan Rekap pesanan Anda terlebih dahulu.'
          )
        }
      }
    })
  }

  if (landingPage.setting.is_maximum_order_qty_product_in_cart) {
    let newAddQtyProduct = data.length

    if (
      (customer.customer.maximum_order_qty_product_in_cart &&
        carts.cart.item_quantity + newAddQtyProduct >
          customer.customer.maximum_order_qty_product_in_cart) ||
      carts.cart.item_quantity + newAddQtyProduct >
        landingPage.setting.maximum_order_qty_product_in_cart
    ) {
      if (carts.cart.quantity > 0) {
        throw new Error(
          'Anda sudah mencapai batas maksimal Keepan, Silakan Rekap pesanan Anda terlebih dahulu. '
        )
      } else {
        throw new Error(
          'Maaf, produk ini dibatasi jumlah pembeliannya, silakan coba lagi dengan jumlah yang lebih kecil.'
        )
      }
      // let maximum_order_qty_product_in_cart =
      //   customer.customer.maximum_order_qty_product_in_cart &&
      //   carts.cart.item_quantity >= customer.customer.maximum_order_qty_product_in_cart
      //     ? customer.customer.maximum_order_qty_product_in_cart
      //     : landingPage.setting.maximum_order_qty_product_in_cart
    }
  }

  if (landingPage.setting.is_maximum_order_quantity_per_customer_in_cart) {
    let newAddQty = data.map(item => item.quantity).reduce((a, b) => a + b, 0)

    if (
      (customer.customer.maximum_order_quantity_per_customer_in_cart &&
        carts.cart.product_quantity + newAddQty >
          customer.customer.maximum_order_quantity_per_customer_in_cart) ||
      carts.cart.product_quantity + newAddQty >
        landingPage.setting.maximum_order_quantity_per_customer_in_cart
    ) {
      let maximum_order_quantity_per_customer_in_cart =
        customer.customer.maximum_order_quantity_per_customer_in_cart &&
        carts.cart.product_quantity + newAddQty >
          customer.customer.maximum_order_quantity_per_customer_in_cart
          ? customer.customer.maximum_order_quantity_per_customer_in_cart
          : landingPage.setting.maximum_order_quantity_per_customer_in_cart
      throw new Error(
        'Qty kesulurahn item dalam keranjang mencapai maksimal, maksimal adalah ' +
          maximum_order_quantity_per_customer_in_cart
      )
    }
  }

  const response = await create(data)

  return response
}
