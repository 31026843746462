import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import DesktopLoginForm from '../form/DesktopLogin'
import CloseIcon from '@material-ui/icons/Close'
import { useStore } from '../../hooks/useStore'
const useStyles = makeStyles({
  Root: {
    '&>.MuiDialog-container .MuiDialog-paperWidthSm': {
      width: 370
    },
    '& .MuiBackdrop-root': {
      backgroundColor: '#d3d3d3d6'
    }
  },
  svgMargin: {
    '&>svg': {
      margin: '0px !important'
    }
  },

  CloseIconStyle: {
    color: 'black',
    fontSize: '22px',
    margin: '0px !important',
    zIndex: 1,
    padding: '2px',
    '& :hover': {
      backgroundColor: 'red'
    }
  }
})

const AuthDilog = ({ open, tabIndexOpen, onClose, LoginFunction, SignUpFunction }) => {
  const { landingPage } = useStore()

  const [requiredLogin, setRequiredLogin] = useState(true)

  const classes = useStyles()

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))

  useEffect(() => {
    if (landingPage?.setting_customer) {
      console.log('requiredLogin', landingPage?.setting_customer?.is_customer_required_login)
      setRequiredLogin(landingPage.setting_customer?.is_customer_required_login)
    }
  }, [landingPage])

  return (
    <Dialog
      aria-labelledby='simple-dialog-title'
      open={open}
      className={classes.Root}
      onClose={requiredLogin ? undefined : onClose}
    >
      {!requiredLogin && (
        <div
          style={{
            position: 'absolute',
            right: '0',
            textAlign: 'right',
            padding: '4px',
            paddingBottom: '0',
            paddingTop: '4px',
            zIndex: 1
          }}
          className={classes.svgMargin}
        >
          <CloseIcon
            style={{
              // color
              color: ThemeColor?.color
            }}
            className={classes.CloseIconStyle}
            onClick={
              landingPage?.setting_customer?.is_customer_required_login ? undefined : onClose
            }
          />
        </div>
      )}
      <div
        style={{
          textAlign: 'right',
          padding: '7px',
          paddingBottom: '0',
          paddingTop: '2px'
        }}
      >
        <br />
      </div>

      <DesktopLoginForm
        tabIndexOpen={tabIndexOpen}
        LoginFunction={LoginFunction}
        SignUpFunction={SignUpFunction}
      />
    </Dialog>
  )
}
export default AuthDilog
