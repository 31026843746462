import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  body: {
    '& header': {
      marginBottom: '20px'
    },
    '& .MuiAppBar-colorPrimary': {
      color: '#000',
      backgroundColor: '#fff'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize'
    },
    '& .Mui-selected': {
      color: '#ff3a59 !important',
      fontWeight: '600'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#ff3a59 !important',
      height: '3px'
    }
  }
})

export { useStyles }
