import React, { useState, useEffect, useRef } from 'react'
import ScrollableTabsButtonAuto from '../home/Tab'
import SearchbarForProduct from './SearchForProduct'
import NavigationTab from '../home/bottomnav/NavigationTab'
// import { GetProductDataResponse } from "../../store/Selectors/getproductdata";
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from '@material-ui/core/Paper'
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
// import { GetWishList } from "../../store/actions/GetWishlist";
// import { GetWishlistSelector } from "../../store/Selectors/GetWishlistSelector";
// import { useSelector, useDispatch } from "react-redux";
// import { singleProductDataUser } from "../../store/actions/productDetailsActions";
import { useHistory } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getByClient as getProductsDetail } from '../../api/products'
import { getBadgeCart } from '../../api/badge'
import CardProduct from '../../components/card-product/CardProduct'
import { useAuth } from '../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  // Main: {
  //   width: "100%",
  //   height: "220px"
  // },

  cards_style: {
    '& .card:hover': {
      // border: "1px solid #ff3a59 !important",
    }
  },
  cardTitle: {
    marginBottom: '3px',
    '& .read-more-less--more': {
      display: 'none'
    }
  },

  mobile_slider_cards: {
    '& .card-body': {
      // minHeight: '117px',
      padding: '5px 2px 0px 6px !important'
    },
    '& .mainImageThum': {
      height: '200px'
    },
    '& .cardTitle ': {
      //  textTransform: 'uppercase',
      fontSize: '13px !important'
      // wordBreak: "break-all",
    },
    '& .TowLineText ': {
      height: '38px'
    }
  }
}))

const usercode = JSON.parse(localStorage.getItem('usercode'))
const ProductListHorizontal = ({ t }) => {
  const { token } = useAuth()

  const indexTab = localStorage.getItem('tabval')
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  // const data = GetProductDataResponse();
  const [productid, setProductId] = useState('')
  const [color, setColor] = useState(false)
  const [heart, setheart] = useState(true)
  const [data2, setData2] = useState([])
  const [pageScroll, setPageScroll] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [membershipLevel, setMembershipLevel] = useState(1)
  // console.log("data", data);
  const classes = useStyles()
  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const prevScrollY = useRef(0)
  const [goingUp, setGoingUp] = useState(false)
  const [MyData, setMyData] = useState([])
  const [MyPage, setMyPage] = useState()
  const [NewData, setNewData] = useState([])
  const [find, setFind] = useState(false)
  const [categoriesData, setCategoriesData] = useState([])
  const [TotalCartItems, setItems] = useState(0)

  // const dispatch = useDispatch();
  const whislist = {}
  const history = useHistory()
  const firstEvent = async e => {
    // console.log(e);
    // console.log("scrollTop",e.target.scrollTop)
    const currentScrollY = e.target.scrollTop
    if (prevScrollY.current < currentScrollY && !goingUp) {
      for (var i = 2; i <= MyPage; i++) {
        // var myHeaders = new Headers();
        // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
        // var formdata = new FormData();
        // formdata.append("user_id", UserId);
        // formdata.append("user_code", Usercode);
        // formdata.append("page", i);
        // formdata.append("category", CatId);
        // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
        //   method: "POST",
        //   headers: myHeaders,
        //   body: formdata,
        //   redirect: "follow",
        // })
        //   .then((res) => res.json())
        //   .then((res) => {
        //     for (var i = 0; i < res.data.length; i++) {
        //       // console.log("iii", res.data[i]);
        //       if (!MyData.includes(res.data[i].id)) {
        //         MyData.push(res.data[i]);
        //       }
        //     }
        //   });
        try {
          const response = await getProductsDetail(
            `{"page": ${i}, "limit": 15 ,"category_id":${CatId}}`
          )
          for (var j = 0; j < response.data.length; j++) {
            if (!MyData.includes(response.data[j].id)) {
              MyData.push(response.data[j])
            }
          }
        } catch (error) {
          console.log('Error fetching getProductsDetail:', error)
        }
      }
      // setNewData(getUnique(MyData, "id"));
      // console.log("UpdateData",NewData)
    }

    if (prevScrollY.current > currentScrollY && !goingUp) {
      for (var k = 2; k <= MyPage; k++) {
        // var myHeaders = new Headers();
        // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
        // var formdata = new FormData();
        // formdata.append("user_id", UserId);
        // formdata.append("user_code", Usercode);
        // formdata.append("page", i);
        // formdata.append("category", CatId);
        // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
        //   method: "POST",
        //   headers: myHeaders,
        //   body: formdata,
        //   redirect: "follow",
        // })
        //   .then((res) => res.json())
        //   .then((res) => {
        //     for (var i = 0; i < res.data.length; i++) {
        //       // console.log("iii", res.data[i]);
        //       if (!MyData.includes(res.data[i].id)) {
        //         MyData.push(res.data[i]);
        //       }
        //     }
        //   });
      }
      // setNewData(getUnique(MyData, "id"));
      // console.log("UpdateData",NewData)
    }
    prevScrollY.current = currentScrollY
    // console.log(goingUp, currentScrollY);
  }
  const getUnique = (array, key) => {
    if (typeof key !== 'function') {
      const property = key
      key = function (item) {
        return item[property]
      }
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item)
          if (!map.has(k)) map.set(k, item)
          return map
        }, new Map())
        .values()
    )
  }
  const CatId = JSON.parse(localStorage.getItem('CatId'))
  const UserId = localStorage.getItem('userid')
  const Usercode = localStorage.getItem('usercode')

  useEffect(() => {
    FetchData()
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [CatId])
  const FetchData = () => {
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");

    // var formdata = new FormData();
    // formdata.append("user_id", UserId);
    // formdata.append("user_code", Usercode);
    // formdata.append("page", "1");
    // formdata.append("category", CatId);
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setMyPage(res.total_page);
    //     setMyData(res.data);
    //     console.log("newData", res.data);
    //      setNewData(res.data);
    //     setFind(false);
    //     if (res.data.length < 5) {
    //       setFind(true);
    //     }
    //   });

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }

    const getProductsDetailData = async () => {
      try {
        const response = await getProductsDetail(`{"page":1, "limit": 15 ,"category_id":${CatId}}`)
        setMyPage(response.meta.total_pages)

        const data = response.data ?? []
        setMyData(data)
        setNewData(data)
        setFind(false)
        if (data && data.length < 5) {
          setFind(true)
        }
      } catch (error) {
        console.log('Error fetching getProductsDetail:', error)
      }
    }

    getProductsDetailData()
  }
  const [true1, setTrue1] = useState(true)

  useEffect(() => {
    // let myData = new FormData();
    // myData.append("user_code", usercode);
    // dispatch(GetWishList(myData));
    // setTimeout(() => {
    //   setTrue1(true);
    // }, 1000);
    if (token) {
      const getBadgeCartData = async () => {
        try {
          const response = await getBadgeCart()
          if (response.success) {
            setItems(response.data)
          }
        } catch (error) {
          console.log('Error fetching getBadgeCartData:', error)
        }
      }
      getBadgeCartData()
    }
  }, [])

  const addWishRed = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem('usercode'))
    let data1 = new FormData()
    data1.append('product_id', e)
    data1.append('user_code', usercode)
    data1.append('date_time', '2018-12-25 10:23:00')
    // belum done add wishlist
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData)
    let tmpObj = Object.assign({}, tmpArr[j])
    tmpObj['is_wishlist'] = '1'
    tmpArr[j] = tmpObj
    setNewData(tmpArr)
  }

  const addWishWhite = (e, j) => {
    const usercode = JSON.parse(localStorage.getItem('usercode'))
    let data1 = new FormData()
    data1.append('product_id', e)
    data1.append('user_code', usercode)
    data1.append('date_time', '2018-12-25 10:23:00')
    // belum done add wishlist
    // dispatch(addWishlistProductUser(data1));
    let tmpArr = Object.assign([], NewData)
    let tmpObj = Object.assign({}, tmpArr[j])
    tmpObj['is_wishlist'] = '0'
    tmpArr[j] = tmpObj
    setNewData(tmpArr)
  }

  const getSingleProduct = e => {
    // const usercode=JSON.parse(localStorage.getItem("usercode"));
    // const userid=JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // if(userid){
    //   data3.append("user_id", userid);
    //   data3.append("user_code", usercode);
    // }
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    history.push(`/productdetail?id=${e}`)
  }
  return (
    <React.Fragment>
      <React.Fragment>
        <div
          style={{
            position: 'fixed',
            zIndex: '1',
            width: '100%'
          }}
        >
          <SearchbarForProduct color={ThemeColor} title='' cartCount={TotalCartItems} />
          <div
            style={{
              marginTop: '45px'
            }}
          >
            {pageScroll ? (
              <ScrollableTabsButtonAuto
                id={pageNo}
                activeTab={indexTab}
                color={ThemeColor ? ThemeColor : '#FFFFFF'}
                categoriesData={categoriesData}
              />
            ) : (
              <ScrollableTabsButtonAuto
                id={pageNo}
                activeTab={indexTab}
                color={ThemeColor ? ThemeColor : '#FFFFFF'}
                categoriesData={categoriesData}
              />
            )}
          </div>
        </div>
        <div
          className='container'
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            paddingTop: '96px'
          }}
        >
          {NewData !== null ? (
            NewData.length > 0 ? (
              <div
                className={classes.cards_style}
                style={{
                  backgroundColor: '#fafafa',
                  width: '100%',
                  display: 'flex',
                  overflow: 'auto',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  marginLeft: '0px',
                  marginRight: '0px',
                  marginTop: '9px',
                  paddingLeft: '0px',
                  paddingRight: '1px',
                  height: find ? 'auto' : '750px',
                  clear: 'both',
                  paddingBottom: Mobile ? '0px' : '50px'
                  // marginBottom: (NewData.length>=6?"55px": "0")
                }}
                onScroll={firstEvent}
              >
                {NewData.map((product, index) => {
                  return (
                    <div
                      style={{
                        width: Mobile ? '50%' : '28%',
                        paddingLeft: '5px',
                        marginBottom: '6px'
                      }}
                      key={index}
                    >
                      <CardProduct
                        membershipLevel={membershipLevel}
                        product={product.product}
                        handleClick={getSingleProduct}
                      />
                    </div>
                  )
                })}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '-80px',
                  height: '100vh'
                }}
              >
                <div>{t('empty_product')}</div>
              </div>
            )
          ) : (
            <Paper>
              <Skeleton variant='rect' height={100} />
              <Skeleton variant='rect' height={100} />
              <Skeleton variant='rect' height={100} />
              <Skeleton variant='rect' height={100} />
            </Paper>
          )}

          <NavigationTab />
        </div>
      </React.Fragment>
    </React.Fragment>
  )
}

export default withNamespaces()(ProductListHorizontal)
