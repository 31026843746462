import React, { useState, useEffect } from 'react'
import { makeStyles, styled } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Header from '../header/header'
import { useHistory } from 'react-router-dom'
// import { useDispatch } from "react-redux";
// import { cancelOrderProductUser } from "../../store/actions/CancelOrderActions";
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import ClipLoader from 'react-spinners/ClipLoader'
import { withNamespaces } from 'react-i18next'
import { cancel as cancelOrderRequest } from 'api/order'

import i18n from '../language/localization'
import { priceFormatLocal } from '../../utils/helper'
import {
  discountPercentageOrderItem,
  isDiscountOrderItem,
  isDiscountValid,
  priceAfterDiscountOrderItem
} from 'utils/pricing'
import { useModalMessage } from 'hooks/useModalMessage'

const makeFirstLetterCap = str => {
  return str
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

const OrderDetails = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })

  const { setMessage } = useModalMessage()

  const CssTextField = styled(TextField)({
    fontSize: '0.75rem',
    '& laborderdatas[0].Mui-focused': {
      color: ThemeColor ? ThemeColor : '#FFFFFF'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ThemeColor ? ThemeColor : '#FFFFFF'
    }
  })
  const useStyles = makeStyles(theme => ({
    input: {
      '&::placeholder': {
        padding: '4px 40px 5px'
      }
    }
  }))
  const classes = useStyles()
  const history = useHistory()
  // const dispatch = useDispatch();
  const orderdatas = JSON.parse(localStorage.getItem('ordersdata'))
  console.log('orderdatas', orderdatas)
  const finalprice = orderdatas[0].order.total - orderdatas[0].order.discount
  const finalweight = orderdatas[0].order_items.reduce(
    (sum, a) => sum + JSON.parse(a.product.weight) * JSON.parse(a.quantity),
    0
  )
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)

  const [open, setOpen] = useState(false)

  const cancelOrder = async () => {
    const data = {
      order_id: orderdatas[0].order.id
    }
    const response = await cancelOrderRequest(data)
    if (response.success) {
      history.push('/orderhistory')
    }
  }

  const [true1, setTrue1] = useState(false)
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    // setTimeout(() => {
    //   setTrue1(true);
    // }, 700);
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [])
  return (
    <React.Fragment>
      <React.Fragment>
        <Header title={t('order_details')} color={ThemeColor} />
        <ThemeProvider theme={theme}>
          <div style={{ paddingTop: '48px' }}>
            <div className='shadow-card rounded-card bg-white m-2'>
              <div className='my-1 mx-2'>
                {orderdatas.length > 0 && orderdatas[0].order.customer_address ? (
                  <div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('delivery_address'))}</p>
                    </div>
                    <div>{orderdatas[0].order.customer_address.name}</div>
                    <div>{orderdatas[0].order.customer_address.address}</div>
                    <div>
                      {orderdatas[0].order.customer_addresses_detail.sub_district.name +
                        ', ' +
                        orderdatas[0].order.customer_addresses_detail.district.name}
                    </div>
                    <div>{orderdatas[0].order.customer_addresses_detail.province.name}</div>
                    <div>{orderdatas[0].order.customer_address.phone}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className='shadow-card rounded-card bg-white m-2'>
              <div className='my-1 mx-2'>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('shipping_option'))}</p>
                </div>
                {orderdatas[0].order.shipping &&
                orderdatas[0].order.shipping_type === 'SELFPICKUP' ? (
                  <div
                    className='d-flex'
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <img
                      src={
                        orderdatas[0].order.shipping && orderdatas[0].order.shipping.logo
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            '/' +
                            orderdatas[0].order.shipping.logo.replace('instasoft/', '')
                          : '/assets/images/dummy.png'
                      }
                      height='35px'
                      width='50px'
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        marginLeft: '7px',
                        marginBottom: '10px',
                        marginTop: '10px'
                      }}
                    />
                    <span
                      style={{
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '16px',
                        width: '100%',
                        marginBottom: '13px'
                      }}
                    >
                      {/* {orderdatas[0].selfpickup.code} */}
                      {orderdatas[0].order.shipping ? orderdatas[0].order.shipping.name : ''}
                      <br />
                      {/* ({orderdatas[0].selfpickup.status + "-" + orderdatas[0].selfpickup.status + " day"})  */}
                      {'Rp ' + orderdatas[0].order.shipping.price}
                    </span>
                  </div>
                ) : orderdatas[0].order.shipping &&
                  orderdatas[0].order.shipping_type === 'COURIER VENDOR' ? (
                  <div
                    className='d-flex'
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <img
                      src={
                        orderdatas[0].order && orderdatas[0].order.shipping.logo
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            '/' +
                            orderdatas[0].order.shipping.logo
                          : '/assets/images/dummy.png'
                      }
                      height='35px'
                      width='50px'
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        marginLeft: '7px',
                        marginBottom: '10px',
                        marginTop: '10px'
                      }}
                    />
                    <span
                      style={{
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '16px',
                        width: '100%',
                        marginBottom: '13px'
                      }}
                    >
                      {orderdatas[0].order.shipping ? orderdatas[0].order.shipping.code : ''}
                      {orderdatas[0].order.shipping ? orderdatas[0].order.shipping.name : ''}
                      <br />(
                      {(orderdatas[0].order.shipping ? orderdatas[0].order.shipping.status : '') +
                        ' day'}
                      ){' '}
                      {'Rp ' +
                        (orderdatas[0].order.shipping ? orderdatas[0].order.shipping.price : '')}
                    </span>
                  </div>
                ) : orderdatas[0].order.shipping &&
                  orderdatas[0].order.shipping_type === 'COURIER RAJA ONGKIR' ? (
                  <div
                    className='d-flex'
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <img
                      src={
                        orderdatas[0].order && orderdatas[0].order.shipping.logo
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            '/' +
                            orderdatas[0].order.shipping.logo
                          : '/assets/images/dummy.png'
                      }
                      height='35px'
                      width='50px'
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        marginLeft: '7px',
                        marginBottom: '10px'
                      }}
                    />
                    <span
                      style={{
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: "center",
                        width: '100%',
                        marginBottom: '13px',
                        marginLeft: '16px'
                      }}
                    >
                      {/* {orderdatas[0].order.shipping.account_number} */}
                      {(orderdatas[0].order.shipping ? orderdatas[0].order.shipping.name : '') +
                        ' ' +
                        (orderdatas[0].order.shipping ? orderdatas[0].order.shipping.service : '')}
                      <br />
                      {orderdatas[0].order.shipping && orderdatas[0].order.shipping.estimation != ''
                        ? '(' + orderdatas[0].order.shipping.estimation + ' hari)'
                        : ''}{' '}
                      {orderdatas[0].order.shipping_cost
                        ? priceFormatLocal(orderdatas[0].order.shipping_cost)
                        : 0}
                      {orderdatas[0].order.shipping && orderdatas[0].order.shipping.actual_rate
                        ? orderdatas[0].order.shipping.actual_rate
                        : ''}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div className='shadow-card rounded-card bg-white m-2'>
              <div className='my-1 mx-2'>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='mb-0 fw-bold'>{makeFirstLetterCap(t('payment_option'))}</p>
                </div>
                {orderdatas[0].order.cod_payment ? (
                  <div
                    className='d-flex'
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <img
                      src={
                        orderdatas[0].order.cod_payment.logo
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            '/' +
                            orderdatas[0].order.cod_payment.logo
                          : '/assets/images/dummy.png'
                      }
                      height='35px'
                      width='50px'
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        marginLeft: '7px',
                        marginBottom: '10px'
                      }}
                    />
                    <span
                      style={{
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: "center",
                        marginLeft: '16px',
                        width: '100%',
                        marginBottom: '13px'
                      }}
                    >
                      {t('cash_on_delivery')}
                    </span>
                  </div>
                ) : (
                  <div
                    className='d-flex'
                    style={{
                      justifyContent: 'space-evenly'
                    }}
                  >
                    <img
                      src={
                        orderdatas[0].order && orderdatas[0].order.payment_method_detail
                          ? process.env.REACT_APP_FILE_BASE_URL +
                            '/' +
                            orderdatas[0].order.payment_method_detail.bank_image
                          : '/assets/images/dummy.png'
                      }
                      height='35px'
                      width='50px'
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'flex-start',
                        marginLeft: '7px',
                        marginBottom: '10px'
                      }}
                    />
                    <span
                      style={{
                        fontSize: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '16px',
                        width: '100%',
                        marginBottom: '13px'
                      }}
                    >
                      {orderdatas[0].order.payment_method_detail.account_name}
                      <br />
                      {orderdatas[0].order.payment_method_detail.account_number +
                        ' ' +
                        orderdatas[0].order.payment_method_detail.bank_name}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className='shadow-card rounded-card m-2 pb-1 bg-white'>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='mb-0 fw-bold' style={{ marginLeft: '10px', marginTop: '5px' }}>
                  {t('order_detail')}
                </p>
              </div>
              {orderdatas[0].order_items.length > 0
                ? orderdatas[0].order_items.map((orders, index) => (
                    <div className='m-1' key={index}>
                      <div className='d-flex justify-content-start' style={{ marginLeft: '10px' }}>
                        <img
                          src={
                            orders.product.media
                              ? process.env.REACT_APP_FILE_BASE_URL + '/' + orders.product.media[0]
                              : '/assets/images/dummy.png'
                          }
                          alt='item'
                          style={{
                            width: '80px',
                            height: '80px',
                            position: 'relative'
                          }}
                        />

                        {isDiscountValid(orders) ? (
                          <div
                            style={{
                              background: 'red',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: '#fff',
                              fontSize: '12px',
                              width: '30px',
                              height: '20px',
                              borderRadius: '3px',
                              marginTop: '2px',
                              marginBottom: '5px',
                              cursor: 'pointer',
                              userSelect: 'none',
                              marginLeft: '47px',
                              position: 'absolute'
                            }}
                          >
                            {discountPercentageOrderItem(orders)}%
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='mx-2'>
                          <div
                            style={{
                              maxHeight: '43px',
                              overflow: 'hidden',
                              fontSize: '13px',
                              fontWeight: 'normal'
                            }}
                          >
                            {orders.product.name.length > 70
                              ? orders.product.name.slice(0, 70).toUpperCase() + '...'
                              : orders.product.name.toUpperCase()}
                          </div>
                          {orders.product_variant &&
                          orders.product_variant.attributes &&
                          orders.product_variant.attributes.length > 0 ? (
                            <span
                              className='item-variant mb-1'
                              style={{
                                fontSize: '13px',
                                fontWeight: 'normal',
                                backgroundColor: '#EFEFEF'
                              }}
                            >
                              &nbsp;
                              {t('variant')} :{' '}
                              {orders.product_variant.attributes[0].value.toUpperCase()}
                              &nbsp;
                            </span>
                          ) : (
                            ''
                          )}

                          <div
                            className=' mb-0 product-option-price'
                            style={{
                              display: 'flex',
                              fontSize: '13px',
                              fontWeight: 'normal'
                            }}
                          >
                            <span style={{ marginRight: '10px' }}>
                              {orders.quantity} PCS ({orders.product.weight} gr)
                            </span>
                            <label>
                              <p style={{ color: ThemeColor }}>
                                {priceFormatLocal(priceAfterDiscountOrderItem(orders))}
                              </p>
                            </label>

                            {isDiscountOrderItem(orders) && (
                              <label style={{ color: ThemeColor, marginLeft: '10px' }}>
                                <s className=''>{priceFormatLocal(orders.price)}</s>
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </div>

            <div className='shadow-card rounded-card mx-2 p-1 bg-white'>
              <table style={{ width: '100%', margin: '0.25rem !important' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('total_items')}</td>
                    <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right'
                        }}
                      >
                        <label style={{ fontSize: '1rem !important' }}>
                          {orderdatas[0].order.item_qty} Item
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style={{ width: '100%', margin: '0.25rem !important' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('total_weight')}</td>
                    <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right'
                        }}
                      >
                        <label style={{ fontSize: '1rem !important' }}>
                          <span>{priceFormatLocal(finalweight).replaceAll('Rp', '')} Gram</span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table style={{ width: '100%', margin: '0.25rem !important' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('total_order')}</td>
                    <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span
                          style={{
                            marginLeft: '56px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <label>Rp.</label>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center'
                          }}
                        >
                          {' '}
                          <label style={{ fontSize: '1rem !important' }}>
                            <span>
                              {priceFormatLocal(
                                orderdatas[0].order.total - orderdatas[0].order.discount_membership
                              ).replaceAll('Rp', '')}
                            </span>
                          </label>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* <table style={{ width: '100%', margin: '0.25rem !important' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('voucher')}</td>
                    <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span
                          style={{
                            marginLeft: '56px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <label>Rp.</label>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center'
                          }}
                        >
                          <label>0</label>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table> */}

              <table style={{ width: '100%', margin: '0.25rem !important' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('shipping_cost')}</td>
                    <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span
                          style={{
                            marginLeft: '56px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <label>Rp</label>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center'
                          }}
                        >
                          <label>
                            <span className='bill-value'>
                              {orderdatas[0].order.shipping_cost
                                ? priceFormatLocal(orderdatas[0].order.shipping_cost).replaceAll(
                                    'Rp',
                                    ''
                                  )
                                : 0}
                            </span>
                          </label>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              {orderdatas[0].order.shipping_tax > 0 && (
                <table style={{ width: '100%', margin: '0.25rem !important' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50%', paddingLeft: '0.25rem' }}>
                        {t('ppn_shipping_tax')}
                      </td>
                      <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '56px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <label>Rp.</label>
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center'
                            }}
                          >
                            <label>
                              {orderdatas[0].order.shipping_tax
                                ? priceFormatLocal(orderdatas[0].order.shipping_tax).replaceAll(
                                    'Rp',
                                    ''
                                  )
                                : 0}
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {orderdatas[0].order.tax > 0 && (
                <table style={{ width: '100%', margin: '0.25rem !important' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('fix_tax')}</td>
                      <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '56px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <label>Rp.</label>
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center'
                            }}
                          >
                            <label>
                              {orderdatas[0].order.tax
                                ? priceFormatLocal(orderdatas[0].order.tax).replaceAll('Rp', '')
                                : 0}
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {orderdatas[0].order.is_moota === true ? (
                <table style={{ width: '100%', margin: '0.25rem !important' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('unique_code')}</td>
                      <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '56px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <label>Rp.</label>
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center'
                            }}
                          >
                            <label>
                              {priceFormatLocal(orderdatas[0].order.unique_payment_code).replaceAll(
                                'Rp',
                                ''
                              )}
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                ''
              )}

              {orderdatas[0].order.global_discount_recap > 0 && (
                <table style={{ width: '100%', margin: '0.25rem !important' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50%', paddingLeft: '0.25rem' }}>
                        {t('global_discount')}
                      </td>
                      <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span
                            style={{
                              marginLeft: '56px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center'
                            }}
                          >
                            <label>Rp.</label>
                          </span>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center'
                            }}
                          >
                            <label>
                              <span className='bill-value'>
                                {priceFormatLocal(
                                  orderdatas[0].order.global_discount_recap
                                ).replaceAll('Rp', '')}
                              </span>
                            </label>
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <table style={{ width: '100%', margin: '0.25rem !important' }}>
                <tbody>
                  <tr>
                    <td style={{ width: '50%', paddingLeft: '0.25rem' }}>{t('grand_total')}</td>
                    <td style={{ width: '50%', paddingRight: '0.25rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span
                          style={{
                            marginLeft: '56px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                          }}
                        >
                          <label>Rp.</label>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center'
                          }}
                        >
                          <label>
                            <span className='bill-value'>
                              {priceFormatLocal(orderdatas[0].order.grand_total).replaceAll(
                                'Rp',
                                ''
                              )}
                            </span>
                          </label>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='mx-2 mb-2' style={{ margin: '10px' }}>
              <button
                className='p-2 rounded w-100'
                style={{
                  fontWeight: 'bold',
                  border: `1px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`,
                  color: ThemeColor ? ThemeColor : '#FFFFFF',
                  backgroundColor: '#f5f5f5',
                  marginBottom: '10px'
                }}
              >
                {t('message')}
              </button>
              {orderdatas[0].order.order_status === 'UNPAID' ? (
                <button
                  className='p-2 rounded text-light w-100 border-0'
                  style={{
                    fontWeight: 'bold',
                    backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF'
                  }}
                  onClick={() => {
                    setMessage(t('ask_cancel_order'), cancelOrder)
                  }}
                >
                  {t('cancel_order')}
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </ThemeProvider>
      </React.Fragment>
      {open ? (
        <React.Fragment>
          <Dialog
            open={open}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent>
              <DialogContentText
                id='alert-dialog-description'
                style={{
                  color: 'black',
                  fontWeight: '500',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '12px 24px'
                }}
              >
                <div style={{ marginRight: '14px' }}>
                  <ClipLoader
                    color={ThemeColor ? ThemeColor : '#FFFFFF'}
                    height={50}
                    style={{
                      paddingLeft: '1px',
                      marginTop: '8px',
                      fontSize: '4'
                    }}
                  />{' '}
                </div>
                <div>Loading ...</div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default withNamespaces()(OrderDetails)

// <div className="text-center" style={{
//   margin: "10px"
// }}>
//   <div style={{
//     display: "flex",
//     justifyContent: "flex-start",
//     fontSize: "16px"
//   }}>
//     {t("optional_message_here")}
//   </div>
//   <div style={{
//     display: "flex",
//     justifyContent: "flex-start",
//     fontSize: "14px"
//   }}>
//     {orders.note == 0 ? "" : orders.note}
//   </div>
//   <div style={{
//     borderBottom: "1px solid grey",
//     margin: "2px 0px"
//   }}>
//   </div>
//   {/* <CssTextField
// id="standard-basic"
// label={t("optional_message_here")}
// // variant="standard"
// value={orders.note}
// style={{
// width: "95%",
// borderWidth: "0 0 1px",
// borderColor: "#8b8b8b",
// fontSize: "0.75rem",
// }}
// /> */}
// </div>
