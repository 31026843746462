import React, { useState, useEffect } from 'react'
// import { useDispatch } from "react-redux";
// import { getAddressUser } from "../../store/actions/GetAddressActions";
// import { deleteAddressUser } from "../../store/actions/GetAddressActions";
// import { setDefaultAddressUser } from "../../store/actions/GetAddressActions";
// import { GetAddressResponse } from "../../store/Selectors/getaddress";
// import { DeleteAddressResponse } from "../../store/Selectors/getaddress";
// import { SetDefaultAddressResponse } from "../../store/Selectors/getaddress";
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ClipLoader from 'react-spinners/ClipLoader'
import { withNamespaces } from 'react-i18next'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {
  getDetail as getAddressDetail,
  deleteData as deleteAddressData,
  update as updateAddressData
} from '../../api/address'
import { useStore } from '../../hooks/useStore'
import { FormControlLabel, Radio } from '@mui/material'

function SelectAddress({ t }) {
  const { storeTitle } = useStore()

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  // const dispatch = useDispatch();
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [addressid, setAddressId] = useState(false)
  const [addressDatas, setAddressDatas] = useState([])
  // const data1 = GetAddressResponse();
  const sitetitle = storeTitle || process.env.REACT_APP_NICK_SITE_TITLE

  const getAddressData = async () => {
    const userID = JSON.parse(localStorage.getItem('userid'))
    try {
      const response = await getAddressDetail(`{"customer_id":${userID}}`)
      setAddressDatas(response)

      if (response.length == 1 && response[0].customer_address.is_primary == 0) {
        handleSetPrimaryAddress(response[0].customer_address.id, true)
      }
    } catch (error) {
      console.log('Error fetching getAddress:', error)
    }
  }

  useEffect(() => {
    // getData();
    // setTimeout(() => {
    //   setOpen(true);
    //   setTrue1(true);
    // }, 500);
    getAddressData()
  }, [])

  const history = useHistory()
  const [from, setFrom] = useState(null)

  useEffect(() => {
    const from = new URLSearchParams(history.location.search).get('from')

    setFrom(from)
  }, [])

  const selectedAddress = localStorage.getItem('address')
    ? JSON.parse(localStorage.getItem('address'))
    : null

  const handleSetPrimaryAddress = (address_id, is_primary) => {
    updateAddressData({ address_id: address_id, is_primary: is_primary }).then(response =>
      getAddressData()
    )
    // setOpen2(true);
    // let data = new FormData();
    // data.append('address_id', e);
    // dispatch(setDefaultAddressUser(data));
    // const timer = setTimeout(() => {
    //   getData();
    //   setOpen2(false)
    //   setOpen4(true)
    // }, 500)
    // return () => clearTimeout(timer);
  }
  const deleteAddress = e => {
    setAddressId(e)
    setOpen1(true)
  }
  const handleclose1 = () => {
    setOpen3(false)
    setOpen2(false)
  }

  const handleclose2 = () => {
    setOpen4(false)
  }

  const handleclose = async e => {
    if (e == 'yes') {
      // let data = new FormData();
      // data.append('address_id', addressid);
      // dispatch(deleteAddressUser(data));
      const data = {
        address_id: addressid
      }
      const response = await deleteAddressData(data)
      if (response.success) {
        setOpen1(false)
        setOpen2(true)
        // const timer = setTimeout(() => {
        //   getData();
        //   setOpen2(false)
        //   setOpen3(true);
        // }, 300)
        // return () => clearTimeout(timer);
        const userID = JSON.parse(localStorage.getItem('userid'))
        try {
          const response = await getAddressDetail(`{"customer_id":${userID}}`)
          setAddressDatas(response)
          setOpen2(false)
          setOpen3(true)
        } catch (error) {
          console.log('Error fetching getAddress:', error)
        }
      }
    } else {
      setOpen1(false)
    }
  }
  // const deleted = DeleteAddressResponse();
  // const setdefault = SetDefaultAddressResponse();

  const handleClickEditAddress = addrs => {
    localStorage.setItem(
      'editaddress',
      JSON.stringify({
        address: addrs.customer_address.address,
        as_default: addrs.customer_address.is_primary,
        city_id: addrs.district.id,
        city_name: addrs.district.name,
        created_date: addrs.created_at,
        district_id: addrs.sub_district.id,
        district_name: addrs.sub_district.name,
        id: addrs.customer_address.id,
        name: addrs.customer_address.name,
        phone: addrs.customer_address.phone,
        postal_code: addrs.customer_address.postal_code,
        province_id: addrs.province.id,
        province_name: addrs.province.name,
        updated_date: addrs.customer_address.updated_at,
        user_id: addrs.customer_address.customer_id
      })
    )
    history.push(`/addressform?id=${addrs.customer_address.id}`)
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100wh',
            backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF',
            position: 'fixed',
            zIndex: '11111',
            marginBottom: '40px',
            userSelect: 'none'
          }}
        >
          <div
            className='title-box'
            style={{
              height: '48px',
              maxWidth: '100wh',
              display: 'flex',
              justifyContent: 'space-between',
              userSelect: 'none'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                userSelect: 'none'
              }}
            >
              <KeyboardBackspaceIcon
                style={{ color: 'white', width: '25px', userSelect: 'none' }}
                onClick={() => {
                  history.goBack()
                }}
              />
              <h1
                className='title text-uppercase mb-0'
                style={{ marginTop: '2px', marginLeft: '10px' }}
              >
                {t('select_address')}
              </h1>
            </div>
            <div
              style={{
                color: 'white'
              }}
            >
              <AddBoxOutlinedIcon
                onClick={() => {
                  history.push('/addressform')
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ paddingTop: '48px', userSelect: 'none' }}>
          {addressDatas.length > 0 ? (
            <div style={{ flexGrow: 1, width: '100%' }}>
              {addressDatas.map((addrs, index) => (
                <div
                  key={index}
                  className='border-0 shadow-card settingsCouponBox rounded'
                  style={{
                    padding: '10px',
                    margin: '10px',
                    pointerEvents: 'auto'
                  }}
                >
                  <div
                    onClick={e => {
                      if (from) {
                        localStorage.setItem('address', JSON.stringify([addrs]))

                        history.push('/' + from)
                      }
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      cursor: from ? 'pointer' : 'unset'
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '500',
                          userSelect: 'none',
                          paddingBottom: '5px'
                        }}
                      >
                        {addrs.customer_address.name}
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '400',
                          userSelect: 'none',
                          paddingBottom: '2px'
                        }}
                      >
                        {addrs.customer_address.address}
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '400',
                          userSelect: 'none',
                          paddingBottom: '2px'
                        }}
                      >
                        {addrs.province.name +
                          ', ' +
                          addrs.district.name +
                          ', ' +
                          addrs.sub_district.name}
                      </div>
                      <div
                        style={{
                          fontSize: '16px',
                          fontWeight: '400',
                          userSelect: 'none',
                          paddingBottom: '2px'
                        }}
                      >
                        {addrs.phone}
                      </div>
                    </div>
                    {from && (
                      <div
                        style={{
                          marginLeft: 'auto'
                        }}
                      >
                        <Radio
                          sx={{
                            '&.MuiButtonBase-root': {
                              padding: 0
                            },
                            '& .MuiSvgIcon-root': {
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }
                          }}
                          name='address'
                          value={addrs.customer_address.id}
                          checked={
                            selectedAddress
                              ? selectedAddress[0]?.customer_address?.id ==
                                addrs.customer_address.id
                              : false
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '-6px',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          '& .MuiButtonBase-root': {
                            padding: 0
                          },
                          '& .MuiFormControlLabel-label': {
                            marginBottom: '0px !important',
                            marginLeft: '-4px !important'
                          }
                        }}
                        control={
                          <Checkbox
                            checked={addrs.customer_address.is_primary === true ? true : false}
                            onClick={() => {
                              if (addrs.customer_address.is_primary !== 1) {
                                handleSetPrimaryAddress(
                                  addrs.customer_address.id,
                                  !addrs.customer_address.is_primary
                                )
                              }
                            }}
                            style={{
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }}
                          />
                        }
                        label={t('default')}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: 'center',
                        textAlign: 'center',
                        flexDirection: 'row',
                        display: 'flex'
                        // right:"0"
                      }}
                    >
                      <Button
                        style={{
                          color: ThemeColor ? ThemeColor : '#FFFFFF'
                        }}
                        onClick={() => {
                          handleClickEditAddress(addrs)
                        }}
                      >
                        {t('edit')}
                      </Button>
                      <Button
                        style={{
                          color: ThemeColor ? ThemeColor : '#FFFFFF'
                        }}
                        onClick={() => {
                          deleteAddress(addrs.customer_address.id)
                        }}
                      >
                        {t('delete1')}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{
                height: '80vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                paddingTop: '250px',
                paddingBottom: '300px',
                flexDirection: 'column',
                userSelect: 'none'
              }}
            >
              <div>Your address is empty</div>
              <Button
                style={{
                  backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF',
                  marginTop: '5px',
                  color: 'white'
                }}
                onClick={() => {
                  history.push('/addressform')
                }}
              >
                ADD NEW ADDRESS
              </Button>
            </div>
          )}
        </div>
      </React.Fragment>

      {open1 ? (
        <div>
          <Dialog
            open={open1}
            onClick={handleclose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='simple-dialog-title'>Delete Address</DialogTitle>
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500',
                marginBottom: '0px',
                marginTop: '-12px'
              }}
            >
              Are you Sure you want to delete?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  textAlign: 'center',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={() => {
                    handleclose('no')
                  }}
                  style={{
                    color: ThemeColor ? ThemeColor : '#FFFFFF',
                    fontWeight: '500',
                    minWidth: '0px'
                  }}
                >
                  No
                </Button>
                <Button
                  onClick={() => {
                    handleclose('yes')
                  }}
                  style={{
                    color: ThemeColor ? ThemeColor : '#FFFFFF',
                    fontWeight: '500',
                    minWidth: '0px'
                  }}
                >
                  Yes
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {open2 ? (
        <div>
          <Dialog
            open={open2}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '12px 24px'
              }}
            >
              <div style={{ marginRight: '14px' }}>
                <ClipLoader
                  color={ThemeColor ? ThemeColor : '#FFFFFF'}
                  height={50}
                  style={{
                    paddingLeft: '1px',
                    marginTop: '8px',
                    fontSize: '4'
                  }}
                />{' '}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {open3 ? (
        <div>
          <Dialog
            open={open3}
            onClick={handleclose1}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='simple-dialog-title'>Delete Address</DialogTitle>
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500',
                marginBottom: '0px',
                marginTop: '-12px'
              }}
            >
              {'delete success'}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  textAlign: 'center',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={handleclose1}
                  style={{
                    color: ThemeColor ? ThemeColor : '#FFFFFF',
                    fontWeight: '500',
                    minWidth: '0px'
                  }}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {open4 ? (
        <div>
          <Dialog
            open={open4}
            onClick={handleclose2}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='simple-dialog-title'>
              <b>{sitetitle}</b>
            </DialogTitle>
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500',
                marginBottom: '0px',
                marginTop: '-12px'
              }}
            >
              {'set default success'}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  textAlign: 'center',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={handleclose2}
                  style={{
                    color: ThemeColor ? ThemeColor : '#FFFFFF',
                    fontWeight: '500',
                    minWidth: '0px'
                  }}
                >
                  Ok
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default withNamespaces()(SelectAddress)
