import { makeStyles } from '@material-ui/core'

import React, { useEffect, useState, memo } from 'react'
import { withNamespaces } from 'react-i18next'
import Carousel from 'react-material-ui-carousel'
import CardProduct from '../../components/card-product/CardProduct'
import SkeletonHome from '../../components/SkeletonHome'

import 'react-multi-carousel/lib/styles.css'
import { useHistory } from 'react-router-dom'
import i18n from '../language/localization'
import { firstLetterUppercase } from '../../utils/helper'

import { useSelector } from 'react-redux'

// eslint-disable-next-line react/display-name
const ProductSlider = memo(({ t, color }) => {
  const productsAllData = useSelector(state => state.allProducts)
  const productsNewestData = useSelector(state => state.newestProducts)
  const productsDiscountData = useSelector(state => state.discountProducts)
  const productsBestSellerData = useSelector(state => state.bestSellerProducts)
  const randomProducts = useSelector(state => state.randomProducts)

  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)
  const useStyles = makeStyles(theme => ({
    title_div_desktop: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '30px',
      clear: 'both',
      marginBottom: '0px',
      userSelect: 'none',
      marginTop: '1rem'
    },
    Title_div: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '30px',
      clear: 'both',
      marginBottom: '0px',
      userSelect: 'none'
      // cursor:"pointer"
    },
    Title: {
      fontSize: '20px',
      fontWeight: 600,
      paddingLeft: '5px',
      // cursor:"pointer",
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: '19px',
        fontWeight: 500,
        paddingLeft: '5px'
      }
    },
    Para: {
      fontSize: '16px',
      paddingRight: '5px',
      color: color,
      cursor: 'pointer',
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px'
      }
    },
    Container1: {
      height: '100%',
      width: '20%',
      display: 'inline-block',
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        width: '40%',
        display: 'inline-block',
        userSelect: 'none'
      },

      '& .card': {
        // boxShadow: "0 0 1px",
        cursor: 'pointer'
        // border: "1px solid #00000038 !important",
      }
      // "& .card:hover": {
      //   border: "1px solid #ff3a59 !important",
      // },
    },
    containerCard: {
      minHeight: '250px'
    },
    desktop_slider_cards: {
      '& .card': {
        border: '1px solid transparent',
        transition: 'all 0.1s ease-out'
      },
      '& .card:hover': {
        border: '1px solid ' + color + ' !important',
        transform: 'translateY(-2px) scale(1.005) translateZ(0)',
        cursor: 'pointer'
      },
      '& .card-body': {
        padding: '5px'
        // minHeight: "80px",
      },
      '& .cardTitle ': {
        // textTransform: 'uppercase'
      }
    },

    mobile_slider_cards: {
      '& .card': {
        border: '1px solid transparent'
      },
      '& .card-body': {
        // minHeight: '117px',
        padding: '5px 2px !important'
      },

      '& .cardTitle ': {
        //  textTransform: 'uppercase',
        fontSize: '13px !important'
      },
      '& .TowLineText ': {
        height: '38px'
      }
    },

    // Name1: {
    //   fontSize: "14px",
    //   fontWeight: 400,
    //   whiteSpace: "break-spaces",
    //   marginBottom: "0.25px",
    //   cursor: "pointer",
    //   overflow: "hidden",
    //   maxHeight: "45px",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //     fontWeight: 400,
    //     marginBottom: "0.25px",
    //     maxHeight: "37px",
    //     // height: "35px"
    //   },
    // },
    // Price1: {
    //   fontSize: "14px",
    //   color: "red",
    //   marginBottom: "0px",
    //   cursor: "pointer",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //   },
    // },
    card_img: {
      position: 'relative',
      // height: "190px",
      width: '100%',
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        // height: "142px",
      }
    },
    div_bottom: {
      marginBottom: '50px',
      marginTop: '20px',
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0px'
      }
    },
    // realprice: {
    //   textDecoration: " line-through",
    //   color: "grey",
    //   fontSize: "14px",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //   },
    // }

    cardTitle: {
      '& .read-more-less--more': {
        display: 'none'
      }
    },

    slider_style: {
      '& .MuiButtonBase-root': {
        // boxShadow: "0 0 1px 0px ",
        padding: '4px',

        '& .MuiSvgIcon-root': {
          margin: '0 !important'
        }
      },
      '& .css-1abc02a:hover button,  .css-hn784z:hover button': {
        boxShadow: '0 0 6px 0px',
        backgroundColor: '#ffffff',
        opacity: '1 !important',
        color: '#000000'
      },

      '& .MuiIconButton-root:hover': {
        // backgroundColor: "#ff3a59",
        backgroundColor: '#ffffff',
        color: '#000000',
        boxShadow: '0 0 1px 0px '
      }
    },

    '@media(max-width: 768px)': {
      mobileTitle: {
        whiteSpace: 'break-spaces !important',
        marginBottom: '3px'
      }
    },

    price_parent: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      overflow: 'hidden'
    }
  }))
  const classes = useStyles()
  const history = useHistory()

  const getSingleProduct = e => {
    history.push(`/product-detail?${e}`)
  }

  const proview = category => {
    localStorage.setItem('viewproid', category.id ? category.id : '')
    localStorage.setItem('viewproname', category.name || category.categoryName)
    localStorage.setItem('viewprochild', JSON.stringify(''))
    localStorage.setItem('viewtabs', JSON.stringify(category.name || category.categoryName))
    history.push('/products')
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [])

  // pagination slider product list
  const [numberNextPage, setNumberNextPage] = useState([])

  const nextPageProductList = (id, productLength) => {
    const perPage = 5
    const startInitial = 5
    const endInitial = 10
    const findIndex = numberNextPage.findIndex(data => data.id === id)
    if (findIndex < 0) {
      setNumberNextPage(numberNextPage => [
        ...numberNextPage,
        {
          id: id,
          active: startInitial,
          next: endInitial
        }
      ])
    } else {
      const activeCalc =
        numberNextPage[findIndex]['next'] >= productLength
          ? 0
          : numberNextPage[findIndex]['active'] + perPage
      const endCalc =
        numberNextPage[findIndex]['next'] >= productLength
          ? 5
          : numberNextPage[findIndex]['next'] + perPage

      let updatePage = [...numberNextPage]
      updatePage[findIndex] = {
        id: id,
        active: activeCalc,
        next: endCalc
      }
      setNumberNextPage(updatePage)
    }
  }

  const prevPageProductList = (id, productLength) => {
    if (numberNextPage.length > 0) {
      const findIndex = numberNextPage.findIndex(data => data.id === id)
      const activeCalc =
        numberNextPage[findIndex]['active'] === 0 ? 0 : numberNextPage[findIndex]['active'] - 5
      const endCalc =
        numberNextPage[findIndex]['active'] === 0 ? 5 : numberNextPage[findIndex]['next'] - 5

      let updatePage = [...numberNextPage]
      updatePage[findIndex] = {
        id: id,
        active: activeCalc,
        next: endCalc
      }
      setNumberNextPage(updatePage)
    }
  }

  return (
    <React.Fragment>
      {/* terbaru */}
      {productsNewestData.loading === 'success' && productsNewestData.newestProducts.length > 0 && (
        <div
          className={`${classes.div_bottom} ${classes.containerCard}`}
          style={{ marginBottom: '0' }}
        >
          <React.Fragment>
            <div className={classes.title_div_desktop}>
              <h3 className={classes.Title}>{firstLetterUppercase(t('new_arrivals'))}</h3>
              <p
                onClick={() => {
                  proview({ name: 'Produk Terbaru' })
                }}
                className={classes.Para}
              >
                {t('see_all')}
              </p>
            </div>
            <div className={classes.slider_style + ' '}>
              <Carousel
                animation='slide'
                navButtonsAlwaysVisible={true}
                indicators={false}
                // autoPlay={dt.product.length >= 5 ? true : false}
                autoPlay={false}
                navButtonsAlwaysInvisible={
                  productsNewestData.newestProducts.length >= 5 ? false : true
                }
                // hardcode , 1 = product terbaru
                next={(next, active) =>
                  nextPageProductList('newest', productsNewestData.newestProducts.length)
                }
                prev={(prev, active) =>
                  prevPageProductList('newest', productsNewestData.newestProducts.length)
                }
                stopAutoPlayOnHover={true}
                key={'newest'}
              >
                {productsNewestData.newestProducts.map((_dp, index) => {
                  const findIndex = numberNextPage.findIndex(data => data.id === 'newest')
                  return (
                    <div style={{ display: 'flex', gap: '10px' }} key={index}>
                      {productsNewestData.newestProducts
                        .slice(
                          findIndex < 0 ? 0 : numberNextPage[findIndex].active,
                          findIndex < 0 ? 5 : numberNextPage[findIndex].next
                        )
                        .map((pr, index) => {
                          return (
                            <CardProduct
                              key={index}
                              membershipLevel={membershipLevel}
                              product={pr}
                              handleClick={getSingleProduct}
                            />
                          )
                        })}
                    </div>
                  )
                })}
              </Carousel>
            </div>
          </React.Fragment>
        </div>
      )}
      {productsNewestData.loading === 'loading' && (
        <div
          className={classes.containerCard}
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          {[1, 2, 3, 4, 5].map(row => (
            <SkeletonHome key={row} />
          ))}
        </div>
      )}

      {/* diskon */}
      {console.log(productsDiscountData.discountProducts)}
      {productsDiscountData.loading === 'success' &&
        productsDiscountData.discountProducts.length > 0 && (
          <div
            className={`${classes.div_bottom} ${classes.containerCard}`}
            style={{ marginBottom: '0' }}
          >
            <React.Fragment>
              <div className={classes.title_div_desktop}>
                <h3 className={classes.Title}>{firstLetterUppercase(t('product_discount'))}</h3>
                <p
                  onClick={() => {
                    proview({ name: 'Discount Product' })
                  }}
                  className={classes.Para}
                >
                  {t('see_all')}
                </p>
              </div>
              <div className={classes.slider_style + ' '}>
                <Carousel
                  animation='slide'
                  navButtonsAlwaysVisible={true}
                  indicators={false}
                  // autoPlay={dt.product.length >= 5 ? true : false}
                  autoPlay={false}
                  navButtonsAlwaysInvisible={
                    productsDiscountData.discountProducts.length >= 5 ? false : true
                  }
                  // hardcode , 1 = product terbaru
                  next={(next, active) =>
                    nextPageProductList('discount', productsDiscountData.discountProducts.length)
                  }
                  prev={(prev, active) =>
                    prevPageProductList('discount', productsDiscountData.discountProducts.length)
                  }
                  stopAutoPlayOnHover={true}
                  key={'discount'}
                >
                  {productsDiscountData.discountProducts.map((_dp, index) => {
                    const findIndex = numberNextPage.findIndex(data => data.id === 'discount')
                    return (
                      <div style={{ display: 'flex', gap: '10px' }} key={index}>
                        {productsDiscountData.discountProducts
                          .slice(
                            findIndex < 0 ? 0 : numberNextPage[findIndex].active,
                            findIndex < 0 ? 5 : numberNextPage[findIndex].next
                          )
                          .map((pr, index) => {
                            return (
                              <CardProduct
                                key={index}
                                membershipLevel={membershipLevel}
                                product={pr}
                                handleClick={getSingleProduct}
                              />
                            )
                          })}
                      </div>
                    )
                  })}
                </Carousel>
              </div>
            </React.Fragment>
          </div>
        )}
      {productsDiscountData.loading === 'loading' && (
        <div
          className={classes.containerCard}
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          {[1, 2, 3, 4, 5].map(row => (
            <SkeletonHome key={row} />
          ))}
        </div>
      )}

      {/* terlaris */}
      {productsBestSellerData.loading === 'success' &&
        productsBestSellerData.bestSellerProducts.length > 0 && (
          <div
            className={`${classes.div_bottom} ${classes.containerCard}`}
            style={{ marginBottom: '0' }}
          >
            <React.Fragment>
              <div className={classes.title_div_desktop}>
                <h3 className={classes.Title}>{firstLetterUppercase(t('best_seller2'))}</h3>
                <p
                  onClick={() => {
                    proview({ name: 'Best Seller' })
                  }}
                  className={classes.Para}
                >
                  {t('see_all')}
                </p>
              </div>
              <div className={classes.slider_style + ' '}>
                <Carousel
                  animation='slide'
                  navButtonsAlwaysVisible={true}
                  indicators={false}
                  // autoPlay={dt.product.length >= 5 ? true : false}
                  autoPlay={false}
                  navButtonsAlwaysInvisible={
                    productsBestSellerData.bestSellerProducts.length >= 5 ? false : true
                  }
                  // hardcode , 1 = product terbaru
                  next={(next, active) =>
                    nextPageProductList(
                      'best_seller',
                      productsBestSellerData.bestSellerProducts.length
                    )
                  }
                  prev={(prev, active) =>
                    prevPageProductList(
                      'best_seller',
                      productsBestSellerData.bestSellerProducts.length
                    )
                  }
                  stopAutoPlayOnHover={true}
                  key={'best_seller'}
                >
                  {productsBestSellerData.bestSellerProducts.map((_dp, index) => {
                    const findIndex = numberNextPage.findIndex(data => data.id === 'best_seller')
                    return (
                      <div style={{ display: 'flex', gap: '10px' }} key={index}>
                        {productsBestSellerData.bestSellerProducts
                          .slice(
                            findIndex < 0 ? 0 : numberNextPage[findIndex].active,
                            findIndex < 0 ? 5 : numberNextPage[findIndex].next
                          )
                          .map((pr, index) => {
                            return (
                              <CardProduct
                                key={index}
                                membershipLevel={membershipLevel}
                                product={pr}
                                handleClick={getSingleProduct}
                              />
                            )
                          })}
                      </div>
                    )
                  })}
                </Carousel>
              </div>
            </React.Fragment>
          </div>
        )}
      {productsBestSellerData.loading === 'loading' && (
        <div
          className={classes.containerCard}
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          {[1, 2, 3, 4, 5].map(row => (
            <SkeletonHome key={row} />
          ))}
        </div>
      )}

      {/* per category */}
      {randomProducts.loading === 'success' && randomProducts.randomProducts.length > 0 && (
        <div
          className={`${classes.div_bottom} ${classes.containerCard}`}
          style={{ marginBottom: '0' }}
        >
          <React.Fragment>
            {randomProducts.randomProducts.map((dt, index) => {
              return (
                <React.Fragment key={index}>
                  {dt.products && dt.products.length > 0 ? (
                    <React.Fragment>
                      <div className={classes.title_div_desktop}>
                        <h3 className={classes.Title}>{firstLetterUppercase(dt.categoryName)}</h3>
                        <p
                          onClick={() => {
                            proview(dt)
                          }}
                          className={classes.Para}
                        >
                          {t('see_all')}
                        </p>
                      </div>
                      <div className={classes.slider_style + ' '}>
                        <Carousel
                          animation='slide'
                          navButtonsAlwaysVisible={true}
                          indicators={false}
                          // autoPlay={dt.products.length >= 5 ? true : false}
                          autoPlay={false}
                          navButtonsAlwaysInvisible={dt.products.length >= 5 ? false : true}
                          next={(next, active) => nextPageProductList(dt.id, dt.products.length)}
                          prev={(prev, active) => prevPageProductList(dt.id, dt.products.length)}
                          stopAutoPlayOnHover={true}
                          key={index}
                        >
                          {dt.products.map((_dp, index) => {
                            const findIndex = numberNextPage.findIndex(data => data.id === dt.id)
                            return (
                              <div style={{ display: 'flex', gap: '10px' }} key={index}>
                                {dt.products
                                  .slice(
                                    findIndex < 0 ? 0 : numberNextPage[findIndex].active,
                                    findIndex < 0 ? 5 : numberNextPage[findIndex].next
                                  )
                                  .map((pr, index) => {
                                    return (
                                      <CardProduct
                                        key={index}
                                        membershipLevel={membershipLevel}
                                        product={pr}
                                        handleClick={getSingleProduct}
                                      />
                                    )
                                  })}
                              </div>
                            )
                          })}
                        </Carousel>
                      </div>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )
            })}
          </React.Fragment>
        </div>
      )}
      {randomProducts.loading === 'loading' && (
        <div
          className={classes.containerCard}
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          {[1, 2, 3, 4, 5].map(row => (
            <SkeletonHome key={row} />
          ))}
        </div>
      )}

      {/* all product */}
      {productsAllData.loading === 'success' && productsAllData.allProducts.length > 0 && (
        <div
          className={`${classes.div_bottom} ${classes.containerCard}`}
          style={{ marginBottom: '80px' }}
        >
          <React.Fragment>
            <div className={classes.title_div_desktop}>
              <h3 className={classes.Title}>{t('all_products')}</h3>
              <p
                onClick={() => {
                  proview({ name: 'Semua Produk' })
                }}
                className={classes.Para}
              >
                {t('see_all')}
              </p>
            </div>
            <div className={classes.slider_style + ' '}>
              <Carousel
                animation='slide'
                navButtonsAlwaysVisible={true}
                indicators={false}
                // autoPlay={dt.product.length >= 5 ? true : false}
                autoPlay={false}
                navButtonsAlwaysInvisible={productsAllData.allProducts.length >= 5 ? false : true}
                // hardcode , 1 = product terbaru
                next={(next, active) =>
                  nextPageProductList('all_products', productsAllData.allProducts.length)
                }
                prev={(prev, active) =>
                  prevPageProductList('all_products', productsAllData.allProducts.length)
                }
                stopAutoPlayOnHover={true}
                key={'all_products'}
              >
                {productsAllData.allProducts.map((_dp, index) => {
                  const findIndex = numberNextPage.findIndex(data => data.id === 'all_products')
                  return (
                    <div style={{ display: 'flex', gap: '10px' }} key={index}>
                      {productsAllData.allProducts
                        .slice(
                          findIndex < 0 ? 0 : numberNextPage[findIndex].active,
                          findIndex < 0 ? 5 : numberNextPage[findIndex].next
                        )
                        .map((pr, index) => {
                          return (
                            <CardProduct
                              key={index}
                              membershipLevel={membershipLevel}
                              product={pr}
                              handleClick={getSingleProduct}
                            />
                          )
                        })}
                    </div>
                  )
                })}
              </Carousel>
            </div>
          </React.Fragment>
        </div>
      )}
      {productsAllData.loading === 'loading' && (
        <div
          className={classes.containerCard}
          style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
        >
          {[1, 2, 3, 4, 5].map(row => (
            <SkeletonHome key={row} />
          ))}
        </div>
      )}
    </React.Fragment>
  )
})

export default withNamespaces()(ProductSlider)
