import React from 'react'
import Header from '../header/header'

const ComingSoonMobile = () => {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  return (
    <React.Fragment>
      <Header title='Coming Soon' color={ThemeColor} />
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'white'
        }}
      >
        <h4>Coming Soon</h4>
      </div>
    </React.Fragment>
  )
}

export default ComingSoonMobile
