import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

const resources = {
  en: {
    translation: require('./en.json')
  },
  id: {
    translation: require('./id.json')
  }
}

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    resources,
    fallbackLng: 'en',

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
