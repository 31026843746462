import { instance, instance2 } from '../helper/api'

export const getProducts = async param => {
  let resultData = []
  const axiosConfig = {
    url: '/shopi/v1/product/',
    method: 'GET',
    params: JSON.parse(param)
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const get = async param => {
  let resultData = []
  const axiosConfig = {
    url: '/shopi/v1/product/detail/',
    method: 'GET',
    params: JSON.parse(param)
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getByClient = async param => {
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, {
      limit: paramValue.limit ? paramValue.limit : 10,
      status: paramValue.status ? paramValue.status : 'live'
    })
    paramValue = Object.assign(paramValue, {
      page: paramValue.page ? paramValue.page : 1
    })
  }

  let resultData = []
  const axiosConfig = {
    url: '/shopi/v1/product/detail/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getDetail = async productID => {
  let resultData = []
  const axiosConfig = {
    url: `/shopi/v1/product/${productID}/detail/`,
    method: 'GET'
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getListLastViewProduct = async param => {
  let resultData = []
  const axiosConfig = {
    url: '/shopi/v1/customer/last-view/product/',
    method: 'GET',
    params: JSON.parse(param)
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getCountLastViewProduct = async () => {
  let resultData = []
  const axiosConfig = {
    url: '/shopi/v1/customer/last-view/product/count/',
    method: 'GET'
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
