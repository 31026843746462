import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import BottNavigation from '../home/bottomnav/NavigationTab'
import Loader from '../loader'
import Searchbar from '../home/Searchbar'
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'

const Notification = ({ t }) => {
  const { token } = useAuth()

  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const [open, setOpen] = useState(false)
  const [TotalCartItems, setItems] = useState(0)

  useEffect(() => {
    setOpen(true)
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))

    if (token) {
      const getBadgeCartData = async () => {
        try {
          const response = await getBadgeCart()
          if (response.success) {
            setItems(response.data)
          }
        } catch (error) {
          console.log('Error fetching getBadgeCartData:', error)
        }
      }
      getBadgeCartData()
    }
  }, [])

  return (
    <React.Fragment>
      {open ? (
        <React.Fragment>
          <Searchbar color={ThemeColor} title={t('notification')} cartCount={TotalCartItems} />

          {/* <Header title={t("notification")} color={ThemeColor} /> */}
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                paddingTop: '250px',
                paddingBottom: '300px',
                height: '100vh',
                backgroundColor: 'white'
              }}
            >
              <h4>Coming Soon...</h4>
            </div>
          </div>
          <BottNavigation />
        </React.Fragment>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: 'white'
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
    </React.Fragment>
  )
}

export default withNamespaces()(Notification)
