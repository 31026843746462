import { makeStyles } from '@material-ui/core'

const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))

export const useStyles1 = makeStyles(theme => ({
  textfont: {
    fontSize: '14px',
    fontWeight: '400',
    // marginBottom: "0.25px",
    whiteSpace: 'break-spaces',
    // maxHeight: "45px",
    // height: '2lh',
    overflow: 'hidden',
    cursor: 'pointer',
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px'
      // minHeight: "37px",
      // maxHeight: "36px",
      // height: '2lh',
    },
    color: 'inherit !important',
    marginBottom: '0px !important'
  },
  pricediv: {
    fontSize: '14px',
    color: ThemeColor,
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px'
    }
  },
  cutdiv: {
    fontSize: '14px',
    textDecoration: 'line-through',
    color: 'grey',
    userSelect: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px'
    }
  }
}))
