export const showPricing = (prices, membershipLevel) => {
  return prices[membershipLevel] || prices[1]
}

export const isDiscountValid = (discount, discountStartDate, discountEndDate) => {
  const currentDate = new Date()

  if (discount && discount > 0) {
    if (!discountStartDate && !discountEndDate) {
      return true // => discount is true
    } else if (
      new Date(discountStartDate) < currentDate &&
      new Date(discountEndDate) > currentDate
    ) {
      return true // => discount is true
    } else {
      return false
    }
  }

  return false
}

export const isDiscountOrderItem = orderItem => {
  const discount_item = orderItem.discount_per_item
  const discount_membership = orderItem.discount_membership

  if ((discount_item && discount_item > 0) || (discount_membership && discount_membership > 0)) {
    return true
  }
}

export const priceBeforeDiscountOrderItem = orderItem => {
  return orderItem.price
}

export const priceAfterDiscountOrderItem = orderItem => {
  let price = orderItem.price
  const discount_item = orderItem.discount_per_item
  const discount_membership = orderItem.discount_membership

  if (discount_item && discount_item > 0) {
    price -= discount_item
  } else if (discount_membership && discount_membership > 0) {
    price -= discount_membership
  }

  return price
}

export const discountPercentageOrderItem = orderItem => {
  const price = orderItem.price
  const priceAfterDiscount = priceAfterDiscountOrderItem(orderItem)

  return ((price - priceAfterDiscount) / price) * 100
}
