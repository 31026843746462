import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MailIcon from '@material-ui/icons/Mail'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import Store from '@material-ui/icons/Store'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import Youtube from '@material-ui/icons/YouTube'
import Web from '@material-ui/icons/Public'
import { FaTiktok } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { SiShopee } from 'react-icons/si'
import { SiBukalapak } from 'react-icons/si'
import { IoBagHandle } from 'react-icons/io5'
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { useDispatch } from "react-redux";
import i18n from '../language/localization'
import { withNamespaces } from 'react-i18next'
import { useStore } from '../../hooks/useStore'
import { ReactComponent as Tokopedia } from './Asset/images/brand/tokopedia.svg'
import { ReactComponent as Lazada } from './Asset/images/brand/lazada.svg'
import { ReactComponent as Olx } from './Asset/images/brand/olx.svg'
import { Container } from '@mui/material'

const DeskTopFooter = ({ t }) => {
  const { store } = useStore()

  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const useStyles = makeStyles(theme => ({
    Root: {
      height: 'auto',
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      paddingTop: '2rem',
      flexDirection: 'column',
      rowGap: '30px'
    },
    CopyRightDiv: {
      color: 'white',
      width: '100%',
      background: themeColor,
      borderTop: '1px solid white',
      textAlign: 'center',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem'
    },
    FooterMenu: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 7
    },
    FooterList: {
      display: 'flex',
      width: '100%',
      margin: '0px auto',
      columnGap: '72px'
    },
    FooterMenuIcon: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232'
    },
    FooterMenuImage: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 4
    },
    FooterMenuSpan: {
      width: '180px',
      fontSize: 16,
      '&:hover': {
        //   borderBottom: "1px solid white",
        cursol: 'pointer'
      }
    },
    footerMenuIcone: {
      display: 'flex',
      columnGap: 2,
      alignItems: 'center',
      width: '100%',
      fontSize: 16,
      '&>svg': {
        marginLeft: '0px !important',
        height: '24px',
        width: '24px'
      },
      '&:hover': {
        borderBottom: '1px solid white',
        cursol: 'pointer'
      }
    },

    aLink_style: {
      color: 'inherit !important'
    }
  }))
  const classes = useStyles()

  // Get Footer Data
  // const dispatch = useDispatch();
  useEffect(() => {
    let data = new FormData()
    // dispatch(getSplashDataUser(data));
  }, [])

  const landingPageValue = JSON.parse(localStorage.getItem('landing_page'))
  const footer = landingPageValue

  const DataHelp = [{ slug: 'How to buy', title: 'How to buy' }]
  const DataMarketPlace = [
    ...(store?.online_store?.mp_shopee
      ? [
          {
            icon: <SiShopee size={22} />,
            name: 'Shopee',
            aLink: store?.online_store?.mp_shopee
          }
        ]
      : []),
    ...(store?.online_store?.mp_tokopedia
      ? [
          {
            icon: <Tokopedia />,
            name: 'Tokopedia',
            aLink: store?.online_store?.mp_tokopedia
          }
        ]
      : []),
    ...(store?.online_store?.mp_olx
      ? [
          {
            icon: <Olx />,
            name: 'Olx',
            aLink: store?.online_store?.mp_olx
          }
        ]
      : []),
    ...(store?.online_store?.mp_blibli
      ? [
          {
            icon: <IoBagHandle size={22} />,
            name: 'Blibli',
            aLink: store?.online_store?.mp_blibli
          }
        ]
      : []),
    ...(store?.online_store?.mp_bukalapak
      ? [
          {
            icon: <SiBukalapak size={22} />,
            name: 'Bukalapak',
            aLink: store?.online_store?.mp_bukalapak
          }
        ]
      : []),
    ...(store?.online_store?.mp_lazada
      ? [
          {
            icon: <Lazada />,
            name: 'Lazada',
            aLink: store?.online_store?.mp_lazada
          }
        ]
      : []),
    ...(store?.online_store?.mp_other
      ? [
          {
            icon: <Web />,
            name: 'Other',
            aLink: store?.online_store?.mp_other
          }
        ]
      : [])
  ]
  const DataCustomer = [
    // {
    //   icon: <CallIcon />,
    //   // name: "+6281219082008",
    //   name: footer && footer.setting_customer ? footer.setting_customer.phone_services : null
    // },
    ...(store?.online_store?.email
      ? [
          {
            icon: <MailIcon />,
            name: store?.online_store?.email,
            aLink: `mailto:${store?.online_store?.email}`
          }
        ]
      : []),
    ...(store?.online_store?.wa1
      ? [
          {
            icon: <WhatsAppIcon />,
            name: store?.online_store?.wa1,
            aLink: `https://wa.me/${store?.online_store?.wa1}`
          }
        ]
      : []),
    ...(store?.online_store?.wa2
      ? [
          {
            icon: <WhatsAppIcon />,
            name: store?.online_store?.wa2,
            aLink: `https://wa.me/${store?.online_store?.wa2}`
          }
        ]
      : []),
    ...(store?.online_store?.wa3
      ? [
          {
            icon: <WhatsAppIcon />,
            name: store?.online_store?.wa3,
            aLink: `https://wa.me/${store?.online_store?.wa3}`
          }
        ]
      : []),
    ...(store?.online_store?.address
      ? [
          {
            icon: <Store />,
            name: store?.online_store?.address,
            aLink: `https://www.google.com/maps/search/?api=1&query=${store?.online_store?.address}`
          }
        ]
      : [])

    // {
    //   icon: <EventIcon />,
    //   name: footer ? footer.working_day : null
    //   // name: "Monday - Saturday (Sunday OFF)",
    // },
    // {
    //   icon: <WatchLaterIcon />,
    //   // name: "09.00 - 18.30",
    //   name: footer ? footer.working_hours : null
    // }
  ]
  const DataFollow = [
    ...(store?.online_store?.socmed_fb
      ? [
          {
            icon: <FacebookIcon />,
            name: 'Facebook',
            aLink: store?.online_store?.socmed_fb
          }
        ]
      : []),
    ...(store?.online_store?.socmed_ig
      ? [
          {
            icon: <InstagramIcon />,
            name: 'Instagram',
            aLink: store?.online_store?.socmed_ig
          }
        ]
      : []),
    ...(store?.online_store?.socmed_x
      ? [
          {
            icon: <FaXTwitter size={18} />,
            name: 'X Twitter',
            aLink: store?.online_store?.socmed_x
          }
        ]
      : []),
    ...(store?.online_store?.socmed_tt
      ? [
          {
            icon: <FaTiktok size={18} />,
            name: 'Tiktok',
            aLink: store?.online_store?.socmed_tt
          }
        ]
      : []),
    ...(store?.online_store?.socmed_yt
      ? [
          {
            icon: <Youtube />,
            name: 'Youtube',
            aLink: store?.online_store?.socmed_yt
          }
        ]
      : []),
    ...(store?.online_store?.socmed_other
      ? [
          {
            icon: <Web />,
            name: 'Website',
            aLink: store?.online_store?.socmed_other
          }
        ]
      : [])
  ]
  const DataApp = [
    ...(store?.online_store?.url_playstore
      ? [
          {
            img: '/assets/images/googleplay.png',
            aLink: store?.online_store?.url_playstore
          }
        ]
      : []),
    ...(store?.online_store?.url_appstore
      ? [
          {
            img: '/assets/images/appstore.png',
            aLink: store?.online_store?.url_appstore
          }
        ]
      : [])
  ]

  const DataSstem = [
    {
      img: '/assets/images/norton.png'
    },
    {
      img: '/assets/images/comodo.png'
    },
    {
      img: '/assets/images/pci.png'
    }
  ]

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <div className={classes.Root}>
      <Container>
        <div className={classes.FooterList}>
          <FooterMenu
            title={store?.online_store?.title}
            data={[{ title: store?.online_store?.slogan, slug: null }]}
          />
          {/* <FooterMenu title={t('help')} data={DataHelp} /> */}
          <FooterMenuWithIcon title={t('market_place')} data={DataMarketPlace} />
          <FooterMenuWithIcon title={t('customer_care')} data={DataCustomer} />
          <FooterMenuWithIcon title={t('follow_us')} data={DataFollow} />
          {DataApp.length !== 0 && (
            <FooterMenuWithImage title={t('download_our_app')} data={DataApp} />
          )}
          {/* <FooterMenuWithImage title="Security System" data={DataSstem} /> */}
        </div>
      </Container>
      <div className={classes.CopyRightDiv}>Copyright @2024 All Right Reserved</div>
    </div>
  )
}
export default withNamespaces()(DeskTopFooter)
const FooterMenu = ({ title, data }) => {
  const useStyles = makeStyles(theme => ({
    Root: {
      height: 'auto',
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      paddingTop: '2rem',
      flexDirection: 'column',
      rowGap: '30px'
    },
    CopyRightDiv: {
      color: 'white',
      borderTop: '1px solid white',
      width: '100%',
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    FooterMenu: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 7
    },
    FooterList: {
      display: 'flex',
      width: '100%',
      margin: '0px auto',
      columnGap: '72px'
    },
    FooterMenuIcon: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232'
    },
    FooterMenuImage: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 4
    },
    FooterMenuSpan: {
      width: '180px',
      fontSize: 16,
      '&:hover': {
        //   borderBottom: "1px solid white",
        cursol: 'pointer'
      }
    },
    footerMenuIcone: {
      display: 'flex',
      columnGap: 2,
      alignItems: 'center',
      width: '100%',
      fontSize: 16,
      '&>svg': {
        marginLeft: '0px !important',
        height: '24px',
        width: '24px'
      },
      '&:hover': {
        borderBottom: '1px solid white',
        cursol: 'pointer'
      }
    },

    aLink_style: {
      color: 'inherit !important'
    }
  }))
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <h5 style={{ color: '#323232' }}>{title}</h5>
      <div className={classes.FooterMenu}>
        {data
          ? data.map((dt, index) => {
              return (
                <a
                  key={index}
                  {...(dt.slug ? { href: `/${dt.slug}` } : {})}
                  target='_blank'
                  className={classes.aLink_style}
                  rel='noreferrer'
                >
                  <span className={classes.FooterMenuSpan}>{dt.title}</span>
                </a>
              )
            })
          : null}
      </div>
    </div>
  )
}
const FooterMenuWithIcon = ({ title, data }) => {
  const useStyles = makeStyles(theme => ({
    Root: {
      height: 'auto',
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      paddingTop: '2rem',
      flexDirection: 'column',
      rowGap: '30px'
    },
    CopyRightDiv: {
      color: 'white',
      borderTop: '1px solid white',
      width: '100%',
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    FooterMenu: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 7
    },
    FooterList: {
      display: 'flex',
      width: '100%',
      margin: '0px auto',
      columnGap: '72px'
    },
    FooterMenuIcon: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232'
    },
    FooterMenuImage: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 4
    },
    FooterMenuSpan: {
      width: '180px',
      fontSize: 16,
      '&:hover': {
        //   borderBottom: "1px solid white",
        cursol: 'pointer'
      }
    },
    footerMenuIcone: {
      display: 'flex',
      columnGap: 2,
      alignItems: 'center',
      width: '100%',
      fontSize: 16,
      '&>svg': {
        marginLeft: '0px !important',
        height: '24px',
        width: '24px'
      },
      '&:hover': {
        borderBottom: '1px solid white',
        cursol: 'pointer'
      }
    },

    aLink_style: {
      color: 'inherit !important'
    }
  }))
  const classes = useStyles()
  return (
    <div style={{ width: '100%' }}>
      <h5 style={{ color: '#323232' }}>{title}</h5>
      <div className={classes.FooterMenuIcon}>
        {data.map((dt, index) => {
          return (
            <a
              href={dt.aLink}
              target='_blank'
              className={classes.aLink_style}
              rel='noreferrer'
              key={index}
            >
              <div className={classes.footerMenuIcone}>
                {dt.icon}
                <span>{dt.name}</span>
              </div>
            </a>
          )
        })}
      </div>
    </div>
  )
}
const FooterMenuWithImage = ({ title, data }) => {
  const useStyles = makeStyles(theme => ({
    Root: {
      height: 'auto',
      display: 'flex',
      background: 'white',
      alignItems: 'center',
      paddingTop: '2rem',
      flexDirection: 'column',
      rowGap: '30px'
    },
    CopyRightDiv: {
      color: 'white',
      borderTop: '1px solid white',
      width: '100%',
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem'
    },
    FooterMenu: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 7
    },
    FooterList: {
      display: 'flex',
      width: '100%',
      margin: '0px auto',
      columnGap: '72px'
    },
    FooterMenuIcon: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232'
    },
    FooterMenuImage: {
      display: 'flex',
      flexDirection: 'column',
      color: '#323232',
      rowGap: 4
    },
    FooterMenuSpan: {
      width: '180px',
      fontSize: 16,
      '&:hover': {
        //   borderBottom: "1px solid white",
        cursol: 'pointer'
      }
    },
    footerMenuIcone: {
      display: 'flex',
      columnGap: 2,
      alignItems: 'center',
      width: '100%',
      fontSize: 16,
      '&>svg': {
        marginLeft: '0px !important',
        height: '24px',
        width: '24px'
      },
      '&:hover': {
        borderBottom: '1px solid white',
        cursol: 'pointer'
      }
    },

    aLink_style: {
      color: 'inherit !important'
    }
  }))
  const classes = useStyles()
  console.log('footer ', data)

  return (
    <div style={{ width: '100%' }}>
      <h5 style={{ color: '#323232' }}>{title}</h5>
      <div className={classes.FooterMenuImage}>
        {data.map((dt, index) => {
          return (
            <a href={dt.aLink} target='_blank' rel='noreferrer' key={index}>
              <img src={dt.img} width={150} />
            </a>
          )
        })}
      </div>
    </div>
  )
}
