import React, { useState, useEffect } from 'react'
import CardProduct from '../../../components/card-product/CardProduct'
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MyPagination from 'components/Pagination'
import { getByClient as getProductsDetail } from '../../../api/products'

const ProductData = ({ t, catId, sortBy, pageNumber, membershipLevel }) => {
  const [pageNo, setPageNo] = useState(1)
  // console.log("data", data);
  // const classes = useStyles();
  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [MyPage, setMyPage] = useState()
  const [pageCount, setPageCount] = useState(1)
  const [NewData, setNewData] = useState([])
  const [find, setFind] = useState(false)
  // const dispatch = useDispatch();
  // const whislist = GetWishlistSelector();
  const history = useHistory()
  const searchname = JSON.parse(localStorage.getItem('desktopSearch'))

  const getProductsDetailData = async page => {
    try {
      let sortByValue = null
      let sortByAscDesc = null
      switch (sortBy) {
        // case 1:
        //   sortByValue = `"order": "created_at"`
        //   sortByAscDesc = `"sort": "desc"`
        //   break;
        case 1:
          sortByValue = `"labels": "['Terbaru']"`
          break
        case 2:
          sortByValue = `"labels": "['Terbaru']"`
          break
        case 3:
          sortByValue = `"order": "price.${membershipLevel}"`
          sortByAscDesc = `"sort": "asc"`
          break
        case 4:
          sortByValue = `"order": "price.${membershipLevel}"`
          sortByAscDesc = `"sort": "desc"`
          break
        case 5:
          sortByValue = `"labels": "['Diskon']"`
          break
        default:
          break
      }

      const response = await getProductsDetail(`{
        "query":"${searchname}", "limit":"50", "page":"${page}"
        ${sortByValue ? `,${sortByValue}` : ''}
        ${sortByAscDesc ? `,${sortByAscDesc}` : ''}
      }`)
      setMyPage(response.meta.total_pages)
      // setMyData(response.data);
      setNewData(response.data)
      setFind(false)
      if (response.data.length < 5) {
        setFind(true)
      }
    } catch (error) {
      console.log('Error fetching getProductsDetail:', error)
    }
  }

  useEffect(() => {
    if (searchname) {
      getProductsDetailData(1)
    }
  }, [searchname, catId, sortBy, pageCount, pageNumber])

  const pageChange = (event, value) => {
    getProductsDetailData(value)
    setPageNo(value)
  }

  const naigateToDetailProduct = idProduct => {
    if (Mobile) history.push(`/productdetail?id=${idProduct}`)
    else history.push(`/product-detail?${idProduct}`)
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* <h1>{pageCount}</h1> */}
        <div
          className='container'
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            maxWidth: '100%'
          }}
        >
          {NewData !== '' ? (
            NewData && NewData.length > 0 ? (
              <Box display='grid' gridTemplateColumns='repeat(5, 1fr)' style={{ gap: '5px' }}>
                {NewData.map((dt, index) => {
                  return (
                    <CardProduct
                      key={index}
                      width='100%'
                      membershipLevel={membershipLevel}
                      limitTitle={25}
                      product={dt.product}
                      heightWrapperImage='180px'
                      handleClick={naigateToDetailProduct}
                    />
                  )
                })}
              </Box>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80vh'
                }}
              >
                <b>{t('product_not_found')}</b>
              </div>
            )
          ) : (
            <Paper>
              <Skeleton variant='rect' height={100} />
              <Skeleton variant='rect' height={100} />
              <Skeleton variant='rect' height={100} />
              <Skeleton variant='rect' height={100} />
            </Paper>
          )}
        </div>
        {MyPage > 1 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <MyPagination count={MyPage} page={pageNo} handleChange={pageChange} />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withNamespaces()(ProductData)
