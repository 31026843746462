import React, { useState, useRef } from 'react'
import { Box, Grid, ImageList } from '@material-ui/core'
import ReactImageZoom from 'react-image-zoom'
import { useStyles } from './SingleProductDetail.style'
// import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
// import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import CloseIcon from '@material-ui/icons/Close'

// import shose from "../../../../../../public/assets/images/bglogin.jpg";
// import space from "../../../../../../public/assets/images/bglogin.jpg";
// import space from "/public/images/space.jpg";
// import { shose, space } from "../../utils/constant";

const ImageZoom = props => {
  const { images, defaultImage, productName } = props
  const classes = useStyles()

  const isVideo = file =>
    ['mp4', 'webm', 'ogg', 'avi', 'flv', 'mov', 'wmv', '3gp', 'mkv', 'm4v'].includes(
      file.split('.').pop()
    )
  const [isImage, setImage] = useState(null)

  //Slide click Start
  let scrl = useRef(null)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)

  const slide = shift => {
    scrl.current.scrollLeft += shift
    setscrollX(scrollX + shift)

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft)
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
    ) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }

    //Slide click end
  }

  const defaultProps = {
    width: 400,
    height: 250,
    zoomWidth: 500,
    img: defaultImage ? defaultImage : images
  }

  // popup list images
  const [openPopUpImage, setOpenPopUpImage] = useState(false)
  const [indexImage, setIndexImage] = useState(0)

  const handleClickOpenPopUpImage = () => {
    setOpenPopUpImage(true)
  }

  const handleClosePopUpImage = () => {
    setOpenPopUpImage(false)
  }

  const handleSetIndexImage = valueIndex => {
    setIndexImage(valueIndex)
    setOpenPopUpImage(true)
  }

  return (
    <Grid item md={5}>
      <Box className={classes.product_left}>
        <Box
          className={classes.product_main_image}
          onClick={handleClickOpenPopUpImage}
          style={{ cursor: 'pointer' }}
        >
          {isImage && isVideo(isImage.img) ? (
            <video width='450' height='450' controls>
              <source src={isImage.img} type='video/mp4' />
            </video>
          ) : isImage && !isVideo(isImage.img) ? (
            <ReactImageZoom
              {...isImage}
              zoomPosition='original'
              width={450}
              height={450}
              zoomWidth={450}
            />
          ) : defaultProps.img && defaultProps.img.length != 0 && isVideo(defaultProps.img) ? (
            <video width='450' height='450' controls>
              <source src={defaultProps.img} type='video/mp4' />
            </video>
          ) : (
            <ReactImageZoom
              {...defaultProps}
              zoomPosition='original'
              width={450}
              height={450}
              zoomWidth={450}
            />
          )}
        </Box>

        <div className={classes.sliderContent}>
          {/* {scrollX !== 0 && ( */}
          {/* {images.length > 3 && (
            // <Button className="prev" onClick={() => slide(-50)}>
            //   <ArrowBackIosIcon />
            // </Button>
            // <ArrowBackIosIcon className="prev" onClick={() => slide(-50)}/>
          )} */}
          {/* )} */}
          <ArrowBackIosRoundedIcon className='prev' onClick={() => slide(-100)} />
          <div className='thumbs sliderParent' ref={scrl} onScroll={scrollCheck}>
            {images && images.length > 0 ? (
              images.map((data, index) => {
                if (isVideo(data)) {
                  return (
                    <div key={index} className='containerSliderItemImage'>
                      <img
                        src={process.env.REACT_APP_FILE_BASE_URL + '/' + images[0]}
                        className='sliderItemImage'
                        width={85}
                        height={85}
                        onClick={() => handleSetIndexImage(index)}
                        onMouseOver={() =>
                          setImage({
                            img: process.env.REACT_APP_FILE_BASE_URL + '/' + data
                          })
                        }
                      />
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className='containerSliderItemImage'>
                      <img
                        src={process.env.REACT_APP_FILE_BASE_URL + '/' + data}
                        className='sliderItemImage'
                        width={85}
                        height={85}
                        onClick={() => handleSetIndexImage(index)}
                        onMouseOver={() =>
                          setImage({
                            img: process.env.REACT_APP_FILE_BASE_URL + '/' + data
                          })
                        }
                      />
                    </div>
                  )
                }
              })
            ) : (
              <img src='/assets/images/dummy.png' height='400px' width='100%' />
            )}

            {/*
            <Button onClick={() => setImage(shose_props)}>
              <img src="http://malaman.github.io/react-image-zoom/example/1.jpg" />
            </Button>
             */}
          </div>
          <ArrowForwardIosRoundedIcon className='next' onClick={() => slide(+100)} />
          {/* {!scrolEnd && ( */}
          {/* {images.length > 3 && (
            <Button className="next" onClick={() => slide(+50)}>
              <ArrowForwardIosIcon />
            </Button>
            // <ArrowForwardIosIcon className="next" onClick={() => slide(+50)}/>
          )} */}
          {/* )} */}
        </div>
      </Box>
      <Dialog
        open={openPopUpImage}
        onClose={handleClosePopUpImage}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id='alert-dialog-title' style={{ height: '50px', padding: '5px 15px' }}>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <label style={{ fontSize: '20px' }}>{productName}</label>
            <CloseIcon
              onClick={handleClosePopUpImage}
              style={{ fontSize: '30px', paddingBottom: '10px' }}
            />
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: '0px 15px' }}>
          <Grid container spacing={1}>
            <Grid item md={6}>
              <Carousel
                animation='slide'
                navButtonsAlwaysVisible={true}
                indicators={false}
                autoPlay={false}
                navButtonsAlwaysInvisible={images && images.length >= 1 ? false : true}
                index={indexImage}
                // NextIcon={<ArrowRightOutlined/>}
                // PrevIcon={<ArrowLeftOutlined/>}
                navButtonsProps={{
                  // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                  style: {
                    backgroundColor: 'white',
                    color: 'black',
                    width: '24px',
                    height: '24px'
                  }
                }}
              >
                {images &&
                  images.map((data, index) => {
                    return (
                      <div style={{ display: 'flex' }} key={index}>
                        <img
                          src={process.env.REACT_APP_FILE_BASE_URL + '/' + data}
                          alt='img'
                          style={{
                            height: '516px',
                            width: '100%',
                            maxHeight: '516px',
                            borderRadius: '2px',
                            objectFit: 'cover'
                          }}
                        />
                      </div>
                    )
                  })}
              </Carousel>
            </Grid>

            <Grid item md={6}>
              <ImageList rowHeight={100} cols={3} className={classes.imageListParent}>
                {images &&
                  images.map((data, index) => (
                    <img
                      src={`${process.env.REACT_APP_FILE_BASE_URL + '/' + data}`}
                      srcSet={`${process.env.REACT_APP_FILE_BASE_URL + '/' + data}`}
                      alt={'image'}
                      loading='lazy'
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIndexImage(index)}
                    />
                  ))}
                {images &&
                  images.map((data, index) => (
                    <img
                      src={`${process.env.REACT_APP_FILE_BASE_URL + '/' + data}`}
                      srcSet={`${process.env.REACT_APP_FILE_BASE_URL + '/' + data}`}
                      alt={'image'}
                      loading='lazy'
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIndexImage(index)}
                    />
                  ))}
                {images &&
                  images.map((data, index) => (
                    <img
                      src={`${process.env.REACT_APP_FILE_BASE_URL + '/' + data}`}
                      srcSet={`${process.env.REACT_APP_FILE_BASE_URL + '/' + data}`}
                      alt={'image'}
                      loading='lazy'
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIndexImage(index)}
                    />
                  ))}
              </ImageList>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default ImageZoom
