import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setSnackbar } from '../stores/features/snackbar/snackbar'
import { Button, Snackbar } from '@mui/material'

export const Snackbars = () => {
  const dispatch = useDispatch()
  const { show, message, sx = null, action = null } = useSelector(state => state.snackbar)
  const handleClose = () => {
    dispatch(
      setSnackbar({
        show: false,
        message: ''
      })
    )
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      sx={{
        top: { xs: 60, sm: 0 },
        ...sx,
        '& .MuiPaper-root': { backgroundColor: '#381e11' }
      }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      action={
        action && action.onClick && action.text ? (
          <Button
            sx={{ color: 'var(--warning)' }}
            size='small'
            onClick={() => {
              handleClose()

              if (action.onClick) {
                action.onClick()
              }
            }}
          >
            {action.text}
          </Button>
        ) : action ? (
          action
        ) : (
          <Button
            sx={{ color: 'var(--warning)' }}
            color='secondary'
            size='small'
            onClick={handleClose}
          >
            OK
          </Button>
        )
      }
    />
  )
}
