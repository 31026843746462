import { Box } from '@material-ui/core'
import React, { useEffect } from 'react'
// import { useDispatch } from "react-redux";
import Amount from '../../../../components/common/Amount'
import ButtonCustom from '../../../../components/common/ButtonCustom'
import Card from '../../../../components/common/Card'
// import { getMyCartProductUser } from "../../../../store/actions/GetMyCartActions";
// import { GetMyCartProductResponse } from "../../../../store/Selectors/mycart";
import { useStyles } from './CartRight.style'
import i18n from '../../../language/localization'
import { withNamespaces } from 'react-i18next'
// import { GetSplashResponse } from "../../../../store/Selectors/getSplashData";

const Right = ({ qty, total, checkOutClick, payOnSpotClick, t, webThemeColor, cartDatas }) => {
  const classes = useStyles()
  // const dispatch = useDispatch();
  // const CartData = GetMyCartProductResponse();

  const mycart = () => {
    // const Usercode = JSON.parse(localStorage.getItem("usercode"));
    // let data = new FormData();
    // data.append("user_code", Usercode);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));
  }
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    mycart()
  }, [])

  // const dataSplashResponse = GetSplashResponse();
  const landingPageData = JSON.parse(localStorage.getItem('landing_page'))

  const ListItem = props => {
    return (
      <Box className={classes.listStyle}>
        <h4 className='lable'>{props.lable}</h4>
        <h4 className='lable'>{props.value}</h4>
      </Box>
    )
  }

  return (
    <Card className={classes.cardStyle}>
      <header>
        <h1 className='heading'>{t('shopping_summary')}</h1>
      </header>
      <Box className='cardContent'>
        <Box className='listItems'>
          {cartDatas ? <ListItem lable={t('Total items')} value={`${qty} Items`} /> : null}

          <Box className={classes.listStyle}>
            <h4 className='lable'>{t('Total order')}</h4>
            <h4 className='lable'>
              Rp <Amount amount={total} />
            </h4>
          </Box>
        </Box>

        {landingPageData &&
          landingPageData.setting_cms &&
          landingPageData.setting_cms.is_show_pay_on_spot_button === true && (
            <ButtonCustom
              bg={webThemeColor}
              className={classes.btn_fullwidth}
              onClick={payOnSpotClick}
            >
              {landingPageData.setting_cms.pay_on_spot_button_text}
            </ButtonCustom>
          )}

        {landingPageData &&
          landingPageData.setting_cms &&
          landingPageData.setting_cms.is_show_checkout_button === true && (
            <div className={classes.NavLink_style_none}>
              <ButtonCustom
                bg={webThemeColor}
                className={classes.btn_fullwidth}
                onClick={checkOutClick}
              >
                {landingPageData.setting_cms.checkout_button_text}
              </ButtonCustom>
            </div>
          )}
      </Box>
    </Card>
  )
}

export default withNamespaces()(Right)
