import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  body: {
    '& header': {
      marginBottom: '40px'
    }
  },

  pr15: {
    paddingRight: '15px !important'
  },
  content: {
    paddingTop: '50px'
  },
  title: {
    fontSize: '18px',
    color: '#545454',
    fontWeight: '700',
    marginBottom: '10px'
  },

  input_style: {
    '& input': {
      width: '100%'
    },

    '& .label': {
      textAlign: 'left',
      width: '15%'
    },
    '& input:focus': {
      boxShadow: 'none',
      border: props => '1px solid ' + props.themeColor
    }
  },

  label: {
    fontSize: '14px',
    color: '#575757',
    marginBottom: '10px',
    textAlign: 'right'
  },

  select_style: {
    marginBottom: '15px',
    // "& select": {
    display: 'block',
    width: '66%',
    marginLeft: '34%',
    // height: "28px",
    padding: '10px 10px',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    // },
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none'
    },
    '& input:focus': {
      boxShadow: 'none',
      border: props => '1px solid ' + props.themeColor
    }
  },

  textarea_style: {
    marginBottom: '15px',
    // "& select": {
    display: 'block',
    width: '100%',
    minHeight: '100px !important',
    marginLeft: '8%',
    // height: "28px",
    padding: '10px 10px',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    // },
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none'
    },
    '&:focus': {
      boxShadow: 'none',
      border: props => '1px solid ' + props.themeColor
    }
  },

  input_leftTitle: {
    display: 'flex',
    gap: '15px',
    width: '100%'
  },

  upload__image: {
    display: 'flex',
    justifyContent: 'center',

    '& .btn_uploadImage': {
      borderRadius: '50%',
      height: '220px',
      width: '220px',
      padding: '33px',
      border: 'none'
    },
    '& .image-item': {
      borderRadius: '50%',
      height: '220px',
      width: '220px',
      border: 'none',
      backgroundColor: '#e1e1e1',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',

      '& img': {
        width: '100%'
      },
      '& .image-item__btn-wrapper': {
        overflow: 'visible',

        position: 'absolute',
        marginTop: '-50px',
        display: 'flex',
        gap: '5px',
        paddingBottom: '10px',

        '& button': {
          borderRadius: '50%',
          backgroundColor: 'rgba(0,0,0,.7)',
          border: '2px solid rgba(0,0,0,.7)',

          '& svg': {
            color: '#fff',
            width: '0.8em',
            height: '0.8em',
            margin: '6px !important'
          },
          '&:hover': {
            border: '2px solid #fff'
          }
        }
      }
    }
  }
})

export { useStyles }
