import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from './stores/store'
import { PersistGate } from 'redux-persist/integration/react'
// import { Provider } from "react-redux";
import './index.css'
import { AuthProvider } from './context/AuthContext'
import { StoreProvider } from './context/StoreContext'
import { ModalMessageProvider } from 'context/ModalMessageContext'
// ReactDOM.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById("root")
// );
if (document.getElementById('root')) {
  document.getElementById('root').style.display = 'block'
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <StoreProvider>
          <AuthProvider>
            <PersistGate loading={null} persistor={persistor}>
              <ModalMessageProvider>
                <App />
              </ModalMessageProvider>
            </PersistGate>
          </AuthProvider>
        </StoreProvider>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
}
