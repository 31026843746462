import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

const LoaderDots = props => {
  return (
    <span className={props.className} style={{ position: 'relative', top: '3px' }}>
      <BeatLoader size={props.size ? props.size : 12} color={props.color ? props.color : 'white'} />
    </span>
  )
}

export default LoaderDots
