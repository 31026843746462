import { instance } from '../helper/api'

export const get = async param => {
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
  }

  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/vendor/x-api-key/',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getDahboard = async () => {
  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/dashboard/profile/',
    method: 'GET'
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getOnlineStore = async () => {
  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/vendor/online-store/',
    method: 'GET'
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
