import React from 'react'
import { Dialog as MuiDialog, Typography, Box } from '@material-ui/core'

const MaintenanceModal = ({ isOpen, title, content, onClose }) => {
  return (
    <MuiDialog open={isOpen} onClose={onClose}>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
      >
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body1'>{content}</Typography>
      </Box>
    </MuiDialog>
  )
}

export default MaintenanceModal
