import React, { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../../containers/home/DesktopFooter'
import { makeStyles, Card, Typography } from '@material-ui/core'
// import { GetSplashResponse } from "../../store/Selectors/getSplashData";
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'

const useStyles = makeStyles(theme => ({
  Root: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      border: 'none',
      width: '100%',
      maxWidth: '100wh',
      margin: '0px auto'
      // border: '1px solid #d3d3d3a3'
    }
  },
  labelTitle: {
    textAlign: 'center',
    marginTop: '15px',
    marginBottom: '15px',
    fontSize: '20px',
    width: '1200px'
  },
  cardCategory: {
    '&  :hover': {
      cursor: 'pointer'
    }
  }
}))

const ComingSoonPage = ({ t }) => {
  const { token } = useAuth()

  const classes = useStyles()
  const [TotalCartItems, setItems] = useState(0)
  const [TotalWishlistItems, setWishlistItems] = useState(0)
  const [categoriesData, setCategoriesData] = useState([])
  const Usercode = JSON.parse(localStorage.getItem('usercode'))
  const mycart = async () => {
    // let data = new FormData();
    // data.append("user_code", Usercode);
    // data.append("current_date", moment().format("YYYY-MM-DD hh:mm:ss"));
    // dispatch(getMyCartProductUser(data));

    // fetch(`${baseUrlApi()}/Api/Order/myCart`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log("getSearchProduct", res.data);
    //     setItems(res.data.length)
    //   });

    // fetch(`${baseUrlApi()}/Api/Product/getWishlist`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     console.log("getSearchProduct", res.data);
    //     setWishlistItems(res.data.length)
    //   });
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }
  }
  useEffect(() => {
    mycart()
  }, [Usercode])

  // const data1 = GetSplashResponse();
  const footer = {}

  return (
    <div className={classes.Root}>
      <div>
        <DesktopHeaderWrap
          cartNumber={TotalCartItems}
          wishlistCount={TotalWishlistItems}
          categoriesData={categoriesData}
        />

        <div style={{ background: '#f5f5f5', height: '370px' }}>
          <div
            style={{
              width: '1200px',
              margin: '0px auto',
              zIndex: -1,
              paddingTop: '170px'
            }}
          >
            <Card variant='outlined'>
              <Typography
                style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  textAlign: 'center',
                  width: '100%',
                  paddingTop: '15px'
                }}
              >
                {t('is_feature_not_available')}
              </Typography>
              <Typography
                style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                {t('download_our_app_on_this_below')}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px',
                  marginTop: '15px',
                  marginBottom: '15px'
                }}
              >
                <a href={footer ? footer.store_url : null} target='_blank' rel='noreferrer'>
                  <img
                    src={'/assets/images/googleplay.png'}
                    style={{ width: '120px', height: '42.5px' }}
                  />
                </a>
                <a href={footer ? footer.app_store_url : null} target='_blank' rel='noreferrer'>
                  <img
                    src={'/assets/images/appstore.png'}
                    style={{ width: '120px', height: '36px' }}
                  />
                </a>
              </div>
            </Card>
          </div>
        </div>
        <DeskTopFooter />
      </div>
    </div>
  )
}
export default withNamespaces()(ComingSoonPage)
