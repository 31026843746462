import { makeStyles } from '@material-ui/core'

const themeColor = JSON.parse(localStorage.getItem('themeColor'))

const useStyles = makeStyles({
  cardStyle: {
    '& .cardContent': {
      padding: '15px'
    },
    '& .side-terms': {
      lineHeight: 'normal',
      fontSize: '12px',
      color: '#575757',
      marginTop: '10px'
    },

    '& .listItems': {
      minHeight: '170px',
      marginBottom: '20px'
    },
    '& header': {
      '& .heading': {
        padding: '8px 15px',
        backgroundColor: 'white',
        fontSize: '16px',
        color: 'black',
        fontWeight: '600',
        fontFamily: '"Poppins", sans-serif'
      },
      borderBottom: '1px solid #d6cbcb'
    }
  },

  listStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '35px',
    '& h4': {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      marginBottom: '0'
    }
  },

  btn_fullwidth: {
    display: 'block',
    backgroundColor: '#ff3a59',
    lineHeight: '40px',
    borderRadius: '4px',
    textAlign: 'center',
    color: '#fff !important',
    width: '100%',
    marginBottom: '10px'
  },

  NavLink_style_none: {
    lineHeight: '0 !important',
    borderRadius: '0 !important',
    border: 'none !important',
    padding: '0 !important',
    marginRight: '0 !important'
  },

  applyInput: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    gap: '7px',

    '& .promo-fill': {
      width: '100% !important',
      height: '44px'
    },
    '& .MuiButton-root': {
      marginBottom: '0'
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .MuiInputBase-root': {
      height: '32px'
    },
    '& .MuiFormLabel-root': {
      lineHeight: '0.6'
    },
    '& .MuiButton-outlined': {
      height: '32px'
    }
  },
  valueText: {
    display: 'flex',
    gap: '5px'
  },
  borderColorTextfield: {
    '& label.Mui-focused': {
      color: themeColor
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: themeColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: themeColor
      }
    }
  }
})

export { useStyles }
