import { instance } from '../helper/api'

export const getCountry = async param => {
  // custom parameter
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, { limit: 9999 })
    paramValue = Object.assign(paramValue, { page: 1 })
  }

  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/location/country/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getProvince = async param => {
  // custom parameter
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, { get_all_data: true })
  }

  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/location/province/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getCity = async param => {
  // custom parameter
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, { get_all_data: true })
  }

  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/location/district/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getDistrict = async param => {
  // custom parameter
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
    paramValue = Object.assign(paramValue, { get_all_data: true })
  }

  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/location/subdistrict/',
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getSubDistrict = async param => {
  // custom parameter
  // let paramValue = null;
  // if (param) {
  //   paramValue = JSON.parse(param);
  //   paramValue = Object.assign(paramValue, { limit: 9999 });
  //   paramValue = Object.assign(paramValue, { page: 1 });
  // }

  let resultData = {}
  const axiosConfig = {
    url: '/shopi/v1/location/village/',
    method: 'GET',
    params: JSON.parse(param)
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
