import React, { useEffect, useState } from 'react'
import Header from '../header/header2'
import { IoIosArrowForward } from 'react-icons/io'
import { useHistory } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
// import { getSplashDataUser } from "../../store/actions/getSplashDataActions";
// import { useDispatch } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import Loader from '../loader'
import { useAuth } from '../../hooks/useAuth'
import { useModalMessage } from 'hooks/useModalMessage'

const AccountSetting = ({ t }) => {
  const { setMessage } = useModalMessage()
  const auth = useAuth()
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const useStyles = makeStyles(theme => ({
    root: {
      '&> .finish-btn': {
        height: '2.7rem',
        backgroundColor: `${ThemeColor ? ThemeColor : '#FFFFFF'} !important`
      }
    }
  }))

  const history = useHistory()
  const datauser = JSON.parse(localStorage.getItem('userdata'))
  const classes = useStyles()
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })

  // const dispatch = useDispatch();
  const logout = () => {
    auth.logout()

    history.push('/')
    // let data = new FormData();
    // dispatch(getSplashDataUser(data));
  }

  const [open, setOpen] = useState(true)

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    setOpen(true)
  }, [])
  const landing = JSON.parse(localStorage.getItem('Lanpage'))
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <div>
      {open ? (
        <div style={{ maxWidth: '100wh' }}>
          <Header title={t('account_settings')} color={ThemeColor} />
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ paddingTop: '48px', userSelect: 'none' }}
          >
            <ul className='list-group list-group-flush w-100'>
              <div>
                <li
                  className='list-group-item d-flex justify-content-left align-items-center p-0'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (datauser) {
                      history.push('/editprofile')
                    } else {
                      // if (landing&&landing.landing_page == 0) {
                      //   history.push("/login");
                      // } else {
                      //   history.push("/");
                      // }
                      history.push('/login')
                    }
                  }}
                >
                  <svg
                    style={{
                      height: '24px',
                      width: '24px',
                      margin: '9px !important'
                    }}
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.99992 10.0003C12.3011 10.0003 14.1666 8.13485 14.1666 5.83366C14.1666 3.53247 12.3011 1.66699 9.99992 1.66699C7.69873 1.66699 5.83325 3.53247 5.83325 5.83366C5.83325 8.13485 7.69873 10.0003 9.99992 10.0003Z'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M17.1585 18.3333C17.1585 15.1083 13.9501 12.5 10.0001 12.5C6.05013 12.5 2.8418 15.1083 2.8418 18.3333'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  <div className=' me-auto'>
                    <div className='mt-2'>
                      <h6 className='text-muted fw-normal text-left'>{t('my_profile')}</h6>
                    </div>
                  </div>
                  <span
                    style={{
                      marginRight: '0',
                      marginLeft: 'auto',
                      width: '60px'
                    }}
                  >
                    <IoIosArrowForward className='text-muted' style={{ fontSize: '20px' }} />
                  </span>
                </li>
              </div>
              <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
              <div>
                <li
                  className='list-group-item d-flex justify-content-between align-items-center p-0'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (datauser) {
                      history.push('/selectaddress')
                    } else {
                      // if (landing&&landing.landing_page == 0) {
                      //   history.push("/login");
                      // } else {
                      //   history.push("/");
                      // }
                      history.push('/login')
                    }
                  }}
                >
                  <svg
                    style={{
                      height: '24px',
                      width: '24px',
                      margin: '9px !important'
                    }}
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10.0001 11.1912C11.4361 11.1912 12.6001 10.0272 12.6001 8.59121C12.6001 7.15527 11.4361 5.99121 10.0001 5.99121C8.56421 5.99121 7.40015 7.15527 7.40015 8.59121C7.40015 10.0272 8.56421 11.1912 10.0001 11.1912Z'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                    />
                    <path
                      d='M3.01675 7.07533C4.65842 -0.141339 15.3501 -0.133006 16.9834 7.08366C17.9417 11.317 15.3084 14.9003 13.0001 17.117C11.3251 18.7337 8.67508 18.7337 6.99175 17.117C4.69175 14.9003 2.05842 11.3087 3.01675 7.07533Z'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                    />
                  </svg>

                  <div className=' me-auto'>
                    <div className='mt-2'>
                      <h6 className='text-muted fw-normal text-left'>{t('my_address')}</h6>
                    </div>
                  </div>
                  <span
                    style={{
                      marginRight: '0',
                      marginLeft: 'auto',
                      width: '60px'
                    }}
                  >
                    <IoIosArrowForward className='text-muted' style={{ fontSize: '20px' }} />
                  </span>
                </li>
              </div>
              <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
              {/* <div>
              <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                <VolumeUpOutlinedIcon />
                <div className=" me-auto">
                  <div className="mt-2">
                    <h6 className="text-muted fw-normal text-center">
                    {t("manage_notifications")}
                    </h6>
                  </div>
                </div>
                <span className="">
                  <IoIosArrowForward className="text-muted" />
                </span>
              </li>
            </div><hr style={{marginTop:"0px",marginBottom:"0px"}}/> */}
              <div>
                <li
                  className='list-group-item d-flex justify-content-between align-items-center p-0'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (datauser) {
                      history.push('/changepassword')
                    } else {
                      // if (landing&&landing.landing_page == 0) {
                      //   history.push("/login");
                      // } else {
                      //   history.push("/");
                      // }
                      history.push('/login')
                    }
                  }}
                >
                  <svg
                    style={{
                      height: '24px',
                      width: '24px',
                      margin: '9px !important'
                    }}
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.18325 16.25H6.24992C5.73325 16.25 5.27492 16.2333 4.86659 16.175C2.67492 15.9333 2.08325 14.9 2.08325 12.0833V7.91667C2.08325 5.1 2.67492 4.06667 4.86659 3.825C5.27492 3.76667 5.73325 3.75 6.24992 3.75H9.13325'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M12.5168 3.75H13.7502C14.2668 3.75 14.7252 3.76667 15.1335 3.825C17.3252 4.06667 17.9168 5.1 17.9168 7.91667V12.0833C17.9168 14.9 17.3252 15.9333 15.1335 16.175C14.7252 16.2333 14.2668 16.25 13.7502 16.25H12.5168'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M12.5 1.66699V18.3337'
                      stroke='#2B2B2B'
                      strokeWidth='1.26894'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9.24534 9.99967H9.25283'
                      stroke='#2B2B2B'
                      strokeWidth='1.69192'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M5.91209 9.99967H5.91957'
                      stroke='#2B2B2B'
                      strokeWidth='1.69192'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>

                  <div className=' me-auto'>
                    <div className='mt-2'>
                      <h6 className='text-muted fw-normal text-left'>{t('change_password')}</h6>
                    </div>
                  </div>
                  <span
                    style={{
                      marginRight: '0',
                      marginLeft: 'auto',
                      width: '60px'
                    }}
                  >
                    <IoIosArrowForward className='text-muted' style={{ fontSize: '20px' }} />
                  </span>
                </li>
              </div>
              <hr style={{ marginTop: '0px', marginBottom: '0px' }} />
              {/* <div>
              <li className="list-group-item d-flex justify-content-between align-items-center p-0">
                <DeleteOutlinedIcon />
                <div className=" me-auto">
                  <div className="mt-2">
                    <h6 className="fw-normal text-center text-muted">
                     {t("clear_cache")}
                    </h6>
                  </div>
                </div>
                <span className="">
                  <button className="outline-0 border-0 p-1 cache px-3 mx-1 my-0 rounded-pill text-white">
                    {t("clear")}
                  </button>
                </span>
              </li>
            </div><hr style={{marginTop:"0px",marginBottom:"0px"}}/> */}
            </ul>
          </div>
          {datauser ? (
            <div
              className={classes.root + ' ' + ' finish fixed-bottom'}
              style={{
                maxWidth: Mobile ? '' : '460px',
                marginTop: Mobile ? '' : '0px',
                marginBottom: Mobile ? '30px' : '25px',
                marginLeft: Mobile ? '20px' : 'auto',
                marginRight: Mobile ? '20px' : 'auto'
              }}
            >
              <button
                className='finish-btn mt-3 text-uppercase'
                onClick={() => {
                  setMessage(t('ask_logout'), logout)
                }}
              >
                {t('logout')}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            backgroundColor: 'white',
            height: '100vh'
          }}
        >
          <div>
            <Loader />
          </div>
          <div>{t('loadingdot')}</div>
        </div>
      )}
    </div>
  )
}

export default withNamespaces()(AccountSetting)
