import { baseUrlApi, instance } from '../helper/api'

export const forgotPasswordSendOtp = async data => {
  const axiosConfig = {
    url: baseUrlApi() + '/shopi/v1/customer/reset-password/',
    method: 'POST',
    data: data
  }

  return await instance.request(axiosConfig)
}

export const forgotPasswordVerifcationOtp = async data => {
  const axiosConfig = {
    url: baseUrlApi() + '/shopi/v1/customer/reset-password/verification-otp/',
    method: 'POST',
    data: data
  }

  return await instance.request(axiosConfig)
}

export const forgotPasswordResetPassword = async data => {
  const axiosConfig = {
    url: baseUrlApi() + '/shopi/v1/customer/reset-password/reset/',
    method: 'POST',
    data: data
  }

  return await instance.request(axiosConfig)
}
