import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { Avatar } from '@material-ui/core'
import { BiUser } from 'react-icons/bi'
import Header from '../header/header'
import NavigationTab from '../home/bottomnav/NavigationTab'
import { useHistory } from 'react-router-dom'
// import { GetSplashResponse } from "../../store/Selectors/getSplashData"
import i18n from '../language/localization'
import { withNamespaces } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getCountOrderByStatus } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'
import CardListMobile from 'components/list/CardListMobile'
import { AiFillCustomerService } from 'react-icons/ai'

const useStyles = makeStyles(theme => ({
  settingsTitleBox: {
    // position: "relative",
    color: '#fff'
    // margin: "0 auto",
  }
}))

const Settings = ({ t }) => {
  const { countWishlist, countLastViewedProduct } = useAuth()
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const classes = useStyles()
  const history = useHistory()
  const datauser = JSON.parse(localStorage.getItem('userdata'))
  const landing = JSON.parse(localStorage.getItem('Lanpage'))
  const userid = JSON.parse(localStorage.getItem('userid'))
  const landingPageData = JSON.parse(localStorage.getItem('landing_page'))
  const [field, setField] = React.useState()
  const lang = JSON.parse(localStorage.getItem('Language'))
  const [unpaidTotalRecord, setunpaidTotalRecord] = React.useState(0)
  const [onProcessTotalRecord, setonProcessTotalRecord] = React.useState(0)
  const [cancelTotalRecord, setcancelTotalRecord] = React.useState(0)
  const [onDeliveryTotalRecord, setonDeliveryTotalRecord] = React.useState(0)
  useEffect(() => {
    // let data = new FormData();
    // data.append("user_id", userid);
    // fetch(`${baseUrlApi()}/Api/Dashboard/getSplashData`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.ResponseCode == 1) {
    //       setField(res.data);
    //     }
    //   });
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])
  const getCount = async () => {
    try {
      const response = await getCountOrderByStatus()
      if (response.success) {
        if (
          response.data['UNPAID'] +
            response.data['WAITING VALIDATION'] +
            response.data['ON VALIDATION'] >
          99
        ) {
          setunpaidTotalRecord('99+')
        } else {
          setunpaidTotalRecord(
            response.data['UNPAID'] +
              response.data['WAITING VALIDATION'] +
              response.data['ON VALIDATION']
          )
        }

        if (response.data['ON PROCESS'] > 99) {
          setonProcessTotalRecord('99+')
        } else {
          setonProcessTotalRecord(response.data['ON PROCESS'])
        }

        if (response.data['ON DELIVERY'] > 99) {
          setonDeliveryTotalRecord('99+')
        } else {
          setonDeliveryTotalRecord(response.data['ON DELIVERY'])
        }

        if (response.data['CANCELED'] > 99) {
          setcancelTotalRecord('99+')
        } else {
          setcancelTotalRecord(response.data['CANCELED'])
        }
      } else {
        throw response
      }
    } catch (error) {
      console.log('Error fetching unpaid:', error)
    }
  }

  useEffect(() => {
    getCount()
  }, [])

  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <div>
      <React.Fragment>
        <Header title={t('settings1')} color={ThemeColor ? ThemeColor : '#FFFFFF'} />
        <div
          style={{
            paddingTop: '40px',
            background: 'transparent'
          }}
        >
          <React.Fragment>
            <div
              style={{
                position: 'absolute',
                height: '108px',
                width: '100vw',
                background: `${ThemeColor}`,
                display: 'inline-block',
                margin: '0 1em 1em 0',
                zIndex: '-1'
              }}
            ></div>
            <div
              className='d-flex mt-3 ms-4 mb-4 justify-content-start align-items-center '
              style={{ marginLeft: '10px' }}
            >
              <Avatar
                src={process.env.REACT_APP_FILE_BASE_URL + '/' + datauser?.profile_picture}
                sx={{ width: 64, height: 64 }}
                style={{
                  backgroundColor: 'transparent',
                  height: Mobile ? '55px' : '70px',
                  width: Mobile ? '55px' : '70px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  history.push('/editprofile')
                }}
              >
                <BiUser size={48} />
              </Avatar>

              {datauser ? (
                <div className='px-2'>
                  <div className='text-white'>{datauser.username}</div>
                  <div
                    className='text-white'
                    style={{
                      borderRadius: '8px',
                      backgroundColor: 'rgb(0 0 0 / 10%)'
                    }}
                  >
                    <label style={{ margin: '5px', fontSize: '12px' }}>{datauser.email}</label>
                  </div>
                  {/* <MdKeyboardArrowRight size="22" /> */}
                </div>
              ) : (
                <div className='px-2'>
                  <p className='text-white'>{t('welcome_guest')}</p>
                  <div
                    className='silver-box d-flex gap-1 align-items-center rounded-pill'
                    style={{
                      width: '125px',
                      paddingLeft: '7px',
                      marginLeft: '0px'
                    }}
                    onClick={() => {
                      history.push('/login')
                    }}
                  >
                    {/* <Typography variant="p" className="ms-3"> */}
                    {t('login')} | {t('register')}
                    {/* </Typography> */}
                  </div>
                </div>
              )}
            </div>
            <div className='mb-2 mx-auto'>
              <div
                className='settingsCouponBox mx-auto p-2 shadow-card rounded d-flex text-center ps-3 mt-3 align-items-center mx-3 pe-3 mb-2'
                style={{ justifyContent: 'space-around' }}
              >
                <p
                  className='mb-0 setting-p'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (datauser) {
                      history.push('/viewed')
                    } else {
                      // if (landing&&landing.landing_page == 0) {
                      //   history.push("/login")
                      // } else {
                      //   history.push("/")
                      // }
                      history.push('/login')
                    }
                  }}
                >
                  {countLastViewedProduct} <br /> <span className='text-muted'>{t('viewed')}</span>
                </p>
                <p
                  className='mb-0 setting-p'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (datauser) {
                      history.push('/wishlist')
                    } else {
                      history.push('/login')
                    }
                  }}
                >
                  {countWishlist} <br /> <span className='text-muted'>{t('wishlist')}</span>
                </p>
                <p
                  className='mb-0 setting-p'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (datauser) {
                      history.push('/coupon')
                    } else {
                      // if (landing&&landing.landing_page == 0) {
                      //   history.push("/login")
                      // } else {
                      //   history.push("/")
                      // }
                      history.push('/login')
                    }
                  }}
                >
                  - <br /> <span className='text-muted'>{t('coupon')}</span>
                </p>
              </div>
            </div>
          </React.Fragment>
        </div>
        <div className=' shadow-card settingsCouponBox mb-1 rounded mx-auto'>
          <div className='d-flex justify-content-between align-items-center mx-2 px-1 mb-2'>
            <h6 className='mt-2' style={{ fontSize: '14px', fontWeight: '600' }}>
              {t('my_order1')}
            </h6>
            <div
              className='text-center all-orders'
              onClick={() => {
                if (datauser) {
                  history.push('/orderhistory')
                  localStorage.setItem('ordertab', JSON.stringify(1))
                } else {
                  // if (landing&&landing.landing_page == 0) {
                  //   history.push("/login")
                  // } else {
                  //   history.push("/")
                  // }
                  history.push('/login')
                }
              }}
            >
              <span
                style={{
                  fontSize: '14px',
                  color: 'rgba(119, 129, 137, 1)',
                  cursor: 'pointer',
                  marginRight: '10px',
                  fontWeight: '500'
                  // color: ThemeColor
                }}
              >
                {t('all_orders1')}
              </span>
              {/* <span style={{ marginRight: "-20px" }}>
                    <IoIosArrowForward fontSize="20px" className="allorders-icon" style={{ color: "grey" }} />
                  </span> */}
            </div>
          </div>
          <div className='options  d-flex justify-content-around align-items-center text-center mb-2'>
            <div
              onClick={() => {
                if (datauser) {
                  history.push('/orderhistory')
                  localStorage.setItem('ordertab', JSON.stringify(1))
                } else {
                  // if (landing&&landing.landing_page == 0) {
                  //   history.push("/login")
                  // } else {
                  //   history.push("/")
                  // }
                  history.push('/login')
                }
              }}
            >
              {datauser ? (
                <div
                  style={{
                    marginBottom: '-18px',
                    marginLeft: lang == 'id' ? '40px' : '22px',
                    background: ThemeColor ? ThemeColor : '#FFFFFF',
                    color: 'white',
                    position: 'relative',
                    borderRadius: '148px',
                    width: '22px',
                    top: '0'
                  }}
                >
                  {unpaidTotalRecord}
                </div>
              ) : (
                ''
              )}
              {/* <img src="/assets/frontend/img/ic_setting_unpaid.png" style={{ height: "32px", width: "32px", margin: "9px" }} /> */}
              <svg
                style={{
                  height: '32px',
                  width: '32px',
                  margin: '9px',
                  fill: 'none'
                }}
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.64C8.47 21.27 9 20.2 9 19C9 16.79 7.21 15 5 15C4.06 15 3.19 15.33 2.5 15.88V11.51C2.5 9.44001 4.18999 7.75 6.25999 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M2.5 11.51V7.84002C2.5 6.65002 3.23 5.58997 4.34 5.16997L12.28 2.16997C13.52 1.70997 14.85 2.62 14.85 3.95V7.74999'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7 12H14'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M9 19C9 20.2 8.47 21.27 7.64 22C6.93 22.62 6.01 23 5 23C2.79 23 1 21.21 1 19C1 17.74 1.58 16.61 2.5 15.88C3.19 15.33 4.06 15 5 15C7.21 15 9 16.79 9 19Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.25 17.75V19.25L4 20'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <br />
              <p className='setting-subtext' style={{ fontSize: '14px' }}>
                {t('unpaid1')}
              </p>
            </div>
            <div
              onClick={() => {
                if (datauser) {
                  history.push('/orderhistory')
                  localStorage.setItem('ordertab', JSON.stringify(2))
                } else {
                  // if (landing&&landing.landing_page == 0) {
                  //   history.push("/login")
                  // } else {
                  //   history.push("/")
                  // }
                  history.push('/login')
                }
              }}
            >
              {datauser ? (
                <div
                  style={{
                    marginBottom: '-18px',
                    marginLeft: '22px',
                    background: ThemeColor ? ThemeColor : '#FFFFFF',
                    color: 'white',
                    position: 'relative',
                    borderRadius: '148px',
                    width: '22px',
                    top: '0'
                  }}
                >
                  {onProcessTotalRecord}
                </div>
              ) : (
                ''
              )}
              {/* <img src="/assets/frontend/img/ic_setting_on_process.png" style={{ height: "32px", width: "32px", margin: "9px" }} /> */}
              <svg
                style={{
                  height: '32px',
                  width: '32px',
                  margin: '9px',
                  fill: 'none'
                }}
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15.3335 2V12C15.3335 13.1 14.4335 14 13.3335 14H2.3335V7.62C3.0635 8.49 4.18352 9.03 5.42352 9C6.43352 8.98 7.3435 8.59 8.0235 7.94C8.3335 7.68 8.59352 7.34999 8.79352 6.98999C9.15352 6.37999 9.3535 5.65997 9.3335 4.90997C9.3035 3.73997 8.78351 2.71 7.97351 2H15.3335Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.3335 14V17C22.3335 18.66 20.9935 20 19.3335 20H18.3335C18.3335 18.9 17.4335 18 16.3335 18C15.2335 18 14.3335 18.9 14.3335 20H10.3335C10.3335 18.9 9.4335 18 8.3335 18C7.2335 18 6.3335 18.9 6.3335 20H5.3335C3.6735 20 2.3335 18.66 2.3335 17V14H13.3335C14.4335 14 15.3335 13.1 15.3335 12V5H17.1735C17.8935 5 18.5535 5.39001 18.9135 6.01001L20.6235 9H19.3335C18.7835 9 18.3335 9.45 18.3335 10V13C18.3335 13.55 18.7835 14 19.3335 14H22.3335Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.3335 22C9.43807 22 10.3335 21.1046 10.3335 20C10.3335 18.8954 9.43807 18 8.3335 18C7.22893 18 6.3335 18.8954 6.3335 20C6.3335 21.1046 7.22893 22 8.3335 22Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M16.3335 22C17.4381 22 18.3335 21.1046 18.3335 20C18.3335 18.8954 17.4381 18 16.3335 18C15.2289 18 14.3335 18.8954 14.3335 20C14.3335 21.1046 15.2289 22 16.3335 22Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.3335 12V14H19.3335C18.7835 14 18.3335 13.55 18.3335 13V10C18.3335 9.45 18.7835 9 19.3335 9H20.6235L22.3335 12Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M9.3335 5C9.3335 6.2 8.80351 7.27 7.97351 8C7.26351 8.62 6.3435 9 5.3335 9C3.1235 9 1.3335 7.21 1.3335 5C1.3335 3.74 1.9135 2.61 2.8335 1.88C3.5235 1.33 4.3935 1 5.3335 1C7.5435 1 9.3335 2.79 9.3335 5Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.5835 3.75V5.25L4.3335 6'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <br />
              <p className='setting-subtext' style={{ fontSize: '14px' }}>
                {t('onprocess1')}
              </p>
            </div>
            <div
              onClick={() => {
                if (datauser) {
                  history.push('/orderhistory')
                  localStorage.setItem('ordertab', JSON.stringify(3))
                } else {
                  // if (landing&&landing.landing_page == 0) {
                  //   history.push("/login")
                  // } else {
                  //   history.push("/")
                  // }
                  history.push('/login')
                }
              }}
            >
              {datauser ? (
                <div
                  style={{
                    marginBottom: '-18px',
                    marginLeft: lang == 'id' ? '22px' : '37px',
                    background: ThemeColor ? ThemeColor : '#FFFFFF',
                    color: 'white',
                    position: 'relative',
                    borderRadius: '148px',
                    width: '22px',
                    top: '0'
                  }}
                >
                  {onDeliveryTotalRecord}
                </div>
              ) : (
                ''
              )}
              {/* <img src="/assets/frontend/img/ic_setting_delivery.png" style={{ height: "32px", width: "32px", margin: "9px" }} /> */}
              <svg
                style={{
                  height: '32px',
                  width: '32px',
                  margin: '9px',
                  fill: 'none'
                }}
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.6667 14H13.6667C14.7667 14 15.6667 13.1 15.6667 12V2H6.66675C5.16675 2 3.85676 2.82999 3.17676 4.04999'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M2.6665 17C2.6665 18.66 4.0065 20 5.6665 20H6.6665C6.6665 18.9 7.5665 18 8.6665 18C9.7665 18 10.6665 18.9 10.6665 20H14.6665C14.6665 18.9 15.5665 18 16.6665 18C17.7665 18 18.6665 18.9 18.6665 20H19.6665C21.3265 20 22.6665 18.66 22.6665 17V14H19.6665C19.1165 14 18.6665 13.55 18.6665 13V10C18.6665 9.45 19.1165 9 19.6665 9H20.9565L19.2465 6.01001C18.8865 5.39001 18.2265 5 17.5065 5H15.6665V12C15.6665 13.1 14.7665 14 13.6665 14H12.6665'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.6665 22C9.77107 22 10.6665 21.1046 10.6665 20C10.6665 18.8954 9.77107 18 8.6665 18C7.56193 18 6.6665 18.8954 6.6665 20C6.6665 21.1046 7.56193 22 8.6665 22Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M16.6665 22C17.7711 22 18.6665 21.1046 18.6665 20C18.6665 18.8954 17.7711 18 16.6665 18C15.5619 18 14.6665 18.8954 14.6665 20C14.6665 21.1046 15.5619 22 16.6665 22Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22.6665 12V14H19.6665C19.1165 14 18.6665 13.55 18.6665 13V10C18.6665 9.45 19.1165 9 19.6665 9H20.9565L22.6665 12Z'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M2.6665 8H8.6665'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M2.6665 11H6.6665'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M2.6665 14H4.6665'
                  stroke='#2B2B2B'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <br />
              <p className='setting-subtext' style={{ fontSize: '14px' }}>
                {t('ondelivery')}
              </p>
            </div>
            <div
              onClick={() => {
                if (datauser) {
                  history.push('/orderhistory')
                  localStorage.setItem('ordertab', JSON.stringify(5))
                } else {
                  // if (landing&&landing.landing_page == 0) {
                  //   history.push("/login")
                  // } else {
                  //   history.push("/")
                  // }
                  history.push('/login')
                }
              }}
            >
              {datauser ? (
                <div
                  style={{
                    marginBottom: '-18px',
                    marginLeft: '22px',
                    background: ThemeColor ? ThemeColor : '#FFFFFF',
                    color: 'white',
                    position: 'relative',
                    borderRadius: '148px',
                    width: '22px',
                    top: '0'
                  }}
                >
                  {cancelTotalRecord}
                </div>
              ) : (
                ''
              )}
              {/* <img src="/assets/frontend/img/ic_setting_cancel.png" /> */}
              <svg
                style={{
                  height: '32px',
                  width: '32px',
                  margin: '9px',
                  fill: 'none'
                }}
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15 2V12C15 13.1 14.1 14 13 14H2V7.62C2.73 8.49 3.85003 9.03 5.09003 9C6.10003 8.98 7.01 8.59 7.69 7.94C8 7.68 8.26002 7.34999 8.46002 6.98999C8.82002 6.37999 9.02 5.65997 9 4.90997C8.97 3.73997 8.45001 2.71 7.64001 2H15Z'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22 14V17C22 18.66 20.66 20 19 20H18C18 18.9 17.1 18 16 18C14.9 18 14 18.9 14 20H10C10 18.9 9.1 18 8 18C6.9 18 6 18.9 6 20H5C3.34 20 2 18.66 2 17V14H13C14.1 14 15 13.1 15 12V5H16.84C17.56 5 18.22 5.39001 18.58 6.01001L20.29 9H19C18.45 9 18 9.45 18 10V13C18 13.55 18.45 14 19 14H22Z'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8 22C9.10457 22 10 21.1046 10 20C10 18.8954 9.10457 18 8 18C6.89543 18 6 18.8954 6 20C6 21.1046 6.89543 22 8 22Z'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22Z'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M22 12V14H19C18.45 14 18 13.55 18 13V10C18 9.45 18.45 9 19 9H20.29L22 12Z'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M9 5C9 5.75 8.78998 6.46 8.41998 7.06C8.20998 7.42 7.94 7.74 7.63 8C6.93 8.63 6.01 9 5 9C3.54 9 2.27002 8.22 1.58002 7.06C1.21002 6.46 1 5.75 1 5C1 3.74 1.58 2.61 2.5 1.88C3.19 1.33 4.06 1 5 1C7.21 1 9 2.79 9 5Z'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M6.06995 6.03992L3.95996 3.92993'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M6.04016 3.95996L3.93018 6.06995'
                  stroke='#F04438'
                  strokeWidth='1.5'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>

              <br />
              <p className='setting-subtext' style={{ color: 'red', fontSize: '14px' }}>
                {t('cancel1')}
              </p>
            </div>
          </div>
        </div>
        <CardListMobile
          links={[
            {
              title: t('account_settings'),
              link: '/accountsettings',
              icon: (
                <svg
                  style={{
                    height: '25px',
                    width: '25px',
                    margin: '9px 20px 10px 15px !important'
                  }}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.0002 9.99996C12.3013 9.99996 14.1668 8.13448 14.1668 5.83329C14.1668 3.53211 12.3013 1.66663 10.0002 1.66663C7.69898 1.66663 5.8335 3.53211 5.8335 5.83329C5.8335 8.13448 7.69898 9.99996 10.0002 9.99996Z'
                    stroke='#2B2B2B'
                    strokeWidth='1.2437'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M16.0081 13.1167L13.058 16.0667C12.9414 16.1834 12.833 16.4 12.808 16.5584L12.6497 17.6833C12.5914 18.0917 12.8747 18.375 13.283 18.3167L14.408 18.1583C14.5664 18.1333 14.7914 18.025 14.8997 17.9083L17.8497 14.9584C18.358 14.45 18.5997 13.8583 17.8497 13.1083C17.108 12.3667 16.5164 12.6083 16.0081 13.1167Z'
                    stroke='#2B2B2B'
                    strokeWidth='1.2437'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M15.583 13.5416C15.833 14.4416 16.533 15.1416 17.433 15.3916'
                    stroke='#2B2B2B'
                    strokeWidth='1.2437'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M2.8418 18.3333C2.8418 15.1083 6.05015 12.5 10.0002 12.5C10.8668 12.5 11.7001 12.625 12.4751 12.8583'
                    stroke='#2B2B2B'
                    strokeWidth='1.2437'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              )
            },
            {
              title: t('language'),
              link: '/selectlanguage',
              icon: (
                <svg
                  style={{
                    height: '25px',
                    width: '25px',
                    margin: '9px 20px 10px 15px !important'
                  }}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.16671 0.833313H2.50004C1.58337 0.833313 0.833374 1.58331 0.833374 2.49998V12.5L3.33337 9.99998H7.50004V9.16665C7.50004 7.33331 8.99171 5.83331 10.8334 5.83331V2.49998C10.8334 1.58331 10.0834 0.833313 9.16671 0.833313ZM9.16671 3.33331H7.91671C7.63337 4.32498 7.11671 5.24998 6.40004 6.04998L6.38337 6.06665L7.43337 7.10831L7.12504 7.94998L5.83337 6.66665L3.75004 8.74998L3.17504 8.14165L5.28337 6.06665C4.77391 5.50287 4.35758 4.86149 4.05004 4.16665H4.87504C5.13337 4.66665 5.45004 5.14165 5.83337 5.56665C6.41685 4.91894 6.86204 4.159 7.14171 3.33331H2.50004V2.49998H5.41671V1.66665H6.25004V2.49998H9.16671V3.33331ZM17.5 7.49998H10.8334C9.91671 7.49998 9.16671 8.24998 9.16671 9.16665V15C9.16671 15.9166 9.91671 16.6666 10.8334 16.6666H16.6667L19.1667 19.1666V9.16665C19.1667 8.24998 18.4167 7.49998 17.5 7.49998ZM16.3584 15.8333L15.65 13.9583H12.6834L11.9834 15.8333H10.7334L13.5417 8.33331H14.7917L17.6084 15.8333H16.3584ZM14.1667 9.99998L15.1834 12.7083H13.1584L14.1667 9.99998Z'
                    fill='#2B2B2B'
                  />
                </svg>
              )
            },
            {
              title: t('criticism_advice'),
              external: true,
              link: `//wa.me/6285296006009`,
              icon: (
                <div style={{ width: '43px' }}>
                  <AiFillCustomerService color='black' size={24} />
                </div>
              )
            },
            {
              title: t('contact_us'),
              external: true,
              link: `//wa.me/${
                landingPageData && landingPageData.setting_customer
                  ? landingPageData.setting_customer.phone_services
                  : ''
              }`,
              icon: (
                <svg
                  style={{
                    height: '25px',
                    width: '25px',
                    margin: '9px 20px 10px 15px !important'
                  }}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M18.3087 15.275C18.3087 15.575 18.242 15.8833 18.1003 16.1833C17.9587 16.4833 17.7753 16.7666 17.5337 17.0333C17.1253 17.4833 16.6753 17.8083 16.167 18.0166C15.667 18.225 15.1253 18.3333 14.542 18.3333C13.692 18.3333 12.7837 18.1333 11.8253 17.725C10.867 17.3166 9.90866 16.7666 8.95866 16.075C8.00032 15.375 7.09199 14.6 6.22532 13.7416C5.36699 12.875 4.59199 11.9666 3.90033 11.0166C3.21699 10.0666 2.66699 9.11663 2.26699 8.17496C1.86699 7.22496 1.66699 6.31663 1.66699 5.44996C1.66699 4.88329 1.76699 4.34163 1.96699 3.84163C2.16699 3.33329 2.48366 2.86663 2.92533 2.44996C3.45866 1.92496 4.04199 1.66663 4.65866 1.66663C4.89199 1.66663 5.12533 1.71663 5.33366 1.81663C5.55033 1.91663 5.74199 2.06663 5.89199 2.28329L7.82533 5.00829C7.97533 5.21663 8.08366 5.40829 8.15866 5.59163C8.23366 5.76663 8.27533 5.94163 8.27533 6.09996C8.27533 6.29996 8.21699 6.49996 8.10033 6.69163C7.99199 6.88329 7.83366 7.08329 7.63366 7.28329L7.00032 7.94163C6.90866 8.03329 6.86699 8.14163 6.86699 8.27496C6.86699 8.34163 6.87533 8.39996 6.89199 8.46663C6.91699 8.53329 6.94199 8.58329 6.95866 8.63329C7.10866 8.90829 7.36699 9.26663 7.73366 9.69996C8.10866 10.1333 8.50866 10.575 8.94199 11.0166C9.39199 11.4583 9.82532 11.8666 10.267 12.2416C10.7003 12.6083 11.0587 12.8583 11.342 13.0083C11.3837 13.025 11.4337 13.05 11.492 13.075C11.5587 13.1 11.6253 13.1083 11.7003 13.1083C11.842 13.1083 11.9503 13.0583 12.042 12.9666L12.6753 12.3416C12.8837 12.1333 13.0837 11.975 13.2753 11.875C13.467 11.7583 13.6587 11.7 13.867 11.7C14.0253 11.7 14.192 11.7333 14.3753 11.8083C14.5587 11.8833 14.7503 11.9916 14.9587 12.1333L17.717 14.0916C17.9337 14.2416 18.0837 14.4166 18.1753 14.625C18.2587 14.8333 18.3087 15.0416 18.3087 15.275Z'
                    stroke='#2B2B2B'
                    strokeWidth='1.2437'
                    strokeMiterlimit='10'
                  />
                </svg>
              )
            }
          ]}
        />

        <br />
        <br />
        <NavigationTab />
      </React.Fragment>
    </div>
  )
}
export default withNamespaces()(Settings)
