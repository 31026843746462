import React, { useEffect, useState } from 'react'

import { getOnlineStore, get as getVendorXApiKeyData } from '../api/vendor'
import { get as getLandingPage } from '../api/landing-page'

import Favicon from 'react-favicon'
import { getFileUrl } from '../utils/storageHelper'
import { createTheme } from '@material-ui/core/styles'
import i18n from '../containers/language/localization'

const defaultValues = {
  X_API_KEY: null,
  store: null,
  storeTitle: null,
  storefavicon: '/favicon.ico',
  storeSetting: null,
  storeSettingCMS: null,
  landingPage: null,
  webColor: '#000000',
  themeColor: '#000000',
  theme: createTheme({}),
  isMaintenance: false,
  maintenanceText: '',
  initStore: () => Promise.resolve()
}

const StoreContext = React.createContext(defaultValues)

const StoreProvider = props => {
  // localstorage
  const getTitleLocal = () =>
    localStorage.getItem('store_title') ??
    // old localstorage
    localStorage.getItem('app_name') ??
    process.env.REACT_APP_SITE_TITLE
  const setTitleLocal = data => localStorage.setItem('store_title', data)

  const getFaviconLocal = () => localStorage.getItem('store_favicon') ?? '/favicon.ico'
  const setFaviconLocal = data => localStorage.setItem('store_favicon', data)

  const getWebColorLocal = () =>
    localStorage.getItem('store_web_color') ??
    // old localstorage
    JSON.parse(localStorage.getItem('websiteColor')) ??
    '#000000'
  const setWebColorLocal = data => localStorage.setItem('store_web_color', data)

  const getThemeColorLocal = () =>
    localStorage.getItem('store_theme_color') ??
    // old localstorage
    JSON.parse(localStorage.getItem('themeColor')) ??
    '#000000'
  const setThemeColorLocal = data => localStorage.setItem('store_theme_color', data)

  // state
  const [X_API_KEY, setX_API_KEY] = useState(null)
  const [store, setStore] = useState(null)
  const [storeTitle, setStoreTitle] = useState(getTitleLocal)
  const [storefavicon, setStoreFavicon] = useState(getFaviconLocal)
  const [storeSetting, setStoreSetting] = useState(null)
  const [storeSettingCMS, setStoreSettingCMS] = useState(null)

  const [landingPage, setLandingPage] = useState(null)

  const [webColor, setWebColor] = useState(getWebColorLocal)
  const [themeColor, setThemeColor] = useState(getThemeColorLocal)

  const [theme, setTheme] = useState(createTheme({}))

  const [isMaintenance, setIsMaintenance] = useState(false)
  const [maintenanceText, setMaintenanceText] = useState('')

  const handleSetOnlineStore = data => {
    setStore(data)

    if (data.online_store) {
      // set state
      setStoreFavicon(getFileUrl(data.online_store.favicon))

      // set localstorage
      setFaviconLocal(getFileUrl(data.online_store.favicon))
    }

    if (data.online_store.title) {
      // set state
      setStoreTitle(data.online_store.title)

      // set localstorage
      setTitleLocal(data.online_store.title)

      // set title page
      document.title = data.online_store.title
    }

    if (data.online_store.website_color) {
      // set state
      setWebColor(data.online_store.website_color)
      setThemeColor(data.online_store.website_color)

      // set localstorage
      setWebColorLocal(data.online_store.website_color)
      setThemeColorLocal(data.online_store.website_color)
    }
  }

  const handleSetLandingPage = data => {
    data.product_categories = data.product_categories.filter(item => item.is_active === true)

    // sortir position
    data.product_categories.sort((a, b) => a.position - b.position)
    localStorage.setItem('landing_page', JSON.stringify(data))
    localStorage.setItem('landing_page_setting', JSON.stringify(data.setting))
    setStoreSetting(data.setting)
    setStoreSettingCMS(data.setting_cms)
    setLandingPage(data)
  }

  const initLanguage = () => {
    const lgs = JSON.parse(localStorage.getItem('Language')) || localStorage.getItem('lg')
    if (lgs === 'id') {
      localStorage.setItem('Language', JSON.stringify('id'))
    } else if (lgs === 'en') {
      localStorage.setItem('Language', JSON.stringify('en'))
    } else {
      localStorage.setItem('Language', JSON.stringify('id'))
    }

    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }

  // set webColor and themeColor after landingPage is set
  const initStore = () => {
    // old localstorage
    localStorage.setItem('websiteColor', JSON.stringify(getWebColorLocal()))
    localStorage.setItem('themeColor', JSON.stringify(getWebColorLocal()))

    setTheme(
      createTheme({
        palette: {
          primary: {
            main: themeColor ? themeColor : '#FFFFFF',
            color: '#FFFFFF'
          }
        }
      })
    )

    getVendorXApiKey()
  }

  const getVendorXApiKey = async () => {
    try {
      let baseUrl = window.location.origin

      // delete www
      if (baseUrl.includes('www.')) {
        baseUrl = baseUrl.replace('www.', '')
      }

      console.log(
        'REACT_APP_IS_CUSTOM_DOMAIN_NAME',
        process.env.REACT_APP_IS_CUSTOM_DOMAIN_NAME === 'true'
          ? process.env.REACT_APP_CUSTOM_DOMAIN_NAME
          : baseUrl
      )

      if (baseUrl.includes('localhost')) {
        baseUrl = 'https://store.motapos.id'
      }
      const response = await getVendorXApiKeyData(
        `{"url": "${
          process.env.REACT_APP_IS_CUSTOM_DOMAIN_NAME === 'true'
            ? process.env.REACT_APP_CUSTOM_DOMAIN_NAME
            : baseUrl
        }/" }`
      )
      if (response.success) {
        setX_API_KEY(response.data.x_api_key)
        localStorage.setItem('X_API_KEY', response.data.x_api_key)
      }
    } catch (error) {
      console.log('Error fetching getVendorXApiKey:', error)
    }
  }

  // get store and landing page data after X_API_KEY is set
  useEffect(() => {
    if (X_API_KEY) {
      getOnlineStore().then(data => {
        if (data) {
          handleSetOnlineStore(data)
        }
      })

      getLandingPage().then(data => {
        if (data) {
          handleSetLandingPage(data)
        }
      })
    }
  }, [X_API_KEY])

  // set webColor and themeColor after landingPage is set
  useEffect(() => {
    if (landingPage) {
      setIsMaintenance(landingPage.maintenance_detail.is_web_maintenance)
      setMaintenanceText(landingPage.maintenance_detail.maintenance_text)
    }
  }, [landingPage])

  // run initStore when component is mounted
  useEffect(() => {
    initLanguage()
    initStore()
  }, [])

  const values = {
    X_API_KEY,
    store,
    storeTitle,
    storeSetting,
    storeSettingCMS,
    landingPage,
    webColor,
    themeColor,
    theme,
    isMaintenance,
    maintenanceText,
    initStore
  }

  return (
    <StoreContext.Provider value={values}>
      <Favicon url={storefavicon} />
      {props.children}
    </StoreContext.Provider>
  )
}

export { StoreContext, StoreProvider }
