import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Avatar, Box, Container } from '@material-ui/core'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../home/DesktopFooter'
import { useStyles } from './MyAccount.style'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

// import ForumIcon from '@material-ui/icons/Forum'
import Card from '../../components/common/Card'
import ChangePasswordProfile from './pages/changePassword/ChangePasswordProfile'
import MyProfile from './pages/myProfile/MyProfile'
import MyAddress from './pages/myAddress/MyAddress'
import MyOrder from './pages/myOrder/MyOrder'
import MyWishlist from './pages/myWishlist/MyWishlist'
import AddAddress from './pages/myAddress/AddAddress'
import UpdateAddress from './pages/myAddress/UpdateAddress'
import DialogAlert from '../../components/common/DialogAlert'
import { t } from 'i18next'
import i18n from '../language/localization'
import { getBadgeCart } from '../../api/badge'
import { useAuth } from '../../hooks/useAuth'
import { useQuery } from '../../hooks/useQuery'
import { ArrowCircleLeft2, Heart, Lock, Message } from 'iconsax-react'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const MyAccount = props => {
  const history = useHistory()
  const searchParams = useQuery()
  const paramTab = searchParams.get('tab')
  const { token } = useAuth()

  const { active, webThemeColor } = props
  const classes = useStyles()
  const [navigate, setNavigate] = useState('')
  const [open, setOpen] = useState(true)
  const [categoriesData, setCategoriesData] = useState([])
  const [TotalCartItems, setItems] = useState(0)
  const [value, setValue] = React.useState(
    active === 'changeAddress' ? 1 : active === 'successOrderPay' ? 2 : 0
  )
  const usersdata = JSON.parse(localStorage.getItem('userdata'))
  const logout = () => {
    localStorage.clear()
    window.location.href = '/'
  }
  const close = () => {
    setOpen(false)
  }
  const openModal = () => {
    setOpen(true)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
    history.push(`/my-profile?tab=${newValue}`)
  }

  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    if (paramTab) {
      setValue(Number(paramTab))
    }
  }, [paramTab])

  const MenuItem = props => {
    return (
      <div className='menuItem'>
        {props.icon}
        <h4 className='menuTitle'>{props.title}</h4>
      </div>
    )
  }

  const themeColor = JSON.parse(localStorage.getItem('themeColor'))

  // console.log("navigate___ ", navigate);
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }
    getBadge()
  }, [])

  const getBadge = async () => {
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }

  return (
    <div className={classes.body}>
      <DesktopHeaderWrap
        // clickTab={() => setInlineCategory(false)}
        themeColor={'rgb(231, 0, 116)'}
        categoriesData={categoriesData}
        cartNumber={TotalCartItems}
      />

      <Container>
        <Box className={classes.root_MyAccount}>
          <h5 className={classes.pageTitle}></h5>
          <div className={classes.leftTabs_style}>
            <Card className={classes.cardStyle_LeftSide + ' ' + classes.left_Sidebar}>
              <div className={classes.user_profile_name} onClick={() => setValue(0)}>
                <Avatar
                  src={
                    usersdata
                      ? process.env.REACT_APP_FILE_BASE_URL + '/' + usersdata.profile_picture
                      : null
                  }
                  style={{ marginRight: 10 }}
                />

                <div className='media-body'>
                  <h3 className='profileName'>{usersdata ? usersdata.username : null}</h3>
                  <p className='font12 font-weight-normal text-white poppins mb-0'>
                    <span className='mr-1'>
                      <i className='fa fa-edit' aria-hidden='true'></i>
                    </span>
                    {t('edit')}
                  </p>
                </div>
              </div>

              <Tabs
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChange}
                aria-label='Vertical tabs example'
                className={classes.tabs + ' ' + ' ' + classes.Leftmenu}
              >
                <Tab
                  label={
                    <MenuItem
                      title={t('my_profile')}
                      icon={
                        <svg
                          className='MuiSvgIcon-root'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M12.16 10.87C12.06 10.86 11.94 10.86 11.83 10.87C9.45 10.79 7.56 8.84 7.56 6.44C7.56 3.99 9.54 2 12 2C14.45 2 16.44 3.99 16.44 6.44C16.43 8.84 14.54 10.79 12.16 10.87Z'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      }
                    />
                  }
                  {...a11yProps(0)}
                />

                <Tab
                  label={
                    <MenuItem
                      title={t('my_address')}
                      icon={
                        <svg
                          className='MuiSvgIcon-root'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M12 13.4314C13.7231 13.4314 15.12 12.0345 15.12 10.3114C15.12 8.58828 13.7231 7.19141 12 7.19141C10.2769 7.19141 8.88 8.58828 8.88 10.3114C8.88 12.0345 10.2769 13.4314 12 13.4314Z'
                            strokeWidth='1.5'
                          />
                          <path
                            d='M3.62001 8.49C5.59001 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.39001 20.54C5.63001 17.88 2.47001 13.57 3.62001 8.49Z'
                            strokeWidth='1.5'
                          />
                        </svg>
                      }
                    />
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label={
                    <MenuItem
                      title={t('my_order')}
                      icon={
                        <svg
                          className='MuiSvgIcon-root'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.17004 7.44141L12 12.5514L20.77 7.47137'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 21.613V12.543'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9.92999 2.48L4.59 5.45003C3.38 6.12003 2.39001 7.80001 2.39001 9.18001V14.83C2.39001 16.21 3.38 17.89 4.59 18.56L9.92999 21.53C11.07 22.16 12.94 22.16 14.08 21.53L19.42 18.56C20.63 17.89 21.62 16.21 21.62 14.83V9.18001C21.62 7.80001 20.63 6.12003 19.42 5.45003L14.08 2.48C12.93 1.84 11.07 1.84 9.92999 2.48Z'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M17 13.2416V9.5816L7.51001 4.10156'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      }
                    />
                  }
                  {...a11yProps(2)}
                />

                <Tab
                  label={<MenuItem title={t('my_wishlist')} icon={<Heart size={20} />} />}
                  {...a11yProps(3)}
                />
                <Tab
                  label={<MenuItem title={t('chat')} icon={<Message size={20} />} />}
                  {...a11yProps(4)}
                />

                <Tab
                  label={<MenuItem title={t('change_password')} icon={<Lock size={20} />} />}
                  {...a11yProps(5)}
                />
                <Tab
                  label={<MenuItem title={t('logout2')} icon={<ArrowCircleLeft2 size={20} />} />}
                  onClick={openModal}
                  {...a11yProps(6)}
                />
              </Tabs>
            </Card>
            <Card
              className={
                value == 2 || value == 3 ? classes.card_style_myOrder : classes.right_Sidebar
              }
              style={{ width: '100%' }}
            >
              <TabPanel value={value} index={0}>
                <MyProfile webThemeColor={webThemeColor} themeColor={themeColor} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {navigate == 'addAddress' ? (
                  <AddAddress
                    setNavigate={setNavigate}
                    webThemeColor={webThemeColor}
                    themeColor={themeColor}
                  />
                ) : navigate == 'myAddress' ? (
                  <MyAddress
                    setNavigate={setNavigate}
                    webThemeColor={webThemeColor}
                    themeColor={themeColor}
                  />
                ) : navigate == 'updateAddress' ? (
                  <UpdateAddress
                    setNavigate={setNavigate}
                    webThemeColor={webThemeColor}
                    themeColor={themeColor}
                  />
                ) : (
                  <MyAddress
                    setNavigate={setNavigate}
                    webThemeColor={webThemeColor}
                    themeColor={themeColor}
                  />
                )}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MyOrder webThemeColor={webThemeColor} themeColor={themeColor} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <MyWishlist valueTab={value} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                {t('content_will_coming_soon')}
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ChangePasswordProfile webThemeColor={webThemeColor} themeColor={themeColor} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <DialogAlert open={open} close={close} yesno='true' no={close} ok={logout}>
                  {t('ask_logout')}
                </DialogAlert>
              </TabPanel>
            </Card>
          </div>
        </Box>
      </Container>

      <DeskTopFooter themeColor={'rgb(231, 0, 116)'} />
    </div>
  )
}

export default MyAccount
