import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
// import { myOrderProductUser } from "../../store/actions/myOrderActions";
// import { useDispatch } from "react-redux";
// import { myOrderProductResponse } from "../../store/Selectors/myorder";
import InfiniteScroll from 'react-infinite-scroll-component'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import { get as getOrders } from '../../api/order'
import TableDetailOrder from './TableDetailOrder'

const useStyles = makeStyles(theme => ({
  orderBox: {
    border: '0.125rem solid #fff',
    minHeight: '6.25rem',
    backgroundColor: '#fff',
    borderRadius: '4px',
    fontWeight: '400',
    margin: '10px !important',
    '& h5': {
      fontSize: '14px',
      margin: '0.25rem 0'
    }
  },
  itemSpace: {
    '& span': {
      // marginLeft: "0.5rem",
    }
  },
  myDiv: {
    background: 'white'
  },
  myButton: {
    backgroundColor: 'white'
  }
}))

const Unpaid = ({ t, color }) => {
  const classes = useStyles()
  // const dispatch = useDispatch();
  const history = useHistory()
  let [responseData, setResponseData] = React.useState([])
  let [isNext, isNextFunc] = React.useState(false)
  let [pageCount, setCount] = React.useState(1)
  let [page, setPage] = React.useState('')
  let [status, setStatus] = React.useState(false)
  let [open, setOpen] = React.useState(false)
  const loadMoreData = () => {
    setCount(pageCount + 1)
  }
  React.useEffect(() => {
    if (pageCount !== 1) isNextFunc(true)
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append('user_id', userid);
    // data3.append('type', 'unpaid');
    // data3.append('page', pageCount);
    // dispatch(myOrderProductUser(data3));
    // setTimeout(() => {
    //   setOpen(true);
    // }, 700)
    const getOrderDataUnpaid = async () => {
      try {
        // belum done hardcode status
        const response = await getOrders(
          `{"order_status": "WAITING VALIDATION,UNPAID", "page": ${pageCount}, "limit": 15}`
        )
        if (response.success) {
          if (response.data && response.data.length > 0) {
            if (pageCount === 1) {
              setResponseData(response.data)
            } else {
              const dataValue = responseData.concat(response.data)
              setResponseData(dataValue)
            }
            isNextFunc(true)
            setPage(response.meta.total_pages)
            localStorage.setItem('unpaidorder', JSON.stringify(response.meta.total_count))
          }
        }
      } catch (error) {
        console.log('Error fetching get order:', error)
      }
    }
    getOrderDataUnpaid()
  }, [pageCount])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setStatus(true)
  //   }, 1200);
  //   return () => clearTimeout(timer);
  // }, []);
  const [open1, setOpen1] = useState(true)
  // const data1 = myOrderProductResponse();
  // console.log(data1);

  // if (open === true) {
  //   setResponseData([...responseData, ...data1.unpaid]);
  //   console.log("not iterable", responseData);
  //   isNextFunc(true);
  //   setPage(data1.total_page);
  //   setOpen(false);
  //   localStorage.setItem("unpaidorder", JSON.stringify(data1.total_record));
  // }

  const [orderId, setOrderId] = useState([])
  const setOrder = e => {
    for (var i = 0; i < responseData.length; i++) {
      if (responseData[i].order.id === e) {
        orderId.push(responseData[i])
      }
    }
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  return (
    <React.Fragment>
      {responseData.length > 0 ? (
        <InfiniteScroll
          style={{ minHeight: '750px', backgroundColor: '#FAFAFA' }}
          dataLength={responseData.length}
          next={loadMoreData}
          hasMore={isNext}
          // loader={
          //   <div style={{ height: "30%", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "8px" }}>
          //     {pageCount < page ? (
          //       <ClipLoader color={ThemeColor} height={50} style={{ marginTop: "8px", marginBottom: "8px", fontSize: "7", fontWeight: "600" }} />
          //     ) : (
          //       ""
          //     )}
          //   </div>
          // }
        >
          {responseData.map((order, idx) => (
            <div key={idx} className={`${classes.orderBox} p-2 py-1 shadow-card rounded`}>
              <TableDetailOrder order={order} key={idx} showExpired />

              <div
                className='d-flex justify-content-between p-2 rounded grey-box mt-2'
                style={{ backgroundColor: '#F0F4F7' }}
              >
                <h5 className={classes.itemSpace}>
                  Total Qty: <span>{order.order.item_qty} PCS</span>
                </h5>
                <h5 className={classes.itemSpace}>
                  {t('payment')} Rp.
                  {Object.keys(order.order.grand_total.toString()).length > 0 ? (
                    Object.keys(order.order.grand_total.toString()).length <= 3 ? (
                      <span>{order.order.grand_total.toString()}</span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 4 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-4, 1)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 5 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-5, 2)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 6 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 7 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-7, 1)}.
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 8 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-8, 2)}.
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : Object.keys(order.order.grand_total.toString()).length == 9 ? (
                      <span>
                        {order.order.grand_total.toString().substr(-9, 3)}.
                        {order.order.grand_total.toString().substr(-6, 3)}.
                        {order.order.grand_total.toString().substr(-3, 3)}
                      </span>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </h5>
              </div>
              <div className='d-flex justify-content-evenly mt-2' style={{ gap: '10px' }}>
                {/* {order.order.order_status === "PAYMENT DENIED" && order.order.is_moota === false ? (
                  <Button
                    variant="contained"
                    className="w-100 text-uppercase bg-success"
                    style={{ color: "white", position: "unset", fontSize: "11px" }}> // if confirmed green if not pink */}

                {/* {t("confirmation")}
                  </Button>
                ) : ""} */}
                {/* {order.order.order_status === "WAITING VALIDATION" && order.order.is_moota === false ?
                  <Button
                    variant="contained"
                    className="w-100 text-uppercase"
                    style={{
                      color: "white",
                      backgroundColor: "lightgreen",
                      fontSize: "11px",
                      padding: "8px",
                      position: "unset", 
                      // background: ThemeColor + " !important" 
                    // }}
                  // onClick={()=>{
                  //   setOrder(order.order.id);
                  //   localStorage.setItem("ordersdata", JSON.stringify(orderId));
                  //   history.push("/paymentconfirm");
                  // }}
                  >
                    {/* Confirmation Again
                  </Button> : ""
                } */}
                {/* {order.order.order_status === "unpaid" && order.order.is_moota === false ?
                  <Button
                    variant="contained"
                    className="w-100 text-uppercase"
                    style={{ color: "white", position: "unset", background: color, fontSize: "11px" }}
                    onClick={() => {
                      setOrder(order.order.id);
                      localStorage.setItem("ordersdata", JSON.stringify(orderId));
                      history.push("/paymentconfirm");
                    }}
                  >
                    {t("confirmation")}
                  </Button>
                  : ""
                } */}
                <Button
                  variant='contained'
                  className='w-100 text-uppercase'
                  style={{
                    color: 'white',
                    position: 'unset',
                    background: color
                  }}
                  onClick={() => {
                    //  setOrder(order.order.id);
                    localStorage.setItem('ordersdata', JSON.stringify([order]))
                    localStorage.setItem('ordertab', JSON.stringify(1))
                    history.push('/orderdetail')
                  }}
                >
                  {t('order_details')}
                </Button>
              </div>
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: 'white'
          }}
        >
          No Data Found
        </div>
      )}
      {/* <br />
      <br />
      <NavigationTab /> */}
    </React.Fragment>
  )
}

export default withNamespaces()(Unpaid)
