import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'

const GuestRoute = ({ component: Component, render: Render, ...rest }) => {
  const { token } = useAuth()

  return (
    <Route
      {...rest}
      render={props => {
        if (token) {
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        } else {
          return <Route path={props} component={Component} render={Render} />
        }
      }}
    />
  )
}

export default GuestRoute
