import React, { useState, useEffect } from 'react'
// import { addWishlistProductUser } from "../../store/actions/addWishlistActions";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import Pagination from '@material-ui/lab/Pagination'
import { getByClient as getProductsDetail } from '../../api/products'
import CardProduct from '../../components/card-product/CardProduct'
import MyPagination from 'components/Pagination'

const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))

const useCSS = makeStyles(() => ({
  hoverCard: {
    border: '1px solid #e8e7ea !important',
    transition: 'all 0.1s ease-out',
    marginTop: '3px',
    '&:hover': {
      border: '0.5px solid ' + ThemeColor + ' !important',
      transform: 'translateY(-2px) scale(1.005) translateZ(0)',
      cursor: 'pointer'
    }
  }
}))

const ProductByCategory = ({ t, sortBy, membershipLevel, setTotalRecord }) => {
  const [pageNo, setPageNo] = useState(1)

  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [MyPage, setMyPage] = useState()
  // eslint-disable-next-line no-unused-vars
  const [pageCount, setPageCount] = useState(1)
  const [NewData, setNewData] = useState([])
  const [find, setFind] = useState(false)
  // const dispatch = useDispatch();
  const history = useHistory()

  const categoryID = localStorage.getItem('viewproid')
  const searchname = JSON.parse(localStorage.getItem('desktopSearch'))

  useEffect(() => {
    const getProductsDetailData = async () => {
      try {
        let sortByValue = null
        let sortByAscDesc = null

        if (sortBy !== undefined) {
          switch (sortBy) {
            // case 1:
            //   sortByValue = `"order": "created_at"`
            //   sortByAscDesc = `"sort": "desc"`
            //   break;
            case 1:
              sortByValue = `"labels": "['Terbaru']"`
              break
            case 2:
              sortByValue = `"labels": "['Terlaris']"`
              break
            case 3:
              sortByValue = `"order": "price.${membershipLevel}"`
              sortByAscDesc = `"sort": "asc"`
              break
            case 4:
              sortByValue = `"order": "price.${membershipLevel}"`
              sortByAscDesc = `"sort": "desc"`
              break
            case 5:
              sortByValue = `"labels": "['Diskon']"`
              break
            default:
              break
          }

          const response = await getProductsDetail(
            `{
                "page":${pageCount}, "limit": 30, "query":"${searchname || ''}"
                ${categoryID ? `,"category_id":${categoryID}` : ''}
                ${sortByValue ? `,${sortByValue}` : ''}
                ${sortByAscDesc ? `,${sortByAscDesc}` : ''}
              }`
          )
          setMyPage(response.meta.total_pages)
          setTotalRecord(response.meta.total_count)
          // setMyData(response.data);
          setNewData(response.data)
          setFind(false)

          if (response.data && response.data.length < 5) {
            setFind(true)
          }
        }
      } catch (error) {
        console.log('Error fetching getProductsDetail:', error)
      }
    }

    getProductsDetailData()

    // FetchData();
  }, [sortBy, pageCount, searchname])

  const pageChange = async (event, value) => {
    setPageNo(value)
    let sortByValue = null
    let sortByAscDesc = null

    try {
      switch (sortBy) {
        case 1:
          sortByValue = `"labels": "['Terbaru']"`
          break
        case 2:
          sortByValue = `"order": "sold_quantity"`
          sortByAscDesc = `"sort": "desc"`
          break
        case 3:
          sortByValue = `"order": "price.${membershipLevel}"`
          sortByAscDesc = `"sort": "asc"`
          break
        case 4:
          sortByValue = `"order": "price.${membershipLevel}"`
          sortByAscDesc = `"sort": "desc"`
          break
        case 5:
          sortByValue = `"labels": "['Diskon']"`
          break
        default:
          break
      }

      const response = await getProductsDetail(
        `{
          "page":${value}, "limit": 30
          ${searchname ? `,"query": ${searchname}` : ''}
          ${categoryID ? `,"category_id":${categoryID}` : ''}
          ${sortByValue ? `,${sortByValue}` : ''}
          ${sortByAscDesc ? `,${sortByAscDesc}` : ''}
        }`
      )
      setMyPage(response.meta.total_pages)
      setNewData(response.data)
    } catch (error) {
      console.log('Error fetching getProductsDetail:', error)
    }
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        {/* <h1>{pageCount}</h1> */}
        <div
          className='container'
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            maxWidth: '100%'
          }}
        >
          {NewData && NewData.length > 0 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                overflow: 'auto',
                flexWrap: 'wrap',
                flexDirection: 'row',
                marginLeft: '0px',
                marginRight: '0px',
                marginTop: '0px',
                paddingLeft: '0px',
                paddingRight: '1px',
                height: find ? 'auto' : 'auto',
                clear: 'both',
                paddingBottom: Mobile ? '0px' : '50px',
                // columnGap: "7px",
                gap: '8px',
                rowGap: '6px'
              }}
            >
              {NewData.map((dt, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: 'calc(17.5% - 16.66px)',
                      clear: 'both'
                    }}
                  >
                    <CardProduct
                      membershipLevel={membershipLevel}
                      product={dt.product}
                      limitTitle={25}
                      width='100%'
                      heightWrapperImage='180px'
                      handleClick={() => history.push(`/product-detail?${dt.product.id}`)}
                    />
                  </div>
                )
              })}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh'
              }}
            >
              <b>{t('empty_product')}</b>
            </div>
          )}
        </div>
        {MyPage > 1 && <MyPagination count={MyPage} page={pageNo} handleChange={pageChange} />}
      </div>
    </React.Fragment>
  )
}

export default withNamespaces()(ProductByCategory)
