import React, { useEffect } from 'react'
// import { GetProductDataResponse } from "../../../store/Selectors/getproductdata";
// import { getProductData } from "../../../store/actions/GetProductdataActions";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
// import { singleProductDataUser } from "../../../store/actions/productDetailsActions";
import { ThemeColor } from '../../themecolor'
import Loader from '../../loader'
import { withNamespaces } from 'react-i18next'
import i18n from '../../language/localization'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import { addWishlistProductUser } from "../../../store/actions/addWishlistActions";
import { getByClient as getProductsDetail } from '../../../api/products'
import CardProduct from '../../../components/card-product/CardProduct'

const Discount = ({ t, membershipLevel, sortBy }) => {
  const history = useHistory()
  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const viewid =
    typeof localStorage.getItem('viewproid') === 'string'
      ? localStorage.getItem('viewproid')
      : JSON.parse(localStorage.getItem('viewproid'))
  let [responseData, setResponseData] = React.useState([])
  let [isNext, isNextFunc] = React.useState(false)
  let [pageCount, setCount] = React.useState(1)
  let [page, setPage] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const search = localStorage.getItem('searchname')

  const loadMoreData = () => {
    setCount(pageCount + 1)
  }

  React.useEffect(() => {
    const getProductsDetailData = async () => {
      setLoading(true)
      try {
        console.log('sortBy diskon', sortBy)
        let sortByValue = null
        switch (sortBy) {
          // case 1:
          //   sortByValue = `"order": "created_at"`
          //   sortByAscDesc = `"sort": "desc"`
          //   break;

          case 5:
            sortByValue = `"labels": "['Diskon']"`
            break

          default:
            break
        }
        const response = await getProductsDetail(`{
          "page":${pageCount},
          "limit": 10
          ${search ? `, "query": ${search}` : ''}
          ${sortByValue ? `,${sortByValue}` : ''}
          ${viewid ? `,"category_id":${viewid}` : ''}
        }`)
        setResponseData([...responseData, ...response.data])
        isNextFunc(true)
        setPage(response.meta.total_count)
      } catch (error) {
        console.log('Error fetching getProductsDetail:', error)
      } finally {
        setLoading(false)
      }
    }

    getProductsDetailData()
  }, [])

  const getSingleProduct = e => {
    history.push(`/productdetail?id=${e}`)
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          paddingTop: '250px',
          paddingBottom: '300px',
          flexDirection: 'column',
          backgroundColor: 'white',
          height: '100vh'
        }}
      >
        <div>
          <Loader />
        </div>
        <div>{t('loadingdot')}</div>
      </div>
    )
  }

  return (
    <div
      className='container bg-white'
      style={{
        paddingLeft: '0px',
        paddingRight: '0px',
        boxShadow: 'none',
        marginTop: '4px'
      }}
    >
      <React.Fragment>
        {responseData.length > 0 ? (
          <InfiniteScroll
            style={{
              backgroundColor: '#f5f5f5',
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              marginLeft: '0px',
              marginRight: '0px',
              marginTop: '0px',
              paddingLeft: '0px',
              paddingRight: '5px',
              paddingBottom: Mobile ? '0px' : '50px',
              marginBottom: responseData.length >= 6 ? '60px' : '0'
            }}
            dataLength={responseData.length}
            next={loadMoreData}
            hasMore={isNext}
            loader={
              <div style={{ height: '50%', overflow: 'hidden' }}>
                {pageCount < page ? (
                  <div
                    style={{
                      color: ThemeColor,
                      textAlign: 'center',
                      marginLeft: '160px'
                    }}
                  >
                    Loading...
                  </div>
                ) : (
                  ''
                )}
              </div>
            }
          >
            {responseData.map((product, index) => (
              <div
                style={{
                  width: '50%',
                  paddingLeft: '5px',
                  marginBottom: '6px'
                }}
                key={index}
              >
                <CardProduct
                  membershipLevel={membershipLevel}
                  product={product.product}
                  handleClick={getSingleProduct}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              paddingTop: '250px',
              paddingBottom: '300px',
              flexDirection: 'column',
              backgroundColor: 'white',
              height: '100vh'
            }}
          >
            <div>{t('empty_product')}</div>
          </div>
        )}
      </React.Fragment>
    </div>
  )
}
export default withNamespaces()(Discount)
