import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Add, Remove } from '@material-ui/icons/'
import { makeStyles } from '@material-ui/core'
import ProductOptionHeader from './ProductOptionHeader'
import { AiOutlineCheck } from 'react-icons/ai'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
// import { addToCartProductUser } from "../../store/actions/AddToCartActions";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import Loader from '../loader'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import Snackbar from '@material-ui/core/Snackbar'
// import { addNotes } from "../../store/actions/AddNoteActions";
// import { AddToCartResponse } from "../../store/Selectors/AddCart";
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import {singleProductDataUser} from "../../store/actions/productDetailsActions";

const ProductOption = ({ t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })
  const useStyles = makeStyles(theme => ({
    chatBtn: {
      backgroundColor: '#FFF',
      height: '100%',
      color: '#000',
      border: 'none',
      borderRadius: '0px',
      width: '100%',
      marginBottom: '9px',
      paddingBottom: '7px'
    },
    buyBtn: {
      height: '100%',
      backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF',
      color: '#fff',
      width: '100%',
      border: 'none',
      marginBottom: '9px',
      paddingBottom: '12px'
    },
    snackbarStyleViaContentProps: {
      backgroundColor: 'red !important',
      textAlign: 'center !important',
      color: 'white !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      fontWeight: '500 !important',
      fontSize: '15px !important',
      marginBottom: '2px !important',
      borderRadius: '10px !important'
    },
    snackbarStyleViaContentProps1: {
      backgroundColor: 'black !important',
      textAlign: 'center !important',
      color: 'white !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      fontWeight: '500 !important',
      fontSize: '17px !important',
      borderRadius: '3px !important',
      margin: '0px 0px 35px 0px !important'
    },

    snackbar_style: {
      '& .MuiSnackbarContent-message': {
        width: '100%'
      }
    },
    snackbar_message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& p': {
        marginBottom: '0'
      },
      '& .goToCart': {
        color: '#b4a86a'
      }
    }
  }))
  const classes = useStyles()
  // const dispatch = useDispatch();
  const history = useHistory()
  const [true1, setTrue1] = useState(true)
  const [trueopen, setTrueOpen] = useState(false)
  const [open5, setOpen5] = useState(false)
  const [open6, setOpen6] = useState(false)
  const [open9, setOpen9] = useState([])
  const [open10, setOpen10] = useState([])

  const [data1, setData1] = useState('')
  const [data2, setData2] = useState([])
  const [data3, setData3] = useState([])
  const [data4, setData4] = useState([])
  const [data5, setData5] = useState([])
  const [data6, setData6] = useState([])
  const [data7, setData7] = useState([])

  useEffect(() => {
    // const usercode = JSON.parse(localStorage.getItem("usercode"));
    // const userid = JSON.parse(localStorage.getItem("userid"));
    // const productId = JSON.parse(localStorage.getItem("productId"));
    // var data11 = new FormData();
    // data11.append("user_id", userid);
    // data11.append("user_code", usercode);
    // data11.append("product_id", productId);
    // dispatch(singleProductDataUser(data11));
    // fetch(`${baseUrlApi()}/Api/Product/singleProductData`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data11,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.ResponseCode == 1) {
    //       setData1(res);
    //       setData2(res.product_details);
    //       setData3(res.size_color_data.size_color);
    //       setData4(res.unique_size);
    //       setData5(res.unique_color);
    //       setData6(res.list_stock);
    //     }
    //   });
    // setTimeout(() => {
    //   setTrue1(true);
    // }, 700);
  }, [])

  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))

  const [quant, setQuant] = useState(1)
  const [open, setOpen] = useState(false)
  const [open7, setOpen7] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)

  const [stock, setStock] = useState([])
  const [stockId, setStockId] = useState([])
  const [price, setPrice] = useState([])
  const [total, setTotal] = useState([])

  const [notes, setNotes] = useState('')
  const [select, setSelect] = useState('')
  const [check, setCheck] = useState('')

  const incrementItem = e => {
    if (quant >= 1 && stock[0] > 0) {
      if (stock[0] >= quant + 1) {
        setQuant(quant + 1)
        const newp = JSON.parse(total[0]) + JSON.parse(price[0])
        total.unshift(newp)
      }
    }
  }

  const decrementItem = () => {
    if (quant >= 2) {
      setQuant(quant - 1)
      const newp = JSON.parse(total[0]) - JSON.parse(price[0])
      total.unshift(newp)
    }
  }

  useEffect(() => {
    // setOpen(true);
    setOpen2(true)
    setOpen3(false)
  }, [])

  if (open2) {
    let tempUser = data5.map(option => {
      return { ...option, size: [] }
    })
    setData5(tempUser)
    setOpen2(false)
    setOpen3(true)
  }

  if (open3) {
    for (var i = 0; i < data5.length; i++) {
      for (var j = 0; j < data3.length; j++) {
        if (data5[i].color === data3[j].color) {
          data5[i].size.push(data3[j].size[0])
        }
      }
    }
    setOpen(true)
    setOpen3(false)
    setOpen4(true)
  }

  if (open4) {
    for (var i2 = 0; i2 < data5.length; i2++) {
      for (var j2 = 0; j2 < data5[i2].size.length; j2++) {
        // console.log(i,j,data5[i].size.length);
        if (data5[i2].size.length > 1) {
          // console.log(data5[i].size[j].total==0);
          if (data5[i2].size[j2].total == 0) {
            // console.log("i",data5[i].size[j])
            data5[i2].size.splice(j2, 1)
          } else {
            if (data5[i2].size[j2].size == '') {
              data5[i2].size.splice(j2, 1)
            }
          }
        }
      }
    }
    setOpen4(false)
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  const AddToCartProduct = () => {
    if (stock[0] !== 0 && select !== '') {
      const UserId = JSON.parse(localStorage.getItem('userid'))
      if (UserId) {
        const Usercode = JSON.parse(localStorage.getItem('usercode'))
        let data = new FormData()
        data.append('user_code', Usercode)
        data.append('product_id', data2.id)
        data.append('weight', data2.weight)
        data.append('grand_total', total[0])
        data.append('qty', quant)
        data.append('size_color_id', stockId[0])
        data.append('date', '2019-03-12 15:33:15')
        data.append('user_id', UserId)
        data.append('note', notes)
        // dispatch(addToCartProductUser(data));
        setTimeout(() => setOpen7(true), 700)
      } else {
        alert('Please Login To Buy Any Product')
      }
    } else {
      setOpen1(true)
      setTimeout(() => setOpen1(false), 1000)
    }
  }

  const vertical = 'bottom',
    horizontal = 'center'

  const datas = {}
  if (open7) {
    if (datas.ResponseCode == 1) {
      let data = new FormData()
      data.append('cart_id', datas.cart_id)
      data.append('note', notes)
      // dispatch(addNotes(data));
      setTrueOpen(true)
      history.push('/cart')
      setTrueOpen(false)
    } else {
      alert(datas.ResponseMsg)
    }
    setOpen7(false)
  }

  const checkSizeProduct = e => {
    price.splice(0, price.length)
    total.splice(0, total.length)
    stock.splice(0, stock.length)
    stockId.splice(0, stockId.length)
    for (var i = 0; i < data6.length; i++) {
      if (data6[i].size == e && data6[i].color == select) {
        setCheck(e)
        price.push(data6[i].price)
        setQuant(1)
        total.push(data6[i].price)
        stock.push(data6[i].total)
        stockId.push(data6[i].id)
      }
    }
  }

  const ChangeColor = e => {
    for (var i = 0; i < data5.length; i++) {
      if (data5[i].color == e) {
        if (data5[i].size[0].size != '') {
          setCheck(data5[i].size[0].size)
          price.push(data5[i].size[0].price)
          setQuant(1)
          total.push(data5[i].size[0].price)
          stock.push(data5[i].size[0].total)
          stockId.push(data5[i].size[0].size_color_id)
        } else {
          total.push(data5[i].size[0].price)
          setCheck('')
          price.push(data5[i].size[0].price)
          setQuant(1)
          stock.push(data5[i].size[0].total)
          stockId.push(data5[i].size[0].size_color_id)
        }
      }
    }
  }

  return (
    <React.Fragment>
      <ProductOptionHeader color={ThemeColor} />
      <ThemeProvider theme={theme}>
        {true1 ? (
          <div className='productoption-height product-option' style={{ background: 'white' }}>
            {open ? (
              data2 ? (
                <React.Fragment>
                  <div style={{ marginTop: '53px' }}>
                    <div
                      className='d-flex mt-2 justify-content-start ps-2 mb-2'
                      style={{ gap: '10px' }}
                    >
                      <Zoom>
                        <img
                          src={
                            data1
                              ? data1.images[0].file_name != ''
                                ? data1.images[0].file_name
                                : '/assets/images/dummy.png'
                              : null
                          }
                          alt='demo-img'
                          style={{ width: '100px', height: '100px' }}
                        />
                      </Zoom>
                      <div className=''>
                        <h1
                          className='mb-1  product-option-price'
                          style={{
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none',
                            cursor: 'pointer',
                            width: Mobile ? '250px' : '350px'
                          }}
                        >
                          {data2.name}
                        </h1>

                        <div
                          className='mb-0 product-option-price'
                          style={{
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none',
                            cursor: 'pointer',
                            color: 'red'
                          }}
                        >
                          <b style={{ cursor: 'pointer' }}>
                            {price.length > 0 ? (
                              Object.keys(price[0].toString()).length > 0 ? (
                                Object.keys(price[0].toString()).length <= 3 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString()}
                                  </span>
                                ) : Object.keys(price[0].toString()).length == 4 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString().substr(-4, 1)}.
                                    {price[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(price[0].toString()).length == 5 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString().substr(-5, 2)}.
                                    {price[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(price[0].toString()).length == 6 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString().substr(-6, 3)}.
                                    {price[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(price[0].toString()).length == 7 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString().substr(-7, 1)}.
                                    {price[0].toString().substr(-6, 3)}.
                                    {price[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(price[0].toString()).length == 8 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString().substr(-8, 2)}.
                                    {price[0].toString().substr(-6, 3)}.
                                    {price[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(price[0].toString()).length == 9 ? (
                                  <span
                                    style={{
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none'
                                    }}
                                  >
                                    Rp {price[0].toString().substr(-9, 3)}.
                                    {price[0].toString().substr(-6, 3)}.
                                    {price[0].toString().substr(-3, 3)}
                                  </span>
                                ) : (
                                  ''
                                )
                              ) : (
                                ''
                              )
                            ) : (
                              '0'
                            )}
                          </b>
                          {/* <span className="badge badge-red ms-2">50%</span>
                    <s className="text-muted ms-4">Rp 5.000.000</s> */}
                        </div>
                        <p
                          className='mb-0 product-option-price mt-1'
                          style={{
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          {t('stock')} :{stock.length > 0 ? stock[0] : '0'}
                        </p>
                      </div>
                    </div>
                    <p
                      className='ps-2 mt-1 fw-normal'
                      style={{
                        marginBottom: '0.5rem',
                        MozUserSelect: 'none',
                        WebkitUserSelect: 'none',
                        MsUserSelect: 'none',
                        userSelect: 'none',
                        cursor: 'pointer',
                        display: 'flex'
                      }}
                    >
                      {t('color')}
                      <div style={{ marginLeft: '5px', marginRight: '5px' }}>:</div>
                      {select !== '' ? select : `${t('please_select')}`}
                    </p>
                    <div className='ms-1 m-2 my-2 d-flex justify-content-start align-item-center flex-wrap'>
                      {data5.map((colors, id) => (
                        <div
                          key={id}
                          style={{
                            flex: '0 1 18%',
                            marginRight: '6px'
                          }}
                        >
                          <div>
                            {colors ? (
                              colors.size ? (
                                <div>
                                  {colors.size[0].total != 0 ? (
                                    colors.color === select ? (
                                      <div
                                        key={id}
                                        className='card color-card mx-1 mb-3 ms-2'
                                        style={{
                                          MozUserSelect: 'none',
                                          WebkitUserSelect: 'none',
                                          MsUserSelect: 'none',
                                          userSelect: 'none',
                                          textAlign: 'center',
                                          fontSize: '12px',
                                          cursor: 'pointer',
                                          opacity: data1.total_stock != 0 ? '1' : '0.7',
                                          border: `1px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`
                                        }}
                                        onClick={() => {
                                          // if (data1.total_stock != 0) {
                                          setSelect(colors.color)
                                          total.splice(0)
                                          stock.splice(0)
                                          price.splice(0)
                                          stockId.splice(0)
                                          ChangeColor(colors.color)
                                          // }
                                        }}
                                      >
                                        <div
                                          className='color-change'
                                          style={{
                                            borderBottom: `15px solid ${
                                              ThemeColor ? ThemeColor : '#FFFFFF'
                                            }`
                                          }}
                                        />
                                        <AiOutlineCheck
                                          className='tick-align'
                                          color='#fff'
                                          style={{
                                            width: '10px',
                                            cursor: 'pointer'
                                          }}
                                        />
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '51px',
                                            maxWidth: '55px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          {colors.color}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        key={id}
                                        className='card color-card mx-1 mb-3 ms-2'
                                        style={{
                                          border: '1px solid grey',
                                          textAlign: 'center',
                                          fontSize: '12px',
                                          MozUserSelect: 'none',
                                          WebkitUserSelect: 'none',
                                          MsUserSelect: 'none',
                                          userSelect: 'none',
                                          cursor: 'pointer',
                                          opacity: data1.total_stock != 0 ? '1' : '0.7'
                                        }}
                                        onClick={() => {
                                          // if (data1.total_stock != 0) {
                                          setSelect(colors.color)
                                          total.splice(0)
                                          stock.splice(0)
                                          price.splice(0)
                                          stockId.splice(0)
                                          ChangeColor(colors.color)
                                          // }
                                        }}
                                      >
                                        <div
                                          className='color-change'
                                          style={{
                                            borderBottom: 'transparent'
                                          }}
                                        />
                                        <AiOutlineCheck
                                          className='tick-align'
                                          color='#fff'
                                          style={{
                                            width: '10px',
                                            background: 'transparent',
                                            cursor: 'pointer'
                                          }}
                                        />
                                        <div
                                          style={{
                                            width: '100%',
                                            height: '51px',
                                            maxWidth: '55px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          {colors.color}
                                        </div>
                                      </div>
                                    )
                                  ) : (
                                    <div
                                      key={id}
                                      className='card color-card mx-1 mb-3 ms-2'
                                      style={{
                                        border: '1px solid grey',
                                        textAlign: 'center',
                                        fontSize: '12px',
                                        MozUserSelect: 'none',
                                        WebkitUserSelect: 'none',
                                        MsUserSelect: 'none',
                                        userSelect: 'none',
                                        opacity: '0.7',
                                        cursor: 'pointer'
                                        // border: `1px solid ${ThemeColor ? ThemeColor : "#FFFFFF"}`
                                      }}
                                      onClick={() => {
                                        if (data1.total_stock != 0) {
                                          // setSelect(colors.color),
                                          total.splice(0)
                                          stock.splice(0)
                                          price.splice(0)
                                          // ChangeColor(colors.color);
                                        }
                                      }}
                                    >
                                      <div
                                        className='color-change'
                                        style={{ borderBottom: 'transparent' }}
                                      />
                                      <AiOutlineCheck
                                        className='tick-align'
                                        color='#fff'
                                        style={{
                                          width: '10px',
                                          background: 'transparent'
                                        }}
                                      />
                                      <div
                                        style={{
                                          width: '100%',
                                          height: '51px',
                                          maxWidth: '55px',
                                          overflow: 'hidden',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        {colors.color}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                    {data4.length > 0 ? (
                      <div>
                        <p
                          className='ps-2 mt-1 fw-normal'
                          style={{
                            marginBottom: '0.5rem',
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          {t('size')}: {check !== '' ? check : `${t('please_select')}`}
                        </p>
                        <div className='ms-1 m-2 my-2 d-flex justify-content-start align-item-center flex-wrap'>
                          {data4.map((sizes, id) => (
                            <div
                              key={id}
                              style={{
                                flex: '0 1 18%',
                                marginRight: '6px',
                                cursor: 'pointer'
                              }}
                            >
                              {sizes.size === check ? (
                                <div
                                  key={id}
                                  className='card color-card mx-1 mb-3 ms-2'
                                  style={{
                                    background: 'lightpink',
                                    height: '30px',
                                    fontSize: '12px',
                                    MozUserSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    MsUserSelect: 'none',
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                    opacity: data1.total_stock != 0 ? '1' : '0.7',
                                    border: `1px solid ${ThemeColor ? ThemeColor : '#FFFFFF'}`
                                  }}
                                  onClick={() => {
                                    if (data1.total_stock != 0) {
                                      checkSizeProduct(sizes.size)
                                    }
                                  }}
                                >
                                  <div
                                    className='color-change'
                                    style={{
                                      borderBottom: `15px solid ${
                                        ThemeColor ? ThemeColor : '#FFFFFF'
                                      }`
                                    }}
                                  />
                                  <AiOutlineCheck
                                    className='tick-align'
                                    color='#fff'
                                    style={{ width: '10px', cursor: 'pointer' }}
                                  />
                                  {sizes.size}
                                </div>
                              ) : (
                                <div
                                  key={id}
                                  className='card color-card mx-1 mb-3 ms-2'
                                  style={{
                                    border: '1px solid lightgrey',
                                    background: 'lightgrey',
                                    height: '30px',
                                    fontSize: '12px',
                                    MozUserSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    MsUserSelect: 'none',
                                    userSelect: 'none',
                                    cursor: 'pointer',
                                    opacity: data1.total_stock != 0 ? '1' : '0.7'
                                  }}
                                  onClick={() => {
                                    if (data1.total_stock != 0) {
                                      checkSizeProduct(sizes.size)
                                    }
                                  }}
                                >
                                  <div
                                    className='color-change'
                                    style={{ borderBottom: 'transparent' }}
                                  />
                                  <AiOutlineCheck
                                    className='tick-align'
                                    color='lightgrey'
                                    style={{
                                      width: '10px',
                                      background: 'transparent',
                                      cursor: 'pointer'
                                    }}
                                  />
                                  {sizes.size}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      className='fixed-bottom bg-white'
                      style={{
                        boxShadow: 'none',
                        maxWidth: '100wh',
                        margin: '0px auto',
                        padding: '0.5rem',
                        width: '100%',
                        borderRight: Mobile ? '0px' : '1px solid #d3d3d3a3',
                        borderLeft: Mobile ? '0px' : '1px solid #d3d3d3a3'
                      }}
                    >
                      <TextField
                        id='standard-basic'
                        label={t('optional_here') + '..'}
                        variant='standard'
                        className='mb-2'
                        fullWidth={true}
                        style={{
                          MozUserSelect: 'none',
                          WebkitUserSelect: 'none',
                          MsUserSelect: 'none',
                          userSelect: 'none'
                        }}
                        onChange={e => {
                          setNotes(e.target.value)
                        }}
                      />
                      <div
                        className='d-flex my-3 justify-content-between'
                        style={{
                          marginBottom: ' 0px !important'
                        }}
                      >
                        <h6
                          className=''
                          style={{
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          Total{' '}
                          <b style={{ cursor: 'pointer' }}>
                            {total.length > 0 ? (
                              Object.keys(total[0].toString()).length > 0 ? (
                                Object.keys(total[0].toString()).length <= 3 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString()}
                                  </span>
                                ) : Object.keys(total[0].toString()).length == 4 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString().substr(-4, 1)}.
                                    {total[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(total[0].toString()).length == 5 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString().substr(-5, 2)}.
                                    {total[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(total[0].toString()).length == 6 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString().substr(-6, 3)}.
                                    {total[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(total[0].toString()).length == 7 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString().substr(-7, 1)}.
                                    {total[0].toString().substr(-6, 3)}.
                                    {total[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(total[0].toString()).length == 8 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString().substr(-8, 2)}.
                                    {total[0].toString().substr(-6, 3)}.
                                    {total[0].toString().substr(-3, 3)}
                                  </span>
                                ) : Object.keys(total[0].toString()).length == 9 ? (
                                  <span
                                    className='ms-2'
                                    style={{
                                      marginBottom: '0.5rem',
                                      MozUserSelect: 'none',
                                      WebkitUserSelect: 'none',
                                      MsUserSelect: 'none',
                                      userSelect: 'none',
                                      color: 'red'
                                    }}
                                  >
                                    Rp {total[0].toString().substr(-9, 3)}.
                                    {total[0].toString().substr(-6, 3)}.
                                    {total[0].toString().substr(-3, 3)}
                                  </span>
                                ) : (
                                  ''
                                )
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </b>
                        </h6>
                        <span
                          className='pro-option-quantity pb-2'
                          style={{
                            marginBottom: '0.5rem',
                            MozUserSelect: 'none',
                            WebkitUserSelect: 'none',
                            MsUserSelect: 'none',
                            userSelect: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          <Remove className='me-4' onClick={decrementItem} />
                          {quant}
                          <Add className='ms-4' onClick={incrementItem} />
                        </span>

                        <div
                          className='d-flex fixed-bottom shadow-card'
                          style={{
                            marginBottom: '-14px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            height: '57px',
                            maxWidth: '100wh'
                          }}
                        >
                          <button className={classes.chatBtn}>
                            <img
                              src={'/assets/frontend/img/Header Icon/ic_message_top_si.png'}
                              className='me-1'
                              style={{
                                width: '24px',
                                height: '25px',
                                marginBottom: '1px'
                              }}
                              alt='chat'
                            />
                            <span style={{ fontSize: '17px' }}>{t('chat')}</span>
                          </button>
                          <button
                            className={classes.buyBtn}
                            onClick={() => {
                              AddToCartProduct()
                            }}
                          >
                            <span>{t('buy')}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                'No Data Found'
              )
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  paddingTop: '250px',
                  paddingBottom: '300px',
                  height: '100vh',
                  backgroundColor: 'white'
                }}
              >
                <div>
                  <Loader />
                </div>
                <div>Loading...</div>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              paddingTop: '250px',
              paddingBottom: '300px',
              flexDirection: 'column',
              height: '100vh',
              backgroundColor: 'white'
            }}
          >
            <div>
              <Loader />
            </div>
            <div>Loading...</div>
          </div>
        )}
      </ThemeProvider>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open1}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps
        }}
        style={{ textAlign: 'center', backgroundColor: 'red', color: 'white' }}
        message={
          // data1.total_stock != "0"
          // ? t("sorry_stock_not_available")
          // :
          'Please select size and color'
        }
        key={vertical + horizontal}
      />
      {trueopen ? (
        <Snackbar
          className={classes.snackbar_style}
          anchorOrigin={{ vertical, horizontal }}
          open={trueopen}
          ContentProps={{
            'aria-describedby': 'message-id',
            className: classes.snackbarStyleViaContentProps1
          }}
          // style={{textAlign:"center",backgroundColor:"white",color:"black"}}
          // message="Item Added To Cart Go To Cart"
          message={
            <div className={classes.snackbar_message}>
              <p>Item Added To Cart</p> <p className='goToCart'>Go To Cart</p>
            </div>
          }
          key={vertical + horizontal}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
export default withNamespaces()(ProductOption)
