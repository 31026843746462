import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Avatar } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Header from '../header/header2'
// import { updateUserData } from "../../store/actions/updateUserActions";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
// import { UpdateUserResponse } from "../../store/Selectors/UpdateUser"
// import { getAddressUser } from "../../store/actions/GetAddressActions";
// import { GetAddressResponse } from "../../store/Selectors/getaddress";
// import { getProvinceDataUser, getCityDataUser, getDistrictDataUser } from "../../store/actions/getProvinceCityDistrict"
// import { GetProvinceResponse, GetCityResponse, GetDistrictResponse } from "../../store/Selectors/getCityDistrictData"
// import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent'
// import { addAddressUser } from "../../store/actions/userActions";
import Button from '@material-ui/core/Button'
import Loader from '../loader'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import MuiDialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import { updateProfile, updateProfilePicture } from '../../api/authorization'
import { useDispatch } from 'react-redux'
import { setSnackbar } from 'stores/features/snackbar/snackbar'

const EditProfile = ({ t }) => {
  const dispatch = useDispatch()
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const theme = createTheme({
    palette: {
      primary: {
        main: ThemeColor ? ThemeColor : '#FFFFFF'
      }
    }
  })
  const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%'
      // backgroundColor: theme.palette.background.paper
    },
    filterBox: {
      border: '0.125rem solid #fff',
      backgroundColor: '#fff',
      margin: '12px 8px 12px 8px'
    },
    Dialogcss: {
      color: 'black',
      fontWeight: '500',
      marginBottom: '0px',
      padding: '0px',
      width: '400px',
      display: 'block',
      margin: '0px auto',
      paddingTop: '0px !important',
      [theme.breakpoints.down('xs')]: {
        width: '280px'
      }
    }
  }))
  const classes = useStyles()
  // const dispatch = useDispatch();
  const history = useHistory()
  const userid = JSON.parse(localStorage.getItem('userid'))
  const usersdata = JSON.parse(localStorage.getItem('userdata'))
  const [users, setUsers] = useState({
    address_id: '',
    customer_id: '',
    image: '',
    name: '',
    username: '',
    email: '',
    phone: '',
    address: '',
    province: '',
    city: '',
    subdistrict: '',
    province_id: '',
    city_id: '',
    district_id: '',
    subdistrict_id: '',
    postal_code: '',
    as_default: '',
    receiver_name: ''
  })
  const [true1, setTrue1] = useState(true)
  const [open, setOpen] = useState(false)
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)
  const [open4, setOpen4] = useState(false)
  const [addressid, setAddressId] = useState('')
  const [Image, setImage] = useState('')
  const [provinceData, setProvinceData] = useState([])
  const [cityData, setCityData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [addressDataDefault, setAddressDataDefault] = useState(null)
  const imageRef = useRef()
  const onChangeFile = e => {
    setImage(URL.createObjectURL(e.target.files[0]))
    setFile(e.target.files[0])
  }
  const [file, setFile] = useState('')

  const getData = () => {
    let data = new FormData()
    data.append('user_id', userid)
    // dispatch(getAddressUser(data));
  }

  useEffect(() => {
    // getData();
    // let data12 = new FormData();
    // dispatch(getProvinceDataUser(data12));

    setUsers({
      ...users,
      image: usersdata.profile_picture,
      name: usersdata.name,
      username: usersdata.username,
      email: usersdata.email,
      phone: usersdata.phone
    })
  }, [])

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    setTrue1(true)
  }, [])
  // const data1 = GetAddressResponse();
  // if (open) {
  //   if (data1) {
  //     for (var i = 0; i < data1.data.length; i++) {
  //       if (data1.data[i].as_default === 1) {
  //         const finalarray = data1.data.filter(
  //           (x) => x.id === data1.data[i].id
  //         );
  //         console.log(data1.data[0])
  //         setUsers({
  //           ...users,
  //           address: finalarray[0].address,
  //           province: finalarray[0].province_name,
  //           city: finalarray[0].city_name,
  //           city_id: finalarray[0].city_id,
  //           province_id: finalarray[0].province_id,
  //           district_id: finalarray[0].district_id,
  //           subdistrict: finalarray[0].district_name,
  //           postal_code: finalarray[0].postal_code,
  //           as_default: finalarray[0].as_default,
  //           name: finalarray[0].name
  //         });
  //         setAddressId(finalarray[0].id)
  //       }
  //     }
  //     setOpen(false);
  //   }
  // }

  const handleChange = e => {
    setUsers({ ...users, [e.target.name]: e.target.value })
  }

  // const datas = UpdateUserResponse();
  const [messageUpdateProfile, setMessageUpdateProfile] = useState('')
  const handleClickUpdateProfile = async () => {
    // if (addressid != "") {

    const data = {
      username: users.username,
      name: users.name,
      email: users.email,
      phone: users.phone,
      // hardcode
      language_id: 1
    }

    const response = await updateProfile(data)
    if (response.success) {
      localStorage.setItem('userdata', JSON.stringify(response.data))

      dispatch(
        setSnackbar({
          show: true,
          message: t('Success Update')
        })
      )
    } else {
      setMessageUpdateProfile(response.message)
    }

    if (Image !== '') {
      let formdata = new FormData()
      formdata.append('file', file)
      const responsePicture = await updateProfilePicture(formdata)
      if (responsePicture.success) {
        console.log('sukses update picture')
      } else {
        console.log('error update picture', responsePicture)
      }
    }
  }

  const handleclose = () => {
    setOpen4(false)
    history.push('/accountsettings')
  }

  const submit = () => {
    //
  }

  const Dialog = withStyles(theme => ({
    root: {
      margin: '0px !important',
      marginTop: '60px !important',
      [theme.breakpoints.down('xs')]: {
        width: '350px !important'
      }
    }
  }))(MuiDialog)

  return (
    <div>
      {true1 ? (
        <div style={{ backgroundColor: ThemeColor ? ThemeColor : '#FFFFFF' }}>
          <Header title={t('my_profile1')} color={ThemeColor} />
          <header
            className='h-100 mt-0 d-flex justify-content-center align-items-center'
            style={{
              background: ThemeColor ? ThemeColor : '#FFFFFF',
              paddingTop: '47px'
            }}
          >
            {/* <Avatar
            style={{ height: "10rem", width: "10rem" }}
            className="mb-4 mt-2"
            src={users.image}
          /> */}
            <div>
              <Avatar
                src={Image !== '' ? Image : process.env.REACT_APP_FILE_BASE_URL + '/' + users.image}
                onClick={() => imageRef.current.click()}
                style={{ height: '80px', width: '80px', cursor: 'pointer' }}
                className='mb-4 mt-2'
              />
              <input
                type='file'
                ref={imageRef}
                style={{ display: 'none' }}
                onChange={onChangeFile}
              />
            </div>
          </header>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            height: '100vh',
            backgroundColor: 'white'
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}

      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <div
            className={`p-0 border-0 shadow-card settingsCouponBox rounded d-flex align-items-center justify-content-center`}
            style={{
              margin: '10px'
            }}
          >
            <ValidatorForm
              className='w-100'
              style={{ padding: '15px', display: 'flex', flexDirection: 'column', gap: '15px' }}
              onSubmit={submit}
            >
              {/* <Grid container>
                    <Grid item xs={5} style={{ margin: "15px" }}>
                      <TextValidator
                        label={t("name")}
                        className=""
                        fullWidth={true}
                        onChange={handleChange}
                        value={users.name}
                        name="name"
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{ marginTop: "15px", maxWidth: '43.5%', flexBasis: '43.5%' }}
                      className=""
                    >
                      <TextValidator
                        label={t("last_name")}
                        className=""
                        fullWidth={true}
                        onChange={handleChange}
                        name="lastname"
                        value={users.lastname}
                        variant="standard"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                      />
                    </Grid>
                  </Grid> */}

              <Grid item xs={12}>
                <TextValidator
                  label={t('name')}
                  onChange={handleChange}
                  name='name'
                  fullWidth={true}
                  value={users.name}
                  variant='standard'
                  validators={['required']}
                  errorMessages={['this field is required']}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  label={t('username')}
                  onChange={handleChange}
                  name='username'
                  fullWidth={true}
                  value={users.username}
                  variant='standard'
                  validators={['required']}
                  errorMessages={['this field is required']}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  label={t('email')}
                  onChange={handleChange}
                  name='email'
                  fullWidth={true}
                  value={users.email}
                  variant='standard'
                  validators={['required']}
                  errorMessages={['this field is required']}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  label={t('phone_number')}
                  fullWidth={true}
                  onChange={handleChange}
                  name='phone'
                  value={users.phone}
                  variant='standard'
                  validators={['required']}
                  errorMessages={['this field is required']}
                  InputLabelProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
            </ValidatorForm>
          </div>
        </div>
        <div className='finish'>
          <button
            style={{
              fontSize: '1rem',
              width: '100%',
              height: '90%',
              color: '#fff',
              border: 'none',
              borderRadius: '6px',
              backgroundColor: `${ThemeColor ? ThemeColor : '#FFFFFF'}`
            }}
            onClick={() => {
              handleClickUpdateProfile()
            }}
          >
            {t('save')}
          </button>
        </div>
      </ThemeProvider>

      {messageUpdateProfile ? (
        <div>
          <Dialog
            open={true}
            onClick={handleclose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500'
              }}
            >
              {messageUpdateProfile}
              <br />
              <Button
                onClick={handleclose}
                style={{
                  color: ThemeColor ? ThemeColor : '#FFFFFF',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                Ok
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default withNamespaces()(EditProfile)
