import React from 'react'
import { withNamespaces } from 'react-i18next'

import { makeStyles } from '@material-ui/core'
import { ArrowDown, ArrowUp } from 'iconsax-react'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    padding: '5px',
    backgroundColor: 'white'
  },
  tabItem: {
    border: 'none',
    outline: 'none',
    width: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
    color: 'black',

    '&.active span': {
      color: props => props.themeColor
    },
    '&.active div': {
      borderBottom: props => `2px solid ${props.themeColor}`
    }
  },
  tabItemText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    height: '28px',
    width: '100%',
    fontSize: '12px',
    fontWeight: '600',
    borderRight: '1px solid #DEDEDE80',
    '& svg': {
      margin: '0px !important'
    }
  },
  tabItemIndicator: {
    position: 'absolute',
    left: 0,
    bottom: '-5px',
    width: '100%',
    height: '100%'
  }
}))

const TabSortProductMobile = ({ t, sortBy, setSortBy }) => {
  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const classes = useStyles({ themeColor: themeColor })

  const handleClickSetSortBy = sortByValue => {
    // check if sortByValue is equals sortBy then reset
    const valueSortBy = sortByValue === sortBy ? null : sortByValue
    setSortBy(valueSortBy)
  }

  const handleClickSetSortByPrice = () => {
    const sortPrice = sortBy === 4 ? 3 : sortBy === 3 ? null : 4
    handleClickSetSortBy(sortPrice)
  }

  return (
    <div className={`${classes.wrapper} shadow-tab`}>
      <button
        className={`${classes.tabItem} ${sortBy === 1 && 'active'}`}
        onClick={() => handleClickSetSortBy(1)}
      >
        <span className={classes.tabItemText}>{t('new')}</span>
        <div className={classes.tabItemIndicator}></div>
      </button>

      <button
        className={`${classes.tabItem} ${sortBy === 5 && 'active'}`}
        onClick={() => handleClickSetSortBy(5)}
      >
        <span className={classes.tabItemText}>{t('discount')}</span>
        <div className={classes.tabItemIndicator}></div>
      </button>

      <button
        className={`${classes.tabItem} ${sortBy === 2 && 'active'}`}
        onClick={() => handleClickSetSortBy(2)}
      >
        <span className={classes.tabItemText}>{t('best_seller')}</span>
        <div className={classes.tabItemIndicator}></div>
      </button>

      <button
        className={`${classes.tabItem} ${(sortBy === 3 || sortBy === 4) && 'active'}`}
        onClick={handleClickSetSortByPrice}
      >
        <span className={classes.tabItemText}>
          {t('price')}
          {sortBy === 3 && <ArrowUp size={15} />}
          {sortBy === 4 && <ArrowDown size={15} />}
        </span>
        <div className={classes.tabItemIndicator}></div>
      </button>
    </div>
  )
}

export default withNamespaces()(TabSortProductMobile)
