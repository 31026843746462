import { Box } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Skeleton from '@material-ui/lab/Skeleton'
import React, { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import NoDataFound from '../myAccount/compo/NoDataFound'
import { getByClient as getProductsDetail } from '../../api/products'
import CardProduct from '../../components/card-product/CardProduct'
import MyPagination from 'components/Pagination'

const usercode = JSON.parse(localStorage.getItem('usercode'))
const ProductData = ({ catId, sortBy, membershipLevel }) => {
  const [pageNo, setPageNo] = useState(1)
  const theme = useTheme()
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [MyPage, setMyPage] = useState()
  const [NewData, setNewData] = useState([])
  const [find, setFind] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    FetchData()
  }, [catId, sortBy])
  const FetchData = async () => {
    let sortByValue = null
    let sortByAscDesc = null
    switch (sortBy) {
      // case 1:
      //   sortByValue = `"order": "created_at"`
      //   sortByAscDesc = `"sort": "desc"`
      //   break;
      case 1:
        sortByValue = `"labels": "['Terbaru']"`
        break
      case 2:
        sortByValue = `"order": "sold_quantity"`
        sortByAscDesc = `"sort": "desc"`
        break
      case 3:
        sortByValue = `"order": "price.${membershipLevel}"`
        sortByAscDesc = `"sort": "asc"`
        break
      case 4:
        sortByValue = `"order": "price.${membershipLevel}"`
        sortByAscDesc = `"sort": "desc"`
        break
      case 5:
        sortByValue = `"labels": "['Diskon']"`
        break
      default:
        break
    }

    try {
      const response = await getProductsDetail(
        `{"page":1, "limit": 15, "category_id":${catId} ${
          sortByValue ? `,${sortByValue}` : ''
        } ${sortByAscDesc ? `,${sortByAscDesc}` : ''}}`
      )
      setMyPage(response.meta.total_pages)
      // setMyData(response.data);
      setNewData(response.data)
      setFind(false)
      setLoading(false)
      if (response.data && response.data.length < 5) {
        setFind(true)
      }
    } catch (error) {
      console.log('Error fetching getProductsDetail:', error)
    }

    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setMyPage(res.total_page);

    //     setMyData(res.data);
    //     setNewData(res.data);
    //     setFind(false);
    //     setLoading(false);
    //     if (res.data.length < 5) {
    //       setFind(true);
    //     }
    //   });
  }
  const [true1, setTrue1] = useState(false)

  useEffect(() => {
    let myData = new FormData()
    myData.append('user_code', usercode)
    // belum done add wishlist
    // dispatch(GetWishList(myData));
    setTrue1(true)
  }, [])

  const pageChange = async (event, value) => {
    // console.log("Pagination", event);

    setPageNo(value)
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // var formdata = new FormData();
    // // formdata.append("user_id", UserId);
    // // formdata.append("user_code", Usercode);
    // formdata.append("category", catId);
    // formdata.append("page", value);
    //
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setNewData(res.data);
    //     // for (var i = 0; i < res.data.length; i++) {
    //     //   // console.log("iii", res.data[i]);
    //     //   setNewData(res.data[i]);
    //     // //   if (!MyData.includes(res.data[i].id)) {
    //     // //     MyData.push(res.data[i]);

    //     // //     setNewData(getUnique(MyData, "id"));
    //     // //   }
    //     // }
    //   });

    let sortByValue = null
    let sortByAscDesc = null
    try {
      switch (sortBy) {
        // case 1:
        //   sortByValue = `"order": "created_at"`
        //   sortByAscDesc = `"sort": "desc"`
        //   break;
        case 1:
          sortByValue = `"labels": "['Terbaru']"`
          break
        case 2:
          sortByValue = `"order": "sold_quantity"`
          sortByAscDesc = `"sort": "desc"`
          break
        case 3:
          sortByValue = `"order": "price.${membershipLevel}"`
          sortByAscDesc = `"sort": "asc"`
          break
        case 4:
          sortByValue = `"order": "price.${membershipLevel}"`
          sortByAscDesc = `"sort": "desc"`
          break
        case 5:
          sortByValue = `"labels": "['Diskon']"`
          break
        default:
          break
      }
      const response = await getProductsDetail(
        `{"page":${value}, "limit": 18 ${
          catId ? `,"category_id":${catId}` : ''
        } ${sortByValue ? `,${sortByValue}` : ''} ${sortByAscDesc ? `,${sortByAscDesc}` : ''}}`
      )
      setMyPage(response.meta.total_pages)
      setNewData(response.data)
    } catch (error) {
      console.log('Error fetching getProductsDetail:', error)
    }
  }
  const getSingleProduct = idProduct => {
    // const usercode=JSON.parse(localStorage.getItem("usercode"));
    // const userid=JSON.parse(localStorage.getItem("userid"));
    // var data3 = new FormData();
    // data3.append("product_id", e);
    // if(userid){
    //   data3.append("user_id", userid);
    //   data3.append("user_code", usercode);
    // }
    // dispatch(singleProductDataUser(data3));
    // history.push("/productdetail");
    if (Mobile) {
      history.push(`/productdetail?id=${idProduct}`)
    } else {
      history.push(`/product-detail?${idProduct}`)
    }
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          className='container'
          style={{ paddingRight: '0px', paddingLeft: '0px', maxWidth: '100%' }}
        >
          {loading ? (
            <div
              style={{
                display: 'flex',
                columnGap: '10px',
                flexFlow: 'wrap',
                marginBottom: '4rem'
              }}
            >
              {['', '', '', '', '', '', '', '', '', ''].map((_data, index) => (
                <Skeleton
                  key={index}
                  animation='pulse'
                  height={430}
                  width={'calc(20% - 16.66px)'}
                  style={{
                    marginTop: '-5rem',
                    borderRadius: 5,
                    marginBottom: '-5rem'
                  }}
                />
              ))}
            </div>
          ) : NewData && NewData.length > 0 ? (
            <React.Fragment>
              <Box display='grid' gridTemplateColumns='repeat(5, 1fr)' style={{ gap: '5px' }}>
                {NewData.map((dt, index) => {
                  return (
                    <CardProduct
                      key={index}
                      width='100%'
                      membershipLevel={membershipLevel}
                      product={dt.product}
                      limitTitle={25}
                      heightWrapperImage='150px'
                      handleClick={getSingleProduct}
                    />
                  )
                })}
              </Box>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <MyPagination
                  count={MyPage}
                  variant='outlined'
                  shape='rounded'
                  page={pageNo}
                  handleChange={pageChange}
                />
              </div>
            </React.Fragment>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ProductData)
