import { createSlice } from '@reduxjs/toolkit'
import { getProducts } from '../../../api/products'

const initialState = {
  newestProducts: [],
  loading: 'idle'
}

export const newestProducts = createSlice({
  name: 'newestProducts',
  initialState,
  reducers: {
    newestProductsLoading(state, action) {
      state.loading = action.payload
    },

    newestProductsReceived(state, action) {
      state.newestProducts = action.payload
    }
  }
})

export const { newestProductsLoading, newestProductsReceived } = newestProducts.actions

const fetchNewestProducts = () => async dispatch => {
  try {
    dispatch(newestProductsLoading('loading'))
    const products = await getProducts(
      JSON.stringify({
        page: 1,
        limit: 10,
        labels: '["Terbaru"]',
        status: 'live'
      })
    )

    if (products.success) {
      dispatch(newestProductsReceived(products.data || []))
      dispatch(newestProductsLoading('success'))
    } else {
      dispatch(newestProductsLoading('success'))
    }
  } catch (error) {
    console.log('async reducer ', error)
    dispatch(newestProductsLoading('error'))
  }
}

export { fetchNewestProducts }

export default newestProducts.reducer
