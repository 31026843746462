import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import i18n from '../language/localization'
import CardProduct from '../../components/card-product/CardProduct'

const RelatedProduct = ({ dataSimilar, color, t }) => {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)
  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [])

  const useStyles = makeStyles(theme => ({
    box: {
      boxShadow: '0px 0px 8px -1px rgb(219 213 213)',
      '& h6': {
        color: color
      }
    },

    cards_style: {
      transition: 'all 0.1s ease-out',

      '& .card': {
        border: '1px solid transparent !important',
        marginTop: '3px'
      },
      '& .card:hover': {
        border: '1px solid ' + ThemeColor + ' !important',

        transform: 'translateY(-2px) scale(1.005) translateZ(0)',
        cursor: 'pointer'
      }
    },

    cardTitle: {
      '& .read-more-less--more': {
        display: 'none'
      }
    },

    imageslide: {
      '&>.image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail.active':
        {
          border: `1px solid ${color} !important`
        },
      '&>.image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail:hover':
        {
          border: `3px solid ${color} !important`
        }
      // "& image-gallery-thumbnail.active": {
      //
      // }
    },
    title: {
      fontSize: '13px'
    },
    avatarfav: {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      marginBottom: '60px'
    },
    avatarwhat: {
      backgroundColor: 'rgba(20, 9, 9, 0.3);',
      border: '1px solid #fff',
      marginBottom: '5px'
    },
    sectionHeader: {
      // gap: "10px",
      // display: "flex",
      // backgroundColor: "#d5d5d5",
      // padding: "10px 20px",
      // fontSize: "16px",
      fontSize: '20px',
      fontWeight: '600',
      userSelect: 'none',
      paddingLeft: '5px'

      // "& .link": {
      //   color: "#323232",
      //   cursor: "pointer",
      // },
    },
    whatsAppIcon: {
      background: 'rgba(37, 211, 102, 1)',
      borderRadius: '50%'
    },
    favBorderIcon: {
      backgroundColor: color,
      marginBottom: '0px'
    },
    wholeSale: {
      color: 'rgba(68, 210, 255, 1)',
      fontSize: '10px'
    },
    colorBox: {
      height: '3.4rem',
      width: '3.5rem',
      color: 'black'
    },
    icons: {
      color: '#8B8B8B'
    },
    chatBtn: {
      backgroundColor: '#FFF',
      color: '#000',
      border: 'none',
      borderRadius: '0px',
      width: '100%',
      height: '100%',
      marginBottom: '9px',
      paddingBottom: '5px'
    },
    buyBtn: {
      backgroundColor: color,
      color: '#fff',
      width: '100%',
      border: 'none',
      height: '100%',
      marginBottom: '12px',
      paddingBottom: '12px'
    },
    specificationTable: {
      color: 'rgba(139, 139, 139, 1)',
      width: '100%',
      borderCollapse: 'collapse',
      '& td': {
        paddingTop: '0.4rem',
        paddingBottom: '0.4rem',
        paddingLeft: '0.25rem'
      },
      '& td:nth-child(1)': {
        width: '40%'
      },
      '& tr:nth-child(odd)': {
        backgroundColor: '#F2F0F1'
      }
    },
    bestSeller: {
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
      backgroundColor: color,
      color: '#fff',
      fontSize: '0.75rem',
      borderRadius: '0.25rem'
    },
    shareButton: {
      backgroundColor: '#E4E5E9',
      border: 'none',
      width: '25%',
      fontSize: '10px',
      // padding: "0.35rem",
      textAlign: 'center'
    },
    wishIconDiv: {
      marginRight: '10px',
      [theme.breakpoints.down('xs')]: {
        marginRight: '15px'
      },
      [theme.breakpoints.down(375)]: {
        marginRight: '7px'
      }
    },
    snackbarStyleViaContentProps1: {
      backgroundColor: 'black !important',
      textAlign: 'center !important',
      color: 'white !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'center !important',
      fontWeight: '500 !important',
      fontSize: '17px !important',
      borderRadius: '3px !important',
      margin: '0px 30px !important'
    },
    desdiv: {
      '& p': {
        marginBottom: '0px !important'
      }
    },

    mobile_slider_cards: {
      '& .card-body': {
        // minHeight: '117px',
        padding: '5px !important'
      },
      '& .mainImageThum': {
        height: '200px'
      },
      '& .cardTitle ': {
        //  textTransform: 'uppercase',
        fontSize: '13px !important'
      }
    }
  }))
  const classes = useStyles()

  const getSingleProduct = e => {
    window.location.href = '/product-detail?' + e + ''
  }

  return (
    <div>
      <Box className={classes.sectionHeader}>{t('related_products')}</Box>
      <div>
        <div
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            paddingTop: '10px',
            paddingBottom: '22px',
            marginBottom: '2rem',
            display: 'grid',
            gridTemplateColumns: 'repeat(5, 1fr)',
            gap: '5px'
          }}
        >
          {dataSimilar.map((dt, index) => {
            return (
              <CardProduct
                key={index}
                width='100%'
                membershipLevel={membershipLevel}
                product={dt.product}
                handleClick={getSingleProduct}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default RelatedProduct
