import React from 'react'
import { t } from 'i18next'
import { Grid, Box, Button, makeStyles } from '@material-ui/core'
import Amount from 'components/common/Amount'
import { useStore } from 'hooks/useStore'
import moment from 'moment'
import {
  discountPercentageOrderItem,
  isDiscountOrderItem,
  priceAfterDiscountOrderItem
} from 'utils/pricing'
import { getWeightProduct, orderGetPaymentMethod, priceFormatLocal } from 'utils/helper'

const useStyles = makeStyles({
  btn_reset: {
    color: '#44910a !important',
    textTransform: 'lowercase',
    fontSize: '14px',
    fontWeight: '600',
    padding: '0',
    minWidth: '37px'
  },

  cardStyle: {
    padding: '0'
  },

  header: {
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
    marginBottom: '15px'
  },

  input_style: {
    '& .MuiFormControl-root': {
      marginBottom: '0 !important'
    }
  },

  cardItem: {
    backgroundColor: 'white',
    border: '1px solid #fbfbfb',
    padding: '0 15px',
    marginBottom: '15px',
    borderRadius: '8px',
    boxShadow: '1px 0px 5px #ccc',

    '& .header_cardItem': {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '10px',
      borderBottom: '1px solid #dee2e6!important',
      marginBottom: '0',

      '& .description': {
        // fontSize: "14px",
        color: '#545454',
        marginBottom: '5px'
      }
    },
    '& .Shipping': {
      display: 'flex',
      // gap: "5px",
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .ShippingStart': {
      display: 'flex',
      // gap: "5px",
      alignItems: 'center',
      justifyContent: 'start'
    },
    '& .ShippingEnd': {
      display: 'flex',
      // gap: "5px",
      alignItems: 'center',
      justifyContent: 'end'
    }
  },

  content_cardItem: {
    padding: '20px 10px',

    '& .boldTitle': {
      fontSize: '14px',
      color: '#545454',
      fontWeight: '500',
      marginBottom: '15px'
    },
    '& .description': {
      fontSize: '14px',
      color: '#545454'
    }
  },
  btn_payment: {
    backgroundColor: '#01a2e8'
  },

  footer: {
    borderTop: '1px solid #dee2e6!important',

    padding: '10px 10px !important',

    '& .btnGroup': {
      display: 'flex',
      gap: '10px'
    },
    '& .MuiButtonBase-root': {
      marginBottom: '0',
      textTransform: 'capitalize'
    },
    '& .btn_see': {
      float: 'right'
    }
  },

  content_see_hide: {
    borderTop: '1px solid #dee2e6!important',
    padding: '10px 10px !important',
    margin: '0px 10px 0px 10px',

    '& .content': {
      width: '330px',
      maxWidth: '100%',
      fontSize: '14px',
      color: '#545454'
    },
    '& .titleContent': {
      fontWeight: '600',
      marginBottom: '10px',
      fontSize: '14px'
    },
    '& .price': {
      marginBottom: '15px',
      fontSize: '14px',
      color: '#545454 !important'
    }
  },

  item_see_hide: {
    borderBottom: '1px solid #dee2e6',
    padding: '10px 0px 10px 0 !important'
  },

  price_item_see_hide: {
    borderBottom: 'none'
  },

  square_box: {
    backgroundColor: '#ededed',
    height: '100px',
    width: '100px'
  },

  item: {
    display: 'flex',
    gap: '10px'
  },

  price_item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .price': {
      marginBottom: '0.5rem'
    },
    '& .lable': {
      fontSize: '14px'
    }
  },

  content_price: {
    width: '400px',
    marginLeft: 'auto'
  },

  // --------------------------------------------------

  tableContent: {
    borderBottom: '1px solid gray',
    paddingBottom: '15px',
    marginBottom: '20px',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '0px'
    }
  },

  description_style: {
    '& .title_de': {
      color: '#000',
      fontSize: '14px',
      marginBottom: '5px'
    },
    '& .values': {
      marginBottom: '0',
      fontSize: '14px'
    },

    '& .thumbImg': {
      width: '100%'
    }
  },

  priceContent_style: {
    '& .values': {
      marginBottom: '0',
      fontSize: '14px'
    },
    '& .discount-rate span': {
      padding: '3px 10px',
      fontSize: '14px',
      position: 'absolute'
    },
    '& .price-wdout': {
      textDecoration: 'line-through'
    }
  },
  gTotal: {
    fontSize: '14px'
  },
  pcs: {
    fontSize: '14px'
  }
})

const CardHistoryOrder = ({ data, showDetail, setShowDetail, webThemeColor }) => {
  const { landingPage } = useStore()
  const classes = useStyles()

  return (
    <Box className={classes.cardItem}>
      <header className='header_cardItem'>
        <Grid container style={{ height: '35px' }}>
          <Grid item style={{ maxWidth: '16%', flexBasis: '16%' }}>
            <div className='ShippingStart' style={{ paddingTop: '5px' }}>
              <label style={{ fontSize: '17px' }}>#</label>
              <label style={{ fontSize: '17px' }}>{data.order.order_number}</label>
            </div>
          </Grid>
          <Grid item style={{ maxWidth: '21%', flexBasis: '21%' }}>
            <div className='Shipping'>
              <svg
                className={'MuiSvgIcon-root'}
                style={{ fill: 'transparent' }}
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.33334 1.33203V3.33203'
                  stroke='#2B2B2B'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10.6667 1.33203V3.33203'
                  stroke='#2B2B2B'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M2.33334 6.05859H13.6667'
                  stroke='#2B2B2B'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M14 5.66536V11.332C14 13.332 13 14.6654 10.6667 14.6654H5.33333C3 14.6654 2 13.332 2 11.332V5.66536C2 3.66536 3 2.33203 5.33333 2.33203H10.6667C13 2.33203 14 3.66536 14 5.66536Z'
                  stroke='#2B2B2B'
                  strokeMiterlimit='10'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10.4631 9.13411H10.4691'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10.4631 11.1341H10.4691'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.997 9.13411H8.00299'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.997 11.1341H8.00299'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.52953 9.13411H5.53552'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.52953 11.1341H5.53552'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <label style={{ fontSize: '15px' }}>
                {moment(data.order.created_at).format('DD-MM-YYYY hh:mm')}
              </label>
            </div>
          </Grid>
          <Grid item style={{ maxWidth: '16%', flexBasis: '16%' }}>
            <div className='Shipping'>
              <svg
                className={'MuiSvgIcon-root'}
                style={{ fill: 'transparent' }}
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.66666 6H4.66666'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M14.6666 7.31278V8.68616C14.6666 9.05282 14.3733 9.3528 14 9.36613H12.6933C11.9733 9.36613 11.3133 8.83947 11.2533 8.11947C11.2133 7.69947 11.3733 7.30613 11.6533 7.0328C11.9 6.77946 12.24 6.63281 12.6133 6.63281H14C14.3733 6.64615 14.6666 6.94612 14.6666 7.31278Z'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.6533 7.03202C11.3733 7.30536 11.2133 7.69869 11.2533 8.11869C11.3133 8.83869 11.9733 9.36536 12.6933 9.36536H14V10.332C14 12.332 12.6667 13.6654 10.6667 13.6654H4.66667C2.66667 13.6654 1.33334 12.332 1.33334 10.332V5.66536C1.33334 3.85203 2.42667 2.58536 4.12667 2.37203C4.3 2.34536 4.48 2.33203 4.66667 2.33203H10.6667C10.84 2.33203 11.0067 2.33869 11.1667 2.36536C12.8867 2.56536 14 3.8387 14 5.66536V6.63204H12.6133C12.24 6.63204 11.9 6.77869 11.6533 7.03202Z'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <label style={{ fontSize: '15px' }}>{data.order.order_status}</label>
            </div>
          </Grid>
          <Grid item style={{ maxWidth: '25%', flexBasis: '25%' }}>
            <div className='Shipping'>
              <svg
                className={'MuiSvgIcon-root'}
                style={{ fill: 'transparent' }}
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.78133 9.55453C5.78133 10.4145 6.44133 11.1079 7.26133 11.1079H8.93466C9.64799 11.1079 10.228 10.5012 10.228 9.75453C10.228 8.9412 9.87466 8.65453 9.34799 8.46786L6.66133 7.53453C6.13466 7.34786 5.78133 7.0612 5.78133 6.24786C5.78133 5.5012 6.36133 4.89453 7.07466 4.89453H8.74799C9.56799 4.89453 10.228 5.58786 10.228 6.44786'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M7.99997 4V12'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M9.99996 14.6654H5.99996C2.66663 14.6654 1.3333 13.332 1.3333 9.9987V5.9987C1.3333 2.66536 2.66663 1.33203 5.99996 1.33203H9.99996C13.3333 1.33203 14.6666 2.66536 14.6666 5.9987V9.9987C14.6666 13.332 13.3333 14.6654 9.99996 14.6654Z'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <label style={{ fontSize: '15px' }}>{orderGetPaymentMethod(data.order)}</label>
            </div>
          </Grid>
          <Grid item style={{ maxWidth: '22%', flexBasis: '22%' }}>
            <div className='ShippingEnd'>
              <svg
                className={'MuiSvgIcon-root'}
                style={{ fill: 'transparent' }}
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8 9.33203H8.66667C9.4 9.33203 10 8.73203 10 7.9987V1.33203H4C3 1.33203 2.12667 1.88536 1.67334 2.69869'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.33334 11.332C1.33334 12.4387 2.22668 13.332 3.33334 13.332H4.00001C4.00001 12.5987 4.60001 11.9987 5.33334 11.9987C6.06668 11.9987 6.66668 12.5987 6.66668 13.332H9.33334C9.33334 12.5987 9.93334 11.9987 10.6667 11.9987C11.4 11.9987 12 12.5987 12 13.332H12.6667C13.7733 13.332 14.6667 12.4387 14.6667 11.332V9.33203H12.6667C12.3 9.33203 12 9.03203 12 8.66536V6.66536C12 6.2987 12.3 5.9987 12.6667 5.9987H13.5267L12.3867 4.00537C12.1467 3.59204 11.7067 3.33203 11.2267 3.33203H10V7.9987C10 8.73203 9.40001 9.33203 8.66668 9.33203H8.00001'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M10.6667 14.6667C11.4031 14.6667 12 14.0697 12 13.3333C12 12.597 11.4031 12 10.6667 12C9.9303 12 9.33334 12.597 9.33334 13.3333C9.33334 14.0697 9.9303 14.6667 10.6667 14.6667Z'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M14.6667 8V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.6667 8Z'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.33334 5.33203H5.33334'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.33334 7.33203H4.00001'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.33334 9.33203H2.66668'
                  stroke='#2B2B2B'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              {data.order.shipping && data.order.shipping_type !== 'COURIER RAJA ONGKIR' ? (
                <label style={{ fontSize: '15px' }}>
                  {data.order.shipping ? data.order.shipping.name : ''}
                </label>
              ) : (
                <label style={{ fontSize: '15px' }}>
                  {
                    (data.order.shipping ? data.order.shipping.service : '') +
                      ' ' +
                      (data.order.shipping ? data.order.shipping.name : '')
                    // +
                    // " " +
                    // "(" +
                    // data.order.shipping.estimation +
                    // ") " +
                    // "days"
                  }
                </label>
              )}
            </div>
          </Grid>
        </Grid>
      </header>

      <Grid container spacing={2} className={classes.content_cardItem}>
        <Grid item md={2}>
          <h5 className='boldTitle'>{t('no_invoice')}</h5>
          <p className='description'>#{data.order.order_number}</p>
        </Grid>
        <Grid item md={2}>
          <h5 className='boldTitle'>{t('receiver_name')}</h5>
          <p className='description'>{data.order.customer.name}</p>
        </Grid>
        <Grid item md={4}>
          <h5 className='boldTitle'>{t('address')}</h5>
          <p className='description'>
            {data.order.customer_address ? data.order.customer_address.address : ''}
          </p>
        </Grid>
        <Grid item md={2}>
          <h5 className='boldTitle'>{t('total_quantity')}</h5>
          <p className='description'>{data.order.item_qty} Pcs</p>
        </Grid>
        <Grid item md={2}>
          <h5 className='boldTitle'>{t('total_price')}</h5>
          <p className='description'>
            Rp <Amount amount={data.order.grand_total} />
          </p>
        </Grid>
      </Grid>

      {showDetail === data.order.id && (
        <Box className={classes.content_see_hide}>
          <Grid container spacing={2}>
            {/* ITEM DETAIL  */}
            {data.order_items.map((data, index) => (
              <Grid item md={12} className={classes.item_see_hide} key={index}>
                <Box className={classes.tableContent}>
                  <Grid container spacing={2}>
                    <Grid item style={{ maxWidth: '10%', flexBasis: '10%' }}>
                      <img
                        src={
                          data.product.media
                            ? process.env.REACT_APP_FILE_BASE_URL + '/' + data.product.media[0]
                            : '/assets/images/dummy.png'
                        }
                        style={{
                          width: '80px',
                          height: '80px',
                          position: 'relative'
                        }}
                      />
                    </Grid>
                    {isDiscountOrderItem(data) && (
                      <div
                        style={{
                          background: ' #D92D20',
                          color: '#fff',
                          fontSize: '12px',
                          width: '28px',
                          height: '19px',
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '11px',
                          marginLeft: '55px'
                        }}
                      >
                        {discountPercentageOrderItem(data)}%
                      </div>
                    )}

                    <Grid item md={4}>
                      <Box className={classes.description_style}>
                        <h4 className='title_de '>{data.product.name}</h4>

                        {data.product_variant ? (
                          <p className='values'>
                            <span>{t('variant')}: </span>
                            {data.product_variant.attributes[0].value}
                          </p>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item md={2}>
                      <p className={classes.pcs}>{data.quantity} pcs</p>
                    </Grid>
                    <Grid item md={2}>
                      <Box className={classes.priceContent_style}>
                        <p className='values'>
                          {priceFormatLocal(priceAfterDiscountOrderItem(data))}
                        </p>

                        {isDiscountOrderItem(data) && (
                          <p className='values price-wdout'>{priceFormatLocal(data.price)}</p>
                        )}
                      </Box>
                    </Grid>
                    <Grid item md={2}>
                      <p className={classes.gTotal}>
                        Rp&nbsp;&nbsp;
                        <Amount amount={data.total} />
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}

            <Grid
              item
              md={12}
              className={classes.item_see_hide + ' ' + classes.price_item_see_hide}
            >
              {/* ITEM DETAIL - Totals Right side */}
              <Box className={classes.content_price}>
                <Box className={classes.price_item}>
                  <p className='lable'>{t('Total items')}</p>
                  <p className='price'>{data.order.item_qty} Item</p>
                </Box>
                <Box className={classes.price_item}>
                  <p className='lable'>{t('Total weight')}</p>
                  <p className='price'>{getWeightProduct(data.order_items)} Gram</p>
                </Box>
                <Box className={classes.price_item}>
                  <p className='lable'>{t('Total order')}</p>
                  <p className='price'>
                    Rp <Amount amount={data.order.total - data.order.discount_membership} />
                  </p>
                </Box>
                {/* <Box className={classes.price_item}>
                  <p className='lable'>{t('voucher')}</p>
                  <p className='price'>
                    Rp 0
                  </p>
                </Box> */}
                <Box className={classes.price_item}>
                  <p className='lable'>{t('shipping_cost')}</p>
                  <p className='price'>
                    Rp <Amount amount={data.order.shipping_cost} />
                  </p>
                </Box>

                {data.order.shipping_tax > 0 && (
                  <Box className={classes.price_item}>
                    <p className='lable'>{t('ppn_shipping_tax')}</p>
                    <p className='price'>
                      Rp <Amount amount={data.order.shipping_tax} />
                    </p>
                  </Box>
                )}

                {data.order.tax > 0 && (
                  <Box className={classes.price_item}>
                    <p className='lable'>{t('fix_tax')}</p>
                    <p className='price'>
                      Rp <Amount amount={data.order.tax} />
                    </p>
                  </Box>
                )}

                {data.order.is_moota === true ? (
                  <Box className={classes.price_item}>
                    <p className='lable'>{t('unique_code')}</p>
                    <p className='price'>
                      Rp <Amount amount={data.order.unique_payment_code} />
                    </p>
                  </Box>
                ) : (
                  ''
                )}
                {data.order.global_discount_recap > 0 && (
                  <Box className={classes.price_item}>
                    <p className='lable'>{t('global_discount')}</p>
                    <p className='price'>
                      Rp <Amount amount={data.order.global_discount_recap} />
                    </p>
                  </Box>
                )}

                <Box className={classes.price_item}>
                  <p className='lable'>{t('grand_total')}</p>
                  <p className='price'>
                    Rp <Amount amount={data.order.grand_total} />
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box className={classes.footer}>
        <Grid container spacing={2}>
          <Grid item md={8}></Grid>
          {/* <Grid item md={4}></Grid> */}

          <Grid item md={4}>
            {showDetail === data.order.id ? (
              <Button
                onClick={() => setShowDetail(0)}
                className='btn_see'
                style={{ color: webThemeColor }}
              >
                {t('hide_details')}
              </Button>
            ) : (
              <Button
                onClick={() => setShowDetail(data.order.id)}
                className='btn_see'
                style={{ color: webThemeColor }}
              >
                {t('see_details')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CardHistoryOrder
