import { makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import DesktopHeaderWrap from '../../../components/DeskTopHeaderWrap'
import DeskTopFooter from '../../home/DesktopFooter'
import ProductData from './ProductData'
import { t } from 'i18next'
import i18n from '../../language/localization'
import { getByClient as getProductsDetail } from '../../../api/products'
import { getBadgeCart } from '../../../api/badge'
import { useAuth } from '../../../hooks/useAuth'
import TabSortProductDesktop from 'components/tab/TabSortProductDesktop'

const themeColor = JSON.parse(localStorage.getItem('themeColor'))

// import FillterBy from "./FilterBy";
const useStyles = makeStyles(() => ({
  MainRoot: {
    paddingTop: '1rem',
    zIndex: -1,
    display: 'flex',
    alignItems: 'start',
    columnGap: '20px',
    width: '1200px',
    margin: '0px auto',
    paddingBottom: '6rem'
  },
  FilterRoot: {
    width: '290px',
    padding: '15px',
    flexFlow: 'column',
    background: '#fff',
    height: '100vh'
  },
  filterHead: {
    fontSize: '22px',
    color: '#333',
    margin: '0 0 25px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '0',
    marginBottom: '0',
    listStyle: 'none'
  },
  nav_list: {
    width: '100%',
    margin: '0 0 20px'
  },
  labelText: {
    fontSize: '14px',
    borderBottom: '2px solid #ff3a59',
    padding: '0 0 10px',
    cursor: 'pointer',
    position: 'relative',
    fontFamily: '"Open Sans", sans-serif',
    display: 'block',
    margin: '0 0 15px',
    color: '#ff3a59',
    transition: 'all 0.25s ease-in'
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  sortByRoot: {
    color: '#000',
    borderRadius: '3px',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    '&:hover': {
      background: themeColor || '#ffce0b',
      color: '#fff',
      cursor: 'pointer'
    },
    height: '2.125rem'
  },

  sortByAv: {
    borderRadius: '3px',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    background: themeColor || '#ffce0b',
    color: '#fff',
    cursor: 'default',
    '&>svg': {
      margin: '0px !important',
      fontSize: '18px !important',
      marginLeft: '6px !important'
    },
    height: '2.125rem'
  },

  sortByRootIcon: {
    color: '#000',
    borderRadius: '3px',
    height: '2.125rem',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    '&>svg': {
      margin: '0px !important',
      fontSize: '18px !important'
    },
    '&:hover': {
      background: themeColor || '#ffce0b',
      color: '#fff',
      border: '1px solid #ffce0b',
      cursor: 'pointer'
    }
  },
  NaviPageItems: {
    '& button': {
      border: 'none',
      background: 'none',
      '& i': {
        // color: "#999",
        margin: '0 10px'
      },
      '& span': {
        border: '1px solid #707070',
        borderRadius: '50%',
        padding: '4px 8px',
        marginRight: '5px'
      }
    },
    '& .btnNext': {
      marginLeft: '5px'
    }
  },
  Main: {
    '&>ul li Button': {
      border: '1px solid ' + themeColor + '',
      color: 'black'
    },
    '&>ul li .MuiPaginationItem-page.Mui-selected': {
      border: '1px solid ' + themeColor + '',
      background: themeColor,
      color: '#fff'
    }
    // "&>ul li .MuiPaginationItem-page": {
    //   display: "none"
    // },
  }
}))

const ProductListSearch = props => {
  const { token } = useAuth()

  const { webThemeColor } = props
  // console.log("webThemeColor___ser", webThemeColor);
  // console.log("webThemeColor___themeColor", themeColor);
  const classes = useStyles()
  const CatId2 = JSON.parse(localStorage.getItem('CatId'))
  const [catId, setCatId] = useState()
  const [sortBy, setSortBy] = useState()
  const [isPrice, setIsPrice] = useState(false)
  const [isBestSeller, setIsBestSeller] = useState(false)
  const [isDiscount, setIsDiscount] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [inlineCategory, setInlineCategory] = useState(false)

  const [newPageNo, setNewPageNo] = useState()
  const [MyPage, setMyPage] = useState()
  const [totalRecord, setTotalRecord] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [membershipLevel, setMembershipLevel] = useState(1)
  const [categoriesData, setCategoriesData] = useState([])
  const [TotalCartItems, setItems] = useState(0)
  const pageChangeMinus = () => {
    setPageNo(pageNo - 1)
    setNewPageNo(pageNo - 1)
  }
  const pageChangePlus = () => {
    setPageNo(pageNo + 1)
    setNewPageNo(pageNo + 1)
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  const searchname = JSON.parse(localStorage.getItem('desktopSearch'))
  const membershipData = JSON.parse(localStorage.getItem('membership'))

  const mycart = async () => {
    if (token) {
      const cartDataResult = await getBadgeCart()
      if (cartDataResult.success) {
        setItems(cartDataResult.data)
      }
    }
  }

  useEffect(() => {
    const getProductsDetailData = async () => {
      try {
        const response = await getProductsDetail(
          `${searchname ? `{"query":"${searchname}", "limit": "50"}` : null}`
        )
        setTotalRecord(response.meta.total_count)
        setMyPage(response.meta.total_pages)
      } catch (error) {
        console.log('Error fetching getProductsDetail on productview:', error)
      }
    }

    getProductsDetailData()

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }

    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }

    mycart()
  }, [])

  return (
    <div
      style={{ background: '#f5f5f5', height: 750, overflow: 'auto' }}
      //   onScroll={firstEvent}
    >
      <DesktopHeaderWrap
        clickTab={() => setInlineCategory(false)}
        cartNumber={TotalCartItems}
        themeColor={'rgb(231, 0, 116)'}
        isFixed={false}
        categoriesData={categoriesData}
      />
      <div className={classes.MainRoot}>
        <div style={{ width: '100%' }}>
          <div
            style={{
              background: '#fff',
              padding: '7px',
              margin: '0 0 5px',
              display: 'flex',
              columnGap: '10px',
              alignItems: 'left',
              justifyContent: 'left',
              position: 'relative',
              width: '100%',
              boxSizing: 'border-box'
            }}
          >
            <label
              style={{
                padding: '7px',
                color: '#333',
                fontSize: '20px'
              }}
            >
              {searchname}
            </label>
            <label
              style={{
                paddingTop: '13px',
                color: '#393D4E',
                fontSize: '15px'
              }}
            >
              {totalRecord} {t('product')}
            </label>

            <div
              style={{
                position: 'absolute',
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '10px'
              }}
            >
              <TabSortProductDesktop sortBy={sortBy} setSortBy={setSortBy} />
            </div>

            {/* <div
              style={{
                display: 'FLEX',
                alignItems: 'center',
                justifyContent: 'right',
                width: '38%'
              }}
            >
              <label style={{ paddingRight: '5px', paddingTop: '10px' }}>
                {pageNo} / {MyPage}
              </label>
              <Button
                variant='outlined'
                style={{
                  maxWidth: '40px',
                  maxHeight: '40px',
                  minWidth: '40px',
                  minHeight: '40px'
                }}
                onClick={pageChangeMinus}
                disabled={pageNo === 1 ? true : false}
              >
                <ArrowBackIosIcon style={{ height: '15px', paddingLeft: '5px' }} />
              </Button>
              <Button
                variant='outlined'
                style={{
                  maxWidth: '40px',
                  maxHeight: '40px',
                  minWidth: '40px',
                  minHeight: '40px'
                }}
                onClick={pageChangePlus}
                disabled={pageNo === MyPage ? true : false}
              >
                <ArrowForwardIosIcon style={{ height: '15px', paddingLeft: '5px' }} />
              </Button>
            </div> */}
          </div>

          <div>
            {inlineCategory ? (
              <ProductData
                catId={catId}
                sortBy={sortBy}
                pageNumber={pageNo}
                membershipLevel={membershipLevel}
              />
            ) : (
              <ProductData
                catId={CatId2}
                sortBy={sortBy}
                pageNumber={pageNo}
                membershipLevel={membershipLevel}
              />
            )}
          </div>
        </div>
      </div>
      <DeskTopFooter />
    </div>
  )
}
export default ProductListSearch
