import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import $ from 'jquery'
import { Container } from '@mui/material'

const useStyles = makeStyles(() => ({
  MenuRoot: {
    width: '100%',
    display: 'flex',
    margin: '0px auto',
    fontSize: 13,
    fontWeight: 600,
    padding: '10px 0',
    columnGap: '20px',
    color: '#323232'
  },
  MenuItems: {
    paddingLeft: '18px',
    paddingRight: '18px',
    paddingBottom: 5,
    minWidth: 'fit-content',

    '&:hover': {
      cursor: 'pointer',
      color: props => props.colorTheme,
      borderBottom: props => `2px solid ${props.colorTheme}`
    }
  },

  MenuActive: {
    color: props => props.colorTheme,
    borderBottom: props => `2px solid ${props.colorTheme}`
  },

  sliderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    margin: '0 auto',
    // marginBottom: '20px',

    '& button': {
      border: '0',
      color: '#777',
      fontSize: '24px',
      margin: '0px',
      background: 'transparent',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '13px',
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      }
    },

    '& .dNone': {
      display: 'none'
    },

    // "& .next": {
    //   position: 'relative',
    //   left: '-80px',
    // },

    '& .sliderParent': {
      display: 'flex',
      alignItems: 'center',
      listStyle: 'none',
      maxWidth: '100%',
      overflowX: 'scroll',
      scrollBehavior: 'smooth',
      margin: 'unset',
      marginRight: '0px',
      paddingBottom: '0px',

      // "&::-webkit-scrollbar": {
      //   background: "transparent",
      //   WebkitAppearance: "none",
      //   width: "0",
      //   height: "0",
      // },

      '& li': {
        background: '#2185D0',
        color: '#fff',
        padding: '5px 15px',
        borderRadius: '20px',
        margin: '0 4px',
        whiteSpace: 'nowrap'
      }
    }
  }
}))
const DesktopNevigationBar = ({ t, clickTab, isFixed, categoriesData }) => {
  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const classes = useStyles({ colorTheme: themeColor })
  const history = useHistory()
  const [selectIndex, setSelectIndex] = useState(0)

  // auto scroll
  const [value, setValue] = useState(0)
  const tabCenter = event => {
    // console.log(value,event);
    if (event !== 2 || (value !== 1 && value !== 0)) {
      if (event > value) {
        $('#content').animate({
          scrollLeft: '+=100px'
        })
        setValue(event)
        // console.log("right");
      } else {
        $('#content').animate({
          scrollLeft: '-=100px'
        })
        setValue(event)
        // console.log("left");
      }
      // console.log("if part",value,event);
    } else {
      // console.log("else part",value,event);
      setValue(event)
    }
  }
  const onClickIndex = async (index, categoryID, categoryName) => {
    tabCenter(index + 1)
    setValue(index + 1)

    // const UserId = localStorage.getItem("userid");
    // const Usercode = localStorage.getItem("usercode");
    // var myHeaders = new Headers();
    // myHeaders.append("key", "c4c5fc54cd5c071f3fe4785b8ae167e0");
    // var formdata = new FormData();
    // formdata.append("user_id", UserId);
    // formdata.append("user_code", Usercode);
    // formdata.append("category", categoryID);
    // formdata.append("page", "1");
    // fetch(`${baseUrlApi()}/Api/Product/getProductData`, {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // })
    // .then((res) => res.json())
    // .then((res) => {
    //   setSelectIndex(index + 1);
    //   localStorage.setItem("CatId", categoryID);
    //   localStorage.setItem("totalRecord", res.total_record);
    //   localStorage.setItem("categoryName", categoryName);
    //   history.push("/categorylistdata");
    //   clickTab();
    // });

    setSelectIndex(index + 1)
    localStorage.setItem('CatId', categoryID)
    localStorage.setItem('categoryName', categoryName)
    history.push('/product-by-category')
  }
  // end auto scroll

  const Navigate = url => {
    history.push(url)
    setSelectIndex(0)
  }

  // Navigation Slider Start

  let scrl = useRef(null)
  const [scrollX, setscrollX] = useState(0)
  const [scrolEnd, setscrolEnd] = useState(false)
  const [tabdata, settabdata] = useState([])
  // React.useEffect(()=>{
  //   const data=new FormData()
  //   fetch(`${baseUrlApi()}/Api/Dashboard/dashboardData`, {
  //     method: "POST",
  //     headers: {
  //       key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     body: data,
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       settabdata(json.category)
  //     });
  // },[])
  //Slide click
  // const slide = (shift) => {
  //   scrl.current.scrollLeft += shift;
  //   setscrollX(scrollX + shift);
  //   console.log("scrollX:",scrollX)
  //   console.log("scrl.current.scrollLeft:",scrl.current.scrollLeft)
  //   console.log("scrl.current.scrollWidth:",scrl.current.scrollWidth)
  //   console.log("scrl.current.offsetWidth:",scrl.current.offsetWidth)
  //   // if (
  //   //   Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
  //   //   scrl.current.offsetWidth
  //   // ) {
  //   //   setscrollRight(true);
  //   // } else {
  //   //   setscrollRight(false);
  //   // }
  // };
  //Slide click

  //Anim

  // const [isScrollCategory, setIsScrollCategory] = useState(false)
  // const scrollCheck = (e) => {
  //   if(isScrollCategory){
  //     // console.log("scrollRight:",scrollRight)
  //     // if(scrollRight){
  //       // slide(-5)
  //     // }else{
  //       slide(+5)
  //     // }
  //   }
  // };

  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isDown, setIsDown] = useState(false)

  const mouseDownCategory = async e => {
    setIsDown(true)
    setStartX(e.pageX - scrl.current.offsetLeft)
    setScrollLeft(scrl.current.scrollLeft)
  }

  const mouseLeaveCategory = async () => {
    setIsDown(false)
  }

  const mouseUpCategory = async () => {
    setIsDown(false)
  }

  const mouseMoveCategory = async e => {
    if (!isDown) return
    e.preventDefault()
    const x = e.pageX - scrl.current.offsetLeft
    const walk = (x - startX) * 2
    scrl.current.scrollLeft = scrollLeft - walk
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  // Navigation Slider end
  return (
    <div
      style={{
        position: isFixed ? 'fixed' : 'unset',
        marginTop: isFixed ? '90px' : '0px',
        background: 'white',
        width: '100%',
        zIndex: 11,
        boxShadow: isFixed ? '0 0 1px 1px rgb(0 0 0 / 20%)' : '0 1px 1px 0px rgb(0 0 0 / 20%)'
      }}
    >
      <div className={classes.sliderContent}>
        {/* {scrollX !== 0 && (
          <button
            className="prev"
            onClick={() => slide(-50)}
            // onMouseEnter={(e) => anim(e)}
            // onMouseLeave={(e) => anim2(e)}
          >
            <ArrowBackIosIcon />
          </button>
        )} */}

        <div
          style={{
            width: '100%',
            boxShadow: '0px 2px 3px 0px #6B6B6B33'
          }}
        >
          <Container>
            <div
              className={classes.MenuRoot + ' ' + 'sliderParent'}
              ref={scrl}
              onMouseDown={mouseDownCategory}
              onMouseLeave={mouseLeaveCategory}
              onMouseUp={mouseUpCategory}
              onMouseMove={mouseMoveCategory}
              id='content'
            >
              <div
                className={classes.MenuItems + ' ' + (selectIndex == 0 && classes.MenuActive)}
                onClick={() => Navigate('/')}
              >
                {t('home')}
              </div>

              {tabdata !== null
                ? categoriesData != null &&
                  categoriesData.map((md, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          classes.MenuItems + ' ' + (selectIndex == index + 1 && classes.MenuActive)
                        }
                        onClick={() => onClickIndex(index, md.id, md.name)}
                      >
                        {md.name.toUpperCase()}
                      </div>
                    )
                  })
                : null}
            </div>
          </Container>
        </div>

        {/* {console.log("tabdata___", tabdata.length)} */}
        {/* {!scrolEnd && (
          <button
            className={` ${
              tabdata !== null ? (tabdata.length > 8 ? "" : "dNone") : null
            } next`}
            onClick={() => slide(+50)}
            // onMouseEnter={(e) => anim(e)}
            // onMouseLeave={(e) => anim2(e)}
          >
            <ArrowForwardIosIcon />
          </button>
        )} */}
      </div>
    </div>
  )
}
export default withNamespaces()(DesktopNevigationBar)
