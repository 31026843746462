import React, { useState, useEffect } from 'react'
import Header from '../header/header2'
import { useHistory } from 'react-router-dom'
// import { useDispatch } from "react-redux";
// import { GetShippingDataResponse } from "../../store/Selectors/getShipping";
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import {
  getVendor as getCourierVendor,
  getRajaOngkir as getCourierRajaOngkir,
  getSelfPickup as getCourierSelfPickup
} from '../../api/courier'
import { priceFormatLocal } from '../../utils/helper'
import { Radio, Skeleton } from '@mui/material'

function ShippingMethod({ t }) {
  const ThemeColor = JSON.parse(localStorage.getItem('themeColor'))
  const shippingData = localStorage.getItem('shipping')
    ? JSON.parse(localStorage.getItem('shipping'))
    : null
  const history = useHistory()
  // const dispatch = useDispatch();
  const [field, setField] = useState([])
  const totalWeight = JSON.parse(localStorage.getItem('totalweight'))
  const cityids = JSON.parse(localStorage.getItem('cityid'))
  const districtids = JSON.parse(localStorage.getItem('subdistrictid'))
  const totlalPrice = JSON.parse(localStorage.getItem('setT'))
  const cartdetail = JSON.parse(localStorage.getItem('cartids'))

  // Shipping Data
  const [courierRajaOngkir, setCourierRajaOngkir] = useState([])
  const [courierVendor, setCourierVendor] = useState([])
  const [courierSelfPickup, setCourierSelfPickup] = useState({})

  // const datas = GetShippingDataResponse();
  const shipping = async () => {
    setOpen(false)
    const courierVendorResult = await getCourierVendor(
      `{"district_id":${cityids}, "weight": ${totalWeight}}`
    )
    if (courierVendorResult.success) {
      setCourierVendor(courierVendorResult.data.data)
    }

    const courierRajaOngkirResult = await getCourierRajaOngkir(
      `{"subdistrict_id":${districtids}, "weight": ${totalWeight}}`
    )
    if (courierRajaOngkirResult.success) {
      setCourierRajaOngkir(courierRajaOngkirResult.data.data)
    }

    const courierSelfPickupResult = await getCourierSelfPickup()
    if (courierSelfPickupResult.success) {
      if (courierSelfPickupResult.data.is_active === true) {
        setCourierSelfPickup(courierSelfPickupResult.data)
      }
    }

    setOpen(true)

    // let data = new FormData();
    // data.append("city_id", cityids);
    // data.append("subdistrict_id", districtids);
    // data.append("weight", prices);
    // data.append("total", totlalPrice);
    // data.append("cart", cartdetail ? cartdetail[0].product_id : "68");
    // fetch(`${baseUrlApi()}/Api/Dashboard/getCourierData`, {
    //   method: "POST",
    //   headers: {
    //     key: "c4c5fc54cd5c071f3fe4785b8ae167e0",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    //   body: data,
    // })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     // console.log("appi time",res.data.length);
    //     // setField([...field,...res.data]);
    //     console.log("resposneshiping", res);
    //     if (res.ResponseCode == 1) {
    //       // console.log("api time res.",res.data)
    //       setField(res.data);
    //     }
    //   });
    // dispatch(getShippingUserData(data));
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
  }, [])

  const [true1, setTrue1] = useState(true)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  useEffect(() => {
    shipping()
    // setTimeout(() => {
    //   setTrue1(true);
    // }, 700);
    // setTimeout(() => {
    //   if(field.length>0){
    //     setOpen(true);
    //   }
    // }, 500);
  }, [])

  return (
    <div>
      <div>
        <Header title={t('shipping_method')} color={ThemeColor ? ThemeColor : '#FFFFFF'} />
        <div
          className='container card shadow-card border-0'
          style={{
            paddingTop: '60px'
          }}
        >
          {!open ? (
            <>
              <div
                style={{
                  minHeight: '100vh',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '10px'
                    }}
                  >
                    <Skeleton variant='rectangular' width={'80px'} height={69} />

                    <Skeleton variant='rectangular' width={'100%'} height={69} />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {/* di hide dlu pada tgl 29 agustus karena belum ada settingan dinamis */}
              {/* {courierSelfPickup && Object.keys(courierSelfPickup).length > 0 ? (
              <div key={courierSelfPickup.id}>
                <div
                  className='d-flex border rounded p-1 mb-2'
                  onClick={() => {
                    localStorage.setItem(
                      'shipping',
                      JSON.stringify({
                        courier_id: courierSelfPickup.id,
                        courier_name: courierSelfPickup.name,
                        service_code: 'SELFPICKUP',
                        service_type: 'SELFPICKUP',
                        nominal: courierSelfPickup.price,
                        rate: '',
                        // actual_rate: data.actual_rate,
                        etd: '',
                        courier_image: courierSelfPickup.logo
                          ? process.env.REACT_APP_FILE_BASE_URL + '/' + courierSelfPickup.logo
                          : '/assets/images/dummy.png'
                      })
                    )
                    history.push('/orderconfirm')
                  }}
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={
                      courierSelfPickup.logo
                        ? process.env.REACT_APP_FILE_BASE_URL + '/' + courierSelfPickup.logo
                        : '/assets/images/dummy.png'
                    }
                    height='35px'
                    width='50px'
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'flex-start'
                    }}
                    alt=''
                  />
                  <span
                    style={{
                      // fontSize: "15px",
                      // display: "flex",
                      // alignItems: "center",
                      // // justifyContent:"center",
                      marginLeft: '16px',
                      width: '100%'
                    }}
                  >
                    {courierSelfPickup.name} <br />
                    <span>{priceFormatLocal(courierSelfPickup.price)}</span>
                    &nbsp;
                  </span>
                  <Radio
                    checked={
                      shippingData &&
                      shippingData.courier_id === courierSelfPickup.id &&
                      shippingData.service_type === 'SELFPICKUP'
                    }
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: ThemeColor ? ThemeColor : '#FFFFFF'
                      }
                    }}
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      flexDirection: 'column',
                      alignItems: 'flex-end'
                    }}
                  />
                </div>
              </div>
            ) : null} */}

              {courierVendor && courierVendor.length > 0
                ? // datas.data.length>0?
                  courierVendor.map((data, index) => (
                    <div key={index}>
                      <div
                        className='d-flex border rounded p-1 mb-2'
                        onClick={() => {
                          localStorage.setItem(
                            'shipping',
                            JSON.stringify({
                              courier_id: data.id,
                              courier_name: data.name,
                              service_code: data.service ? data.service : '',
                              service_type: 'COURIER VENDOR',
                              nominal: data.price,
                              rate: data.rate ? data.rate : '',
                              // actual_rate: data.actual_rate,
                              etd: data.estimation ? data.estimation : '',
                              courier_image: data.logo || '/assets/images/dummy.png'
                            })
                          )
                          history.push('/orderconfirm')
                        }}
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={data.logo ? data.logo : '/assets/images/dummy.png'}
                          height='35px'
                          width='50px'
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'flex-start'
                          }}
                          alt=''
                        />
                        <span
                          style={{
                            // fontSize: "15px",
                            // display: "flex",
                            // alignItems: "center",
                            // // justifyContent:"center",
                            marginLeft: '16px',
                            width: '100%'
                          }}
                        >
                          {data.name + ' ' + data.service}
                          <br />
                          {data.estimation && `(${data.estimation}) Hari `}
                          {/* {data.rate == data.actual_rate ? null : (
                        <span>{data.rate}</span>
                      )} */}
                          <span>{priceFormatLocal(data.price)}</span>
                          &nbsp;
                          {/* {data.rate == data.actual_rate ? (
                        <span>{data.actual_rate}</span>
                      ) : (
                        <span style={{ textDecoration: "line-through" }}>
                          {data.actual_rate}
                        </span>
                      )} */}
                        </span>
                        <Radio
                          checked={
                            shippingData &&
                            shippingData.courier_id === data.id &&
                            shippingData.service_type === 'COURIER VENDOR'
                          }
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        />
                      </div>
                    </div>
                  ))
                : // <hr
                  //   style={{
                  //     marginBottom: "14px",
                  //     marginTop: "12px",
                  //     height: "2px",
                  //     background: "black",
                  //   }}
                  // />
                  // :
                  //   <div
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       alignItems: "center",
                  //       textAlign: "center",
                  //       paddingTop: "250px",
                  //       paddingBottom: "300px",
                  //       backgroundColor:"white"
                  //     }}
                  //   >
                  //   No Data Found
                  //   </div>
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //     textAlign: "center",
                  //     paddingTop: "250px",
                  //     paddingBottom: "300px",
                  //     flexDirection: "column",
                  //     height: "100vh",
                  //     backgroundColor: "white",
                  //   }}
                  // >
                  //   <div>
                  //     {" "}
                  //     <Loader />
                  //   </div>
                  //   <div>Loading...</div>
                  // </div>
                  ''}

              {courierRajaOngkir && courierRajaOngkir.length > 0
                ? // datas.data.length>0?
                  courierRajaOngkir.map((data, index) => (
                    <div key={index}>
                      <div
                        className='d-flex border rounded p-1 mb-2'
                        onClick={() => {
                          localStorage.setItem(
                            'shipping',
                            JSON.stringify({
                              courier_id: data.id,
                              courier_name: data.name,
                              service_code: data.service ? data.service : '',
                              nominal: data.price,
                              service_type: 'COURIER RAJA ONGKIR',
                              rate: data.rate ? data.rate : '',
                              // actual_rate: data.actual_rate,
                              etd: data.estimation ? data.estimation : '',
                              courier_image: data.logo ? data.logo : '/assets/images/dummy.png'
                            })
                          )
                          history.push('/orderconfirm')
                        }}
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={
                            data.logo
                              ? process.env.REACT_APP_FILE_BASE_URL + '/' + data.logo
                              : '/assets/images/dummy.png'
                          }
                          height='35px'
                          width='50px'
                          style={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'flex-start'
                          }}
                          alt=''
                        />
                        <span
                          style={{
                            // fontSize: "15px",
                            // display: "flex",
                            // alignItems: "center",
                            // // justifyContent:"center",
                            marginLeft: '16px',
                            width: '100%'
                          }}
                        >
                          {data.name + ' ' + data.service}
                          <br />
                          {data.estimation && `(${data.estimation}) Hari `}
                          {/* {data.rate == data.actual_rate ? null : (
                        <span>{data.rate}</span>
                      )} */}
                          <span>{priceFormatLocal(data.price)}</span>
                          &nbsp;
                          {/* {data.rate == data.actual_rate ? (
                        <span>{data.actual_rate}</span>
                      ) : (
                        <span style={{ textDecoration: "line-through" }}>
                          {data.actual_rate}
                        </span>
                      )} */}
                        </span>
                        <Radio
                          checked={
                            shippingData &&
                            shippingData.service_code === data.service &&
                            shippingData.service_type === 'COURIER RAJA ONGKIR'
                          }
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: ThemeColor ? ThemeColor : '#FFFFFF'
                            }
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        />
                      </div>
                    </div>
                  ))
                : // <hr
                  //   style={{
                  //     marginBottom: "14px",
                  //     marginTop: "12px",
                  //     height: "2px",
                  //     background: "black",
                  //   }}
                  // />
                  // :
                  //   <div
                  //     style={{
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       alignItems: "center",
                  //       textAlign: "center",
                  //       paddingTop: "250px",
                  //       paddingBottom: "300px",
                  //       backgroundColor:"white"
                  //     }}
                  //   >
                  //   No Data Found
                  //   </div>
                  // <div
                  //   style={{
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     alignItems: "center",
                  //     textAlign: "center",
                  //     paddingTop: "250px",
                  //     paddingBottom: "300px",
                  //     flexDirection: "column",
                  //     height: "100vh",
                  //     backgroundColor: "white",
                  //   }}
                  // >
                  //   <div>
                  //     {" "}
                  //     <Loader />
                  //   </div>
                  //   <div>Loading...</div>
                  // </div>
                  ''}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default withNamespaces()(ShippingMethod)
