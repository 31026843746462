import { makeStyles } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons//MoreHoriz'
import React, { useEffect } from 'react'
import { withNamespaces } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import SkeletonCircleText from '../../components/common/SkeletonCircleText'
// import { DashboardResponse } from "../../store/Selectors/dashboard";
import i18n from '../language/localization'
const CardDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
  user-select: none;
  @media (min-width: 320px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 350px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 360px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 375px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 395px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 410px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  @media (min-width: 425px) {
    margin-right: 10px;
    margin-left: 10px;
  }
`

const useStyles = makeStyles(() => ({
  Root11: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '155px',
    paddingLeft: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white'
  },
  Root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    height: '155px',
    paddingLeft: '10px',
    alignItems: 'center',
    // justifyContent: "space-between",
    background: 'white'
  },
  Container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100px',
    height: '100px',
    marginRight: '5px',
    // marginLeft: "10px",
    userSelect: 'none'
  },
  Para: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
    paddingTop: '12px',
    marginBottom: '5px',
    cursor: 'pointer',
    width: 'max-content',
    userSelect: 'none'
  },
  All_div: {
    backgroundColor: '#6e32ad',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none'
  }
}))

const ShortcutMenuWeb = ({ t, categoriesData }) => {
  const history = useHistory()
  const classes = useStyles()
  // const data = DashboardResponse();

  const go = (e, j, k) => {
    localStorage.setItem('viewproid', e)
    localStorage.setItem('viewproname', j)
    localStorage.setItem('viewprochild', JSON.stringify(k))
    // localStorage.setItem("viewtabs", JSON.stringify("zero"));
    history.push('/products')
    // localStorage.setItem("CatId", e);
    // history.push("/categorylistdata");
  }

  useEffect(() => {
    i18n.changeLanguage('id')
  }, [])

  return (
    <div>
      <div style={{ background: 'white', borderRadius: '2px' }}>
        <h5
          style={{
            marginTop: '1REM',
            paddingBottom: '-10REM',
            marginLeft: '1rem',
            paddingTop: '1.5rem'
          }}
        >
          {t('categories')}
        </h5>
        <div className={classes.Root11}>
          {categoriesData ? (
            <div className={classes.Root}>
              {categoriesData.length >= 8
                ? categoriesData.slice(0, 8).map((item, index) => {
                    return (
                      <CardDiv
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          // go(item.id, item.name, item.haveachild);
                          go(item.id, item.name, null)
                        }}
                      >
                        <img
                          src={
                            item.image
                              ? process.env.REACT_APP_FILE_BASE_URL + '/' + item.image
                              : '/assets/images/dummy.png'
                          }
                          alt='Icon'
                          style={{ borderRadius: '50%' }}
                          width='100'
                          height='100'
                        />
                        <div>
                          <p className={classes.Para}>{item.name}</p>
                        </div>
                      </CardDiv>
                    )
                  })
                : categoriesData.map((item, index) => {
                    return (
                      <CardDiv
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          go(item.id, item.name, null)
                        }}
                      >
                        <img
                          src={
                            item.image
                              ? process.env.REACT_APP_FILE_BASE_URL + '/' + item.image
                              : '/assets/images/dummy.png'
                          }
                          alt='Icon'
                          style={{
                            borderRadius: '50%',
                            width: '90px',
                            height: '90px',
                            userSelect: 'none'
                          }}
                        />
                        <div>
                          <p className={classes.Para}>{item.name}</p>
                        </div>
                      </CardDiv>
                    )
                  })}
              {categoriesData.length >= 8 ? (
                <div className={classes.Container}>
                  <div
                    className={classes.All_div}
                    onClick={() => {
                      history.push('/category')
                    }}
                  >
                    <MoreHorizIcon style={{ color: 'white', width: '80px', height: '85px' }} />
                  </div>
                  <p className={classes.Para}>{t('all_category')}</p>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ['', '', '', '', '', '', '', '', ''].map((_el, index) => (
              <div style={{ margin: '0 20px' }} key={index}>
                <SkeletonCircleText
                  animation='wave'
                  variant='circle'
                  style={{
                    width: '100px',
                    height: '100px',
                    margin: 'auto'
                  }}
                />
                <SkeletonCircleText animation='wave' variant='text' />
              </div>
            ))
          )}
        </div>
        <hr style={{ width: '100%', marginBottom: '0.8rem', marginTop: '0px' }} />
      </div>
    </div>
  )
}

export default withNamespaces()(ShortcutMenuWeb)
