import React, { memo } from 'react'
import { t } from 'i18next'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

import { priceFormatLocal } from '../../utils/helper'
import { isDiscountValid } from '../../utils/pricing'

import { useTheme, useMediaQuery, makeStyles, Button } from '@material-ui/core'

// eslint-disable-next-line react/display-name
const CardProduct = memo(
  ({
    membershipLevel,
    product,
    handleClick,
    handleRemoveWishlist,
    handleBuyProduct,
    desktopView = true,
    width = '20%',
    widthOnXsScreen = '100%',
    heightWrapperImage = null,
    clickableCard = true,
    isWishlist = false,
    loadingWishlist = false
  }) => {
    const themeColor = JSON.parse(localStorage.getItem('themeColor'))
    const useStyles = makeStyles(theme => ({
      title_div_desktop: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '30px',
        clear: 'both',
        marginBottom: '0px',
        userSelect: 'none',
        marginTop: '1rem'
      },
      Title_div: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '30px',
        clear: 'both',
        marginBottom: '0px',
        userSelect: 'none'
        // cursor:"pointer"
      },
      Title: {
        fontSize: '20px',
        fontWeight: 600,
        paddingLeft: '5px',
        // cursor:"pointer",
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '19px',
          fontWeight: 500,
          paddingLeft: '5px'
        }
      },
      Para: {
        fontSize: '16px',
        paddingRight: '5px',
        color: themeColor,
        cursor: props => (props.clickableCard ? 'pointer' : 'default'),
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '14px'
        }
      },
      Container1: {
        height: '100%',
        width: width,
        display: 'inline-block',
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          height: '100%',
          width: widthOnXsScreen,
          display: 'inline-block',
          userSelect: 'none'
        },

        '& .card': {
          border: '0px',
          // boxShadow: "0 0 1px",
          cursor: props => (props.clickableCard ? 'pointer' : 'default')
          // border: "1px solid #00000038 !important",
        }
        // "& .card:hover": {
        //   border: "1px solid #ff3a59 !important",
        // },
      },

      desktop_slider_cards: {
        '& .card': {
          border: '1px solid transparent',
          transition: 'all 0.1s ease-out'
        },
        '& .card:hover': {
          border: '1px solid ' + themeColor + ' !important',
          transform: 'translateY(-2px) scale(1.005) translateZ(0)',
          cursor: props => (props.clickableCard ? 'pointer' : 'default')
        },
        '& .card-body': {
          padding: '5px'
          // minHeight: "80px",
        },
        '& .cardTitle ': {
          // textTransform: 'uppercase'
        }
      },

      mobile_slider_cards: {
        '& .card-body': {
          // minHeight: '117px',
          padding: '3px !important'
        },

        '& .cardTitle ': {
          //  textTransform: 'uppercase',
          fontSize: '13px !important'
        },
        '& .TowLineText ': {
          height: '38px'
        }
      },

      wrapperImage: {
        height: heightWrapperImage ? heightWrapperImage : '230px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        borderBottom: '0.1px solid #80808038',
        [theme.breakpoints.down('xs')]: {
          height: heightWrapperImage ? heightWrapperImage : '185px'
        }
      },

      card_img: {
        position: 'relative',
        width: '100%',
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          // height: "142px",
        }
      },
      div_bottom: {
        marginBottom: '50px',
        marginTop: '20px',
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          marginBottom: '0px'
        }
      },

      cardTitle: {
        '& .read-more-less--more': {
          display: 'none'
        }
      },

      slider_style: {
        '& .MuiButtonBase-root': {
          // boxShadow: "0 0 1px 0px ",
          padding: '4px',

          '& .MuiSvgIcon-root': {
            margin: '0 !important'
          }
        },
        '& .css-1abc02a:hover button,  .css-hn784z:hover button': {
          boxShadow: '0 0 6px 0px',
          backgroundColor: '#ffffff',
          opacity: '1 !important',
          color: '#000000'
        },

        '& .MuiIconButton-root:hover': {
          // backgroundColor: "#ff3a59",
          backgroundColor: '#ffffff',
          color: '#000000',
          boxShadow: '0 0 1px 0px '
        }
      },

      '@media(max-width: 768px)': {
        mobileTitle: {
          whiteSpace: 'break-spaces !important',
          marginBottom: '3px'
        }
      },

      price_parent: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        overflow: 'hidden'
      },

      price: {
        fontSize: '14px',
        color: themeColor,
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '14px'
        }
      },

      cutdiv: {
        fontSize: '14px',
        textDecoration: 'line-through',
        color: 'grey',
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '13px'
        }
      },

      textfont: {
        fontSize: '14px',
        height: '44px',
        fontWeight: '400',
        whiteSpace: 'break-spaces',
        overflow: 'hidden',
        cursor: props => (props.clickableCard ? 'pointer' : 'default'),
        userSelect: 'none',
        [theme.breakpoints.down('xs')]: {
          fontSize: '13px',
          height: '40px'
        },
        color: 'inherit !important'
      },

      buttonWishlist: {
        position: 'absolute',
        left: '5px',
        top: '5px',
        zIndex: 5,
        border: 'none',
        outlined: 'none',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(43, 43, 43, 0.2)',
        '&.disabled': {
          backgroundColor: 'red'
        }
      },

      rootButtonBuy: {
        width: '100%',
        '& .MuiButton-root:hover': {
          backgroundColor: '#000'
        }
      },

      buttonBuy: {
        width: '100%',
        backgroundColor: props => props.colorTheme,
        color: 'white',
        borderRadius: '0 0 4px 4px',
        position: 'relative',
        zIndex: 5
      }
    }))

    const classes = useStyles({ colorTheme: themeColor, clickableCard })
    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const showPricing = prices => {
      return prices[membershipLevel] || prices[1]
    }

    const showLabels =
      product.labels &&
      product.labels.length > 0 &&
      product.labels
        .filter(label => label !== 'Sold Out' && label !== 'Diskon')
        .map((item, index) => {
          let bgColor = '#fdd535'
          let color = '#fff'
          switch (item) {
            case 'Sold Out':
              bgColor = '#1A1818'
              break

            case 'Terbaru':
              bgColor = '#fdd535'
              break

            case 'Terlaris':
              bgColor = 'red'
              color = 'yellow'
              break

            case 'Grosir':
              bgColor = '#00BFFF'
              break

            case 'Promo':
              bgColor = '#0057fc'
              break

            default:
              break
          }
          return (
            <div
              key={index}
              style={{
                background: bgColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: color,
                fontSize: '12px',
                width: '58px',
                height: '20px',
                borderRadius: '3px',
                marginTop: '1px',
                marginBottom: '5px'
              }}
            >
              {item}
            </div>
          )
        })

    return (
      <div
        onClick={() => {
          handleClick(product.id)
        }}
        className={`${classes.Container1} ${
          classes[desktopView ? 'desktop_slider_cards' : 'mobile_slider_cards']
        }`}
      >
        <div>
          <div
            className='card 11border-0 shadow-card'
            style={{
              justifyContent: 'center'
            }}
          >
            <div>
              <div className={classes.wrapperImage}>
                <img
                  src={
                    product.media && product.media.length > 0
                      ? process.env.REACT_APP_FILE_BASE_URL + '/' + product.media[0]
                      : '/assets/images/dummy.png'
                  }
                  alt={product.name}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    maxHeight: '228px',
                    width: '100%',
                    borderRadius: '4px 4px 0 0'
                  }}
                  className={classes.card_img}
                />
                {product.has_video === true && product.stock_status !== 'Sold Out' && (
                  <div
                    className='position-absolute d-flex justify-content-center align-items-center rounded-circle'
                    style={{
                      width: mobileScreen ? '3rem' : '4.5rem',
                      height: mobileScreen ? '3rem' : '4.5rem',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      userSelect: 'none'
                    }}
                  >
                    <span
                      className='text-light'
                      style={{
                        fontSize: '12px', //14
                        fontWeight: 'bolder',
                        cursor: 'pointer',
                        userSelect: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <PlayArrowIcon />
                    </span>
                  </div>
                )}
                {product.stock_status === 'Sold Out' && (
                  <div
                    className='position-absolute d-flex rounded-circle'
                    style={{
                      width: '4.5rem',
                      height: '4.5rem',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      userSelect: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <span
                      className='text-light'
                      style={{
                        fontSize: '12px', //14
                        fontWeight: 'bolder',
                        cursor: 'pointer',
                        userSelect: 'none'
                      }}
                    >
                      {t('sold_out')}
                    </span>
                  </div>
                )}
                {isWishlist && (
                  <button
                    className={classes.buttonWishlist}
                    disabled={loadingWishlist}
                    onClick={handleRemoveWishlist}
                  >
                    <svg
                      width='28'
                      height='28'
                      viewBox='0 0 28 28'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.6186 23.7928C14.2377 23.9272 13.6104 23.9272 13.2295 23.7928C9.98077 22.6838 2.72153 18.0571 2.72153 10.2153C2.72153 6.75376 5.51096 3.95312 8.95013 3.95312C10.989 3.95312 12.7926 4.93895 13.9241 6.46249C15.0555 4.93895 16.8703 3.95312 18.898 3.95312C22.3372 3.95313 25.1266 6.75376 25.1266 10.2153C25.1266 18.0571 17.8673 22.6838 14.6186 23.7928Z'
                        fill='#E70074'
                        stroke='#E70074'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                )}
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  userSelect: 'none'
                }}
              >
                {product.discount_type &&
                  isDiscountValid(
                    product.discount,
                    product.discount_start_date,
                    product.discount_end_date
                  ) &&
                  product.discount_type === 'percentage' && (
                    <div
                      style={{
                        background: 'red',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        fontSize: '12px',
                        width: '58px',
                        height: '20px',
                        borderRadius: '3px',
                        marginTop: '1px',
                        marginBottom: '5px',
                        cursor: 'pointer',
                        userSelect: 'none'
                      }}
                    >
                      {product.discount}%
                    </div>
                  )}

                {product.discount_type &&
                  isDiscountValid(
                    product.discount,
                    product.discount_start_date,
                    product.discount_end_date
                  ) &&
                  product.discount_type === 'nominal' && (
                    <div
                      style={{
                        background: 'red',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#fff',
                        fontSize: '12px',
                        width: '58px',
                        height: '20px',
                        borderRadius: '3px',
                        marginTop: '1px',
                        marginBottom: '5px',
                        cursor: 'pointer',
                        userSelect: 'none'
                      }}
                    >
                      {parseInt((product.discount / showPricing(product.price)) * 100)}%
                    </div>
                  )}

                {showLabels}
              </div>
              <div
                className='card-body'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'start'
                }}
              >
                {mobileScreen ? (
                  <p
                    className={`${classes.textfont} cardTitle desktop`}
                    style={{
                      webkitLineClamp: '2',
                      webkitBoxOrient: 'vertical',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      marginBottom: '4px'
                    }}
                  >
                    {product.name}
                  </p>
                ) : (
                  <p
                    className={`${classes.textfont} cardTitle desktop`}
                    style={{
                      height: 43,
                      webkitLineClamp: '2',
                      webkitBoxOrient: 'vertical',
                      display: '-webkit-box',
                      overflow: 'hidden',
                      marginBottom: '0px'
                    }}
                  >
                    {product.name}
                  </p>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <div className={classes.price}>
                    <b>
                      {product.discount_type &&
                        isDiscountValid(
                          product.discount,
                          product.discount_start_date,
                          product.discount_end_date
                        ) &&
                        product.discount_type === 'nominal' && (
                          <p
                            style={{
                              marginBottom: '0px'
                            }}
                          >
                            {priceFormatLocal(showPricing(product.price) - product.discount)}
                          </p>
                        )}
                      {product.discount_type &&
                        isDiscountValid(
                          product.discount,
                          product.discount_start_date,
                          product.discount_end_date
                        ) &&
                        product.discount_type === 'percentage' && (
                          <p
                            style={{
                              marginBottom: '0px'
                            }}
                          >
                            {priceFormatLocal(
                              showPricing(product.price) -
                                (product.discount / 100) * showPricing(product.price)
                            )}
                          </p>
                        )}
                      {!isDiscountValid(
                        product.discount,
                        product.discount_start_date,
                        product.discount_end_date
                      ) && (
                        <p
                          style={{
                            marginBottom: '0px'
                          }}
                        >
                          {priceFormatLocal(showPricing(product.price))}
                        </p>
                      )}
                    </b>
                  </div>
                  {isDiscountValid(
                    product.discount,
                    product.discount_start_date,
                    product.discount_end_date
                  ) && (
                    <div>
                      <span className={classes.cutdiv}>
                        {priceFormatLocal(showPricing(product.price))}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isWishlist && (
              <div className={classes.rootButtonBuy}>
                <Button className={classes.buttonBuy} onClick={handleBuyProduct}>
                  {t('buy')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)

export default CardProduct
