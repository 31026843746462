import React, { useState, useEffect } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import './women.css'
import { useHistory } from 'react-router-dom'
import New from './productview/new'
import Discount from './productview/discount'
import Bestseller from './productview/bestseller'
import PriceLow from './productview/pricelow'
import PriceHigh from './productview/pricehigh'
import TabSortProductDesktop from '../../components/tab/TabSortProductDesktop'
import { withNamespaces } from 'react-i18next'
import i18n from '../language/localization'
import 'react-web-tabs/dist/react-web-tabs.css'
import ProductByCategory from './ProductByCategory'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MainPage from './productview/mainpage'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import DesktopHeaderWrap from '../../components/DeskTopHeaderWrap'
import { makeStyles } from '@material-ui/core'
import DeskTopFooter from '../home/DesktopFooter'
import { useAuth } from '../../hooks/useAuth'
import TabSortProductMobile from '../../components/tab/TabSortProductMobile'

const useStyles = makeStyles(theme => ({
  Root: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      border: 'none',
      width: '100%',
      maxWidth: '100wh',
      margin: '0px auto'
      // border: '1px solid #d3d3d3a3'
    }
  },
  MainRoot: {
    paddingTop: '1rem',
    zIndex: -1,
    display: 'flex',
    alignItems: 'start',
    columnGap: '20px',
    width: '1200px',
    margin: '0px auto',
    paddingBottom: '6rem'
  },
  FilterRoot: {
    width: '290px',
    padding: '15px',
    flexFlow: 'column',
    background: '#fff',
    height: '100vh'
  },
  filterHead: {
    fontSize: '22px',
    color: '#333',
    margin: '0 0 25px',
    fontWeight: 700,
    lineHeight: '28px'
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '0',
    marginBottom: '0',
    listStyle: 'none'
  },
  nav_list: {
    width: '100%',
    margin: '0 0 20px'
  },
  labelText: {
    fontSize: '14px',
    borderBottom: '2px solid #ff3a59',
    padding: '0 0 10px',
    cursor: 'pointer',
    position: 'relative',
    fontFamily: '"Open Sans", sans-serif',
    display: 'block',
    margin: '0 0 15px',
    color: '#ff3a59',
    transition: 'all 0.25s ease-in'
  },
  hoverPointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  sortByRoot: {
    color: '#000',
    borderRadius: '3px',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    '&:hover': {
      background: props => props.colorTheme,
      color: '#fff',
      cursor: 'pointer'
    },
    height: '2.125rem',
    display: 'flex',
    alignItems: 'center'
  },

  sortByAv: {
    borderRadius: '3px',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    background: props => props.colorTheme,
    color: '#fff',
    cursor: 'pointer',
    '&>svg': {
      margin: '0px !important',
      fontSize: '18px !important',
      marginLeft: '6px !important'
    },
    height: '2.125rem',
    display: 'flex',
    alignItems: 'center'
  },

  sortByRootIcon: {
    color: '#000',
    borderRadius: '3px',
    height: '2.125rem',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    '&>svg': {
      margin: '0px !important',
      fontSize: '18px !important'
    },
    '&:hover': {
      background: props => props.colorTheme,
      color: '#fff',
      cursor: 'pointer'
    }
  },
  NaviPageItems: {
    '& button': {
      border: 'none',
      background: 'none',
      '& i': {
        color: '#999',
        margin: '0 10px'
      },
      '& span': {
        border: '1px solid #707070',
        borderRadius: '50%',
        padding: '4px 8px',
        marginRight: '5px'
      }
    },
    '& .btnNext': {
      marginLeft: '5px'
    }
  }
}))

const ProductView = ({ t }) => {
  const { countCart } = useAuth()
  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  console.log('themeColor', themeColor)
  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor,
        color: '#000'
      }
    }
  })
  const [value, setValue] = React.useState('zero')

  const history = useHistory()
  const [tabsopen, setTabsOpen] = useState(false)
  const [inlineCategory, setInlineCategory] = useState(false)
  const [categoriesData, setCategoriesData] = useState([])
  const viewname = localStorage.getItem('viewproname')
  const Mobile = useMediaQuery(theme.breakpoints.down('xs'))
  const Usercode = JSON.parse(localStorage.getItem('usercode'))

  const classes = useStyles({ colorTheme: themeColor })

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'instant' })
  }, [])

  useEffect(() => {
    // let isMounted = true
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))

    if (localStorage.getItem('landing_page')) {
      setCategoriesData(JSON.parse(localStorage.getItem('landing_page'))['product_categories'])
    }

    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [Usercode])

  useEffect(() => {
    console.log('viewname product view ', viewname == 'Discount Product')
    if (viewname == 'Best Seller') {
      setValue('three')
      setSortBy(2)
      setTimeout(() => setTabsOpen(true), 100)
    } else if (viewname == 'Discount Product') {
      setValue('two')
      setSortBy(5)
      setTimeout(() => setTabsOpen(true), 100)
    } else if (viewname == 'Produk Terbaru') {
      setValue('one')
      setSortBy(1)
      setTimeout(() => setTabsOpen(true), 100)
    } else {
      setValue('zero')
      setSortBy(null)
      setTimeout(() => setTabsOpen(true), 100)
    }
  }, [])

  const viewprochild = localStorage.getItem('viewprochild')
  const viewprotabs = JSON.parse(localStorage.getItem('viewtabs'))
  const categoryData = {}

  const [visible, setVisible] = useState(false)
  const [sortBy, setSortBy] = useState()
  const CatId = JSON.parse(localStorage.getItem('CatId'))
  const categoryProductName = localStorage.getItem('viewproname')
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 120) {
      setVisible(true)
    } else if (scrolled <= 120) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => window.removeEventListener('scroll', listenToScroll)
  }, [])

  const listenToScroll = () => {
    let heightToHideFrom = 100
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > heightToHideFrom) {
      isVisible && // to limit setting state only the first time
        setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  window.addEventListener('scroll', toggleVisible)

  const [totalRecord, setTotalRecord] = useState(0)

  const handleClickSetSortBy = sortByValue => {
    // check if sortByValue is equals sortBy then reset
    const valueSortBy = sortByValue === sortBy ? null : sortByValue
    setSortBy(valueSortBy)
  }

  const handleClickSetSortByPrice = () => {
    const sortPrice = sortBy === 4 ? 3 : sortBy === 3 ? null : 4
    handleClickSetSortBy(sortPrice)
  }

  return (
    <ThemeProvider theme={theme}>
      {Mobile ? (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              maxWidth: '100%',
              backgroundColor: themeColor,
              position: 'fixed',
              zIndex: '11111',
              marginBottom: '40px'
            }}
          >
            <div className='title-box' style={{ height: '48px', maxWidth: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <KeyboardBackspaceIcon
                  style={{ color: 'white', width: '25px' }}
                  onClick={() => {
                    history.push('/')
                  }}
                />

                <div
                  style={{
                    width: '100%',
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <h1 className='title mb-0'>
                    {viewname == 'New Arrivals'
                      ? t('new_arrivals')
                      : viewname == 'Best Seller'
                        ? t('best_seller2')
                        : viewname == 'Discount Product'
                          ? t('product_discount')
                          : viewname == 'All product' ||
                              viewname == 'All Product' ||
                              viewname == '"All Product"'
                            ? t('all_product')
                            : viewname.replaceAll('"', '')}
                  </h1>
                  <svg
                    style={{
                      ml: 'auto',
                      fill: 'transparent',
                      cursor: 'pointer',
                      height: '24px',
                      width: '24px'
                    }}
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={() => {
                      history.push('/search')
                    }}
                  >
                    <path
                      d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
                      stroke='#F5F5F5'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M22 22L20 20'
                      stroke='#F5F5F5'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div
                  style={{
                    paddingRight: '10px'
                  }}
                >
                  <svg
                    style={{
                      fill: 'transparent',
                      cursor: 'pointer',
                      height: '24px',
                      width: '24px'
                    }}
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={() => {
                      history.push('/cart')
                    }}
                  >
                    <path
                      d='M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001'
                      stroke='#F5F5F5'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z'
                      stroke='#F5F5F5'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z'
                      stroke='#F5F5F5'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9 8H21'
                      stroke='#F5F5F5'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-white' style={{ paddingTop: '50px', boxShadow: 'none' }}>
            {viewprochild == 1 ? (
              <div>
                <div className='scrollmenu'>
                  {categoryData
                    ? categoryData.data.length > 0
                      ? categoryData.data.map((cat, index) => (
                          <Chip
                            onClick={() => {
                              localStorage.setItem('procatid', JSON.stringify(cat.cat_id))
                              localStorage.setItem('procatname', JSON.stringify(cat.name))
                              history.push('/productcategory')
                            }}
                            label={cat.name}
                            key={index}
                            className='border-1 border m-2 chip-css text-uppercase mx-1'
                            style={{ background: 'rgb(250 250 250)' }}
                          />
                        ))
                      : ''
                    : ''}
                </div>
                <hr className='m-1' />
              </div>
            ) : (
              ''
            )}

            <div className='mb-1' style={{ height: '35px' }}>
              <TabSortProductMobile sortBy={sortBy} setSortBy={setSortBy} />

              {tabsopen ? (
                !sortBy ? (
                  <MainPage membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : sortBy === 1 ? (
                  <New membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : sortBy === 2 ? (
                  <Bestseller membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : sortBy === 5 ? (
                  <Discount membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : sortBy === 3 ? (
                  <PriceLow membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : sortBy === 4 ? (
                  <PriceHigh membershipLevel={membershipLevel} sortBy={sortBy} />
                ) : null
              ) : (
                ''
              )}
              <div style={{ width: '500px', margin: '0px auto' }}>
                <div
                  onClick={scrollToTop}
                  style={{
                    display: visible ? 'inline' : 'none',
                    height: Mobile ? '39px' : '41px',
                    padding: Mobile ? '10px' : '11px',
                    fontSize: '1rem',
                    zIndex: '1',
                    cursor: 'pointer',
                    color: 'red',
                    background: 'white',
                    borderRadius: '50px',
                    boxShadow: '0px 0px 8px -1px #6c757dd9',
                    position: 'fixed',
                    right: Mobile ? 0 : '',
                    left: Mobile ? '' : '82%',
                    bottom: Mobile ? '64px' : '0px',
                    marginRight: Mobile ? '10px' : '',
                    transform: Mobile ? '' : 'translate(466%, -50%)',
                    margin: '0px auto'
                  }}
                >
                  <img
                    src={'/assets/frontend/img/top_arrow.png'}
                    style={{
                      height: Mobile ? '19px' : '20px',
                      width: Mobile ? '19px' : '20px',
                      marginBottom: Mobile ? '10px' : '8px',
                      cursor: 'pointer'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.Root}>
          <DesktopHeaderWrap
            clickTab={() => setInlineCategory(false)}
            cartNumber={countCart}
            isFixed={false}
            categoriesData={categoriesData}
            showCategory
          />

          <div className={classes.MainRoot}>
            <div style={{ width: '100%' }}>
              <div
                style={{
                  background: '#fff',
                  padding: '7px',
                  margin: '0 0 5px',
                  display: 'flex',
                  columnGap: '10px',
                  alignItems: 'center',
                  justifyContent: 'left'
                }}
              >
                <label
                  style={{
                    color: '#333',
                    fontSize: '20px',
                    marginBottom: '0px'
                  }}
                >
                  {viewname == 'New Arrivals'
                    ? t('new_arrivals')
                    : viewname == 'Best Seller'
                      ? t('best_seller2')
                      : viewname == 'Discount Product'
                        ? t('product_discount')
                        : viewname == 'All product' ||
                            viewname == 'All Product' ||
                            viewname == '"All Product"'
                          ? t('all_product')
                          : viewname.replaceAll('"', '')}
                </label>
                <label
                  style={{
                    marginTop: '5px',
                    color: '#393D4E',
                    fontSize: '15px',
                    marginBottom: '0px'
                  }}
                >
                  {totalRecord} {t('product')}
                </label>
                <div
                  style={{
                    paddingLeft: '160px',
                    height: 'fit-content',
                    display: 'flex'
                  }}
                >
                  <TabSortProductDesktop sortBy={sortBy} setSortBy={setSortBy} />
                </div>
              </div>
              <div>
                {inlineCategory ? (
                  <ProductByCategory
                    catId={CatId}
                    sortBy={sortBy}
                    titleCategory={viewprotabs}
                    membershipLevel={membershipLevel}
                    setTotalRecord={setTotalRecord}
                  />
                ) : (
                  <ProductByCategory
                    catId={CatId}
                    sortBy={sortBy}
                    titleCategory={viewprotabs}
                    membershipLevel={membershipLevel}
                    setTotalRecord={setTotalRecord}
                  />
                )}
              </div>
            </div>
          </div>
          <DeskTopFooter />
        </div>
      )}
    </ThemeProvider>
  )
}

export default withNamespaces()(ProductView)
