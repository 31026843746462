import React, { useState, useEffect } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
// import "./address.css";
import Header from '../header/header2'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Dialog, DialogContent } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'
import ClipLoader from 'react-spinners/ClipLoader'
import { makeStyles } from '@material-ui/core'
import Loader from '../loader'
import {
  getProvince as getProvinceData,
  getCity as getCityData,
  getDistrict as getDistrictData
} from '../../api/region'
import { create as createAddressData, update as updateAddressData } from '../../api/address'
import { useHistory } from 'react-router'

function AddressForm({ t }) {
  const history = useHistory()

  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const [open2, setOpen2] = useState(false)
  const useStyles = makeStyles(theme => ({
    root: {
      '&> .finish-btn': {
        height: '2.7rem',
        backgroundColor: `${themeColor ? themeColor : '#FFFFFF'} !important`,
        textTransform: 'none !important'
      }
    },
    Dialogcss: {
      paddingTop: '0px !important',
      color: 'black',
      fontWeight: '500',
      marginBottom: '0px',
      padding: '0px',
      width: '400px',
      display: 'block',
      margin: '0px auto',
      [theme.breakpoints.down('xs')]: {
        width: '280px'
      }
    }
  }))

  const theme = createTheme({
    palette: {
      primary: {
        main: themeColor ? themeColor : '#FFFFFF'
      }
    }
  })

  const handleChange = e => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value })
  }
  const [addressData, setAddressData] = useState({
    user_id: '',
    name: '',
    address: '',
    province_id: '',
    province_name: '',
    city_id: '',
    city_name: '',
    district_id: '',
    district_name: '',
    postal_code: '',
    phone: '',
    as_default: ''
  })
  // const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false)
  const [provinceData, setProvinceData] = React.useState([])
  const [cityData, setCityData] = React.useState([])
  const [districtData, setDistrictData] = React.useState([])

  const handleCheck = event => {
    setChecked(event.target.checked)
  }

  const userid = JSON.parse(localStorage.getItem('userid'))
  const editaddress = JSON.parse(localStorage.getItem('editaddress'))
  const save = async () => {
    if (
      addressData.name !== '' &&
      addressData.address !== '' &&
      addressData.province_name !== '' &&
      addressData.city_name &&
      addressData.district_name &&
      addressData.postal_code &&
      addressData.phone !== ''
    ) {
      // var formdata = new FormData();
      // formdata.append("user_id", userid);
      // formdata.append("name", addressData.name);
      // formdata.append("address", addressData.address);
      // formdata.append("province_id", addressData.province_id);
      // formdata.append("province_name", addressData.province_name);
      // formdata.append("city_id", addressData.city_id);
      // formdata.append("city_name", addressData.city_name);
      // formdata.append("district_id", addressData.district_id);
      // formdata.append("district_name", addressData.district_name);
      // formdata.append("postal_code", addressData.postal_code);
      // formdata.append("phone", addressData.phone);
      // formdata.append("as_default", checked ? true : false);
      // dispatch(addAddressUser(formdata));
      const data = {
        address_id: addressData.id,
        customer_id: parseInt(userid),
        name: addressData.name,
        phone: addressData.phone,
        address: addressData.address,
        province_id: parseInt(addressData.province_id),
        district_id: parseInt(addressData.city_id),
        subdistrict_id: parseInt(addressData.district_id),
        postal_code: addressData.postal_code,
        // hardcode
        country_id: 62,
        village_id: 3201130010,
        latitude: -6.534398,
        longitude: 106.821891,
        note: '',
        label: '',
        is_primary: checked ? true : false
      }

      const response = await createAddressData(data)
      if (response.success) {
        history.goBack()
      }
    }
  }

  const update = async () => {
    if (
      addressData.name !== '' &&
      addressData.address !== '' &&
      addressData.province_name !== '' &&
      addressData.city_name &&
      addressData.district_name &&
      addressData.postal_code &&
      addressData.phone !== ''
    ) {
      // var formdata = new FormData();
      // formdata.append("address_id", ids);
      // formdata.append("user_id", userid);
      // formdata.append("name", addressData.name);
      // formdata.append("address", addressData.address);
      // formdata.append("province_id", addressData.province_id);
      // formdata.append("province_name", addressData.province_name);
      // formdata.append("city_id", addressData.city_id);
      // formdata.append("city_name", addressData.city_name);
      // formdata.append("district_id", addressData.district_id);
      // formdata.append("district_name", addressData.district_name);
      // formdata.append("postal_code", addressData.postal_code);
      // formdata.append("phone", addressData.phone);
      // formdata.append("as_default", checked ? 1 : 0);
      // dispatch(addAddressUser(formdata));
      // setTimeout(() => (setAddressMsg(true)), 400)
      const data = {
        address_id: addressData.id,
        customer_id: parseInt(userid),
        name: addressData.name,
        phone: addressData.phone,
        address: addressData.address,
        province_id: parseInt(addressData.province_id),
        district_id: parseInt(addressData.city_id),
        subdistrict_id: parseInt(addressData.district_id),
        postal_code: addressData.postal_code,
        // hardcode
        country_id: 62,
        village_id: 3201130010,
        latitude: -6.534398,
        longitude: 106.821891,
        note: '',
        label: '',
        is_primary: checked ? true : false
      }

      const response = await updateAddressData(data)
      if (response.success) {
        history.goBack()
      }
    }
  }

  const [ids, setIds] = useState('')
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const url_str = window.location.href
    const data11 = url_str.split('/')
    // let data12 = new FormData();
    // dispatch(getProvinceDataUser(data12));
    const getProvince = async () => {
      try {
        const response = await getProvinceData(null)
        setProvinceData(response)
      } catch (error) {
        console.log('Error fetching getProvinceData:', error)
      }
    }
    getProvince()
    if (data11.length > 3 && data11[3].toString().length > 14) {
      const ids = data11[3].toString()
      setIds(ids.slice(12))
      setAddressData({
        ...addressData,
        user_id: editaddress.user_id,
        name: editaddress.name,
        address: editaddress.address,
        province_id: editaddress.province_id,
        province_name: editaddress.province_name,
        city_id: editaddress.city_id,
        city_name: editaddress.city_name,
        district_id: editaddress.district_id,
        district_name: editaddress.district_name,
        postal_code: editaddress.postal_code,
        phone: editaddress.phone,
        as_default: editaddress.as_default,
        id: editaddress.id
      })
      if (editaddress.as_default === true) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
    setOpen2(true)
  }, [])

  const [pro, setPro] = useState(false)
  const [city, setCity] = useState(false)
  const [dis, setDis] = useState(false)
  // const provinceData = GetProvinceResponse();
  // const cityData = GetCityResponse();
  // const districtData = GetDistrictResponse();

  const handleClose = async (e, j) => {
    // console.log("id",e,"pro data: ",j);
    setAddressData({
      ...addressData,
      province_id: e,
      province_name: j
    })
    // let data4 = new FormData();
    // data4.append('province_id', e);
    // dispatch(getCityDataUser(data4));
    try {
      const response = await getCityData(`{"province_id":${e}}`)

      setCityData(response)
    } catch (error) {
      console.log('Error fetching getCityData:', error)
    }

    if (ids !== '' && j !== editaddress.province_name) {
      setAddressData({
        ...addressData,
        city_id: '',
        city_name: '',
        district_id: '',
        district_name: ''
      })
    }
    setPro(false)
  }

  const handleClose1 = async (e, j) => {
    setAddressData({
      ...addressData,
      city_id: e,
      city_name: j
    })
    // let data5 = new FormData();
    // data5.append('city_id', e);
    // dispatch(getDistrictDataUser(data5));
    try {
      const response = await getDistrictData(`{"district_id":${e}}`)

      setDistrictData(response)
    } catch (error) {
      console.log('Error fetching getDistrictData:', error)
    }
    setCity(false)
  }

  const handleClose2 = (e, j) => {
    setAddressData({
      ...addressData,
      district_id: e,
      district_name: j
    })
    setDis(false)
  }

  const classes = useStyles()
  const submit = () => {
    //
  }

  return (
    <div className='address-container'>
      {pro ? (
        <div>
          <Dialog
            open={pro}
            scroll='paper'
            maxWidth='md'
            fullWidth
            style={{ marginTop: '50px' }}
            onClick={() => {
              setPro(false)
            }}
          >
            <DialogContent dividers>
              {provinceData.length > 0 ? (
                provinceData.map((data, index) => (
                  <div key={index}>
                    <div
                      onClick={() => {
                        handleClose(data.id, data.name)
                        setAddressData({
                          ...addressData,
                          province_id: data.id,
                          province_name: data.name,
                          city_id: '',
                          city_name: '',
                          district_id: '',
                          district_name: ''
                        })
                      }}
                      style={{
                        fontWeight: '500',
                        padding: '9px',
                        userSelect: 'none'
                      }}
                    >
                      {data.name}
                    </div>
                    <hr style={{ margin: '0px', opacity: '0.09' }} />
                  </div>
                ))
              ) : (
                <div
                  onClick={() => {
                    setPro(false)
                  }}
                >
                  No Data Found
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {city ? (
        <div>
          <Dialog
            open={city}
            scroll='paper'
            maxWidth='md'
            fullWidth
            style={{ marginTop: '50px' }}
            onClick={() => {
              setCity(false)
            }}
          >
            <DialogContent dividers>
              {cityData.length > 0 ? (
                cityData.map((data, index) => (
                  <div key={index}>
                    <div
                      onClick={() => {
                        handleClose1(data.id, data.name)
                      }}
                      style={{
                        fontWeight: '500',
                        padding: '9px',
                        userSelect: 'none'
                      }}
                    >
                      {data.name}
                    </div>
                    <hr style={{ margin: '0px', opacity: '0.09' }} />
                  </div>
                ))
              ) : (
                <div
                  onClick={() => {
                    setCity(false)
                  }}
                >
                  No Data Found
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {dis ? (
        <div>
          <Dialog
            open={dis}
            scroll='paper'
            maxWidth='md'
            fullWidth
            style={{ marginTop: '50px' }}
            onClick={() => {
              setDis(false)
            }}
          >
            <DialogContent dividers>
              {districtData.length > 0 ? (
                districtData.map((data, index) => (
                  <div key={index}>
                    <div
                      key={index}
                      onClick={() => {
                        handleClose2(data.id, data.name)
                      }}
                      style={{
                        fontWeight: '500',
                        padding: '9px',
                        userSelect: 'none'
                      }}
                    >
                      {data.name}
                    </div>
                    <hr style={{ margin: '0px', opacity: '0.09' }} />
                  </div>
                ))
              ) : (
                <div
                  onClick={() => {
                    setDis(false)
                  }}
                >
                  No Data Found
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {open ? (
        <div>
          <Dialog
            open={open}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogContent
              id='alert-dialog-description'
              style={{
                color: 'black',
                fontWeight: '500',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '12px 24px'
              }}
            >
              <div style={{ marginRight: '14px' }}>
                <ClipLoader
                  color={themeColor ? themeColor : '#FFFFFF'}
                  height={50}
                  style={{
                    paddingLeft: '1px',
                    marginTop: '8px',
                    fontSize: '4',
                    userSelect: 'none'
                  }}
                />{' '}
              </div>
              <div>Loading ...</div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        ''
      )}
      {open2 ? (
        <ThemeProvider theme={theme}>
          {ids !== '' ? (
            <Header title='Edit Address' color={themeColor} />
          ) : (
            <Header title={t('add_new_address')} color={themeColor} />
          )}
          <div
            style={{
              paddingTop: '40px',
              userSelect: 'none'
            }}
          >
            <div
              className='card border-0 shadow-card d-flex align-center justify-content-center'
              style={{
                marginTop: '20px',
                marginLeft: '10px',
                marginRight: '10px',
                userSelect: 'none'
              }}
            >
              <ValidatorForm className='w-100' onSubmit={submit}>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('name')}
                    fullWidth={true}
                    onChange={handleChange}
                    name='name'
                    value={addressData.name}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('address1')}
                    onChange={handleChange}
                    name='address'
                    fullWidth={true}
                    value={addressData.address}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('province')}
                    onChange={handleChange}
                    onFocus={() => {
                      setPro(true)
                    }}
                    name='province_name'
                    fullWidth={true}
                    value={addressData.province_name}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required', 'Province is not valid']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('district/city')}
                    onChange={handleChange}
                    onFocus={() => {
                      setOpen(true)
                      setTimeout(() => (setOpen(false), setCity(true)), 700)
                    }}
                    name='city_name'
                    fullWidth={true}
                    value={addressData.city_name}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('subdistricts')}
                    onChange={handleChange}
                    onFocus={() => {
                      setOpen(true)
                      setTimeout(() => (setOpen(false), setDis(true)), 700)
                    }}
                    // onClick={()=>{setDis(true)}}
                    name='district_name'
                    fullWidth={true}
                    value={addressData.district_name}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('postcode')}
                    onChange={handleChange}
                    name='postal_code'
                    fullWidth={true}
                    value={addressData.postal_code}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 15px', userSelect: 'none' }}>
                  <TextValidator
                    label={t('phone_number')}
                    fullWidth={true}
                    onChange={handleChange}
                    name='phone'
                    value={addressData.phone}
                    variant='standard'
                    validators={['required']}
                    errorMessages={['this field is required']}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                </Grid>
                <Grid item xs={12} style={{ margin: '', userSelect: 'none' }}>
                  <Checkbox
                    checked={checked}
                    // {addressData.as_default===1?true:checked}
                    onChange={handleCheck}
                    style={{ color: themeColor ? themeColor : '#FFFFFF' }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  {t('set_as_default_address')}
                </Grid>

                <div className={classes.root + ' ' + ' finish'}>
                  {ids !== '' ? (
                    <button
                      className='finish-btn'
                      onClick={() => {
                        update()
                      }}
                    >
                      {t('update')}
                    </button>
                  ) : (
                    <button
                      className='finish-btn'
                      onClick={() => {
                        save()
                      }}
                    >
                      {t('save')}
                    </button>
                  )}
                </div>
              </ValidatorForm>
            </div>
          </div>
        </ThemeProvider>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            paddingTop: '250px',
            paddingBottom: '300px',
            flexDirection: 'column',
            backgroundColor: 'white',
            height: '100vh'
          }}
        >
          <div>
            <Loader />
          </div>
          <div>Loading...</div>
        </div>
      )}
      {/* {addressmsg ? <AddressAlert /> : ""} */}
    </div>
  )
}
export default withNamespaces()(AddressForm)
