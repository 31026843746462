/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { useStore } from '../../hooks/useStore'
import { useQuery } from 'hooks/useQuery'
import Header from 'containers/header/header'
import { getDetail } from 'api/products'
import { addToCart as createToCart } from 'helper/orderHelper'
import { IconButton, Input, makeStyles } from '@material-ui/core'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { priceFormatLocal } from 'utils/helper'
import { useDispatch } from 'react-redux'
import { useAuth } from 'hooks/useAuth'
import { useModalMessage } from 'hooks/useModalMessage'
import { setSnackbar } from 'stores/features/snackbar/snackbar'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function DetailVariant({ t }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const userid = JSON.parse(localStorage.getItem('userid'))
  const { countCart, refreshCart, refreshCountWishlist, user, carts, user: customer } = useAuth()

  const { setMessage } = useModalMessage()

  const { themeColor, landingPage, storeTitle, storeSettingCMS } = useStore()
  const searchParams = useQuery()
  const id = searchParams.get('id')
  const [productDetail, setProductDetail] = useState(null)
  const [price, setPrice] = useState(0)
  const [stock, setStock] = useState(0)

  const [variantId, setVariantId] = useState(null)
  const [attributeSelected, setAttributeSelected] = useState({})
  const [variants, setVariants] = useState({})
  const [variantSelected, setVariantSelected] = useState(null)

  useEffect(() => {
    if (productDetail && productDetail.variants) {
      if (Object.keys(attributeSelected).length === Object.keys(variants).length) {
        const variant = productDetail.variants.find(variant => {
          return variant.attributes.every(attribute => {
            return attributeSelected[attribute.name] === attribute.value
          })
        })

        if (variant) {
          setVariantId(variant.id)
          // setVariantSelected(variant)
          setPrice(variant.price['1'])
          setStock(variant.stock)
        }
      } else {
        const variants = productDetail.variants || []
        if (variants.length > 0) setPrice(variants[0].price['1'])
      }
    }
  }, [attributeSelected, productDetail])

  // data
  const [qty, setQty] = useState(1)
  const [note, setNote] = useState('')

  const classes = useStyles({ colorTheme: themeColor })

  const getProductDetail = async () => {
    try {
      const response = await getDetail(id)
      if (response.success) {
        setProductDetail(response.data)

        const variants = response.data.variants

        if (variants) {
          let _variants = {}

          const attributeName = variants[0].attributes.map(item => item.name)
          _variants[attributeName] = []

          variants.forEach(variant => {
            variant.attributes.forEach(item => {
              if (_variants[item.name].indexOf(item.value) === -1) {
                _variants[item.name].push(item.value)
              }
            })

            if (searchParams.get('variantId')) {
              const _attributeSelected = {}
              if (variant.id == searchParams.get('variantId')) {
                variant.attributes.forEach(item => {
                  _attributeSelected[item.name] = item.value
                })
                setAttributeSelected(_attributeSelected)
              }
            }
          })

          setVariants(_variants)
        }
      }
    } catch (error) {
      console.log('Error fetching getDetailData:', error)
    }
  }

  const AddToCartProduct = async () => {
    if (variantId) {
      const variant = productDetail.variants.find(variant => variant.id === variantId)
      if (qty > variant.stock) {
        setMessage('Stok Tidak Tersedia Untuk Produk Ini')
        return
      }

      let data = [
        {
          product_id: Number(id),
          product_variant_id: variantId,
          quantity: qty,
          checked_out_quantity: qty,
          note: note
        }
      ]

      try {
        const response = await createToCart(
          data.filter(item => item.quantity > 0),
          landingPage,
          customer,
          carts,
          productDetail,
          productDetail.variants
        )
        if (response.success) {
          setVariantId(null)
          // setVariantSelected(null)
          setAttributeSelected({})
          setQty(1)
          setNote('')

          dispatch(
            setSnackbar({
              show: true,
              sx: {
                top: { xs: '50vh', sm: 0 }
              },
              message: t('success_add_to_cart'),
              action: {
                text: t('show_cart'),
                onClick: () => {
                  history.push('/cart')
                }
              }
            })
          )
          refreshCart()
        } else {
          setMessage(response.message)
        }
      } catch (error) {
        setMessage(error.message)
      }
    }
  }

  const addToCart = async () => {
    if (variantId == null) {
      setMessage('Please select variant.')
    }

    try {
      if (userid) {
        if (qty <= 0) {
          setMessage('Please insert qty.')
        } else {
          localStorage.setItem('productId', Number(id))
          await AddToCartProduct()
        }
      } else {
        history.push('/login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProductDetail()
  }, [])

  return (
    <React.Fragment>
      <Header
        back={false}
        close={true}
        title='Pilih Variasi'
        color={themeColor}
        showMessageButton={false}
      />

      {productDetail && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: '#fff'
          }}
        >
          <div
            style={{
              paddingTop: '48px'
            }}
          ></div>

          <div
            style={{
              marginTop: '8px',
              paddingLeft: '8px',
              paddingRight: '8px',
              display: 'flex',
              gap: '8px'
            }}
          >
            <img
              src={process.env.REACT_APP_FILE_BASE_URL + '/' + productDetail.product?.media[0]}
              style={{
                width: '100px',
                height: '100px',
                objectFit: 'cover',
                borderRadius: '8px'
              }}
            />
            <div
              style={{
                padding: '8px'
              }}
            >
              <div>{productDetail.product?.name}</div>
              <div>{priceFormatLocal(price)}</div>
              {stock ? (
                <div>
                  {t('stock')} : {stock}
                </div>
              ) : null}
            </div>
          </div>

          {Object.keys(variants).map((variant, index) => (
            <div
              key={index}
              style={{
                backgroundColor: '#ffffff'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingLeft: '8px',
                  marginTop: '16px',
                  marginBottom: '8px'
                }}
              >
                <div>{t(variant.toLocaleLowerCase())} : Silahkan Pilih</div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
                  gap: '8px',
                  padding: '8px',
                  paddingTop: '0px'
                }}
              >
                {variants[variant].map((item, indexVariant) => {
                  const _variant = productDetail.variants.find(variant =>
                    variant.attributes.find(attribute => attribute.value === item)
                  )

                  const stock = _variant?.stock || 0

                  return (
                    <div
                      key={indexVariant}
                      style={{
                        fontSize: '12px',
                        backgroundColor: '#f5f5f5',
                        opacity: stock > 0 ? 1 : 0.5,
                        lineHeight: '18px',
                        padding: '8px',
                        textAlign: 'center',
                        cursor: stock > 0 ? 'pointer' : 'not-allowed',
                        border: `1px solid #f5f5f5`,
                        ...(attributeSelected[variant] === item && {
                          border: `1px solid ${themeColor}`,
                          color: themeColor
                        })
                      }}
                      onClick={() => {
                        if (stock <= 0) return

                        setAttributeSelected({
                          ...attributeSelected,
                          [variant]: item
                        })
                      }}
                    >
                      {item}
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
          <div
            className='fixed-bottom'
            style={{
              maxWidth: '100wh',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <div
              className='placeholder-input'
              style={{
                padding: '0px 16px',
                width: '100%'
              }}
            >
              <Input
                value={note}
                onChange={e => setNote(e.target.value)}
                placeholder={t('optional_message_here')}
                fullWidth
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '16px',
                height: '48px'
              }}
            >
              <div
                style={{
                  width: '100%'
                }}
              >
                Total{' '}
                <span
                  style={{
                    color: 'black'
                  }}
                >
                  {priceFormatLocal(price * qty)}
                </span>
              </div>
              <div
                style={{
                  // maxWidth: '500px',
                  // max width is not working please fix it
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center'
                }}
              >
                <IconButton onClick={() => setQty(parseInt(qty) - 1)} disabled={qty <= 1}>
                  <BiMinus />
                </IconButton>
                <Input
                  type='number'
                  value={qty}
                  onChange={e => setQty(e.target.value)}
                  className='qtyinput'
                />
                <IconButton onClick={() => setQty(parseInt(qty) + 1)} disabled={qty >= stock}>
                  <BiPlus />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                height: '42px',
                display: 'flex'
              }}
            >
              <button
                className={classes.chatBtn}
                onClick={() => {
                  if (userid) {
                    history.push('/chat')
                  } else {
                    history.push('/login')
                  }
                }}
              >
                <svg
                  style={{ width: '20px', height: '26px' }}
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z'
                    stroke={themeColor}
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M15.9965 11H16.0054'
                    stroke={themeColor}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.9955 11H12.0045'
                    stroke={themeColor}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M7.99451 11H8.00349'
                    stroke={themeColor}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>

                <span style={{ fontSize: '17px', color: themeColor }}>{t('chat')}</span>
              </button>
              <button
                className='finish-btn text-uppercase btn'
                style={{
                  backgroundColor: themeColor ? themeColor : '#FFFFFF',
                  borderRadius: '0px',
                  height: '100%'
                }}
                onClick={addToCart}
              >
                <span>{t('buy')}</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  box: {
    // boxShadow: '0px 0px 8px -1px rgb(219 213 213)',
    backgroundColor: '#ffffff',
    '& h6': {
      color: props => props.colorTheme
    }
  },

  cards_style: {
    '& .card:hover': {}
  },

  cardTitle: {
    '& .read-more-less--more': {
      display: 'none'
    }
  },

  imageslide: {
    '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail.active':
      {
        border: props => `1.5px solid ${props.colorTheme} !important`,
        borderRadius: `4.5px !important`
      },
    // '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail':
    //   {
    //     width: '22vw !important'
    //   },
    '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails .image-gallery-thumbnails-container .image-gallery-thumbnail .image-gallery-thumbnail-image':
      {
        objectFit: 'cover !Important'
      },
    '& .image-gallery .image-gallery-content .image-gallery-thumbnails-wrapper .image-gallery-thumbnails':
      {
        padding: '0px !important',
        width: '100% !important'
      },
    '& .image-gallery .image-gallery-content .image-gallery-slide .image-gallery-image': {
      height: '350px !important',
      objectFit: 'cover !Important'
    },
    '& .image-gallery-thumbnails': {
      backgroundColor: '#F5F5F5'
    },
    '& .image-gallery .image-gallery-index': {
      padding: '5px',
      borderRadius: '50%',
      fontSize: '13px !important'
    }
  },
  title: {
    fontSize: '13px'
  },
  avatarfav: {
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    marginBottom: '60px'
  },
  avatarwhat: {
    backgroundColor: 'rgba(20, 9, 9, 0.3);',
    border: '1px solid #fff',
    marginBottom: '5px'
  },
  whatsAppIcon: {
    background: 'rgba(37, 211, 102, 1)',
    borderRadius: '50%'
  },
  favBorderIcon: {
    backgroundColor: props => props.colorTheme,
    marginBottom: '0px'
  },
  wholeSale: {
    color: 'rgba(68, 210, 255, 1)',
    fontSize: '10px'
  },
  colorBox: {
    height: '3.4rem',
    width: '3.5rem',
    color: 'black'
  },
  icons: {
    color: '#8B8B8B'
  },
  chatBtn: {
    backgroundColor: '#FFF',
    color: '#000',
    border: 'none',
    borderRadius: '0px',
    width: '100%',
    height: '100%',
    marginBottom: '9px',
    paddingBottom: '5px'
  },
  buyBtn: {
    backgroundColor: props => props.colorTheme,
    color: '#fff',
    width: '100%',
    border: 'none',
    height: '100%'
  },
  specificationTable: {
    color: 'rgba(139, 139, 139, 1)',
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      paddingTop: '0.4rem',
      paddingBottom: '0.4rem',
      paddingLeft: '0.25rem'
    },
    '& td:nth-child(1)': {
      width: '40%'
    },
    '& tr:nth-child(odd)': {
      backgroundColor: '#F2F0F1'
    }
  },
  bestSeller: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    backgroundColor: 'red',
    color: '#fff',
    fontSize: '10px',
    borderRadius: '2px'
  },
  shareButton: {
    backgroundColor: '#E4E5E9',
    border: 'none',
    borderRadius: '100px 0 0 100px',
    width: '25%',
    fontSize: '10px',
    textAlign: 'center',
    position: 'absolute',
    right: '-6px',
    padding: '0px 10px 0px 0px'
  },
  snackbarStyleViaContentProps1: {
    backgroundColor: '#363636 !important',
    textAlign: 'center !important',
    color: 'white !important',
    boxShadow: 'none !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    fontWeight: '500 !important',
    fontSize: '15px !important',
    borderRadius: '8px !important',
    margin: '12px 24px 123px 24px !important',
    height: '47px !important',
    width: '359px !important',
    paddding: 0,
    '& .MuiSnackbarContent-message': {
      padding: 0
    }
  },
  snackbarStyleCopyProduct: {
    backgroundColor: '#363636 !important',
    textAlign: 'center !important',
    color: 'white !important',
    boxShadow: 'none !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    fontWeight: '500 !important',
    fontSize: '15px !important',
    borderRadius: '8px !important',
    margin: '12px 24px 12px 24px !important',
    height: '47px !important',
    width: '359px !important',
    paddding: 0,
    '& .MuiSnackbarContent-message': {
      padding: 0
    }
  },
  desdiv: {
    '& p': {
      marginBottom: '0px !important'
    }
  },

  mobile_slider_cards: {
    '& .card-body': {
      padding: '5px 2px 0px 6px !important'
    },
    '& .mainImageThum': {
      height: '200px'
    },
    '& .cardTitle ': {
      fontSize: '13px !important'
    },
    '& .TowLineText ': {
      height: '38px'
    }
  },
  styleTextfieldNotedForSale: {
    width: '97%',
    marginLeft: '5px',
    marginRight: '5px',
    border: '0',
    '& label.Mui-focused': {
      color: props => props.colorTheme
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: props => props.colorTheme
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: props => props.colorTheme
      }
    }
  },
  qtyinput: {
    '& input[type=number]': {
      WebkitAppearance: 'textfield',
      mozAppearance: 'textfield',
      appearance: 'textfield',
      textAlign: 'center'
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none'
    },
    '& input[type=number]:disabled': {
      cursor: 'not-allowed'
    }
  },
  buttonWishlist: {
    padding: '6px',
    background: '#80808040',
    borderRadius: '54px',
    width: '30px',
    height: '30px',
    outline: 'none',
    border: 'none'
  },
  buttonIcon: {
    border: 'none',
    outline: 'none',
    borderRadius: '50%',
    background: 'rgb(111 104 104 / 25%)',
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg.MuiSvgIcon-root': {
      margin: '0px !important'
    }
  },
  table: {
    '& tbody tr td': {
      fontSize: '14px'
    }
  },
  indexThumbnail: {
    padding: '6px',
    minWidth: '42px',
    height: '30px',
    borderRadius: '100px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

export default withNamespaces()(DetailVariant)
