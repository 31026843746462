import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  btn_reset: {
    color: '#44910a !important',
    textTransform: 'lowercase',
    fontSize: '14px',
    fontWeight: '600',
    padding: '0',
    minWidth: '37px'
  },

  cardStyle: {
    padding: '0'
  },

  header: {
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
    marginBottom: '15px'
  },

  input_style: {
    '& .MuiFormControl-root': {
      marginBottom: '0 !important'
    }
  },

  cardItem: {
    backgroundColor: 'white',
    border: '1px solid #fbfbfb',
    padding: '0 15px',
    marginBottom: '15px',
    borderRadius: '8px',
    boxShadow: '1px 0px 5px #ccc',

    '& .header_cardItem': {
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingTop: '10px',
      borderBottom: '1px solid #dee2e6!important',
      marginBottom: '0',

      '& .description': {
        // fontSize: "14px",
        color: '#545454',
        marginBottom: '5px'
      }
    },
    '& .Shipping': {
      display: 'flex',
      // gap: "5px",
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .ShippingStart': {
      display: 'flex',
      // gap: "5px",
      alignItems: 'center',
      justifyContent: 'start'
    },
    '& .ShippingEnd': {
      display: 'flex',
      // gap: "5px",
      alignItems: 'center',
      justifyContent: 'end'
    }
  },

  content_cardItem: {
    padding: '20px 10px',

    '& .boldTitle': {
      fontSize: '14px',
      color: '#545454',
      fontWeight: '500',
      marginBottom: '15px'
    },
    '& .description': {
      fontSize: '14px',
      color: '#545454'
    }
  },
  btn_payment: {
    backgroundColor: '#01a2e8'
  },

  footer: {
    borderTop: '1px solid #dee2e6!important',

    padding: '10px 10px !important',

    '& .btnGroup': {
      display: 'flex',
      gap: '10px'
    },
    '& .MuiButtonBase-root': {
      marginBottom: '0',
      textTransform: 'capitalize'
    },
    '& .btn_see': {
      float: 'right'
    }
  },

  content_see_hide: {
    borderTop: '1px solid #dee2e6!important',
    padding: '10px 10px !important',
    margin: '0px 10px 0px 10px',

    '& .content': {
      width: '330px',
      maxWidth: '100%',
      fontSize: '14px',
      color: '#545454'
    },
    '& .titleContent': {
      fontWeight: '600',
      marginBottom: '10px',
      fontSize: '14px'
    },
    '& .price': {
      marginBottom: '15px',
      fontSize: '14px',
      color: '#545454 !important'
    }
  },

  item_see_hide: {
    borderBottom: '1px solid #dee2e6',
    padding: '10px 0px 10px 0 !important'
  },

  price_item_see_hide: {
    borderBottom: 'none'
  },

  square_box: {
    backgroundColor: '#ededed',
    height: '100px',
    width: '100px'
  },

  item: {
    display: 'flex',
    gap: '10px'
  },

  price_item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .price': {
      marginBottom: '0.5rem'
    },
    '& .lable': {
      fontSize: '14px'
    }
  },

  content_price: {
    width: '400px',
    marginLeft: 'auto'
  },

  // --------------------------------------------------

  tableContent: {
    borderBottom: '1px solid gray',
    paddingBottom: '15px',
    marginBottom: '20px',
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '0px'
    }
  },

  description_style: {
    '& .title_de': {
      color: '#000',
      fontSize: '14px',
      marginBottom: '5px'
    },
    '& .values': {
      marginBottom: '0',
      fontSize: '14px'
    },

    '& .thumbImg': {
      width: '100%'
    }
  },

  priceContent_style: {
    '& .values': {
      marginBottom: '0',
      fontSize: '14px'
    },
    '& .discount-rate span': {
      padding: '3px 10px',
      fontSize: '14px',
      position: 'absolute'
    },
    '& .price-wdout': {
      textDecoration: 'line-through'
    }
  },
  gTotal: {
    fontSize: '14px'
  },
  pcs: {
    fontSize: '14px'
  }
})

export { useStyles }
