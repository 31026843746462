import { makeStyles, TextField } from '@material-ui/core'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles({
  root: {
    width: '-webkit-fill-available',

    '& .MuiOutlinedInput-input': {
      padding: '14px 14px'
    },

    '& .fullWidth': {
      width: '100%'
    },

    '& .MuiInputLabel-formControl': {
      top: '-4px'
    },
    '& .MuiInputLabel-formControl.MuiInputLabel-shrink': {
      top: '0px'
    }
  },

  input_leftTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    width: '100%'
  },

  input_style: {
    marginBottom: '15px',
    '& input': {
      display: 'block',
      width: '100%',
      height: '28px',
      padding: '6px 15px',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    },
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none'
    },
    '& input:focus': {
      boxShadow: 'none',
      border: '1px solid #ff3a59'
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '-42px'
    }
  },
  search_input_style: {
    // marginBottom: "15px",

    '& svg': {
      position: 'absolute',
      zIndex: '1'
    },
    '& input': {
      display: 'block',
      width: '200px',
      padding: '6px 6px 6px 37px',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#fff',
      backgroundClip: 'padding-box',
      border: '1px solid #ced4da',
      borderRadius: '0.25rem',
      transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out'
    }
    // "& .MuiInput-underline:before, .MuiInput-underline:after": {
    //   display: "none",
    // },
    // "&:focus": {
    //   boxShadow: "none",
    //   border: "1px solid #ff3a59",
    // },
  },

  label: {
    fontSize: '14px',
    color: '#575757',
    marginBottom: '15px !important',
    textAlign: 'right'
  },

  cehckbox_input_style: {
    display: 'flex !important',
    gap: '10px',
    alignItems: 'center',

    '& .css-label': {
      marginBottom: '0',
      cursor: 'pointer'
    },
    "& input[type='checkbox']": {
      accentColor: '#ff3a59',
      height: '20px',
      width: '20px',
      cursor: 'pointer'
    }
  }
})

const Input = props => {
  const classes = useStyles()

  if (props.width == 'full') {
    return (
      <TextField
        type={props.type}
        className={props.className + ' ' + ' fullWidth'}
        style={props.style}
        id={props.id}
        label={props.label}
        variant={props.variant}
        color={props.color}
        value={props.value}
        onChange={props.onChange}
        InputProps={props.InputProps}
        // variant="outlined"
        // color="secondary"
      />
    )
  } else if (props.leftLabel) {
    return (
      <div className={classes.input_leftTitle + ' ' + props.className} style={props.style}>
        <p className={classes.label + '  label'}>{props.leftLabel}</p>
        <TextField
          type={props.type}
          className={classes.input_style}
          style={props.style}
          id={props.id}
          label={props.label}
          variant={props.variant}
          color={props.color}
          value={props.value}
          onChange={props.onChange}
          InputProps={props.InputProps}
        />
      </div>
    )
  } else if (props.search == 'true') {
    return (
      <div className={classes.search_input_style + ' ' + props.className} style={props.style}>
        <SearchIcon />
        <TextField
          type={props.type}
          className={classes.input_style}
          style={props.style}
          id={props.id}
          label={props.label}
          variant={props.variant}
          color={props.color}
          value={props.value}
          onChange={props.onChange}
          InputProps={props.InputProps}
        />
      </div>
    )
  } else if (props.type == 'date') {
    return (
      <div className={classes.search_input_style + ' ' + props.className} style={props.style}>
        {/* <DateRangeIcon /> */}
        <TextField
          type={props.type}
          className={classes.input_style}
          style={props.style}
          id={props.id}
          label={props.label}
          variant={props.variant}
          color={props.color}
          value={props.value}
          onChange={props.onChange}
          InputProps={props.InputProps}
        />
      </div>
    )
  } else if (props.type == 'checkbox') {
    return (
      <div className={classes.cehckbox_input_style + ' ' + props.className} style={props.style}>
        <input
          type='checkbox'
          name={props.name}
          id={props.id}
          className='css-checkbox'
          value={props.value}
          onChange={props.onChange}
        />
        <label htmlFor={props.id} className='css-label'>
          {props.label}
        </label>
      </div>
    )
  } else {
    return (
      <TextField
        type={props.type}
        className={props.className}
        style={props.style}
        id={props.id}
        label={props.label}
        variant={props.variant}
        color={props.color}
        value={props.value}
        onChange={props.onChange}
        InputProps={props.InputProps}
      />
    )
  }
}

export default Input
