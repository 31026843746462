import { instance2 } from '../helper/api'

const prefixUrl = '/shopi/v1/customer/wishlist'

export const getProductsWishlist = async param => {
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
  }
  let resultData = {}
  const axiosConfig = {
    url: `${prefixUrl}/product/`,
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const getCountWishlist = async param => {
  let paramValue = null
  if (param) {
    paramValue = JSON.parse(param)
  }
  let resultData = {}
  const axiosConfig = {
    url: `${prefixUrl}/product/count/`,
    method: 'GET',
    params: paramValue
  }

  try {
    const results = await instance2.request(axiosConfig)
    resultData = results.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}

export const createProductWishlist = async data => {
  const axiosConfig = {
    url: `${prefixUrl}/`,
    method: 'POST',
    data: data
  }

  const results = await instance2.request(axiosConfig)
  return results.data
}

export const deleteProductWishlist = async productId => {
  const axiosConfig = {
    url: `${prefixUrl}/${productId}/`,
    method: 'DELETE'
  }

  const results = await instance2.request(axiosConfig)
  return results.data
}
