import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Login from './containers/form/Login'
import ForgotPassword from './containers/form/ForgotPassword'
import Addressform from './containers/address/addressform'
import AccountSetting from './containers/account/accountsettings'
import Home from './containers/home'
import ProductListHor from './containers/product/ProductListTwoHor'
import DetailTab from './containers/productDetail/DetailTab'
import ProductOption from './containers/product/ProductOption'
import Cart from './containers/cart/Cart'
import OrderConfirmation from './containers/orderconfirmation/index'
import SuccessOrder from './containers/orderconfirmation/successorder'
import OrderHistory from './containers/orderhistory/index'
import SelectAddress from './containers/address/SelectAddress'
import Shipping from './containers/shipping/ShippingMethod'
import Payment from './containers/paymentpage/index'
import Category from './containers/product/Category/index'
import Setting from './containers/setting/Setting'
import EditProfile from './containers/profile/EditProfile'
import ChangePassword from './containers/changepass/changepassword'
import OrderDetails from './containers/orderdetails/orderdetails'
import Chat from './containers/chat/chat'
import Wishlist from './containers/wishlist/Wishlist'
import Productview from './containers/product/ProductView'
// import ProductView2 from "./containers/product/ProductView2";
import ProductCategory from './containers/product/ProductCategory'
import PaymentConfirm from './containers/paymentconfirmation/Payment'
import Notification from './containers/notification/index'
import SearchPage from './containers/searchpage/index'
import LanguageSelect from './containers/language/Language'
import ProtectedRoute from './ProtectedRoute'
import Viewed from './containers/viewed/viewed'
import Coupon from './containers/coupon/coupon'
import LapSidebar from './containers/home/lapSidebar'
// import { ThemeColor } from "./containers/themecolor";
import ProductList from './containers/ProductList'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import SingleProductDetail from './containers/singleProductDetail'

import ProductListSearch from './containers/ProductList/Search'
import AddToCart from './containers/addToCart'
import Checkout from './containers/checkout'
import MyAccount from './containers/myAccount'
import ChangePasswordProfile from './containers/myAccount/pages/changePassword/ChangePasswordProfile'
import FooterSlug from './containers/footerSlug'
import SuccessOrderPay from './containers/SuccessOrderPay/SuccessOrderPay'
import ComingSoon from './containers/coming-soon'
import ComingSoonMobile from './containers/coming-soon/coming-soon-mobile'
import PrivacyPolictPage from './containers/privacy-policy'
import { Snackbars } from './components/Snackbars'
import Demo from './containers/Demo'

import GuestRoute from './GuestRoute'
import { useStore } from './hooks/useStore'
import { useAuth } from './hooks/useAuth'
import DetailVariant from 'containers/productDetail/DetailVariant'

function App() {
  const { themeColor, landingPage } = useStore()
  const { initAuth } = useAuth()

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [active, setActive] = useState('')

  useEffect(() => {
    if (landingPage) {
      initAuth()
    }
  }, [landingPage])

  // if (!landingPage) {
  //   return <div></div>
  // }

  return (
    <div className='App'>
      <Snackbars />
      {/* <GlobalState> */}
      <Router>
        {/* {landing !== null ? (
                maintenance_mode_website == "Website" ? (
                  <Switch>
                      <Route path="/delete-account" component={FooterSlug} exact />
                      <Route path="/reseller" component={FooterSlug} exact />
                      <Route path="/tanya-jawab" component={FooterSlug} exact />
                      <Route path="/cara-order" component={FooterSlug} exact />
                      <Route path="/privacy-policy" component={FooterSlug} exact />
                      <Route path="/support" component={FooterSlug} exact />
                      <Route path="/terms" component={FooterSlug} exact /> 
                      <Route path="/" component={Maintenance} exact />
                  </Switch> 
                ) : landing.landing_page == 0 ? (
                  <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/userlogin" render={(props) => (
                        <Login setIsMobileLoginAlready={setIsMobileLoginAlready} />
                      )} exact />
                    <Route
                      path="/forgotpassword"
                      component={ForgotPassword}
                      exact
                    />
                    <Route path="/productlist" component={ProductListHor} exact />
                    <Route path="/productdetail" component={DetailTab} exact />
                    <Route
                      path="/productdetail/:id"
                      component={DetailTab}
                      exact
                    />
                    <Route path="/category" component={Category} exact />
                    <Route
                      path="/comingsoon"
                      component={ComingSoon}
                      exact
                    />
                    <Route path="/chat" component={Chat} exact />
                    <Route path="/productview" component={Productview} exact />
                    <Route
                      path="/productsearch"
                      component={ProductListSearch}
                      exact
                    />
                    <Route
                      path="/productsearch/:searchvalue"
                      component={ProductListSearch}
                      exact
                    />
                    <Route
                      path="/productcategory"
                      component={ProductCategory}
                      exact
                    />
                    <Route path="/setting" component={Setting} exact />
                    <Route path="/sidebar" component={LapSidebar} exact />
                    <Route
                      path="/accountsettings"
                      component={AccountSetting}
                      exact
                    />
                    <Route path="/searchpage" component={SearchPage} exact />
                    <Route path="/singleProductDetail" component={SingleProductDetail} exact/> 
                    <Route
                      path="/singleProductDetail/:id"
                      component={SingleProductDetail}
                      exact
                    />
                    <Route path="/addToCart" component={AddToCart} exact/> 

                    <Route path="/addToCart" exact>
                      <AddToCart webThemeColor={webThemeColor} />
                    </Route>
                    <Route path="/singleProductDetail" exact>
                      <SingleProductDetail webThemeColor={webThemeColor} />
                    </Route>

                    <Route path="/checkout" exact>
                      <Checkout
                        setActive={setActive}
                        webThemeColor={webThemeColor}
                      />
                    </Route>
                    <Route path="/myAccount" exact>
                      <f active={active} webThemeColor={webThemeColor} />
                    </Route>
                    <Route path="/successOrderPay" exact>
                      <SuccessOrderPay
                        setActive={setActive}
                        webThemeColor={webThemeColor}
                      />
                    </Route>

                    <Route path="/myAccount/:id" component={MyAccount} exact />
                    <Route
                      path="/changePasswordProfile"
                      component={ChangePasswordProfile}
                      exact
                    />
                    <Route
                      path="/selectlanguage"
                      component={LanguageSelect}
                      exact
                    />
                    <Route
                      path="/categorylistdata"
                      component={ProductList}
                      exact
                    />

                    <Route path="/reseller" component={FooterSlug} exact />
                    <Route path="/tanya-jawab" component={FooterSlug} exact />
                    <Route path="/cara-order" component={FooterSlug} exact />
                    <Route path="/delete-account" component={FooterSlug} exact />
                    <Route path="/privacy-policy" component={FooterSlug} exact />
                    <Route path="/support" component={FooterSlug} exact />
                    <Route path="/terms" component={FooterSlug} exact />
                    <Route path="/demo" component={FooterSlug} exact/> 

                    <ProtectedRoute path="/addressform" component={Addressform} />
                    <ProtectedRoute
                      path="/addressform/:id"
                      component={Addressform}
                    />
                    <ProtectedRoute
                      path="/selectaddress"
                      component={SelectAddress}
                    />
                    <ProtectedRoute
                      path="/productoption"
                      component={ProductOption}
                    />
                    <ProtectedRoute path="/shippingmethod" component={Shipping} />
                    <ProtectedRoute path="/paymentmethod" component={Payment} />
                    <ProtectedRoute
                      path="/orderconfirm"
                      component={OrderConfirmation}
                    />
                    <ProtectedRoute
                      path="/orderhistory"
                      component={OrderHistory}
                    />
                    <ProtectedRoute path="/cart" component={Cart} />
                    <ProtectedRoute path="/editprofile" component={EditProfile} />
                    <ProtectedRoute
                      path="/changepassword"
                      component={ChangePassword}
                    />
                    <ProtectedRoute
                      path="/orderdetail"
                      component={OrderDetails}
                    />
                    <ProtectedRoute path="/wishlist" component={Wishlist} />
                    <ProtectedRoute path="/productviews" component={ProductView2} /> 
                    <ProtectedRoute
                      path="/paymentconfirm"
                      component={PaymentConfirm}
                    />
                    <ProtectedRoute
                      path="/successfulorder"
                      component={SuccessOrder}
                    />
                    <ProtectedRoute
                      path="/notification"
                      component={Notification}
                    />
                    <ProtectedRoute path="/viewed" component={Viewed} />
                    <ProtectedRoute path="/coupon" component={Coupon} />
                  </Switch>
                ) : (
                  <Switch>
                    {mobile ? (
                      isMobileLoginAlready ?
                      <Route path="/" component={Home} exact />
                        :
                      <Route path="/" render={(props) => (
                        <Login setIsMobileLoginAlready={setIsMobileLoginAlready} />
                      )} exact />
                    ) : (
                      <Route path="/" component={Home} exact />
                    )}

                    <ProtectedRoute path="/dashboard" component={Home} />
                    <ProtectedRoute
                      path="/forgotpassword"
                      component={ForgotPassword}
                    />
                    <ProtectedRoute
                      path="/productlist"
                      component={ProductListHor}
                    />
                    <ProtectedRoute
                      path="/categorylistdata"
                      component={ProductList}
                      exact
                    />
                    <ProtectedRoute path="/productdetail" component={DetailTab} />
                    <ProtectedRoute
                      path="/productdetail/:id"
                      component={DetailTab}
                    />
                    <ProtectedRoute path="/category" component={Category} />
                    <ProtectedRoute path="/comingsoon" component={ComingSoon} />
                    <ProtectedRoute path="/chat" component={Chat} />
                    <ProtectedRoute path="/productview" component={Productview} />
                    <ProtectedRoute
                      path="/productcategory"
                      component={ProductCategory}
                    />
                    <ProtectedRoute path="/setting" component={Setting} />
                    <ProtectedRoute path="/sidebar" component={LapSidebar} />
                    <ProtectedRoute
                      path="/accountsettings"
                      component={AccountSetting}
                    />
                    <ProtectedRoute path="/searchpage" component={SearchPage} />
                    <ProtectedRoute
                      path="/selectlanguage"
                      component={LanguageSelect}
                    />
                    <ProtectedRoute path="/addressform" component={Addressform} />
                    <ProtectedRoute
                      path="/addressform/:id"
                      component={Addressform}
                    />
                    <ProtectedRoute
                      path="/selectaddress"
                      component={SelectAddress}
                    />
                    <ProtectedRoute
                      path="/productoption"
                      component={ProductOption}
                    />
                    <ProtectedRoute path="/shippingmethod" component={Shipping} />
                    <ProtectedRoute path="/paymentmethod" component={Payment} />
                    <ProtectedRoute
                      path="/orderconfirm"
                      component={OrderConfirmation}
                    />
                    <ProtectedRoute
                      path="/orderhistory"
                      component={OrderHistory}
                    />
                    <ProtectedRoute path="/cart" component={Cart} />
                    <ProtectedRoute path="/editprofile" component={EditProfile} />
                    <ProtectedRoute
                      path="/changepassword"
                      component={ChangePassword}
                    />
                    <ProtectedRoute
                      path="/orderdetail"
                      component={OrderDetails}
                    />
                    <ProtectedRoute path="/wishlist" component={Wishlist} />
                    <ProtectedRoute path="/productviews" component={ProductView2} /> 
                    <ProtectedRoute
                      path="/paymentconfirm"
                      component={PaymentConfirm}
                    />
                    <ProtectedRoute
                      path="/successfulorder"
                      component={SuccessOrder}
                    />
                    <ProtectedRoute
                      path="/notification"
                      component={Notification}
                    />
                    <ProtectedRoute path="/viewed" component={Viewed} />
                    <ProtectedRoute path="/coupon" component={Coupon} />
                    <Route
                      path="/singleProductDetail"
                      component={SingleProductDetail}
                      exact
                    />
                    <Route
                      path="/singleProductDetail/:id"
                      component={SingleProductDetail}
                      exact
                    />

                    <Route path="/searchpage" component={SearchPage} exact />
                    <Route
                      path="/singleProductDetail"
                      component={SingleProductDetail}
                      exact
                    />
                    <Route
                      path="/singleProductDetail/:id"
                      component={SingleProductDetail}
                      exact
                    />
                    <Route path="/addToCart" component={AddToCart} exact />

                    <Route path="/checkout" exact>
                      <Checkout setActive={setActive} />
                    </Route>
                    <Route path="/myAccount" exact>
                      <MyAccount active={active} />
                    </Route>
                    <Route path="/successOrderPay" exact>
                      <SuccessOrderPay setActive={setActive} />
                    </Route>
                    <Route
                      path="/productsearch"
                      component={ProductListSearch}
                      exact
                    />
                    <Route path="/myAccount/:id" component={MyAccount} exact />
                    <Route
                      path="/changePasswordProfile"
                      component={ChangePasswordProfile}
                      exact
                    />
                    <Route path="/reseller" component={FooterSlug} exact />
                    <Route path="/tanya-jawab" component={FooterSlug} exact />
                    <Route path="/cara-order" component={FooterSlug} exact />
                    <Route path="/delete-account" component={FooterSlug} exact />
                    <Route path="/privacy-policy" component={FooterSlug} exact />
                    <Route path="/support" component={FooterSlug} exact />
                    <Route path="/terms" component={FooterSlug} exact />
                    <Route path="/demo" component={FooterSlug} exact/> 
                    <ProtectedRoute path="/comingsoon" component={ComingSoon} /> 


                  </Switch>
                )
              ) : (
                ""
              )}
              */}

        <Switch>
          {/* {isMaintenance === true ? <Route path='/' component={Maintenance} exact /> : ''} */}

          <Route path='/' component={Home} exact />

          <Route path='/secret' component={Demo} />
          {/* <Route path="/dashboard" component={Home} /> */}
          <GuestRoute path='/forgot-password' component={ForgotPassword} />
          <Route path='/product-list' component={ProductListHor} />
          <Route path='/product-by-category' component={ProductList} exact />
          <Route path='/productdetail' component={DetailTab} />
          <Route path='/productdetailvariant' component={DetailVariant} />
          <Route path='/productdetail/:id' component={DetailTab} />
          <Route path='/category' component={Category} />
          <Route path='/comingsoon' component={ComingSoon} />
          <Route path='/comingsoon-mobile' component={ComingSoonMobile} />
          <Route path='/chat' component={Chat} />
          <Route path='/products' component={Productview} />
          <Route path='/productcategory' component={ProductCategory} />
          <ProtectedRoute path='/setting' component={Setting} />
          <ProtectedRoute path='/sidebar' component={LapSidebar} />
          <ProtectedRoute path='/accountsettings' component={AccountSetting} />
          <Route path='/search' component={SearchPage} />
          <ProtectedRoute path='/selectlanguage' component={LanguageSelect} />
          <ProtectedRoute path='/addressform' component={Addressform} />
          <ProtectedRoute path='/addressform/:id' component={Addressform} />
          <ProtectedRoute path='/selectaddress' component={SelectAddress} />
          <ProtectedRoute path='/productoption' component={ProductOption} />
          <ProtectedRoute path='/shippingmethod' component={Shipping} />
          <ProtectedRoute path='/paymentmethod' component={Payment} />
          <ProtectedRoute path='/orderconfirm' component={OrderConfirmation} />
          <ProtectedRoute path='/orderhistory' component={OrderHistory} />
          <ProtectedRoute path='/cart' component={Cart} />
          <ProtectedRoute path='/editprofile' component={EditProfile} />
          <ProtectedRoute path='/changepassword' component={ChangePassword} />
          <ProtectedRoute path='/orderdetail' component={OrderDetails} />
          <ProtectedRoute path='/wishlist' component={Wishlist} />
          {/* <ProtectedRoute path="/productviews" component={ProductView2} />  */}
          <ProtectedRoute path='/paymentconfirm' component={PaymentConfirm} />
          <ProtectedRoute path='/successfulorder' component={SuccessOrder} />
          <Route path='/notification' component={Notification} />
          <ProtectedRoute path='/viewed' component={Viewed} />
          <ProtectedRoute path='/coupon' component={Coupon} />
          <Route path='/product-detail' exact>
            <SingleProductDetail webThemeColor={themeColor} />
          </Route>

          <Route path='/product-detail:id' exact>
            <SingleProductDetail webThemeColor={themeColor} />
          </Route>

          <Route path='/search' component={SearchPage} exact />

          <Route path='/carts' exact>
            <AddToCart webThemeColor={themeColor} />
          </Route>

          <ProtectedRoute path='/checkout' exact>
            <Checkout setActive={setActive} webThemeColor={themeColor} />
          </ProtectedRoute>
          <ProtectedRoute path='/my-profile' exact>
            <MyAccount active={active} webThemeColor={themeColor} />
          </ProtectedRoute>
          <Route path='/success-order-pay' exact>
            <SuccessOrderPay setActive={setActive} webThemeColor={themeColor} />
          </Route>
          <Route path='/productsearch' component={ProductListSearch} exact />
          <ProtectedRoute path='/my-profile/:id' component={MyAccount} exact />
          <Route path='/change-password' component={ChangePasswordProfile} exact />
          <Route path='/reseller' component={FooterSlug} exact />
          <Route path='/tanya-jawab' component={FooterSlug} exact />
          <Route path='/cara-order' component={FooterSlug} exact />
          <Route path='/delete-account' component={FooterSlug} exact />
          <Route path='/privacy-policy' component={PrivacyPolictPage} exact />
          <Route path='/support' component={FooterSlug} exact />
          <Route path='/terms' component={FooterSlug} exact />
          <Route path='/demo' component={FooterSlug} exact />
          <Route path='/comingsoon' component={ComingSoon} />
          <GuestRoute path='/login' render={props => <Login />} exact />
        </Switch>
      </Router>
      {/* </GlobalState> */}
    </div>
  )
}

export default App

{
  /* <Route path="/forgotpassword" component={ForgotPassword} exact />
<Route path="/productlist" component={ProductListHor} exact />
<Route path="/productdetail" component={DetailTab} exact />
<Route path="/productdetail/:id" component={DetailTab} exact />
<Route path="/category" component={Category} exact />
<Route path="/chat" component={Chat} exact />
<Route path="/productview" component={Productview} exact />
<Route path="/productcategory" component={ProductCategory} exact />
<Route path="/setting" component={Setting} exact />
<Route path="/sidebar" component={LapSidebar} exact />
<Route path="/accountsettings" component={AccountSetting} exact />
<Route path="/searchpage" component={SearchPage} exact />
<Route path="/selectlanguage" component={LanguageSelect} exact />
<ProtectedRoute path="/addressform" component={Addressform} />
<ProtectedRoute path="/addressform/:id" component={Addressform} />
<ProtectedRoute path="/selectaddress" component={SelectAddress} />
<ProtectedRoute path="/productoption" component={ProductOption} />
<ProtectedRoute path="/shippingmethod" component={Shipping} />
<ProtectedRoute path="/paymentmethod" component={Payment} />
<ProtectedRoute path="/orderconfirm" component={OrderConfirmation} />
<ProtectedRoute path="/orderhistory" component={OrderHistory} />
<ProtectedRoute path="/cart" component={Cart} />
<ProtectedRoute path="/editprofile" component={EditProfile} />
<ProtectedRoute path="/changepassword" component={ChangePassword} />
<ProtectedRoute path="/orderdetail" component={OrderDetails} />
<ProtectedRoute path="/wishlist" component={Wishlist} />
{/* <ProtectedRoute path="/productviews" component={ProductView2} /> */
}
{
  /* <ProtectedRoute path="/paymentconfirm" component={PaymentConfirm} />
<ProtectedRoute path="/successfulorder" component={SuccessOrder} />
<ProtectedRoute path="/notification" component={Notification} />
<ProtectedRoute path="/viewed" component={Viewed} />
<ProtectedRoute path="/coupon" component={Coupon} /> */
}

{
  /* <Route path="/" component={Home} exact />
<Route path="/userlogin" component={Login} exact />
<Route path="/forgotpassword" component={ForgotPassword} exact />
<Route path="/productlist" component={ProductListHor} exact />
<Route path="/productdetail" component={DetailTab} exact />
<Route path="/addressform" component={Addressform} exact />
<Route path="/addressform/:id" component={Addressform} exact />
<Route path="/selectaddress" component={SelectAddress} exact />
<Route path="/productoption" component={ProductOption} exact />
<Route path="/shippingmethod" component={Shipping} exact />
<Route path="/paymentmethod" component={Payment} exact />
<Route path="/orderconfirm" component={OrderConfirmation} exact />
<Route path="/orderhistory" component={OrderHistory} exact />
<Route path="/cart" component={Cart} exact />
<Route path="/category" component={Category} exact />
<Route path="/accountsettings" component={AccountSetting} exact />
<Route path="/setting" component={Setting} exact />
<Route path="/editprofile" component={EditProfile} exact />
<Route path="/changepassword" component={ChangePassword} exact />
<Route path="/orderdetail" component={OrderDetails} exact />
<Route path="/chat" component={Chat} exact />
<Route path="/wishlist" component={Wishlist} exact />
<Route path="/productview" component={Productview} exact />
<Route path="/productviews" component={ProductView2} exact />
<Route path="/productcategory" component={ProductCategory} exact />
<Route path="/paymentconfirm" component={PaymentConfirm} exact />
<Route path="/successfulorder" component={SuccessOrder} exact /> 
<Route path="/notification" component={Notification} exact />  */
}

{
  /* <ProtectedRoute path="/dashboard" component={Home}  />
<ProtectedRoute path="/forgotpassword" component={ForgotPassword}  />
<ProtectedRoute path="/productlist" component={ProductListHor}  />
<ProtectedRoute path="/productdetail" component={DetailTab}  />
<ProtectedRoute path="/addressform" component={Addressform}  />
<ProtectedRoute path="/addressform/:id" component={Addressform}  />
<ProtectedRoute path="/selectaddress" component={SelectAddress}  />
<ProtectedRoute path="/productoption" component={ProductOption}  />
<ProtectedRoute path="/shippingmethod" component={Shipping}  />
<ProtectedRoute path="/paymentmethod" component={Payment}  />
<ProtectedRoute path="/orderconfirm" component={OrderConfirmation}  />
<ProtectedRoute path="/orderhistory" component={OrderHistory}  />
<ProtectedRoute path="/cart" component={Cart}  />
<ProtectedRoute path="/category" component={Category}  />
<ProtectedRoute path="/accountsettings" component={AccountSetting}  />
<ProtectedRoute path="/setting" component={Setting}  />
<ProtectedRoute path="/editprofile" component={EditProfile}  />
<ProtectedRoute path="/changepassword" component={ChangePassword}  />
<ProtectedRoute path="/orderdetail" component={OrderDetails}  />
<ProtectedRoute path="/chat" component={Chat}  />
<ProtectedRoute path="/wishlist" component={Wishlist}  />
<ProtectedRoute path="/productview" component={Productview}  />
<ProtectedRoute path="/productviews" component={ProductView2}  />
<ProtectedRoute path="/productcategory" component={ProductCategory}  />
<ProtectedRoute path="/paymentconfirm" component={PaymentConfirm}  />
<ProtectedRoute path="/successfulorder" component={SuccessOrder}  />  */
}
