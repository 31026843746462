import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: ''
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload
    }
  }
})

export const { setAuthToken } = auth.actions

export default auth.reducer
