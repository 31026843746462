import React from 'react'
import { withNamespaces } from 'react-i18next'

import { makeStyles } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const useStyles = makeStyles(() => ({
  wrapper: {
    background: '#fff',
    display: 'flex',
    columnGap: '10px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sortByRoot: {
    color: '#000',
    borderRadius: '3px',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    '&:hover': {
      background: props => props.colorTheme,
      color: '#fff',
      cursor: 'pointer'
    },
    height: '2.125rem',
    display: 'flex',
    alignItems: 'center'
  },
  sortByAv: {
    borderRadius: '3px',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    background: props => props.colorTheme,
    color: '#fff',
    cursor: 'pointer',
    '&>svg': {
      margin: '0px !important',
      fontSize: '18px !important',
      marginLeft: '6px !important'
    },
    height: '2.125rem',
    display: 'flex',
    alignItems: 'center'
  },
  sortByRootIcon: {
    color: '#000',
    borderRadius: '3px',
    height: '2.125rem',
    fontSize: '14px',
    border: '1px solid #ccc',
    padding: '3px 10px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    '&>svg': {
      margin: '0px !important',
      fontSize: '18px !important'
    },
    '&:hover': {
      background: props => props.colorTheme,
      color: '#fff',
      cursor: 'pointer'
    }
  }
}))

const TabSortProductDesktop = ({ t, sortBy, setSortBy }) => {
  const themeColor = JSON.parse(localStorage.getItem('themeColor'))
  const classes = useStyles({ colorTheme: themeColor })

  const handleClickSetSortBy = sortByValue => {
    // check if sortByValue is equals sortBy then reset
    const valueSortBy = sortByValue === sortBy ? null : sortByValue
    setSortBy(valueSortBy)
  }

  const handleClickSetSortByPrice = () => {
    const sortPrice = sortBy === 4 ? 3 : sortBy === 3 ? null : 4
    handleClickSetSortBy(sortPrice)
  }

  return (
    <div className={classes.wrapper}>
      <div
        className={sortBy == 1 ? classes.sortByAv : classes.sortByRoot}
        onClick={() => {
          handleClickSetSortBy(1)
        }}
      >
        {t('new')}
      </div>

      <div
        className={sortBy == 5 ? classes.sortByAv : classes.sortByRoot}
        onClick={() => {
          handleClickSetSortBy(5)
        }}
      >
        {t('discount')}
      </div>

      <div
        className={sortBy == 2 ? classes.sortByAv : classes.sortByRoot}
        onClick={() => {
          handleClickSetSortBy(2)
        }}
      >
        {t('best_seller')}
      </div>

      <div
        className={sortBy === 3 || sortBy === 4 ? classes.sortByAv : classes.sortByRootIcon}
        onClick={handleClickSetSortByPrice}
      >
        {t('price')}
        {sortBy === 3 && <ArrowUpwardIcon />}
        {sortBy === 4 && <ArrowDownwardIcon />}
      </div>
    </div>
  )
}

export default withNamespaces()(TabSortProductDesktop)
