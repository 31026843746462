import { Grid, makeStyles } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useEffect, useState } from 'react'
import { withNamespaces } from 'react-i18next'

import 'react-multi-carousel/lib/styles.css'
import { useHistory } from 'react-router-dom'
import i18n from '../language/localization'
import { firstLetterUppercase } from '../../utils/helper'
import CardProduct from '../../components/card-product/CardProduct'
import { capitalizeFirstLetter } from '../../utils/helper'
import InfiniteScroll from 'react-infinite-scroll-component'

import { getProducts } from '../../api/products'

const ProductSlider = ({
  t,
  color,
  productsAllData,
  productsNewestData,
  productsDiscountData,
  productsBestSellerData,
  productsByCategoriesData
}) => {
  const theme = useTheme()
  const membershipData = JSON.parse(localStorage.getItem('membership'))
  const [membershipLevel, setMembershipLevel] = useState(1)
  const useStyles = makeStyles(theme => ({
    title_div_desktop: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '30px',
      clear: 'both',
      marginBottom: '0px',
      userSelect: 'none',
      marginTop: '1rem'
    },
    Title_div: {
      marginRight: '8px',
      marginLeft: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      height: '30px',
      clear: 'both',
      marginBottom: '0px',
      userSelect: 'none'
      // cursor:"pointer"
    },
    Title: {
      fontSize: '20px',
      fontWeight: 600,
      paddingLeft: '5px',
      // cursor:"pointer",
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: '19px',
        fontWeight: 500,
        paddingLeft: '5px'
      }
    },
    Para: {
      fontSize: '16px',
      paddingRight: '5px',
      color: color,
      cursor: 'pointer',
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px'
      }
    },
    Container1: {
      height: '100%',
      width: '43.5%',
      display: 'inline-block',
      userSelect: 'none',
      // [theme.breakpoints.down("xs")]: {
      //   height: "100%",
      //   width: "40%",
      //   display: "inline-block",
      //   userSelect: "none",
      // },

      '& .card': {
        // boxShadow: "0 0 1px",
        cursor: 'pointer'
        // border: "1px solid #00000038 !important",
      }
      // "& .card:hover": {
      //   border: "1px solid #ff3a59 !important",
      // },
    },

    desktop_slider_cards: {
      '& .card': {
        border: '1px solid transparent',
        transition: 'all 0.1s ease-out'
      },
      '& .card:hover': {
        border: '1px solid ' + color + ' !important',
        transform: 'translateY(-2px) scale(1.005) translateZ(0)',
        cursor: 'pointer'
      },
      '& .card-body': {
        padding: '5px'
        // minHeight: "80px",
      },
      '& .cardTitle ': {
        // textTransform: 'uppercase'
      }
    },

    mobile_slider_cards: {
      '& .card': {
        border: '1px solid transparent'
      },
      '& .card-body': {
        // minHeight: '117px',
        padding: '5px 2px !important'
      },

      '& .cardTitle ': {
        //  textTransform: 'uppercase',
        fontSize: '13px !important'
      },
      '& .TowLineText ': {
        height: '38px'
      }
    },

    // Name1: {
    //   fontSize: "14px",
    //   fontWeight: 400,
    //   whiteSpace: "break-spaces",
    //   marginBottom: "0.25px",
    //   cursor: "pointer",
    //   overflow: "hidden",
    //   maxHeight: "45px",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //     fontWeight: 400,
    //     marginBottom: "0.25px",
    //     maxHeight: "37px",
    //     // height: "35px"
    //   },
    // },
    // Price1: {
    //   fontSize: "14px",
    //   color: "red",
    //   marginBottom: "0px",
    //   cursor: "pointer",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //   },
    // },
    card_img: {
      position: 'relative',
      // height: "190px",
      width: '100%',
      userSelect: 'none',
      [theme.breakpoints.down('xs')]: {
        // height: "142px",
      }
    },
    div_bottom: {
      marginBottom: '50px',
      marginTop: '20px',
      userSelect: 'none',
      background: 'rgb(247, 247, 247)',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0px',
        marginTop: '0px'
      }
    },
    // realprice: {
    //   textDecoration: " line-through",
    //   color: "grey",
    //   fontSize: "14px",
    //   [theme.breakpoints.down("xs")]: {
    //     fontSize: "12px",
    //   },
    // }

    cardTitle: {
      '& .read-more-less--more': {
        display: 'none'
      }
    },

    slider_style: {
      '& .MuiButtonBase-root': {
        // boxShadow: "0 0 1px 0px ",
        padding: '4px',

        '& .MuiSvgIcon-root': {
          margin: '0 !important'
        }
      },
      '& .css-1abc02a:hover button,  .css-hn784z:hover button': {
        boxShadow: '0 0 6px 0px',
        backgroundColor: '#ffffff',
        opacity: '1 !important',
        color: '#000000'
      },

      '& .MuiIconButton-root:hover': {
        // backgroundColor: "#ff3a59",
        backgroundColor: '#ffffff',
        color: '#000000',
        boxShadow: '0 0 1px 0px '
      }
    },

    '@media(max-width: 768px)': {
      mobileTitle: {
        whiteSpace: 'break-spaces !important',
        marginBottom: '3px'
      }
    },

    price_parent: {
      display: 'flex',
      gap: '10px',
      alignItems: 'flex-end',
      overflow: 'hidden'
    }
  }))
  const classes = useStyles()
  const history = useHistory()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const themeColor = localStorage.getItem('themeColor')

  const getSingleProduct = e => {
    if (mobile) {
      history.push(`/productdetail?id=${e}`)
    } else {
      history.push(`/product-detail?${e}`)
    }
  }

  const proview = category => {
    localStorage.setItem('viewproid', category.id ? category.id : '')
    localStorage.setItem('viewproname', category.name || category.categoryName)
    localStorage.setItem('viewprochild', JSON.stringify(''))
    localStorage.setItem('viewtabs', JSON.stringify(category.name || category.categoryName))
    history.push('/products')
  }

  useEffect(() => {
    i18n.changeLanguage(JSON.parse(localStorage.getItem('Language')))
    if (membershipData) {
      setMembershipLevel(membershipData.level)
    }
  }, [])

  // pagination slider product list
  const [numberNextPage, setNumberNextPage] = useState([])

  const nextPageProductList = (id, productLength) => {
    const perPage = 5
    const startInitial = 0
    const endInitial = 5
    const findIndex = numberNextPage.findIndex(data => data.id === id)
    if (findIndex < 0) {
      setNumberNextPage(numberNextPage => [
        ...numberNextPage,
        {
          id: id,
          active: startInitial,
          next: endInitial
        }
      ])
    } else {
      const activeCalc =
        numberNextPage[findIndex]['next'] >= productLength
          ? 0
          : numberNextPage[findIndex]['active'] + perPage
      const endCalc =
        numberNextPage[findIndex]['next'] >= productLength
          ? 5
          : numberNextPage[findIndex]['next'] + perPage

      let updatePage = [...numberNextPage]
      updatePage[findIndex] = {
        id: id,
        active: activeCalc,
        next: endCalc
      }
      setNumberNextPage(updatePage)
    }
  }

  const prevPageProductList = (id, productLength) => {
    if (numberNextPage.length > 0) {
      const findIndex = numberNextPage.findIndex(data => data.id === id)
      const activeCalc =
        numberNextPage[findIndex]['active'] === 0 ? 0 : numberNextPage[findIndex]['active'] - 5
      const endCalc =
        numberNextPage[findIndex]['active'] === 0 ? 5 : numberNextPage[findIndex]['next'] - 5

      let updatePage = [...numberNextPage]
      updatePage[findIndex] = {
        id: id,
        active: activeCalc,
        next: endCalc
      }
      setNumberNextPage(updatePage)
    }
  }

  useEffect(() => {
    setItemsAllProducts(productsAllData)
  }, [productsAllData])

  const [itemsAllProducts, setItemsAllProducts] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const loadMoreAllProducts = async () => {
    if (page <= totalPage) {
      const nextPage = page + 1
      setPage(nextPage)
      const res = await getProducts(JSON.stringify({ page: nextPage, limit: 20 }))

      if (res.success) {
        const newItemsAllProducts = res.data || []
        setItemsAllProducts([...itemsAllProducts, ...newItemsAllProducts])
        setTotalPage(res.meta.total_pages)
      }
    }
  }

  return (
    <React.Fragment>
      {/* produk terbaru */}
      <React.Fragment>
        {productsNewestData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div>
              {(() => {
                let myArray = []
                myArray.push(
                  <div key={'newest'} style={{ paddingTop: '25px' }}>
                    {productsNewestData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>{firstLetterUppercase(t('new_arrivals'))}</h3>
                        <p
                          onClick={() => {
                            proview({ name: 'Produk Terbaru' })
                          }}
                          className={classes.Para}
                        >
                          {t('see_all')}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      style={{
                        width: '100vw',
                        maxWidth: '100vw',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'none',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'start'
                        // paddingBottom:
                        // productsNewestData[j].length > 0 && productsByCategoriesData[j].name !== "All product" ? "15px" : productsNewestData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = []

                        for (let j = 0; j < productsNewestData.length; j++) {
                          myArray1.push(
                            <div style={{ minWidth: '43.5%', width: '43.5%' }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsNewestData[j]}
                                desktopView={false}
                                width='100%'
                                heightWrapperImage='185px'
                                handleClick={getSingleProduct}
                              />
                            </div>
                          )
                        }
                        return myArray1
                      })()}
                    </div>
                  </div>
                )
                return myArray
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* produk diskon */}
      <React.Fragment>
        {productsDiscountData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div>
              {(() => {
                let myArray = []
                myArray.push(
                  <div key={'best_seller'} style={{ paddingTop: '30px' }}>
                    {productsDiscountData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>
                          {firstLetterUppercase(t('product_discount'))}
                        </h3>
                        <p
                          onClick={() => {
                            proview({ name: 'Discount Product' })
                          }}
                          className={classes.Para}
                        >
                          {t('see_all')}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      style={{
                        width: '100vw',
                        maxWidth: '100vw',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'none',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'start'
                        // paddingBottom:
                        // productsDiscountData[j].length > 0 && productsByCategoriesData[j].name !== "All product" ? "15px" : productsDiscountData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = []

                        for (let j = 0; j < productsDiscountData.length; j++) {
                          myArray1.push(
                            <div style={{ minWidth: '43.5%', width: '43.5%' }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsDiscountData[j]}
                                desktopView={false}
                                width='100%'
                                heightWrapperImage='185px'
                                handleClick={getSingleProduct}
                              />
                            </div>
                          )
                        }
                        return myArray1
                      })()}
                    </div>
                  </div>
                )
                return myArray
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* produk best seller */}
      <React.Fragment>
        {productsBestSellerData.length > 0 ? (
          <div className={classes.div_bottom}>
            <div>
              {(() => {
                let myArray = []
                myArray.push(
                  <div key={'best_seller'} style={{ paddingTop: '15px' }}>
                    {productsBestSellerData.length > 0 ? (
                      <div className={classes.Title_div}>
                        <h3 className={classes.Title}>{firstLetterUppercase(t('best_seller2'))}</h3>
                        <p
                          onClick={() => {
                            proview({ name: 'Best Seller' })
                          }}
                          className={classes.Para}
                        >
                          {t('see_all')}
                        </p>
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      style={{
                        width: '100vw',
                        maxWidth: '100vw',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        scrollbarWidth: 'none',
                        display: 'flex',
                        gap: '8px',
                        alignItems: 'start'
                        // paddingBottom:
                        // productsBestSellerData[j].length > 0 && productsByCategoriesData[j].name !== "All product" ? "15px" : productsBestSellerData[j].length > 0 && productsByCategoriesData[j].name == "All product" ? "55px" : "0px",
                      }}
                    >
                      {(() => {
                        let myArray1 = []

                        for (let j = 0; j < productsBestSellerData.length; j++) {
                          myArray1.push(
                            <div style={{ minWidth: '43.5%', width: '43.5%' }}>
                              <CardProduct
                                key={j}
                                membershipLevel={membershipLevel}
                                product={productsBestSellerData[j]}
                                desktopView={false}
                                width='100%'
                                heightWrapperImage='185px'
                                handleClick={getSingleProduct}
                              />
                            </div>
                          )
                        }
                        return myArray1
                      })()}
                    </div>
                  </div>
                )
                return myArray
              })()}
            </div>
          </div>
        ) : null}
      </React.Fragment>

      {/* product by category */}
      <React.Fragment>
        {productsByCategoriesData.length > 0 && (
          <div className={classes.div_bottom}>
            <div>
              {(() => {
                let myArray = []
                for (let i = 0; i < productsByCategoriesData.length; i++) {
                  myArray.push(
                    <div
                      key={i}
                      style={{ paddingTop: productsByCategoriesData[i].products.length && '15px' }}
                    >
                      {productsByCategoriesData[i].products.length > 0 ? (
                        <div className={classes.Title_div}>
                          <h3 className={classes.Title}>
                            {capitalizeFirstLetter(productsByCategoriesData[i].categoryName)}
                          </h3>
                          <p
                            onClick={() => {
                              proview(productsByCategoriesData[i])
                            }}
                            className={classes.Para}
                          >
                            {t('see_all')}
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        style={{
                          width: '100vw',
                          maxWidth: '100vw',
                          paddingRight: '8px',
                          paddingLeft: '8px',
                          overflow: 'auto',
                          whiteSpace: 'nowrap',
                          scrollbarWidth: 'none',
                          display: 'flex',
                          gap: '8px',
                          alignItems: 'start'
                          // paddingBottom:
                          // productsByCategoriesData[i].products.length > 0 && productsByCategoriesData[i].name !== "All product" ? "15px" : productsByCategoriesData[i].products.length > 0 && productsByCategoriesData[i].name == "All product" ? "55px" : "0px",
                        }}
                      >
                        {(() => {
                          let myArray1 = []

                          for (let j = 0; j < productsByCategoriesData[i].products.length; j++) {
                            myArray1.push(
                              <div style={{ minWidth: '43.5%', width: '43.5%' }}>
                                <CardProduct
                                  key={`${i}${j}`}
                                  membershipLevel={membershipLevel}
                                  product={productsByCategoriesData[i].products[j]}
                                  desktopView={false}
                                  width='100%'
                                  heightWrapperImage='185px'
                                  handleClick={getSingleProduct}
                                />
                              </div>
                            )
                          }
                          return myArray1
                        })()}
                      </div>
                    </div>
                  )
                }
                return myArray
              })()}
            </div>
          </div>
        )}
      </React.Fragment>

      {/* produk all */}
      <React.Fragment>
        {itemsAllProducts.length > 0 && (
          <div className={classes.div_bottom} style={{ paddingTop: '15px' }}>
            <div className={classes.Title_div}>
              <h3 className={classes.Title}>{t('all_products')}</h3>
              <p
                onClick={() => {
                  proview({ name: 'Semua Produk' })
                }}
                className={classes.Para}
              >
                {t('see_all')}
              </p>
            </div>

            <InfiniteScroll
              dataLength={itemsAllProducts.length}
              next={loadMoreAllProducts}
              hasMore={true}
              loader={
                <React.Fragment>
                  {page <= totalPage && (
                    <div style={{ height: '50px', width: '100%', marginTop: '10px' }}>
                      <div
                        style={{
                          color: themeColor,
                          textAlign: 'center'
                        }}
                      >
                        Loading...
                      </div>
                    </div>
                  )}
                </React.Fragment>
              }
            >
              <Grid container spacing={1} style={{ padding: '0 8px' }}>
                {itemsAllProducts.map(product => (
                  <Grid item xs={6} key={product.id}>
                    <CardProduct
                      membershipLevel={membershipLevel}
                      product={product}
                      desktopView={false}
                      width='100%'
                      heightWrapperImage='185px'
                      handleClick={getSingleProduct}
                    />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default withNamespaces()(ProductSlider)
