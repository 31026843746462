import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  body: {
    '& header': {
      // marginBottom: "20px",
    }
  },

  title: {
    fontSize: '18px',
    color: '#545454',
    fontWeight: '700',
    marginBottom: '10px'
  },

  header_table: {
    '& .heading': {
      padding: '28px 0',
      fontSize: '14px',
      color: '#545454',
      fontWeight: '600'
    }
  },

  bodyContent: {
    borderTop: '1px solid #EBEBEB',
    padding: '10px 0',

    '& .heading': {
      fontSize: '14px',
      color: '#545454',
      fontWeight: '600'
    },
    '& p': {
      fontSize: '14px',
      color: '#545454'
    },

    "& input[type='radio']": {
      accentColor: '#ff3a59',
      height: '20px',
      width: '20px',
      cursor: 'pointer'
    }
  },

  btn_default: {
    // "& .MuiButton-root": {
    fontSize: '14px',
    textTransform: 'capitalize',
    padding: '2px 8px !important',
    lineHeight: '20px !important',
    fontWeight: '500',
    maxWidth: '100%'
    // },
  },

  btns: {
    display: 'flex',
    gap: '5px',
    color: props => props.themeColor,
    border: 'none',
    alignItems: 'right',
    justifyContent: 'right'
  },
  btnsBottom: {
    display: 'flex',
    color: props => props.themeColor,
    border: 'none',
    justifyContent: 'right'
  },

  defaultSpan: {
    backgroundColor: '#00bfa5',
    color: '#FFF',
    fontSize: '14px',
    padding: '0px 5px',
    borderRadius: '4px'
  },

  select_style: {
    // marginBottom: "15px",
    // "& select": {
    display: 'block',
    width: '200px',
    // marginLeft: "34%",
    height: '40px',
    padding: '10px 10px',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '0.25rem',
    transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    // },
    '& .MuiInput-underline:before, .MuiInput-underline:after': {
      display: 'none'
    },
    '& input:focus': {
      boxShadow: 'none',
      border: props => '1px solid ' + props.themeColor
    }
  },

  inputsTop: {
    display: 'flex',
    gap: '15px',
    // marginLeft: 'auto'
    justifyContent: 'end'
  },

  sortBy: {
    fontSize: '12px',
    color: '#545454'
  },

  input_style: {
    '& input': {
      width: '150px'
    }
  }
})

export { useStyles }
