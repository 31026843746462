import moment from 'moment'

export const getCurrentDateTime = () => {
  return moment().format('YYY-MM-DD HH:mm:ss')
}

export const getPriceFormat = price => {
  return parseFloat(price).toLocaleString()
}

export const getIDFromPath = path => {
  const id = parseInt(path.substring(path.lastIndexOf('.') + 1))
  return id || null
}

export const priceFormatLocal = value => {
  value = value ? value : 0

  value.toString().length > 0
    ? value.toString().length <= 3
      ? (value = 'Rp ' + value.toString())
      : value.toString().length === 4
        ? (value = 'Rp ' + value.toString().substr(-4, 1) + '.' + value.toString().substr(-3, 3))
        : value.toString().length === 5
          ? (value = 'Rp ' + value.toString().substr(-5, 2) + '.' + value.toString().substr(-3, 3))
          : value.toString().length === 6
            ? (value =
                'Rp ' + value.toString().substr(-6, 3) + '.' + value.toString().substr(-3, 3))
            : value.toString().length === 7
              ? (value =
                  'Rp ' +
                  value.toString().substr(-7, 1) +
                  '.' +
                  value.toString().substr(-6, 3) +
                  '.' +
                  value.toString().substr(-3, 3))
              : value.toString().length === 8
                ? (value =
                    'Rp ' +
                    value.toString().substr(-8, 2) +
                    '.' +
                    value.toString().substr(-6, 3) +
                    '.' +
                    value.toString().substr(-3, 3))
                : value.toString().length === 9
                  ? (value =
                      'Rp ' +
                      value.toString().substr(-9, 3) +
                      '.' +
                      value.toString().substr(-6, 3) +
                      '.' +
                      value.toString().substr(-3, 3))
                  : (value = 'Rp 0')
    : (value = 'Rp 0')

  return value
}

export const getDiskonRupiah = data => {
  if (data.product.discount_type === 'nominal') {
    return data.product.discount
  } else {
    return (data.product.discount / 100) * data.cart_item.price
  }
}

export const getDiskonRupiahFromOrder = (data, memberShipLevel) => {
  if (data.product.discount_type === 'nominal') {
    return data.product.discount
  } else {
    return (data.product.discount / 100) * data.product.price[memberShipLevel]
  }
}

export const capitalizeFirstLetter = string => {
  const lower = string.toLowerCase()
  return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const firstLetterUppercase = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatDateTime = (dateStr, showSecond = false) => {
  return moment.utc(dateStr).format(showSecond ? 'DD-MM-YYYY HH:mm:ss' : 'DD-MM-YYYY HH:mm')
}

export const getCurrentPaymentMethod = order => {
  return order?.payment_method === 'CASH' ? order?.cod_payment?.name : order?.payment_method
}

export const getWeightProduct = orderItems => {
  const totalWeightPerProduct = orderItems.map(item => item.product.weight * item.quantity)
  return totalWeightPerProduct.reduce((prev, next) => prev + next, 0)
}

export const getFormatCurrency = value => {
  return new Intl.NumberFormat('id-ID').format(value)
}

export const orderGetPaymentMethod = order => {
  return order.payment_method === 'CASH' ? order.cod_payment.name : order.payment_method
}
