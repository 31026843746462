import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function DialogAlert(props) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Shopi Test'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{props.children}</DialogContentText>
        </DialogContent>
        {props.yesno == 'true' && (
          <DialogActions>
            <Button onClick={props.no} color='primary'>
              No
            </Button>
            <Button onClick={props.ok} color='primary' autoFocus>
              Yes
            </Button>
          </DialogActions>
        )}
        {props.ok == 'true' && (
          <DialogActions>
            <Button onClick={props.close} color='primary' autoFocus>
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}
