import { instance } from '../helper/api'

export const get = async () => {
  let resultData = []
  const axiosConfig = {
    url: '/v1/storefront/landing-page/',
    method: 'GET'
  }

  try {
    const results = await instance.request(axiosConfig)
    resultData = results.data.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else {
      return error.message
    }
  }

  return resultData
}
