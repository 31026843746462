import React from 'react'
import { Button } from '@material-ui/core'

const Demo = () => {
  return (
    <div>
      <h1>hello bhai aa ssr che</h1>
      <Button color='primary'>Hello World</Button>
    </div>
  )
}

export default Demo
